import {get} from "../../helpers/api";
import app from '../../main';
import clientGender from "../../config/clientGender";

const absData = {
    lastName: 'lastname',
    firstName: 'name',
    middleName: 'surname',
    status: 'cardStatus',
    clientCode: 'clientCode',
    inn: 'tin',
    pnfl: 'pinfl',
    birthDate: 'birthDate',
    birthPlace: 'birthPlace',
    gender: 'gender',
    citizenship: 'citizenship',
    docType: 'documentType',
    series: 'passSeries',
    number: 'documentNumber',
    docIssueDate: 'documentIssueDate',
    docExpireDate: 'documentExpireDate',
    docIssuePlace: 'documentIssuePlace',
    residenceAddress: 'address',
    phone: 'phone',
    mobilePhone: 'mobilePhone',
    email: 'mail',
    maritalStatus: 'materialStatus',
    codeFilial: 'branch',
    domicileKadastr: 'cadastreNumber',
};
const absDataSources = {
    gender: clientGender.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
    }, {})
};

/**
 * @typedef {string|number} bankClientId bank_client_id, который возвращается у карточки клиента банка
 */

class ABSCustomer {
    /**
     * @param {bankClientId} bankClientId
     */
    selectCustomer(bankClientId) {
        app.$store.commit('customer/selectAbsCustomer', bankClientId);
    }
    // Вроде async/await не настроен на проекте, потому вручную возвращаю промис
    /***
     * Получает abs данные о клиенте, если ранее выполнялось, то берет из кеша vuex
     * Если возвращает null, значит была ошибка в abs и данные не получены
     * @param {bankClientId} bankClientId
     * @return {Promise<null|{[key:string]:string}>}
     */
    getData(bankClientId) {
        const write = (data,result) => {
            app.$store.commit('customer/addAbsCustomer', {
                bankClientId,
                data
            });
            result(data);
        }
        return new Promise((resolve, reject) => {
            const customers = app.$store.state.customer.absCustomers;
            setTimeout(() => {
                if(bankClientId in customers) {
                    if(customers[bankClientId] === null) {
                        reject(null);
                    } else {
                        resolve(customers[bankClientId]);
                    }
                } else {
                    get('/aibs/get-customer/?clientUid=' + bankClientId)
                        .then(({data}) => {
                            if(data.success) {
                                write(this.#parseData(data.data), resolve);
                            } else {
                                write(null, reject);
                            }
                        })
                        .catch(e => {
                            reject(null);
                        });
                }
            }, 150);
        });
    }
    clearData() {
        app.$store.commit('customer/clearAbsCustomers');
    }
    #parseData(dataObject) {
        return Object.entries(absData).map(([key, name]) => {
            const value = dataObject[key];
            return {
                name,
                value: key in absDataSources ? absDataSources[key][value] : value || '-'
            }
        });
    }
}

export default new ABSCustomer();
