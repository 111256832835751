<template>
    <AppAccordion
        class="credit-calculator-accordion"
        :name="accordionName"
        icon="calculator"
    >
        <div class="edit-application">
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.amount")
                }}</AppText>
                <AppInputA
                    v-model="form.productAmount"
                    append
                    style="height: 48px !important; width: 100%"
                    :currency-text="$t('components.common.som')"
                    @input="onProductAmountChange"
                >
                    <template #append>
                        <div class="app-range__currency">
                            {{ $t("components.common.som") }}
                        </div>
                    </template>
                </AppInputA>
            </div>
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.loanAmount")
                }}</AppText>
                <AppRange
                    v-model="form.loan_summa"
                    step="1000"
                    :currency-text="$t('components.common.som')"
                    :max="1000000000"
                    :footer="['0', $t('components.common.oneBillion')]"
                    @input="onLoanSummaChange"
                />
            </div>
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.prepayment")
                }}</AppText>
                <AppRange
                    v-model="form.loan_deposit"
                    :currency-text="$t('components.common.percent')"
                    :min="0"
                    :max="100"
                    :footer="['0%', '100%']"
                    @input="onLoanDepositChange"
                />
            </div>
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.term")
                }}</AppText>
                <AppRange
                    v-model="form.loan_time"
                    :currency-text="$t('components.common.months')"
                    :min="1"
                    :max="60"
                    :footer="['1', '36', '48', '60']"
                />
            </div>
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.rate")
                }}</AppText>
                <AppRange
                    v-model="form.loan_percent"
                    :currency-text="$t('components.common.percent')"
                    :min="0"
                    :max="100"
                    :footer="['0%', '100%']"
                />
            </div>
            <div class="edit-application__item edit-application__item--medium">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.gracePeriod")
                }}</AppText>
                <AppInputA
                    v-model="form.loan_time_benefits"
                    append
                    style="height: 48px !important; width: 100%"
                    :currency-text="$t('components.common.som')"
                >
                    <template #append>
                        <div class="app-range__currency">
                            {{ $t("components.common.months") }}
                        </div>
                    </template>
                </AppInputA>
            </div>

            <div class="edit-application__item">
                <AppText type="input-title">{{
                    $t("components.homePage.creditCalculator.repaymentScheme")
                }}</AppText>

                <div class="edit-application__item-btns">
                    <AppButton
                        theme="primary-outline"
                        @click="
                            setRepaymentScheme(repaymentScheme.differential)
                        "
                    >
                        <AppIcon
                            class="edit-application__item-icon"
                            :class="{
                                active: isMatchesRepaymentScheme(
                                    repaymentScheme.differential
                                ),
                            }"
                            :icon="
                                setRepaymentIcon(repaymentScheme.differential)
                            "
                        />
                        {{
                            $t(
                                "components.homePage.creditCalculator.differential"
                            )
                        }}
                    </AppButton>
                    <AppButton
                        theme="white-outline"
                        @click="setRepaymentScheme(repaymentScheme.annuity)"
                    >
                        <AppIcon
                            class="edit-application__item-icon"
                            :class="{
                                active: isMatchesRepaymentScheme(
                                    repaymentScheme.annuity
                                ),
                            }"
                            :icon="setRepaymentIcon(repaymentScheme.annuity)"
                        />
                        {{ $t("components.homePage.creditCalculator.annuity") }}
                    </AppButton>
                </div>
            </div>
        </div>
        <template #footer-outside>
            <div class="credit-calculator-form">
                <div class="edit-application align-items-center">
                    <div
                        class="edit-application__item edit-application__item--medium"
                    >
                        <AppText type="input-title" theme="min" tag="span">
                            {{ $t("components.common.amountOfCredit") }}:
                        </AppText>
                        <AppText
                            type="calculations-result"
                            class="d-block"
                            theme="min"
                            tag="span"
                        >
                            {{ loanTotalAmount }}
                            {{ $t("components.common.som") }}
                        </AppText>
                    </div>
                    <div
                        class="edit-application__item edit-application__item--medium"
                    ></div>
                    <div
                        class="edit-application__item edit-application__item--medium"
                    >
                        <div class="block-wrap">
                            <div class="block-wrap__buttons">
                                <AppButton
                                    class="block-wrap__buttons-reset"
                                    type="button"
                                    square
                                    theme="white-outline"
                                    tabindex="-1"
                                    @click="emit('reset:form')"
                                >
                                    <AppIcon icon="delete" />
                                </AppButton>
                                <AppButton
                                    class="pl-6-a pr-6-a"
                                    type="button"
                                    theme="gray"
                                    tabindex="-1"
                                    @click="emit('cancel:form')"
                                >
                                    {{ $t("components.common.сancel") }}
                                </AppButton>
                                <AppButton type="button" @click="calculate">
                                    {{ $t("components.common.calculate") }}
                                </AppButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AppAccordion>
</template>
<script>
import AppAccordion from "../AppAccordion";
import AppRange from "../AppRange";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import AppInputA from "@/components/partials/AppInputA.vue";
import MoneyFormatter from "@/helpers/MoneyFormatter";

export default {
    name: "CreditCalculator",
    emits: ["cancel:form", "reset:form", "submit:form"],
    components: {
        AppInputA,
        AppText,
        AppIcon,
        AppButton,
        AppRange,
        AppAccordion,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        accordionName: {
            type: String,
            required: true,
            default: "",
        },
    },
    model: {
        prop: "value",
        event: "update:form",
    },
    computed: {
        repaymentScheme() {
            return {
                differential: 1,
                annuity: 2,
            };
        },
        form: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("update:form", value);
            },
        },
        moneyFormatter() {
            return new MoneyFormatter();
        },
        loanTotalAmount() {
            const total = Math.round(this.calculateLoanTotalAmount());
            return this.moneyFormatter.moneyFormat(total);
        },
    },
    methods: {
        emit(name, value) {
            this.$emit(name, value);
        },
        calculate() {
            if (this.$v.$invalid) {
                this.$store.commit(
                    "notifications/error",
                    "errors.incorrectEnteredFormValues"
                );
                return;
            }
            this.emit("submit:form", this.form);
        },
        setRepaymentScheme(type) {
            this.form.loan_calc_type = type;
        },
        isMatchesRepaymentScheme(type) {
            return this.form.loan_calc_type === type;
        },
        setRepaymentIcon(type) {
            return this.isMatchesRepaymentScheme(type)
                ? "radio-active"
                : "radio-not-active";
        },
        calculateLoanTotalAmount() {
            const productAmount = parseFloat(this.form.productAmount);
            const loanDeposit = parseFloat(this.form.loan_deposit);

            if (
                isNaN(productAmount) ||
                isNaN(loanDeposit) ||
                productAmount < 0 ||
                loanDeposit < 0 ||
                loanDeposit > 100
            ) {
                return 0;
            }

            const total = productAmount * (1 - loanDeposit / 100);

            if (isNaN(total)) {
                this.$store.commit(
                    "notifications/error",
                    "errors.incorrectIncomingData"
                );
                return 0;
            }

            return total;
        },
        calculateProductAmount() {
            const loanTotalAmount = parseFloat(this.form.loan_summa);
            const loanDeposit = parseFloat(this.form.loan_deposit);

            if (
                loanTotalAmount < 0 ||
                isNaN(loanTotalAmount) ||
                loanDeposit < 0 ||
                loanDeposit > 100
            ) {
                return 0;
            }

            const total = loanTotalAmount / (1 - loanDeposit / 100);

            if (isNaN(total)) {
                this.$store.commit(
                    "notifications/error",
                    "errors.incorrectIncomingData"
                );
                return 0;
            }

            return total;
        },
        onProductAmountChange() {
            this.form.loan_summa = Math.round(this.calculateLoanTotalAmount());
        },
        onLoanDepositChange() {
            this.form.loan_summa = Math.round(this.calculateLoanTotalAmount());
        },
        onLoanSummaChange() {
            this.form.productAmount = Math.round(this.calculateProductAmount());
        },
    },
    // watch: {
    //     "form.productAmount"() {
    //         this.form.loan_summa = Math.round(this.calculateLoanTotalAmount());
    //     },
    //     "form.loan_deposit"() {
    //         this.form.loan_summa = Math.round(this.calculateLoanTotalAmount());
    //     },
    //     "form.loan_summa"() {
    //         this.form.productAmount = Math.round(this.calculateProductAmount());
    //     },
    // },
    validations: {
        form: {
            productAmount: {
                required($val) {
                    return !!$val && $val >= 100;
                },
            },
            loan_time: {
                required($val) {
                    return !!$val && $val > 0;
                },
            },
            loan_percent: {
                required($val) {
                    return $val >= 0;
                },
            },
        },
    },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variables";
.credit-calculator-form {
    padding-left: var(--sides);
    padding-right: var(--sides);
}
.credit-calculator-accordion {
    --accordion-padding-body-bottom: 44px;
}
.credit-calculator-text {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 600;
    &__bold {
        font-weight: 700;
        margin-left: 12px;
    }
}
.edit-application {
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px;
    margin-top: -24px;
    &__item {
        width: calc(25% - 28px);
        margin-left: 28px;
        margin-top: 24px;
        &--half {
            width: calc(50% - 28px);
        }
        &--medium {
            width: calc(100% / 3 - 28px);
        }
        &--large {
            width: calc(75% - 28px);
        }
        &--full {
            width: calc(100% - 28px);
        }

        &-btns {
            display: flex;
            gap: 16px;
        }

        &-icon {
            margin-right: 12px;
            &:not([fill="none"]) {
                fill: none;
            }
            [stroke] {
                stroke: #cacaca;
            }
            .app-icon:not(.no-stroke) [fill]:not([fill="none"]) {
                fill: none;
            }

            &.active {
                &:not([fill="none"]) {
                    fill: #0e70eb;
                }
                [stroke] {
                    stroke: #ffffff;
                }
                .app-icon:not(.no-stroke) [fill]:not([fill="none"]) {
                    fill: #0e70eb;
                }
            }
        }
    }
}
</style>
