<template>
    <div class="chat-message__wrap">
        <div v-if="message.isEvent" class="chat-message__information">{{ message.body }}</div>
        <div v-else-if="message.body && message.body.success === false" class="user-identification-card" :class="['user-identification-card--theme-' + message.body.errorCode.toLowerCase()]">
            <div class="user-identification-card__avatar">
                <AppIcon :icon="message.body.errorCode === 'VALIDATION' ? 'error-traced' : 'anonymous-traced'"/>
            </div>
            <div class="user-identification-card__body">
                <div class="user-identification-card__item user-identification-card__item--large">
                    <div class="user-identification-card__name">{{ $t("components.common.cause") }}</div>
                    <div class="user-identification-card__value mb-4-a" v-html="errorMessage"></div>
                    <div class="user-identification-card__name">{{ $t("components.common.tryToAuthenticateYourUserAgain") }}</div>
                </div>
            </div>
            <div class="user-identification-card__footer">
                <div>{{ $t("components.common.identificationViaMyIdNotSuccessful") }}</div>
                <div>{{ formatTime(message.createdAt) }}</div>
            </div>
        </div>
        <div v-else-if="message.body && message.body.docData" class="user-identification-card">
            <div class="user-identification-card__avatar">
                <AppIcon icon="add-user"/>
            </div>
            <div class="user-identification-card__body">
                <div v-for="item in parsedUserIdentificationData" class="user-identification-card__item">
                    <div class="user-identification-card__name">{{ item.name }}</div>
                    <div class="user-identification-card__value">{{ $te(item.value) ? $t(item.value) : item.value }}</div>
                </div>
            </div>
            <div class="user-identification-card__footer">
                <div>{{ $t("components.common.identificationViaMyIdSuccessful") }}</div>
                <div>{{ formatTime(message.createdAt) }}</div>
            </div>
        </div>
        <template v-else>
            <div v-if="parsedMessage.images.length" class="chat-message__message">
                <div class="chat-message__image-cascade">
                    <div v-for="image in parsedMessage.images" class="chat-message__image-wrap">
                        <img :src="errorImages.includes(image) ? transparentPicture : image" alt="" class="chat-message__image" :class="{'chat-message__image--error': errorImages.includes(image)}"
                             @load="onImageLoaded(image)" @error="onImageNotLoaded(image)" @click="openGallery(image)">
                    </div>
                </div>
                <div class="chat-message__date">{{ formatTime(message.createdAt) }}</div>
            </div>
            <template v-for="(file, idx) in parsedMessage.files">
                <a :key="idx" :href="file.url" download target="_blank" class="chat-message__message">
                    <div class="chat-message__text chat-message__text--file">
                        <div class="chat-message__file-icon">
                            <AppIcon icon="file"/>
                        </div>
                        <div>
                            <div class="chat-message__file-name">{{ file.name }}</div>
                        </div>
                    </div>
                    <div class="chat-message__date">{{ formatTime(message.createdAt) }}</div>
                </a>
            </template>
            <div v-if="parsedMessage.files.length === 0 && parsedMessage.message" class="chat-message__message">
                <div class="chat-message__text">
                    <div v-html="parsedMessage.message"></div>
                </div>
                <div class="chat-message__date">{{ formatTime(message.createdAt) }}</div>
            </div>
        </template>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import {galleryController} from "../../../store/modules/gallery";
import clientGender from "../../../config/clientGender";
import {formatTime} from "../../../helpers/dates";

const errorCodeMessages = Object.freeze({
    VALIDATION: 'Не верный ответ от ГЦП - <br> пришли не все необходимые данные для идентификации',
    FRAUD: 'Мошенничество - <br> Попытка идентифицироваться, используя паспортные данные третьего лица.'
});

const transparentPicture = require('../../../assets/img/transparent.svg');

export default {
    name: 'ChatMessagesList',
    components: {AppIcon},
    props: {
        message: Object
    },
    data() {
        return {
            errorImages: [],
            transparentPicture
        }
    },
    computed: {
        errorMessage() {
            return errorCodeMessages[this.message.body.errorCode]
        },
        parsedUserIdentificationData() {
            const body = this.message.body.commonData || {};
            const docData = this.message.body.docData || {};
            return [
                {
                    name: 'Familiya /Surname',
                    value: body.lastName,
                },
                {
                    name: 'Ismi / Given name(s)',
                    value: body.firstName,
                },
                {
                    name: 'Jinsi / Sex',
                    value: clientGender.find(gender => gender.id == body.gender)?.name,
                },
                {
                    name: 'Tug`ilgan sanasi / Date of birth',
                    value: body.birthDate,
                },
                {
                    name: 'Passport raqami / Passport №',
                    value: docData.passData,
                },
                {
                    name: 'Shaxsiy raqami /ID number',
                    value: body.pinfl,
                },
                {
                    name: 'Fuqaroligi / Nationality',
                    value: body.nationality,
                },
                {
                    name: 'Berilgan sana / Date of issue ',
                    value: docData.issuedDate,
                },
                {
                    name: 'Amal qilish muddati / Expire',
                    value: docData.expiryDate,
                },

            ]
        },
        parsedMessage() {
            if(typeof this.message.body !== 'string') {
                return {
                    images: [],
                    files: [],
                    message: ''
                }
            }

            const imageRegex = /https?:\/\/[-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я]*\/([-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я]*\.(?:jpg|jpeg|png|gif|webp|svg))(?:\?(?:[-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я])*)?(?:\s|$)+/gmi;
            const fileRegex = /https?:\/\/[-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я]*\/([-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я]*\.[a-z0-9]+)(?:\?(?:[-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я])*)?(?:\s|$)+/gmi;
            const urlRegex = /https?:\/\/([-()a-zA-Z0-9@:%_\+.~?&#\/=а-яА-Я]*)/gmi;
            const phoneRegex = /\b(998|0)?[-\(\) ]?(\d{2})[-\(\) ]?(\d{3})[-\(\) ]?(\d{2})[-\(\) ]?(\d{2})\b/gmi;

            let messageBody = this.message.body;
            const raw = messageBody;
            const result = {
                files: [],
                images: [],
                raw
            };

            const images = imageRegex[Symbol.match](messageBody);
            if(images) {
                result.images = images.map(img => img.trim());
                messageBody = messageBody.replace(imageRegex, '');
            }

            messageBody = messageBody.replace(phoneRegex, '<span class="chat-message__clickable" data-phone="0$2$3$4$5">$&</span>');

            const files = result.files;
            fileRegex.lastIndex = 0;
            for(let file of fileRegex[Symbol.matchAll](messageBody)) {
                files.push({
                    name: file[1],
                    url: file[0]
                });
            }

            messageBody = messageBody.replace(urlRegex, '<a class="chat-message__clickable" href="$&" target="_blank">$&</a>');
            result.message = messageBody.trim();

            return result;
        }
    },
    methods: {
        formatTime,
        onImageLoaded(imageUrl) {
            this.$emit('image-load', imageUrl);
        },
        onImageNotLoaded(imageUrl) {
            this.errorImages.push(imageUrl)
        },
        openGallery(image) {
            if(!this.errorImages.includes(image)) {
                galleryController.open([image]);
            }
        },
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.user-identification-card {
    $self: &;
    background: #FFFFFF;
    border-radius: 18px 18px 18px 4px;
    display: flex;
    flex-wrap: wrap;
    max-width: 590px;
    &__avatar {
        margin: 24px 36px 24px 24px;
        width: 98px;
        height: 120px;
        background: #F4F4F4;
        border-radius: 8px;
        padding: 29px 26px;
        color: #CACACA;
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__body {
        width: calc(100% - 158px);
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 14px;
        padding: 24px 24px 0 0;
    }
    &__name {
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #4F4F4F;
        opacity: 0.7;
    }
    &__value {
        font-family: $font-secondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #4F4F4F;
        word-break: break-word;
    }
    &__item {
        margin-bottom: 10px;
        width: 50%;
        &:nth-child(7n) {
            width: 100%;
        }
        &--large {
            width: 100%;
        }
    }
    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: var(--footer-color, #00CC56);
        border-radius: 18px 18px 18px 4px;
        padding: 8px 24px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
    }
    &--theme-validation {
        --footer-color: #F2B600;
    }
    &--theme-fraud {
        --footer-color: #FF4921;
    }
}
</style>
