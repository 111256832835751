<template>
    <div class="chat-window-body" ref="window" @scroll="onScrollBody">
        <div v-if="messages.length">
            <div v-for="messagesSection in groupedMessages" :key="messagesSection.date">
                <div class="chat-message chat-message--date" :class="{'chat-message--hidden': messagesSection.date == activeDateItem && !activeDateVisible}" :data-date="messagesSection.date">
                    <div class="chat-message__information">
                        {{ getChatDate(messagesSection.date) }}
                    </div>
                </div>
                <div v-for="messagesBlock in messagesSection.userGroup" :key="messagesBlock.key"
                     class="chat-message"
                     :class="{
                        'chat-message--operator': !users[messagesBlock.nick].is_customer,
                        'chat-message--me': messagesBlock.nick === operatorName,
                        'chat-message--error': messagesBlock.isError
                    }"
                >
                    <div v-if="messagesBlock.date" class="chat-message__large">
                        <div class="chat-message__information">{{ getChatDate(messagesBlock.date) }}</div>
                    </div>

                    <template v-if="messagesBlock.nick !== operatorName">
                        <img v-if="messagesBlock.avatar" :src="messagesBlock.avatar" alt="" class="chat-message__avatar clickable-item" v-tooltip="users[messagesBlock.nick].userName">
                        <NoAvatar v-else-if="messagesBlock.userName" :user-name="users[messagesBlock.nick].userName" :key-name="users[messagesBlock.nick].is_customer ? noAvatarKey : ''"
                                  size="medium" class="chat-message__avatar clickable-item" v-tooltip="users[messagesBlock.nick].userName"/>
                    </template>

                    <div class="chat-message__list">
                        <div v-for="message in messagesBlock.messages" :key="message.id"
                             :class="{'chat-message__list-wrap--myid': message.myidRequest}"
                             class="chat-message__list-wrap"
                             ref="message" :data-date="message.createdAt.valueOf()" @click="onMessageClick" @contextmenu="onMessageContext">
                            <ChatMessagesList :message="message"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="chat-message">
            <div class="chat-message__information">
                {{ $t("common.noMessagesInChat") }}
            </div>
        </div>
    </div>
</template>
<script>
import filterDate from "../../../mixins/FilterDate";
import ListUtils from "../../../mixins/ListUtils";
import NoAvatar from "./NoAvatar";
import ChatMessagesList from "./ChatMessagesList";

export default {
    name: 'ChatWindowBody',
    components: {ChatMessagesList, NoAvatar},
    mixins: [ListUtils, filterDate],
    props: {
        messages: Array,
        noAvatarKey: String,
    },
    data() {
        return {
            errorImages: [],
            activeDateItem: null,
            activeDateVisible: false,
            activeDateTimer: null,
            users: {},
        }
    },
    computed: {
        operatorName() {
            return this.$store.state.auth.username;
        },
        messagesBody() {
            return this.$refs.window;
        },
        messageWrappers() {
            return this.$refs.message;
        },
        groupedMessages() {
            return Object.values(this.messages.reduce((acc, message) => {
                const key = typeof message.body === 'object' ? message.createdAt.valueOf() : message.createdAt.valueOf() + message.body.slice(0,3);
                const dateKey = message.createdAt.toLocaleDateString();
                let dateGroup = acc[dateKey];
                if(!dateGroup) {
                    dateGroup = {
                        date: message.createdAt.valueOf(),
                        userGroup: [
                            {
                                key,
                                nick: message.nick,
                                messages: []
                            }
                        ]
                    };
                    acc[dateKey] = dateGroup;
                }
                const userGroup = dateGroup.userGroup[dateGroup.userGroup.length - 1];

                if(!(message.nick in this.users)) {
                    this.$set(this.users, message.nick, {
                        is_customer: message.is_customer,
                        userName: message.userName,
                        nick: message.nick,
                        avatar: message.avatar,
                    });
                }

                if(userGroup.nick != message.nick) {
                    dateGroup.userGroup.push({
                        key,
                        nick: message.nick,
                        messages: [message]
                    });
                } else {
                    userGroup.messages.push(message);
                }
                return acc;
            }, {}));
        }
    },
    mounted() {
        this.detectActiveDateItem();
    },
    methods: {
        onMessageClick(e) {
            if(e.target.dataset.phone) {
                this.$emit('phone-click', e.target.dataset.phone.trim());
            }
        },
        onMessageContext(e) {
            if(e.target.dataset.phone) {
                window.getSelection().selectAllChildren(e.target);
            }
        },
        scrollDown(isFast) {
            return new Promise((resolve) => {
                if(!isFast) {
                    let speed = 15;
                    const scrollInterval = setInterval(() => {
                        this.messagesBody.scrollBy(0, speed);
                        speed *= 1.015;
                        requestAnimationFrame(() => {
                            if(this.messagesBody.scrollTop + this.messagesBody.offsetHeight === this.messagesBody.scrollHeight) {
                                resolve();
                                clearInterval(scrollInterval);
                            }
                        });
                    }, 4);
                } else {
                    this.messagesBody.scrollBy({
                        left: 0,
                        top: this.messagesBody.scrollHeight * 2,
                    });
                    this.$nextTick(resolve);
                }
            });
        },
        onScrollBody(e) {
            this.delay(() => {
                this.detectActiveDateItem();
                this.$emit('scroll', e);
            }, 13);
        },
        detectActiveDateItem() {
            if(this.activeDateTimer) {
                clearTimeout(this.activeDateTimer);
                this.activeDateTimer = null;
            }
            this.activeDateVisible = true;
            this.activeDateTimer = setTimeout(() => {
                this.activeDateVisible = false;
            }, 1000);

            const elem = document.elementFromPoint(this.$refs.window.getBoundingClientRect().left + Math.floor(this.$refs.window.offsetWidth / 2), this.$refs.window.getBoundingClientRect().top + 20);
            if(!elem) return;
            if(elem.classList.contains('chat-message--date') && this.$refs.window.scrollTop >= 50) {
                if(!this.activeDateItem || this.activeDateItem !== elem.dataset.date) {
                    this.activeDateItem = elem.dataset.date;
                }
            } else {
                this.activeDateItem = null;
            }
        },
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.search-wrap-a {
    $self: &;
    position: relative;
    z-index: 0;
    &__field {
        width: 392px;
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        height: 44px;
        border: 1px solid var(--color-border);
        background-color: var(--color-white);
        border-radius: 80px;
    }
    &__input {
        padding-right: 56px;
        height: 100%;
        border-radius: 80px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
        background: transparent;
        padding-left: 17px;
    }
    &__close {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 9px;
        right: 15px;
        background-color: transparent;
        border: 0;
        padding: 0;
        color: var(--color-gray-light);
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &-enter-active, &-leave-active {
        transition: width .25s, opacity .25s;
        transition-delay: .2s, 0s;
        #{$self}__close {
            transition: opacity .25s;
            transition-delay: .25s;
        }
    }
    &-leave-active {
        transition-delay: 0s, .25s;
    }
    &-leave-to,
    &-enter {
        opacity: 0;
        width: 44px;
        #{$self}__close {
            opacity: 0;
        }
    }
}
.smiles-menu {
    padding: 24px 18px;
    width: 478px;
    &__header {
        margin-bottom: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--color-black);
        opacity: 0.3;
    }
    &__body {
        overflow-x: auto;
        height: 147px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -6px;
        margin-top: -6px;
    }
    &__item {
        font-size: 18px;
        padding: 6px;
        width: 32px;
        height: 32px;
    }
}
.chat-actions {
    width: 266px;
    padding-top: 7px;
    padding-bottom: 7px;
    &__item {
        transition: color .25s;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        line-height: 19px;
        padding: 9px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: $font-secondary;
        svg {
            transition: color .25s;
            width: 24px;
            height: 24px;
            margin-left: 24px;
            color: var(--color-gray-lighten);
        }
        &:hover {
            color: var(--color-primary);
            svg {
                color: inherit;
            }
        }
    }
}
.action-menu {
    &-enter-active, &-leave-active {
        transition: transform .25s, opacity .25s;
        pointer-events: none;
    }
    &-enter, &-leave-to {
        transform: translateY(32px);
        opacity: 0;
    }
}
.footer-button {
    &-enter-active, &-leave-active {
        transition: transform .25s, opacity .25s;
        //z-index: -1;
    }
    &-enter, &-leave-to {
        transform: translateX(82px);
        opacity: 0;
    }
}
.chat-message {
    $self: &;
    transition: opacity .25s, transform .25s;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 24px;
    &__list {
        flex-grow: 1;
        width: calc(100% - 54px);
    }
    &__list-wrap {
        margin-bottom: 4px;
    }
    &__system-message {
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: right;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #717171;
    }
    &__avatar {
        width: 44px;
        height: 44px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 10px;
        position: sticky;
        bottom: 0px;
    }
    &__message {
        max-width: 80%;
        align-self: flex-start;
        position: relative;
        background: var(--color-white);
        border-radius: 18px 18px 18px 4px;
        overflow: hidden;
        font-family: $font-secondary;
        & + & {
            margin-top: 4px;
        }
    }
    &__wrap {
        margin-bottom: 4px;
        display: flex;
        flex-direction: column;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__information {
        background: var(--color-gray-lighten);
        opacity: 0.5;
        border-radius: 40px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-white);
        margin: 10px auto 10px;
        padding: 10px 20px;
    }
    &__large {
        display: flex;
        width: 100%;
    }
    &__image-wrap {
        flex-grow: 1;
        height: 240px;
        max-width: 100%;
        width: 50%;
        &:nth-child(3n) {
            width: 100%;
            height: 480px;
        }
    }
    &__image {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        background-color: #fff;
        &--error {
            cursor: default;
            background: #DEDFEE url('../../../assets/img/disabled-picture.svg') center/48px 48px no-repeat;
            min-width: 240px;
        }
    }
    &__image-cascade {
        display: flex;
        flex-wrap: wrap;
    }
    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        white-space: pre-wrap;
        word-break: break-word;
        padding: 9px 66px 10px 18px;
        &--file {
            display: flex;
            align-items: center;
            color: #454142;
            padding: 14px 88px 14px 18px;
        }
    }
    &__file-icon {
        background: rgba(#397AF5, 0.25);
        border-radius: 6px;
        width: 36px;
        height: 36px;
        margin-right: 16px;
        padding: 7px;
        color: #fff;
        svg {
            display: block;
            width: 22px;
            height: 22px;
        }
    }
    &__clickable {
        cursor: pointer;
        text-decoration: underline;
        color: var(--color-primary);
        &:hover {
            color: var(--color-primary);
            text-decoration: none;
        }
    }
    &__date {
        position: absolute;
        bottom: 8px;
        right: 28px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: var(--color-gray-lighten);
        mix-blend-mode: difference;
    }
    &__read {
        position: absolute;
        right: 8px;
        bottom: 10px;
        width: 17px;
        color: var(--color-gray-lighten);
    }
    &--date {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    &--hidden {
        opacity: 0;
        transform: translateY(-32px);
    }
    &--operator {
        #{$self}__message {
            background: var(--color-primary-dark);
            color: var(--color-white);
        }
        #{$self}__read {
            color: var(--color-primary-lighter-a);
        }
        #{$self}__date {
            color: var(--color-white);
            mix-blend-mode: normal;
        }
        #{$self}__clickable {
            color: inherit;
            &:hover {
                color: inherit;
            }
        }
    }
    &__list-wrap--myid,
    &--me {
        #{$self}__avatar {
            display: none;
        }
        #{$self}__message {
            background: var(--color-primary);
            border-radius: 18px 18px 4px 18px;
            align-self: flex-end;
        }
        #{$self}__text--file {
            color: #fff;
        }
        #{$self}__file-icon {
            background: rgba(#fff, 0.25);
        }
    }
    &__list-wrap--myid {
        #{$self}__message {
            background: linear-gradient(180deg, #3A79F3 0%, #2552C6 100%);
        }
        #{$self}__date,
        #{$self}__read {
            color: #8FBCFF;
            mix-blend-mode: normal;
        }
    }
    &--error {
        #{$self}__message {
            background: #FFEEEF;
            color: #FF4921;
        }
        #{$self}__date,
        #{$self}__read {
            color: #FF4921;
        }
        #{$self}__date {
            mix-blend-mode: normal;
        }
        #{$self}__clickable {
            color: inherit;
            &:hover {
                color: inherit;
            }
        }
    }
}
.chat-window-body {
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 20px 34px;
    position: relative;
}
.chat-window {
    $self: &;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    position: relative;
    background: linear-gradient(164.15deg, var(--color-white-bluesh) 11.06%, var(--color-white-bluesh-a) 100%);
    &__panel-fixed {
        z-index: 1;
        position: absolute;
        top: 76px;
        left: 0;
        width: 100%;
    }
    &__panel {
        display: flex;
        min-height: 32px;
        padding: 8px 48px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        font-family: $font-secondary;
        color: #717171;
        background-color: var(--color-white);
        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        &--error {
            background-color: var(--color-danger);
            color: var(--color-white);
        }
    }
    &__panel-tip {
        margin-left: auto;
    }

    &__header {
        display: flex;
        padding: 16px 34px 15px;
        border-bottom: 1px solid var(--color-border);
        background: var(--color-white);
        border-radius: 12px 12px 0px 0px;
    }
    &__user {
        flex-grow: 1;
    }
    &__edit-button {
        --height: 44px !important;
        padding-left: 16px !important;
        padding-right: 20px !important;
        border-radius: 50px !important;
    }
    &__controls {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    &__control {
        margin-left: 12px;
    }
    &__date {
        position: sticky;
        top: 0px;
        z-index: 1;
        margin-bottom: -59px;
    }
    &__footer {
        display: flex;
        align-items: center;
        padding: 15px 34px 16px;
        background: var(--color-white);
        border-radius: 0px 0px 12px 12px;
        position: relative;
        z-index: 4;
    }
    &__input-wrap {
        flex-grow: 1;
        position: relative;
        margin-right: 12px;
    }
    &__input {
        width: 100%;
        min-height: 44px !important;
        height: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        font-family: $font-secondary;
        padding-left: 18px;
        padding-right: 120px;
        border-radius: 12px;
        margin-bottom: 0 !important;
        resize: none;
        display: block;
        padding-top: 11px;
    }
    &__input-controls {
        position: absolute;
        right: 12px;
        bottom: 8px;
        display: flex;
        align-items: center;
    }
    &__input-control {
        margin-left: 2px;
        background-color: transparent;
        color: var(--color-white-darken);
        padding: 5px;
        svg {
            display: block;
            width: 20px;
            height: 20px;
        }
        &--active,
        &:hover {
            color: var(--color-primary);
        }
    }
    &__footer-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background: var(--color-white);
        z-index: 1;
        flex-shrink: 0;
        margin-top: -56px;
        margin-bottom: 12px;
        margin-left: calc(100% - 82px);
        position: relative;
        svg {
            width: 20px;
            height: 20px;
            color: var(--color-gray-lighten);
        }
    }
    &__footer-counter {
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -15px;
    }
    &__heading {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        svg {
            color: var(--color-gray-lighten);
            margin-right: 16px;
            width: 24px;
            height: 24px;
        }
    }
    &__menu-wrap {
        position: relative;
    }
    &__menu {
        position: absolute;
        bottom: calc(100% + 40px);
        right: -22px;
        z-index: 3;
        &--down {
            bottom: auto;
            top: calc(100% + 11px);
            right: 0;
        }
    }
    &--only-view {
        #{$self}__header {
            height: 85px;
        }
    }
}
</style>
