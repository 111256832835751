<template>
    <div class="auto-debit-card-stats-table">
        <div class="auto-debit-card-stats-table__header">
            <div class="auto-debit-card-stats-table__heading">
                {{ $t("common.autoDebitHistory") }}
            </div>
            <div
                v-if="dateRange"
                class="auto-debit-card-stats-table__header-actions"
            >
                <AppInput
                    class="auto-debit-card-stats-table__card"
                    size="extra-large"
                    placeholder="Поиск по номеру карты"
                    theme="gray"
                    :value="cardNumber"
                    :error="cardError"
                    @change="onCardNumberChange"
                >
                    <template #suffix>
                        <AppIcon icon="search" size="24" />
                    </template>
                </AppInput>
                <div class="auto-debit-card-stats-table__datepicker">
                    <AppDatePicker
                        :value="dateRange.start"
                        value-format="DD.MM.YYYY"
                        size="extra-large"
                        :allow-clear="false"
                        @change="onStartDateChange"
                    />
                    <span>-</span>
                    <AppDatePicker
                        :value="dateRange.end"
                        value-format="DD.MM.YYYY"
                        size="extra-large"
                        :allow-clear="false"
                        @change="onEndDateChange"
                    />
                </div>
                <div class="auto-debit-card-stats-table__submit">
                    <AppButton @click="onFilterSubmit">
                        {{ $t("components.common.apply") }}
                    </AppButton>
                </div>
            </div>
        </div>
        <AppTable
            class="auto-debit-card-stats-table__table"
            :class="tableCssClass"
            :head="tableHeaders"
            :data="tableItems"
            theme="admin-page"
        >
            <template #cell.date="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ item.payed_at | VMask("##.##.####") }}
                </span>
            </template>
            <template #cell.number="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ item.card_number | VMask("#### ##** **** ####") }}
                </span>
            </template>
            <template #cell.fullname="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ item.customer }}
                </span>
            </template>
            <template #cell.pinfl="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ item.customer_pinfl }}
                </span>
            </template>
            <template #cell.creditId="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span
                    v-else
                    :class="{
                        'auto-debit-card-stats-table__link': loanIds.includes(
                            item.loan_id
                        ),
                    }"
                    @click="onIdClick(item.loan_id)"
                >
                    {{ item.loan_id }}
                </span>
            </template>
            <template #cell.debitAmount="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ getFormattedMoney(item.payed_sum) }}
                </span>
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from "../partials/AppTable.vue";
import AppDatePicker from "../partials/AppDatePicker.vue";
import AppButton from "../partials/AppButton.vue";
import AppInput from "../partials/AppInput.vue";
import AppIcon from "../partials/AppIcon.vue"

export default {
    name: "AutoDebitCardStatsTable",

    components: {
        AppTable,
        AppDatePicker,
        AppButton,
        AppInput,
        AppIcon
    },

    props: {
        items: {
            type: Array,
            required: true,
        },

        loading: {
            type: Boolean,
        },

        dateRange: {
            type: Object,
        },

        cardNumber: {
            type: String,
        },

        loanIds: {
            type: Array,
        },

        isCall: {
            type: Boolean,
        },
    },

    data: () => ({
        cardError: false
    }),

    methods: {
        getFormattedMoney(val) {
            if (val) {
                return Intl.NumberFormat("ru", {
                    maximumFractionDigits: 0,
                }).format(val);
            }
            return "-";
        },

        onStartDateChange(_, val) {
            this.$emit("startDate", val);
        },

        onEndDateChange(_, val) {
            this.$emit("endDate", val);
        },

        onCardNumberChange(val) {
            if (typeof val === "string") {
                this.cardError = false
                this.$emit("card", val);
            }
        },

        onFilterSubmit() {
            if (this.cardNumber && this.cardNumber.length > 0) {
                this.cardError = false
                this.$emit("update");
            } else {
                this.cardError = true
            }
        },

        onIdClick(id) {
            if (this.loanIds.includes(item.loan_id)) {
                this.$emit("loanId", id);
            }
        },
    },

    computed: {
        tableHeaders() {
            return [
                [
                    {
                        name: "common.table.date",
                        key: "date",
                    },
                    {
                        name: "components.common.cardNumber",
                        key: "number",
                    },
                    {
                        name: "common.table.fio",
                        key: "fullname",
                    },
                    {
                        name: "common.pinfl",
                        key: "pinfl",
                    },
                    {
                        name: "common.table.creditId",
                        key: "creditId",
                    },
                    {
                        name: "common.table.debitAmount",
                        key: "debitAmount",
                    },
                ],
            ];
        },

        tableItems() {
            if (this.loading) {
                const list = [];
                for (let index = 0; index < 20; index++) {
                    list.push({ id: index });
                }
                return list;
            }

            return this.items;
        },

        tableCssClass() {
            return {
                "call-height": this.isCall,
            };
        },
    },
};
</script>

<style lang="scss">
.auto-debit-card-stats-table__header {
    margin-bottom: 24px;
}

.auto-debit-card-stats-table__heading {
    font-size: 28px;
    font-weight: 500;
}

.auto-debit-card-stats-table__header-actions {
    margin-top: 24px;

    display: flex;
    align-items: center;
    gap: 24px;
}

.auto-debit-card-stats-table__card {
    width: 368px;

    input {
        height: 100%;
        background: transparent;
    }

    .ant-input-suffix {
        width: 24px;
        left: unset !important;
    }
}

.auto-debit-card-stats-table__datepicker {
    display: flex;
    align-items: center;
    gap: 12px;

    .app-datepicker {
        width: 278px;
    }
}

.auto-debit-card-stats-table__link {
    color: var(--color-primary);
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}

.auto-debit-card-stats-table__table {
    background: white;
    height: calc(100vh - 479px);

    &.call-height {
        height: calc(100vh - 570px);
    }

    th {
        background: #EDF2FF;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
    }
}
</style>
