import {get} from "../helpers/api";
import Vue from "vue";
import {EventEmitter} from "./ChatController";

function flattenOptions(arr, collect) {
    arr.forEach(item => {
        collect.push(item);
        if (item.children && item.children.length) {
            flattenOptions(item.children, collect)
        }
    });
}

class TreeProblemOptions extends EventEmitter {
    _options = []

    constructor() {
        super(['update']);
    }

    get list() {
        return this._options;
    }

    set list(list) {
        Vue.set(this, '_options', list);
    }

    get flatOptions() {
        let collect = [];
        flattenOptions(this.list, collect);
        return collect;
    }

    loadOptions(id) {
        this.list = [];
        let url = "/products/";

        if (id !== undefined) url += `type/${id}`

        return get(url)
            .then((response) => {

                if (response.data.success) {
                    this.list = response.data.data;
                    this.emit('update');
                    return response.data;
                }
                return null;
            });
    }
}

export default TreeProblemOptions;
