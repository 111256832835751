import {
    BaseChatCommand,
    getCustomerByPhone,
    initCustomers,
    setSearchMessage,
    useUpdateChat   
} from "../customerHelpers";
import {get} from "@/helpers/api";

export class SearchCustomerByPhoneCommand extends BaseChatCommand {
    execute(phone) {
        getCustomerByPhone(phone, this.chatData);
    }
}
export class SearchCustomer extends BaseChatCommand {
    execute({type, query}) {
        get(`/customers/search-customer?where=${type}&query=${query.replaceAll(' ', '')}`)
            .then(r => {
                if (r.data.success && r.data.data.length) {
                    initCustomers(r.data.data, this.chatData);
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                setSearchMessage({message: 'Клиент не найден', type: 'error'}, this.chatData);
            })
    }
}
