<template>
    <div class="auto-calculator">
        <div class="auto-calculator__form-col">
            <div class="auto-calculator__title product-title mb-10-a">
                <AppIcon class="product-title__icon" icon="folder-close" size="24"/>
                {{ $t("components.common.preScoringKia") }}
            </div>
            <div class="edit-application mb-13-a">
                <div class="edit-application__item edit-application__item--half">
                    <AppText type="input-title"> {{ $t("components.common.clientsSalary") }}</AppText>
                    <AppRange v-model="model.b" class="w-100" step="1000" :currency-text="$t('components.common.som')" :max="1000000000" :footer="['0', $t('components.common.oneBillion')]"/>
                </div>
                <div class="edit-application__item edit-application__item--half">
                    <AppText type="input-title">{{ $t("components.common.creditLoad") }}</AppText>
                    <AppRange v-model="model.b" class="w-100" step="1000" :currency-text="$t('components.common.som')" :max="1000000000" :footer="['0', $t('components.common.oneBillion')]"/>
                </div>
            </div>
            <hr class="separator mb-6-a">
            <div class="block-wrap mb-13-a">
                <AppButton class="mr-auto" type="button" square theme="white-outline" tabindex="-1">
                    <AppIcon icon="delete"/>
                </AppButton>
                <AppButton class="pl-6-a pr-6-a" type="button" theme="success-flat" tabindex="-1">
                    {{ $t("components.common.transferToCalculator") }}
                </AppButton>
                <AppButton type="button">
                    {{ $t("components.common.calculate") }}
                </AppButton>
            </div>
            <div class="edit-application">
                <AutoResultTitle class="edit-application__item" :title="$t('components.common.carCost')" value="490 378 378 сум"/>
                <AutoResultTitle class="edit-application__item" :title="$t('components.common.anInitialFee')" value="50% - 245 189 189 сум"/>
                <AutoResultTitle class="edit-application__item edit-application__item--half" :title="$t('components.common.duration')" value="1 год"/>
                <AutoResultTitle class="edit-application__item" :title="$t('components.common.annuity')" value="0%"/>
                <AutoResultTitle class="edit-application__item" :title="$t('components.common.differential')" value="0%"/>
            </div>
        </div>
        <AutoResult class="auto-calculator__auto-col"/>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import AppRange from "../AppRange";
import AppButton from "../AppButton";
import AutoResult from "./AutoResult";
import AppText from "../AppText";
import AutoResultTitle from "./AutoResultTitle";
export default {
    name: 'AutoPrescoring',
    components: {AutoResultTitle, AppText, AutoResult, AppButton, AppRange, AppIcon},
    data() {
        return {
            model: {
                a: 'h',
                b: 0
            },
            items: [
                {
                    name: 'Hello',
                    value: 'h'
                },
                {
                    name: 'World',
                    value: 'w'
                }
            ]
        }
    }
}
</script>
