<template>
    <div>
        <AppButton v-if="isReset" type="button" square theme="white-outline" @click="emit('reset:form')" tabindex="-1">
            <AppIcon icon="delete"/>
        </AppButton>
        <AppButton
            v-if="isCancel && isEdit"
            class="admin-application-filter__button admin-application-filter__item"
            theme="gray"
            @click="emit('cancel:form')"
        >
            {{ $t("common.cancel") }}
        </AppButton>
        <AppButton
            v-if="isSubmit"
            class="admin-application-filter__button admin-application-filter__item"
            :theme="isEdit ? '' : 'success-flat'"
            @click="onSubmitClick"
        >
            {{ isEdit ? $t("common.save") : $t("common.saved") }}
        </AppButton>
    </div>
</template>

<script>

import AppButton from "@/components/partials/AppButton.vue";
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    name: "CreateAction",
    emits: ["reset:form", "cancel:form", "submit:form"],
    props: {
      isEdit: {
        type: Boolean,
        required: true,
        default: false
      },
      actions: {
        type: Array,
        required: true,
        default: () => ['reset', 'cancel', 'submit']
      }
    },
    components: {AppIcon, AppButton},
    computed: {
        isReset() {
            return this.actions.includes('reset');
        },
        isCancel() {
            return this.actions.includes('cancel');
        },
        isSubmit() {
            return this.actions.includes('submit');
        },
    },
    methods: {
        emit(event) {
            this.$emit(event);
        },

        onSubmitClick() {
            if (this.isEdit) {
                this.emit('submit:form')
            }
        }
    }
}
</script>
