<template>
    <component :is="tag" :class="[textType, margin]" :style="{ color }"><slot/></component>
</template>
<script>
const textColors = {
    'gray-1': '#717171',
    'gray-2': '#949494',
    'gray-3': '#ADADAD',
    'dark-1': '#212121',
    'dark-2': '#3C3C3C',
    'dark-3': '#454142',
    'min': '#000',
    'max': '#fff',
    'primary-1': '#397AF5',
    'primary-2': '#2552C6',
    'none': ''
};

const fontSpacer = 2;
function getTextType(size, lineHeight, weight, family, spacing = '000') {
    return `fnsz-${size/fontSpacer}-a lh-${lineHeight/fontSpacer}-a fw-${weight}-a font-${family} lts-${spacing}`;
}

const textTypes = {
    'auto-title': getTextType(14, 20, 500, 'secondary', '005'),
    'auto-value': getTextType(16, 20, 600, 'secondary', '005'),
    'auto-value-bold': getTextType(16, 20, 700, 'secondary', '005'),
    'input-title': getTextType(16, 20, 500, 'secondary', '005'),
    'auto-price': getTextType(20, 20, 700, 'secondary', '005'),
    'calculations-info': getTextType(16, 20, 500, 'secondary'),
    'calculations-result': getTextType(18, 20, 700, 'secondary'),
    'product-info-description': getTextType(16, 24, 700, 'secondary', '005'),
    'product-info': getTextType(16, 24, 500, 'secondary', '005'),
    'product-info-value': getTextType(16, 24, 600, 'secondary', '005'),
};
const textTypesDefaultColor = {
    'input-title': 'dark-2',
    'product-info': 'gray-1',
    'calculations-info': 'gray-1',
    'calculations-result': 'min',
}
const textMargin = {
    'input-title': 'mb-2-a'
}

export default {
    name: 'AppText',
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        theme: {
            type: String,
            validate(value) {
                return value in textColors;
            }
        },
        type: {
            type: String,
            required: true,
            validate(value) {
                return value in textTypes;
            }
        },
        defaultMargin: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        textType() {
            return textTypes[this.type];
        },
        color() {
            const color = this.theme || textTypesDefaultColor[this.type] || 'dark-1';
            return textColors[color];
        },
        margin() {
            if(!this.defaultMargin) return '';
            return textMargin[this.type] || '';
        }
    }
}
</script>
