<template>
    <AdminCard class="no-user-select" :theme="isChat ? 'client' : 'problem'" :title="isChat ? 'components.common.allClientRequests' : 'components.common.openAppeal'" :body-empty="isChat" :icon="isChat ? 'user-add-outline' : null">
        <template #actions>
            <AppButton :theme="isChat ? 'blueish-a' : 'primary-outline'" :size="isChat ? 'medium-b' : null" @click="$emit('create')">
                {{ $t("components.common.newAppeal") }}
            </AppButton>
        </template>
        <template #default="{classNames}">
            <AppTable :class="classNames.large" :head="problemsHead" :data="problems" :theme="isChat ? 'problem-chat' : 'problem'" @row-click="filterProblem($event.item.id)">
                <template #cell.id="{item}">{{ item.id }}</template>
                <template #cell.type="{item}">{{ getProblemName(item.type_id) }}</template>
                <template #cell.agent="{item}">{{ item.operator.first_name + "  " + item.operator.last_name }}</template>
                <template #cell.description="{item}">{{ stripDescription(item.description) }}</template>
                <template #cell.status="{item}">
                    <ProblemTag :status="item.status"/>
                </template>
                <template #cell.date="{item}">{{ formatDateMoment(item.created_at) }}</template>
            </AppTable>
        </template>
    </AdminCard>
</template>

<script>
import {mapState} from "vuex";
import ListUtils from "../mixins/ListUtils";
import AdminCard from "./partials/AdminCard";
import AppTable from "./partials/AppTable";
import AppButton from "./partials/AppButton";
import FilterDate from "../mixins/FilterDate";
import ProblemTag from "./partials/ProblemTag";
import AppIcon from "./partials/AppIcon";

export default {
    mixins: [ListUtils, FilterDate],
    components: {AppIcon, ProblemTag, AppButton, AppTable, AdminCard},
    props: {
        problems: Array,
        isChat: Boolean
    },
    data() {
        return {
        };
    },
    methods: {
        filterProblem(id) {
            this.$emit('select-problem', id);
        },
        getProblemName(type_id) {
            let needType = this.dictionary.filter((item) => {
                return item.id === type_id;
            });
            return needType[0].title ? needType[0].title : '';
        },
    },
    computed: {
        ...mapState({
            dictionary: state => state.dictionary,
        }),
        problemsHead() {
            let arr = [[
                {
                    name: 'common.table.number',
                    key: 'id',
                    width: '169px'
                },
                {
                    name: 'common.table.theme',
                    key: 'products_name',
                },
                {
                    name: 'components.common.typeOfAppeal',
                    key: 'type',
                },
                {
                    name: 'common.table.operator',
                    key: 'agent',
                },
                {
                    name: 'components.common.aComment',
                    key: 'description',
                },
                {
                    name: 'common.table.status',
                    key: 'status',
                    width: '152px'
                },
                {
                    name: 'components.common.addTime',
                    key: 'date',
                    width: '360px'
                },
            ]]
            if(this.isChat) {
                arr = [[
                    {
                        name: 'common.time',
                        key: 'date',
                        width: '10%',
                    },
                    {
                        name: 'common.table.type',
                        key: 'type',
                        width: '10%',
                    },
                    {
                        name: 'common.table.theme',
                        key: 'products_name',
                    },
                    {
                        name: 'common.table.status',
                        key: 'status',
                        width: '186px'
                    },
                ]]
            }
            return arr;
        },
    },
};
</script>
