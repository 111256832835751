<template>
    <div>
        <div class="block-wrap block-wrap--offset-small">
            <div v-for="option in localizedThemes" class="popular-theme" :class="{'popular-theme--active': value.includes(option.id)}" @click="onInput(option.id)">{{ option.title }}</div>
        </div>
    </div>
</template>
<script>
import PopularProblemOptions from "../../../services/PopularProblemOptions";

export default {
    name: 'PopularProblemOptionsComponent',
    props: {
        popularOptions: PopularProblemOptions,
        value: Array
    },
    computed: {
        localizedThemes() {
            return this.popularOptions.getList();
        }
    },
    methods: {
        onInput(id) {
            const optionsModel = [...this.value];
            if(optionsModel.includes(id)) {
                optionsModel.splice(optionsModel.findIndex(optionId => optionId === id), 1);
            } else {
                optionsModel.push(id);
            }
            this.$emit('input', optionsModel);
            this.$emit('change', id);
        }
    }
}
</script>
