var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-tabs",class:{
        'app-tabs--leave': _vm.isLeave,
        'app-tabs--enter': _vm.isEnter,
        ['app-tabs--theme-' + _vm.theme]: _vm.theme
}},[_c('div',{staticClass:"app-tabs__header",class:{'app-tabs__header--sliced-width': _vm.sliceWidth}},[_vm._l((_vm.items),function(item,idx){return _c('div',{key:item[_vm.itemValue],staticClass:"app-tabs__head",class:{
                'app-tabs__head--active': item[_vm.itemValue] === _vm.activeKey,
                'app-tabs__head--disabled': _vm.disabled
            },on:{"click":function($event){return _vm.select(item[_vm.itemValue], idx)}}},[_vm._v(" "+_vm._s(_vm.$t(item[_vm.itemName]))+" ")])}),(_vm.enableOperations)?_c('AppSelect',{staticClass:"app-tabs__operations",class:{'app-tabs__operations--readonly': _vm.disabled},attrs:{"items":_vm.operationsItems,"title":_vm.$t('components.operations.title'),"item-value":"name","size":"large","text":""},on:{"input":_vm.selectOperation},scopedSlots:_vm._u([{key:"item-name",fn:function({item}){return [_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":item.icon}}),_c('span',[_vm._v(_vm._s(_vm.$t("components.operations." + item.name)))])]}}],null,false,1453860699)}):_vm._e()],2),_c('div',{ref:"body",staticClass:"app-tabs__body"},[_c('div',{ref:"tab",staticClass:"app-tabs__tab"},[_c('keep-alive',{attrs:{"include":_vm.keepInclude}},[_vm._t('body.' + _vm.activeBody)],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }