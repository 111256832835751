<template>
    <div class="monitoring-page" v-loading="loading">
        <NoClientMonitoring v-if="noCustomerData && !isCustomerLoading" @submit="getCustomerDataByPhone"/>
        <AppTabs
            v-else
            v-model="activeTab"
            :items="tabs"
            theme="loan"
            @change="onTabChange"
            :enable-operations="customerData.userId"
            @select-operation="selectOperation"
            :disabled="isCustomerLoading"
            slice-width
        >
            <template #body.devices>
                <div>
                    <div class="product-info-heading">{{ $t("components.common.aboutClient") }}</div>
                    <ProductInfoTable
                        key="a1"
                        :parser="aboutClientParser"
                        :data="[{}]"
                        :loading="isCustomerLoading"
                        :length="1"
                    >
                        <template #slot.card="{item}">
                            <div v-if="item" class="product-info-card">
                                <div class="product-info-card__logo">
                                    <img :src="item.logo" alt="">
                                </div>
                                {{ (item.typeName || '-') | capitalize }}
                            </div>
                        </template>
                        <template #slot.button="{item}">
                            <AppButton v-if="item" class="product-info-table__button" theme="primary-outline" @click="selectCard(item)">{{ $t("components.common.moreDetails") }}</AppButton>
                        </template>
                    </ProductInfoTable>
                    <div class="product-info-heading" style="margin-top: 32px;">{{ $t("components.common.clientDevices") }}</div>
                    <ProductInfoTable key="a" class="mb-8-a" :data="customerData && customerData.devices" empty-name="components.common.userDevices" :parser="devicesParser" :loading="isCustomerLoading"/>

                    <template v-if="isExtraData">
                        <div class="product-info-heading">{{ $t("components.common.savedPaymentss") }}</div>
                        <ProductInfoTable key="b" class="mb-8-a" :data="customerData && customerData.favorites" empty-name="components.common.savedPayments" :parser="favoritesParser" :loading="isCustomerLoading" type="phone-small"/>
                    </template>

                    <template v-if="isExtraData">
                        <div class="product-info-heading">{{ $t("components.common.autoP2pTransfers") }}</div>
                        <ProductInfoTable key="c" class="mb-8-a" :data="customerData && customerData.autoP2Ps" empty-name="components.common.savedAutoP2pTransfers" :parser="autoP2PParser" :loading="isCustomerLoading" type="phone-small">
                            <template #slot.arrow>
                                <div class="d-flex align-items-center justify-content-center">
                                    <AppIcon class="ml-auto mr-auto color-gray flex-shrink-0" icon="chevron-right" size="16"/>
                                </div>
                            </template>
                        </ProductInfoTable>
                    </template>

                    <template v-if="isExtraData">
                        <div class="product-info-heading">{{ $t("components.common.autoConversion") }}</div>
                        <ProductInfoTable key="d" :data="customerData && customerData.autoConversions" empty-name="components.common.savedAutoConcerts" :parser="autoConversionsParser" :loading="isCustomerLoading" type="phone-small">
                            <template #slot.arrow>
                                <div class="d-flex align-items-center justify-content-center">
                                    <AppIcon class="ml-auto mr-auto color-gray flex-shrink-0" icon="chevron-right" size="16"/>
                                </div>
                            </template>
                        </ProductInfoTable>
                    </template>
                </div>
            </template>
            <template #body.cards>
                <div>
                    <div class="product-info-heading">{{ $t("components.common.customerCards") }}</div>
                    <ProductInfoTable key="e" :parser="cardsParser" :data="cardsList" :loading="isCustomerLoading" empty-name="components.common.kart">
                        <template #slot.card="{item}">
                            <div v-if="item" class="product-info-card">
                                <div class="product-info-card__logo">
                                    <img :src="item.logo" alt="">
                                </div>
                                {{ (item.typeName || '-') | capitalize }}
                            </div>
                        </template>
                        <template #slot.button="{item}">
                            <AppButton v-if="item" class="product-info-table__button" theme="primary-outline" @click="selectCard(item)">{{ $t("components.common.moreDetails") }}</AppButton>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
            <template #body.transaction-details>
                <div>
                    <admin-card
                        :title="$t('components.transaction-details.data')"
                        class="mb-4"
                    >
                        <template #header>
                            <div class="m-auto"></div>
                            <search-form
                                :items="searchTransactionItems"
                                @submit="getTransactionDetails"
                                v-model="searchTransactionModel"
                                type="id"
                                disable-categories
                            />
                        </template>
                        <div v-if="transactionDetails.loading || !transactionDetails.fullFiled || !transactionDetails.info.transactions?.length"
                             class="d-flex flex-wrap">
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.card-number")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.card-holder")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.amount")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.created-at")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.status")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.type")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else />
                            </div>
                        </div>
                        <div
                            v-else
                            v-for="(transaction, index) in transactionDetails.info.transactions"
                            class="d-flex flex-wrap"
                        >
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.card-number")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{transaction.cardNumber}}</text-empty>
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.card-holder")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{ transaction.username }}</text-empty>
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.amount")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{transaction.amount}}</text-empty>
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.created-at")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{transaction.created_at}}</text-empty>
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.status")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{transaction.status}}</text-empty>
                            </div>
                            <div class="w-50 mb-3">
                                <p class="color-gray mb-2">
                                    {{$t("components.transaction-details.type")}}
                                </p>
                                <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                <text-empty v-else>{{transaction.type}}</text-empty>
                            </div>
                            <hr class="transaction-details__separator" v-if="transactionDetails.info.transactions[index + 1]">
                        </div>
                    </admin-card>

                    <transition name="opacity">
                        <admin-card
                            :title="$t('components.transaction-details.order-info')"
                            v-show="transactionDetails.fullFiled"
                            class="mb-4"
                        >
                            <div class="d-flex flex-wrap" v-if="transactionDetails.info.request">
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.card-number")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.cardNumber}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.loan-type")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{ transactionDetails.info.request.loanType }}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.loan-amount")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.loanAmount}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.max-loan-amount")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.maxLoanAmount}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.loan-duration")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{ transactionDetails.info.request.loanDuration }}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.status")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.status}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.pinfl")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.pnfl}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.source")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.request.source}}</text-empty>
                                </div>
                            </div>
                        </admin-card>
                    </transition>

                    <transition name="opacity">
                        <div v-show="transactionDetails.fullFiled">
                            <admin-card
                                v-if="transactionDetails.info.request && transactionDetails.info.request?.scoring?.length"
                                :title="$t('components.transaction-details.scoring-info')"
                                class="mb-4"
                            >
                                <div
                                    v-for="(scoringItem, index) in transactionDetails.info.request.scoring"
                                    class="d-flex flex-wrap"
                                >
                                    <div class="d-flex w-100">
                                        <div style="flex-basis: 33%">
                                            <p class="color-gray mb-2">
                                                {{$t("components.transaction-details.title")}}
                                            </p>
                                            <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                            <text-empty v-else>{{scoringItem.title}}</text-empty>
                                        </div>
                                        <div style="flex-basis: 33%" >
                                            <p class="color-gray mb-2">
                                                {{$t("components.transaction-details.scoring-code")}}
                                            </p>
                                            <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                            <text-empty v-else>{{scoringItem.code}}</text-empty>
                                        </div>
                                        <div style="flex-basis: 33%">
                                            <p class="color-gray mb-2">
                                                {{$t("components.transaction-details.score")}}
                                            </p>
                                            <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                            <text-empty v-else>{{scoringItem.score}}</text-empty>
                                        </div>
                                    </div>

                                    <hr class="transaction-details__separator" v-if="transactionDetails.info.request.scoring[index + 1]">
                                </div>
                            </admin-card>
                        </div>

                    </transition>

                    <transition name="opacity">
                        <admin-card
                            :title="$t('components.transaction-details.delivery-info')"
                            v-show="transactionDetails.fullFiled"
                            class="mb-4"
                        >
                            <div class="d-flex flex-wrap" v-if="transactionDetails.info.delivery">
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.delivery-username")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.delivery.username}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.phone")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.delivery.userphone}}</text-empty>
                                </div>

                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.address")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.delivery.address}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.coordinates")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.delivery.add_address}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.delivery-date")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{transactionDetails.info.delivery.delivery_date}}</text-empty>
                                </div>
                                <div class="w-50 mb-3">
                                    <p class="color-gray mb-2">
                                        {{$t("components.transaction-details.delivery-company")}}
                                    </p>
                                    <div v-if="transactionDetails.loading" class="placeholder placeholder--medium" />
                                    <text-empty v-else>{{ transactionDetails.info.delivery.delivery_company }}</text-empty>
                                </div>

                            </div>
                        </admin-card>
                    </transition>
                </div>

            </template>
            <template #body.history>
                <div>
                    <div class="d-flex justify-content-between align-items-center mb-4-a">
                        <div class="product-info-heading mb-0">{{ $t("components.common.loanApplicationHistory") }} </div>
                        <AppButton class="product-info-button" @click="modals.filter = true">
                            <AppIcon class="mr-2-a" icon="filter" size="18"/>
                            {{ $t("components.common.filter") }}
                        </AppButton>
                    </div>

                    <ProductInfoTable key="f" :parser="customerLoansParser" :additional-parser="additionalCustomerLoansParser" :data="customerLoansList" empty-name="components.common.loanApplications" :loading="isCreditLoading" type="history">
                        <template #slot.button="{item}">
                            <AppButton square size="small" :theme="isCreditOpened(item) ? 'primary-outline-a' : null" @click="toggleCreditRequest(item)">
                                <AppIcon class="product-info-icon" :class="{'product-info-icon--active': isCreditOpened(item)}" icon="chevron-down" size="16"/>
                            </AppButton>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
            <template #body.transactions>
                <TransactionsMobilePage :user-id="customerData.userId" :cards-list="cardsList" :is-chat="isChat" :common-data="commonData"/>
            </template>
            <template #body.identifications>
                <div>
                    <div class="d-flex justify-content-between align-items-center mb-4-a">
                        <div class="product-info-heading mb-0">{{ $t("components.common.myIdIdentificationHistory") }}</div>
                    </div>
                    <ProductInfoTable key="g" :parser="identificationParser" :additional-parser="additionalIdentificationParser" :data="identificationsList" empty-name="components.common.myIdIdentificationRequests" :loading="isIdentificationLoading" type="history">
                        <template #slot.button="{item}">
                            <AppButton square size="small" :theme="isCreditOpened(item) ? 'primary-outline-a' : null" @click="toggleCreditRequest(item)">
                                <AppIcon class="product-info-icon" :class="{'product-info-icon--active': isCreditOpened(item)}" icon="chevron-down" size="16"/>
                            </AppButton>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
            <template #body.hash>
                <div class="d-flex">
                    <admin-card
                        class="w-50"
                        :title="$t('components.card-hash.get-card-hash')"
                        v-loading="cardHashIn.loading"
                    >
                        <label for="hash-11" class="mb-2">{{$t("components.card-hash.card-number")}}</label>
                        <AppInput v-model="cardHashIn.in" id="hash-11" size="large" class="mb-4"/>
                        <label for="hash-12" class="mb-2">{{$t("components.card-hash.card-number-hash")}}</label>
                        <AppInput v-model="cardHashIn.out" id="hash-12" size="large" class="mb-4" readonly>
                            <template #addonAfter>
                                <button class="monitoring-page__hash-copy" @click="copyToClipBoard(cardHashIn.out)">
                                    <AppIcon icon="copy"/>
                                </button>
                            </template>
                        </AppInput>

                        <div class="d-flex">
                            <div class="m-auto" />

                            <AppButton
                                type="button"
                                square
                                theme="white-outline-narrow"
                                class="mr-3"
                                v-tooltip="$t('components.card-hash.clear')"
                                @click="clearHashIn"
                            >
                                <AppIcon icon="delete"/>
                            </AppButton>

                            <AppButton
                                type="button"
                                theme="blue-outline-narrow"
                                @click="getCardHash"
                            >
                                {{$t("components.card-hash.calc")}}
                            </AppButton>
                        </div>
                    </admin-card>
                    <admin-card
                        class="w-50"
                        :title="$t('components.card-hash.decode-card-hash')"
                        v-loading="cardHashOut.loading"
                    >
                        <label for="hash-21" class="mb-2">{{$t("components.card-hash.card-number-hash")}}</label>
                        <AppInput v-model="cardHashOut.in" id="hash-21" size="large" class="mb-4" />
                        <label for="hash-22" class="mb-2">{{$t("components.card-hash.card-number")}}</label>
                        <AppInput v-model="cardHashOut.out" id="hash-22" size="large" class="mb-4" readonly>
                            <template #addonAfter>
                                <button class="monitoring-page__hash-copy" @click="copyToClipBoard(cardHashOut.out)">
                                    <AppIcon icon="copy"/>
                                </button>
                            </template>
                        </AppInput>

                        <div class="d-flex">
                            <div class="m-auto" />

                            <AppButton
                                type="button"
                                square
                                theme="white-outline-narrow"
                                class="mr-3"
                                v-tooltip="$t('components.card-hash.clear')"
                                @click="clearHashOut"
                            >
                                <AppIcon icon="delete"/>
                            </AppButton>

                            <AppButton
                                type="button"
                                theme="blue-outline-narrow"
                                @click="getCardNumber"
                            >
                                {{$t("components.card-hash.calc")}}
                            </AppButton>
                        </div>
                    </admin-card>
                </div>
            </template>
            <template #body.check>
                <NoClientCompareData v-if="!compareDataLoading && !compareDataSuccess" />
                <admin-card
                    v-else
                    class="w-50"
                    :title="$t('components.check-data.title')"
                >
                    <template #header>
                        <div class="m-auto"></div>
                        <span class="color-gray">{{$t("components.check-data.compare-percent")}}: <span class="color-success">{{compareDataForm.percent}}%</span></span>
                    </template>
                    <div class="d-flex flex-wrap">
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.passport")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.passport_serial}} {{compareDataForm.passport_number}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.passport-abs")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{ compareDataForm.iabs.passport_serial }} {{ compareDataForm.iabs.passport_number }}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.uzcard-card-holder")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.svgate_customer}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.humo-card-holder")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.humo_customer}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.nibbd")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.nibbd.first_name}} {{compareDataForm.nibbd.last_name}} {{compareDataForm.nibbd.birthdate}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.last-check-date")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.created_at}}</text-empty>
                        </div>
                    </div>
                </admin-card>
            </template>
            <template #body.clientInfo>
                <admin-card
                    :title="$t('components.check-data.title')"
                >
                    <div class="d-flex flex-wrap">
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("common.fio")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.passport_serial}} {{compareDataForm.passport_number}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.passport-abs")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{ compareDataForm.iabs.passport_serial }} {{ compareDataForm.iabs.passport_number }}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.uzcard-card-holder")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.svgate_customer}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.humo-card-holder")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.humo_customer}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.nibbd")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.nibbd.first_name}} {{compareDataForm.nibbd.last_name}} {{compareDataForm.nibbd.birthdate}}</text-empty>
                        </div>
                        <div class="w-50 mb-3">
                            <p class="color-gray mb-2">
                                {{$t("components.check-data.last-check-date")}}
                            </p>
                            <div v-if="compareDataLoading" class="placeholder placeholder--medium" />
                            <text-empty v-else>{{compareDataForm.created_at}}</text-empty>
                        </div>
                    </div>
                </admin-card>
            </template>
        </AppTabs>
        <div v-if="isFoundByPhone && !noCustomerData" class="monitoring-page__search">
            <div class="monitoring-page__search-name">{{ $t("components.common.clientSearch") }}</div>
            <PhoneIdentification :default-value="commonData.phone" id="a" ref="phone-identification" :loading="isCustomerLoading"
                                 @submit="getCustomerDataByPhone" @blur="phoneFocused = false" @focus="phoneFocused = true" @close="phoneSearch = false" />
        </div>
        <CardModal v-if="modals.card" short :user-data="userData" :is-chat="isChat" :common-data="commonData" :card="selectedCard" @close-modal="modals.card = false"/>
        <MonitoringFilterModal v-if="modals.filter" v-model="loanFilter" @submit="getCustomerLoan" @close="modals.filter = false"/>
    </div>
</template>
<script>
import AppTabs from "./partials/AppTabs";
import AppButton from "./partials/AppButton";
import AppIcon from "./partials/AppIcon";
import filterDate from "../mixins/FilterDate";
import {get, post} from "../helpers/api";
import {getCardLogo, NO_VENDOR} from "../config/logos";
import {formatCardNumber} from "../helpers/numbers";
import ListUtils from "../mixins/ListUtils";
import ProductInfoTable from "./partials/pages/monitoring/ProductInfoTable";
import PhoneIdentification from "./partials/pages/PhoneIdentification";
import {capitalize} from "../helpers/strings";
import NoClientMonitoring from "./partials/pages/monitoring/NoClientMonitoring";
import AdminCard from "@/components/partials/AdminCard.vue";
import AppInput from "@/components/partials/AppInput.vue";
import TextEmpty from "@/components/shared/text-empty/text-empty.component.vue";
import ConfirmController from "@/services/ConfirmController";
import NoClientCompareData from "@/components/partials/pages/monitoring/NoClientCompareData.vue";
import SearchForm from "@/components/partials/SearchForm.vue";

const MonitoringFilterModal = () => import("./modals/MonitoringFilterModal");
const TransactionsMobilePage = () => import("./partials/pages/monitoring/TransactionsMobilePage");
const CardModal = () => import("./CardModal");

const formatDate = (date) => {
    return date.toJSON().slice(0,10);
}

class CardModel {
    constructor() {
        this.in = null;
        this.out = null;
        this.loading = false;
    }
}

class PassportInfo {
    constructor() {
        this.passport_number = null;
        this.passport_serial = null;
    }
}
class NIBBDInfo {
    constructor() {
        this.birthdate = null;
        this.last_name = null;
        this.first_name = null;
    }
}
class CompareDataForm extends PassportInfo {
    constructor() {
        super()
        this.percent = 0;
        this.svgate_customer = null;
        this.humo_customer = null;
        this.iabs = new PassportInfo();
        this.nibbd = new NIBBDInfo();
        this.created_at = null;
    }
}

export default {
    name: 'MonitoringProducts',
    components: {
        SearchForm,
        NoClientCompareData,
        TextEmpty,
        AppInput,
        AdminCard,
        NoClientMonitoring,
        PhoneIdentification,
        TransactionsMobilePage,
        ProductInfoTable,
        MonitoringFilterModal,
        CardModal,
        AppIcon,
        AppButton,
        AppTabs
    },
    mixins: [ListUtils],
    filters: {
        capitalize
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        clientFormInfo: {
            type: Object,
            default: () => ({})
        },
        commonData: {
            type: Object,
            required: true
        },
        isChat: Boolean,
        cardStat: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeTab: 'devices',
            tabs: [
                {
                    name: 'components.common.aboutClient',
                    key: 'devices'
                },
                {
                    name: 'components.common.cards',
                    key: 'cards'
                },
                {
                    name: 'components.common.transaction-details',
                    key: 'transaction-details'
                },
                {
                    name: 'components.common.transactions',
                    key: 'transactions'
                },
                {
                    name: 'components.common.creditRequests',
                    key: 'history'
                },
                {
                    name: 'components.common.myIdHistory',
                    key: 'identifications'
                },
                {
                    name: 'components.common.card-hash',
                    key: 'hash'
                },
                {
                    name: 'components.common.check-data',
                    key: 'check'
                }
            ],

            creditRequestList: [],
            creditRequestOpened: [],
            loanFilter: {
                dateFrom: '',
                dateTo: '',
            },

            customerData: {},
            noCustomerData: true,
            isCustomerLoading: false,

            modals: {
                card: false,
                filter: false
            },

            selectedCard: null,
            isCreditLoading: false,

            customerRequestPromise: null,
            creditRequestFulfilled: false,

            phoneSearch: false,
            phoneFocused: false,

            isIdentificationLoading: false,
            identificationsList: [],
            identificationFulfilled: false,

            cardHashIn: new CardModel(),
            cardHashOut: new CardModel(),

            compareDataLoading: false,
            compareDataForm: new CompareDataForm(),

            loading: false,
            isFoundByPhone: false,
            compareDataSuccess: false,
            compareDataFulfilled: false,

            searchTransactionModel: '',
            transactionDetails: {
                info: {},
                loading: false,
                fullFiled: false
            }
        }
    },
    computed: {
        cardsList() {
            let cards = [];

            try {
                cards = this.customerData.cards.map(card => {
                    const obj = {
                        cardId: card.cardId,
                        state: card.status.toLowerCase(),
                        stateName: card.status === 'Active' ? this.$t('components.common.activeCard') : this.$t('components.common.blockedCard'),
                        cardHolder: card.cardHolder,
                        cardNumber: card.pan,
                        logo: getCardLogo(card.processing),
                        cardNumberMasked: formatCardNumber(card.pan)
                    }
                    if(card.processing) {
                        obj.typeName = card.processing[0].toLowerCase() + card.processing.slice(1);
                        obj.type = card.processing.toUpperCase();
                    } else {
                        obj.type = NO_VENDOR;
                        obj.typeName = this.$t('components.common.unknownCardStatus');
                    }
                    return obj;
                })
            } catch(e) {
                console.log(e);
            } finally {
                return cards;
            }
        },
        customerLoansList() {
            let loans = [];

            try {
                loans = this.creditRequestList.map(loan => {
                    return {
                        ...loan,
                        theme: loan.status ? 'success' : 'danger',
                        amountName: this.formatNumber(loan.amount / 100, true) + ' сум'
                    }
                })
            } catch(e) {
                console.log(e);
            } finally {
                return loans;
            }
        },
        searchTransactionItems() {
            return [{
                short: "components.transaction-details.id",
                value: 'id',
                placeholder: "----------",
                mask: '####################'
            }]
        },
        isExtraData() {
            if (process.env.VUE_APP_MOBILE_HIDE_EXT_BLOCK === "true") {
                return true
            }
            return false
        }
    },
    created() {
        this.compareDataLoading = true;

        if ((this.userData && this.userData.bank_client_id) || (this.clientFormInfo && this.clientFormInfo.bank_client_id)) {
            this.getCustomerDataByCode();
        } else {
            this.getCustomerDataByPhone(this.commonData.phone.replace(/\s/g, ''));
        }
    },
    methods: {
        onTabChange(key) {
            if(key === 'history' && !this.creditRequestFulfilled) {
                this.getCustomerLoan();
            } else if(key === 'identifications' && !this.identificationFulfilled) {
                this.getIdentificationsHistory();
            } else if (key === 'check' && !this.compareDataFulfilled) {
                this.compareDataLoading = true;
                this.compareData()
            }
        },
        toggleCreditRequest(item) {
            if(this.isCreditOpened(item)) {
                this.creditRequestOpened.splice(this.creditRequestOpened.indexOf(item), 1);
            } else {
                this.creditRequestOpened.push(item);
            }
        },
        isCreditOpened(item) {
            return this.creditRequestOpened.includes(item);
        },
        getCustomerDataByPhone(phone) {
            this.isCustomerLoading = true;
            this.customerRequestPromise = get('/asaka-mobile/customer-by-phone/' + phone)
                .then(({data}) => {
                    this.noCustomerData = !(data.success && data.data.users);
                    if(data.success && data.data.users) {
                        this.customerData = data.data.users;
                        this.getCustomerLoan();
                    }
                })
                .catch(e => {
                    this.$store.commit('notifications/error', 'Не удалось получить список устройств клиента')
                })
                .finally(() => {
                    this.isCustomerLoading = false;
                    this.isFoundByPhone = true;
                })
        },
        getCustomerDataByCode() {
            this.isCustomerLoading = true;

            let bankClientId = this.userData.bank_client_id

            if (this.clientFormInfo && this.clientFormInfo.bank_client_id) {
                bankClientId = this.clientFormInfo.bank_client_id
            }

            this.customerRequestPromise = get('/asaka-mobile/customer/' + bankClientId)
                .then(({data}) => {
                    this.noCustomerData = !(data.success && data.data.users);
                    if(data.success && data.data.users) {
                        this.customerData = data.data.users;
                        this.isCustomerLoading = false;
                    } else {
                        this.getCustomerDataByPhone(this.commonData.phone);
                    }
                })
                .catch(e => {
                    this.$store.commit('notifications/error', 'Не удалось получить список устройств клиента')
                    this.isCustomerLoading = false;
                });
        },
        getCustomerLoan() {
            this.isCreditLoading = true;
            this.$set(this.modals, 'filter', false);

            this.customerRequestPromise.then(() => {
                let str = `/asaka-mobile/loan-request?userId=${this.customerData.userId}`;
                if(this.loanFilter.dateFrom) {
                    str += `&dateFrom=${this.loanFilter.dateFrom}&dateTo=${this.loanFilter.dateTo}`;
                }
                return get(str)
                    .then(({data}) => {
                        if(data.success) {
                            this.creditRequestList = data.data;
                        }
                    })
                    .catch(e => {
                        this.$store.commit('notifications/error', 'errors.failedToFetchCreditList')
                    })
                    .finally(() => {
                        this.creditRequestFulfilled = true;
                        this.isCreditLoading = false;
                    })
            })
        },
        getIdentificationsHistory() {
            this.isIdentificationLoading = true;

            this.customerRequestPromise.then(() => {
                return get(`/asaka-mobile/identification-log/${this.customerData.userId}`)
                    .then(({data}) => {
                        if(data.success) {
                            this.identificationsList = data.data;
                        }
                    })
                    .catch(e => {
                        this.$store.commit('notifications/error', 'Не удалось получить историю идентификации MyID')
                    })
                    .finally(() => {
                        this.identificationFulfilled = true;
                        this.isIdentificationLoading = false;
                    })
            })
        },

        selectCard(card) {
            this.selectedCard = card;
            this.$set(this.modals, 'card', true);
        },
        getPeriod(period, value) {
            if(period === 'Month') {
                return this.$t("components.common.everyDayOnMonth", { value })
            } else if(period === 'Week') {
                --value; // value начинается с 1, а отсчет в массиве с 0
                const days = [ this.$t("common.daysOfWeek.monday"), this.$t("common.daysOfWeek.tuesday"), this.$t("common.daysOfWeek.wednesday"), this.$t("common.daysOfWeek.thursday"), this.$t("common.daysOfWeek.friday"), this.$t("common.daysOfWeek.saturday"), this.$t("common.daysOfWeek.sunday") ];
                let word = this.$t("common.every1");
                if(days[value].endsWith('у')) {
                    word = this.$t("common.every2");
                } else if(days[value].endsWith('е')) {
                    word = this.$t("common.every1");
                }
                return word + ' ' + days[value];
            }
            return value + ' ' + period;
        },

        devicesParser(item) {
            const identificationType = this.customerData?.bankInformation?.identificationType;
            const fDate = filterDate.methods.parsedDateAndTimeFromString;

            return [
                {
                    type: 'simple',
                    value: '📱 ' + item.name,
                    placeholderType: 'medium-a',
                    width: "350px"
                },
                {
                    name: 'components.common.identificationType',
                    icon: 'user-check',
                    value: identificationType,
                    width: '225px'
                },
                {
                    name: 'components.common.operationSystem',
                    icon: 'device',
                    value: item.os,
                    width: '256px'
                },
                {
                    name: 'components.common.dateAndTimeSuccessAuth',
                    icon: 'time',
                    value: fDate(new Date(item.lastLoginTime * 1000).toString()),
                }
            ]
        },
        aboutClientParser() {
            const identified = this.customerData?.indetified;
            const fullName = `${this.customerData?.lastName} ${this.customerData?.firstName}`
            const phoneNumber = this.customerData?.userPhone ?? "-"

            return [
                {
                    name: 'common.table.fio',
                    icon: 'user-circle-outline',
                    value: fullName,
                    width: "350px"
                },
                {
                    name: 'components.common.phoneNumber',
                    icon: 'control-phone',
                    value: phoneNumber,
                    width: '225px'
                },
                {
                    name: 'components.common.identificationSign',
                    icon: 'identification',
                    value: this.$t("components.common." + (identified ? 'yes' : 'no')),
                    theme: identified ? 'success' : 'danger',
                },
            ]
        },
        favoritesParser(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: idx + 1,
                    width: '102px',
                    placeholderType: 'micro'
                },
                {
                    name: 'components.common.paymentName',
                    value: item.name,
                    placeholderType: 'large'
                },
                {
                    name: 'components.common.amount',
                    value: this.formatNumber(item.amount / 100, true),
                    width: '247px'
                },
                {
                    name: 'components.common.autoPay',
                    value: item.autoPay ? 'common.yes' : 'common.no',
                    theme: item.autoPay ? 'success' : 'danger',
                    width: '240px'
                }
            ];
        },
        autoP2PParser(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: idx + 1,
                    width: '102px',
                    placeholderType: 'micro'
                },
                {
                    name: 'components.common.sender',
                    value: item.senderAccount?.cardHolder,
                },
                {
                    name: 'components.common.cardNumber',
                    value: formatCardNumber(item.senderAccount?.cardNumber),
                    width: '10px'
                },
                {
                    type: 'slot',
                    value: 'arrow',
                    width: 'min(163px,11vw)',
                },
                {
                    name: 'components.common.cardOwner',
                    value: item.receiverAccount?.cardHolder,
                },
                {
                    name: 'components.common.cardNumber',
                    value: formatCardNumber(item.receiverAccount?.cardNumber),
                },
                {
                    name: 'components.common.amount',
                    value: this.formatNumber(item.transferAmount / 100, true) + ' ' + this.getCurrency(item.currency?.code, true),
                    placeholderType: 'small'
                },
                {
                    name: 'components.common.period',
                    value: this.getPeriod(item.period, item.periodValue),
                    width: '10px'
                }
            ]
        },
        autoConversionsParser(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: idx + 1,
                    width: '102px',
                    placeholderType: 'micro'
                },
                {
                    name: 'components.common.sender',
                    value: item.senderAccount?.cardHolder,
                },
                {
                    name: 'components.common.cardNumber',
                    value: formatCardNumber(item.senderAccount?.cardNumber),
                    width: '10px'
                },
                {
                    type: 'slot',
                    value: 'arrow',
                    width: 'min(163px,11vw)',
                },
                {
                    name: 'components.common.cardOwner',
                    value: item.receiverAccount?.cardHolder,
                },
                {
                    name: 'components.common.cardNumber',
                    value: formatCardNumber(item.receiverAccount?.cardNumber),
                },
                {
                    name: 'components.common.amount',
                    value: this.formatNumber(item.amount / 100, true),
                    placeholderType: 'small'
                },
                {
                    name: 'components.common.conversionDirect',
                    value: item.conversionType,
                    placeholderType: 'small'
                },
                {
                    name: 'components.common.period',
                    value: this.getPeriod(item.period, item.periodValue),
                    width: '10px'
                }
            ]
        },
        cardsParser(card) {
            return [
                {
                    type: 'slot',
                    value: 'card',
                    placeholderType: 'medium-a'
                },
                {
                    name: 'components.common.cardNumber',
                    value: card.cardNumberMasked,
                    width: '259px',
                    icon: 'credit-card-front',
                },
                {
                    name: 'components.common.cardStatus',
                    value: card.stateName,
                    width: '238px',
                    icon: 'pulse',
                    theme: card.state === 'active' ? 'success' : 'danger',
                },
                {
                    name: 'components.common.owner',
                    value: card.cardHolder,
                    width: '440px',
                    icon: 'user-circle-outline'
                },
                {
                    type: 'slot',
                    value: 'button',
                    width: '10px',
                    placeholderType: 'button'
                }
            ]
        },
        customerLoansParser(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    placeholderType: 'micro',
                    width: '102px',
                    value: idx + 1
                },
                {
                    name: 'components.common.creditName',
                    width: 'auto',
                    value: 'Кредит'
                },
                {
                    name: 'components.common.dateAndTime',
                    width: '277px',
                    value: item.registeredAt
                },
                {
                    name: 'components.common.amountOfCredit',
                    width: '258px',
                    value: item.amountName
                },
                {
                    name: 'components.common.signOfSuccess',
                    width: '235px',
                    value: this.$t("components.common.loan-" + (item.status ? 'issued' : 'denied')),
                    theme: item.status ? 'success' : 'danger'
                },
                {
                    type: 'slot',
                    value: 'button',
                    placeholderType: 'card-icon',
                    width: '10px'
                }
            ]
        },
        additionalCustomerLoansParser(item) {
            if(this.isCreditOpened(item)) {
                return [
                    {
                        type: 'simple',
                    },
                    {
                        colspan: 6,
                        name: 'components.common.note',
                        normalWrap: true,
                        value: '№' + item.requestId + ' - ' + item.message,
                    }
                ]
            }
        },
        identificationParser(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    placeholderType: 'micro',
                    width: '102px',
                    value: idx + 1
                },
                {
                    name: 'components.common.postId',
                    width: 'auto',
                    value: item.id
                },
                {
                    name: 'components.common.dataAuthentication',
                    width: 'auto',
                    value: item.registeredAt ? this.formatDate(item.registeredAt) : '-'
                },
                {
                    name: 'common.status',
                    width: '236px',
                    value: this.$t('components.common.' + (item.isSuccessful ? 'success' : 'notSuccess')),
                    theme: item.isSuccessful ? 'success' : 'danger'
                },
                {
                    type: 'slot',
                    value: 'button',
                    placeholderType: 'card-icon',
                    width: '10px'
                }
            ]
        },
        additionalIdentificationParser(item) {
            if(this.isCreditOpened(item)) {
                return [
                    {
                        type: 'simple',
                    },
                    {
                        colspan: 4,
                        name: 'components.common.aComment',
                        normalWrap: true,
                        value: item.message,
                    }
                ]
            }
        },
        openPhoneSearch() {
            this.$refs["phone-identification"].focus();
            this.phoneSearch = true;
        },
        async getCardHash() {
            this.cardHashIn.loading = true
            const url = "asaka-mobile/card-hash/" + this.cardHashIn.in

            try {
                const result = await get(url)

                if(result.data.status !== 0 || !result.data.data.hash) {
                    this.$store.commit('notifications/error', 'errors.somethingWentWrong')
                }
                this.cardHashIn.out = result.data.data.hash
            }
            catch (e) {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
            }
            finally {
                this.cardHashIn.loading = false
            }
        },
        async getCardNumber() {
            this.cardHashOut.loading = true
            const url = "asaka-mobile/decoded-card-hash?hash=" + this.cardHashOut.in

            try {
                const result = await post(url)

                if(result.data.status !== 0 || !result.data.data.card_number) {
                    this.$store.commit('notifications/error', 'errors.somethingWentWrong')
                }
                this.cardHashOut.out = result.data.data.card_number
            }
            catch (e) {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
            }
            finally {
                this.cardHashOut.loading = false
            }
        },
        clearHashIn() {
            this.cardHashIn = new CardModel()
        },
        clearHashOut() {
            this.cardHashOut = new CardModel()
        },
        copyToClipBoard(value) {
            if (!value) return
            const textArea = document.createElement('textarea');
            textArea.value = value;

            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            this.$store.commit("notifications/add", {
                type: "success",
                message: this.$t("common.copied")
            })
        },
        async compareData() {
            if(!this.customerData.userId) return;
            this.compareDataLoading = true;
            const url = "asaka-mobile/compare-username/" + this.customerData.userId

            try {
                const result = await get(url)
                const expression = (
                    result.data.status === 0 && result.data.data
                ) && (
                    ('success' in result.data.data) ? result.data.data.success : true
                )
                this.compareDataSuccess = expression;
                if(expression) this.compareDataForm = result.data.data;
            } catch (e) {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
            } finally {
                this.compareDataFulfilled = true;
                this.compareDataLoading = false;
            }
        },
        selectOperation(key) {
            const self = this

            const confirm = new ConfirmController()
            const title = "components.operations.confirm." + key + ".title"
            const description = "components.operations.confirm." + key + ".description"

            confirm.call(title, description).then(async () => {
                self.loading = true
                const url = "asaka-mobile/" + key + "/" + self.customerData.userId

                try {
                    await get(url)
                    self.$store.commit('notifications/add', {type: "success", message: self.$t("components.common.success")})
                } catch (e) {
                    self.$store.commit('notifications/error', 'errors.somethingWentWrong')
                } finally {
                    self.loading = false
                }
            })
        },
        async getTransactionDetails() {
            const url = 'asaka-mobile/transaction/' + this.searchTransactionModel
            this.transactionDetails.loading = true
            this.transactionDetails.fullFiled = false

            try {
                const result = await get(url)
                if(result.data.status !== 404 && result.data.data) {
                    this.transactionDetails.fullFiled = true
                    this.transactionDetails.info = result.data.data
                }
            } catch (e) {
                this.$store.commit('notifications/error', 'errors.somethingWentWrong')
            } finally {
                this.transactionDetails.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
@import '../assets/scss/variables';
.monitoring-page {
    position: relative;
    padding-top: 1px;
    &__search-name {
        margin-right: 20px;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
    &__search {
        display: flex;
        align-items: center;
        position: absolute;
        right: 64px;
        top: 53px;
    }
}

.transaction-details__separator {
    height: 1px;
    width: 100%;
    border-color: var(--color-border);
}

.monitoring-page__hash-copy {
    cursor: pointer;
    background: none;

    &:active {
        transform: scale(0.8);
    }
}
</style>
