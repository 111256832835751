<template>
    <div class="no-avatar" :class="{['no-avatar--size-' + size]: size}" :style="{backgroundColor: color}">{{ initials }}</div>
</template>
<script>
import UserColorsController from "../../../services/UserColorsController";

export default {
    name: 'NoAvatar',
    props: {
        keyName: {
            type: [String, Number],
            default: ''
        },
        userName: String,
        size: String,
    },
    computed: {
        initials() {
            const separated = this.userName.split(' ');
            let firstName = separated[0][0];
            let secondName = '';
            if(separated[1]) {
                secondName = separated[1][0];
            }
            return (firstName + secondName).toUpperCase();
        },
        color() {
            return UserColorsController.getColor(this.userName + this.keyName);
        }
    }
}
</script>
<style lang="scss">
.no-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    background-color: #FFC1C1;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-white);
    &--size-medium {
        width: 44px;
        height: 44px;
        font-size: 16px;
    }
}
</style>
