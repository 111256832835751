<template>
    <div>
        <AppText type="product-info-description" class="mb-8-a">
            {{ $t("components.common.issuanceAndServicingOfCardsInForeignCurrencyNationalCurrency") }}
        </AppText>
        <div class="product-info-cols">
            <div class="product-info-cols__row">
                <AppText class="product-info-cols__title" type="product-info">
                    {{ $t("components.common.theNameOfTheOperation") }}
                </AppText>
                <AppText class="product-info-cols__value" type="product-info-value">
                    {{ $t("components.common.masterCardStandart") }}<br>
                    {{ $t("components.common.cardOfIndividuals") }}<br>
                    {{ $t("components.common.inForeignCurrencyOrNationalCurrency") }}
                </AppText>
            </div>
            <AppText class="product-info-cols__row" type="product-info" theme="primary-1">
                {{ $t("components.common.openingAnInternationalCardForAnIndividual") }}
            </AppText>
            <div class="product-info-cols__row">
                <AppText class="product-info-cols__title" type="product-info">
                    {{ $t("components.common.main") }}
                </AppText>
                <AppText class="product-info-cols__value" type="product-info-value">
                    {{ $t("components.common.forFree") }}
                </AppText>
            </div>
            <div class="product-info-cols__row">
                <AppText class="product-info-cols__title" type="product-info">
                    {{ $t("components.common.additional") }}
                </AppText>
                <AppText class="product-info-cols__value" type="product-info-value">
                    {{ $t("components.common.forFree") }}
                </AppText>
            </div>
            <AppText class="product-info-cols__row" type="product-info" theme="primary-1">
                {{ $t("components.common.reIssueOfCardMainAdditional") }}
            </AppText>
            <div class="product-info-cols__row">
                <AppText class="product-info-cols__title" type="product-info">
                    {{ $t("components.common.uponExpirationOfTheServicePeriod") }}
                </AppText>
                <AppText class="product-info-cols__value" type="product-info-value">
                    {{ $t("components.common.forFree") }}
                </AppText>
            </div>
        </div>
    </div>
</template>
<script>
import AppText from "../AppText";
export default {
    name: 'CreditProductTable',
    components: {AppText}
}
</script>
<style lang="scss">
.product-info-cols {
    &__row {
        display: flex;
        align-items: flex-start;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: -1px;
    }
    &__title {
        width: 39.4%;
    }
    &__value {
        width: 60.6%;
    }
}
</style>
