<template>
    <div class="create-client-form application-input-theme-problem"
         :class="{['create-client-form--' + type]: type, 'create-client-form--client-bank-editable': clientBankEditable}">
        <div class="create-client-form__col application-input-wrap">
            <label class="application-input-title"> {{ $t("common.fio") }} <span
                class="application-input-additional">*</span></label>
            <div class="success-wrap" :class="isChanged('username')">
                <AppInput :value="customer.username" class="application-input" :error="$v.customer.username.$anyError"
                          size="large" :placeholder="$t('components.common.enterYourFullName')"
                          @input="onInput('username', $event)"/>
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
            <div v-if="$v.customer.username.$anyError" class="application-input-error">
                {{ $t("validations.requiredField") }}
            </div>
        </div>
        <div class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{ $t("common.passportNumber") }}
                <span class="application-input-title__small">{{ $t("common.GCP") }}</span>
            </label>
            <div class="success-wrap" :class="isChanged('passport_no')">
                <AppInput :value="customer.passport_no" class="application-input" size="large" v-mask="'AA #######'"
                          :maxLength="10" placeholder="XX XXXXXXX" @input="onInput('passport_no', $event)"/>
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
        </div>
        <div class="create-client-form__col">
            <div class="application-input-wrap" @click="phoneOpened = true">
                <label class="application-input-title">
                    {{ $t("components.common.phoneNumber") }} <span class="application-input-additional">*</span>
                </label>
                <div class="application-input phone-box" :class="{
                    'phone-box--error': phoneError,
                    'phone-box--opened': phoneOpened
                }">
                    {{ callNumber || '-' }}
                    <AppIcon class="phone-box__chevron" icon="chevron-down"/>
                </div>
                <div v-if="phoneError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
            </div>
            <transition name="dialer">
                <phonedropdown
                    v-if="phoneOpened"
                    :phones="customer.customer_phones"
                    :incoming-phone="incomingPhone"
                    :selected-phone="selectedPhone"
                    @select-phone="$emit('select-phone', $event)"
                    @closeDrop="phoneClose"
                    @input="onInput('customer_phones', $event)"/>
            </transition>
        </div>

        <div v-if="!clientBankEditable" class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{$t('common.pinfl')}}
            </label>
            <div class="success-wrap" :class="isChanged('pinfl')">
                <AppInput :value="customer.pinfl" class="application-input" size="large" :placeholder="$t('common.pinfl')"
                          @input="onInput('pinfl', $event)" v-mask="'##############'" />
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
        </div>

        <div class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{ $t("components.common.clientCategory") }} <span class="application-input-additional">*</span>
            </label>
            <div class="success-wrap" :class="isChanged('client_type')">
                <AppSelect
                    :value="categoryValue"
                    :items="category_client"
                    item-name="name"
                    class="application-input"
                    :error="$v.customer.client_type.$anyError"
                    size="large"
                    :placeholder="$t('components.common.selectFromTheList')"
                    @input="onInput('client_type', $event)"
                    translate-items
                />
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
            <div v-if="$v.customer.client_type.$anyError" class="application-input-error">
                {{ $t("validations.requiredField") }}
            </div>
        </div>

        <div class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{ $t("components.common.clientStatus") }} <span class="application-input-additional">*</span>
            </label>
            <div class="success-wrap" :class="isChanged('is_company')">
                <AppSelect :value="customer.is_company" :items="status_client" item-name="name"
                           class="application-input" :error="$v.customer.is_company.$anyError" size="large"
                           :placeholder="$t('components.common.selectFromTheList')"
                           @input="onInput('is_company', $event)"/>
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
            <div v-if="$v.customer.is_company.$anyError" class="application-input-error">
                {{ $t("validations.requiredField") }}
            </div>
        </div>
        <div class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{ $t("components.common.dateOfBirth") }}
                <span class="application-input-title__small">{{ $t("components.common.gcp") }}</span>
            </label>
            <div class="success-wrap" :class="isChanged('birthday')">
                <AppInput :value="customer.birthday" class="application-input" size="large" v-mask="'##.##.####'"
                          :placeholder="$t('components.common.ddMmYyyy')" @input="onInput('birthday', $event)"/>
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
        </div>

        <template v-if="clientBankEditable">
            <div class="create-client-form__col application-input-wrap">
                <label class="application-input-title">
                    {{ $t("components.common.inn") }}
                </label>
                <div class="success-wrap" :class="isChanged('tin')">
                    <AppInput :value="customer.tin" class="application-input" size="large"
                              @input="onInput('tin', $event)"/>
                    <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
                </div>
            </div>
            <div class="create-client-form__col application-input-wrap">
                <label class="application-input-title">
                    {{ $t("components.common.clientCode") }}
                </label>
                <div class="success-wrap" :class="isChanged('code')">
                    <AppInput :value="customer.code" class="application-input" size="large"
                              @input="onInput('code', $event)"/>
                    <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
                </div>
            </div>
        </template>
        <div v-else class="create-client-form__col application-input-wrap">
            <label class="application-input-title">
                {{ $t("components.common.gender") }}
            </label>
            <div class="success-wrap" :class="isChanged('gender')">
                <AppSelect :value="genderValue" :items="genderArray" item-name="name" class="application-input"
                           size="large" :placeholder="$t('components.common.selectGender')"
                           @input="onInput('gender', $event)"/>
                <img class="success-wrap__icon" src="../assets/img/warning-icon.svg"/>
            </div>
        </div>

        <div v-if="type !== 'empty'" class="create-client-form__footer">
            <div class="block-wrap">
                <AbsCustomerButton v-if="customer.bank_client_id && !loading"
                                   class="create-client-form__separated-button"
                                   :bank-client-id="customer.bank_client_id">
                    <AppButton theme="primary-dark-outline-a">
                        {{ type === 'chat' ? $t('components.common.abs') : $t('components.common.absCustomerCard') }}
                    </AppButton>
                </AbsCustomerButton>
                <div v-if="dirty || !customer.id" @click="cancel">
                    <AppButton v-if="type === 'chat'" type="button" square theme="white-outline-narrow">
                        <AppIcon icon="delete"/>
                    </AppButton>
                    <AppButton v-else class="create-client-form__button" theme="gray">
                        {{ $t("common.cancel") }}
                    </AppButton>
                </div>
                <AppButton :theme="!dirty && customer.id ? 'success-flat' : 'primary'" :disabled="loading"
                           @click="submit">
                    {{
                        loading ? $t('components.common.wait') : !dirty && customer.id ? $t('components.common.saved') : $t('components.common.save')
                    }}
                </AppButton>
            </div>
        </div>
    </div>
</template>
<script>
import AppInput from "./partials/AppInput";
import AppSelect from "./partials/AppSelect";
import AppButton from "./partials/AppButton";
import {required} from "vuelidate/lib/validators";
import Phonedropdown from "./phonedropdown";
import clientGender from "../config/clientGender";
import juridicalStatus from "../config/juridicalStatus";
import clientCategories from "@/config/clientCategories";
import AppIcon from "./partials/AppIcon";
import AbsCustomerButton from "./partials/home/about-client/AbsCustomerButton";

const dirtyCheckKeys = [
    'username', 'birthday', 'is_company', 'gender', 'passport_no', 'passport_info', 'customer_phones', 'client_type', 'pinfl'
];

export default {
    name: 'ClientForm',
    components: {AbsCustomerButton, AppIcon, Phonedropdown, AppButton, AppSelect, AppInput},
    model: {
        prop: 'customer',
        event: 'input'
    },
    props: {
        customer: {
            required: true
        },
        userData: Object,
        type: String,
        loading: Boolean,
        incomingPhone: [String, Number],
        selectedPhone: [String, Number],
        clientBankEditable: Boolean,
        disabled: Boolean,
        customerFieldsUpdated: {
            type: [Boolean, Array],
            default: false
        }
    },
    validations: {
        customer: {
            username: {
                required
            },
            client_type: {
                required
            },
            is_company: {
                required
            },
        }
    },
    data() {
        return {
            status_client: juridicalStatus,
            category_client: clientCategories,
            genderArray: clientGender,
            phoneOpened: false,
            phoneError: false,
            changedCustomer: {}
        }
    },
    computed: {
        genderValue() {
            return this.customer.gender || '';
        },
        categoryValue() {
            return this.customer.client_type || '';
        },
        callNumber() {
            return this.selectedPhone || this.incomingPhone
        },
        dirty() {
            if (!this.userData) {
                return true;
            } else {
                return !dirtyCheckKeys.every(key => {
                    if (key === 'customer_phones') {
                        return JSON.stringify(this.customer.customer_phones) === JSON.stringify(this.userData.customer_phones);
                    }
                    return this.customer[key] === this.userData[key];
                })
            }
        }
    },
    methods: {
        // Используется снаружи, при уточнении данных вызывается этот метод
        updateCustomer(customer) {
            this.$emit('input', {
                ...this.customer,
                ...this.processFields(customer)
            });
            this.changedCustomer = customer;
        },
        processFields(fields) {
            fields = {...fields};
            if ('customer_phones' in fields && this.phoneError) {
                this.phoneError = !fields.customer_phones.filter(({phone}) => phone).length;
            }
            if ('gender' in fields) {
                fields.gender = +fields.gender;
            }
            return fields;
        },
        onInput(name, value) {
            if (this.disabled) return;
            this.$emit('input', {
                ...this.customer,
                ...this.processFields({[name]: value})
            })
        },
        cancel() {
            this.$emit('cancel')
        },
        phoneClose() {
            this.phoneOpened = false;
        },
        submit() {
            if (this.disabled) return;
            this.$v.$touch();
            this.phoneError = !this.customer.customer_phones.filter(({phone}) => phone).length;
            if (this.phoneError || !this.dirty) return;

            if (!this.$v.$invalid) {
                this.$emit('submit');
            }
        },
        isChanged(key) {
            if (!key || !this.customerFieldsUpdated?.length) return false;

            return {
                'success-wrap--enabled': this.customerFieldsUpdated.find(item => item === key)
            }
        }
    }
}
</script>
<style lang="scss">
@import '../assets/scss/variables';

.create-client-form {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px;

    &__col {
        position: relative;
        width: calc(25% - 28px);
        margin-bottom: 24px;
        margin-left: 28px;

        &--large {
            width: calc(50% - 28px);
        }
    }

    &__footer {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 28px;

        > * {
            justify-content: flex-end;
            width: calc(100% + 16px);
        }
    }

    &__separated-button:first-child {
        margin-right: auto;
    }

    &--chat {
        #{$self}__col {
            width: calc(50% - 28px);

            &--large {
                width: 100%;
            }
        }
    }

    &--empty {
        margin-top: -24px;

        #{$self}__col {
            margin-top: 24px;
            margin-bottom: 0;
        }
    }

    &--client-bank-editable {
        margin-bottom: -18px !important;
    }
}

.phone-box {
    $self: &;
    transition: border .25s;
    display: flex;
    align-items: center;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    height: 48px;
    padding: 0 17px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-family: $font-secondary;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    &__chevron {
        transition: transform .25s;
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        color: var(--color-gray-lighten);
    }

    &--error {
        border: 1px solid #e55353;
        animation: error 0.5s 1 both;
    }

    &--opened {
        border: 1px solid var(--color-primary);

        #{$self}__chevron {
            transform: rotate(180deg);
        }
    }
}

@keyframes pulseInput {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    15% {
        transform: scaleX(1.1) scaleY(1.6);
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.success-wrap {
    $self: &;
    position: relative;
    width: 100%;
    z-index: 0;

    & > * {
        width: 100% !important;
    }

    &__icon {
        display: none;
    }

    &--enabled {
        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 204, 86, 0.05);
            border: 1px solid #00CC56;
            border-radius: 4px;
            pointer-events: none;
        }

        &:after {
            content: '';
            animation: pulseInput 3s infinite ease-out;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 204, 86, 0.1);
        }

        #{$self}__icon {
            display: block;
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -8px;
            width: 16px !important;
            height: 16px;
        }

    }

    &--radius-medium {
        &:before {
            border-radius: 12px;
        }
    }
}
</style>
