var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrap",staticClass:"home-page",style:({
        '--home-connection-error-height':
            _vm.connectionError && _vm.activeChat ? '54px' : '0px',
        '--home-subscribe-height': _vm.hasSubscribe ? '54px' : '0px',
    })},[(_vm.connectionError && !_vm.activeChat)?_c('ErrorImage',{on:{"reload-click":_vm.reloadPage}}):[_c('transition',{attrs:{"name":"slide-calls"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChat),expression:"!isChat"}],ref:"calls",staticClass:"calls-view"},[_c('div',{staticClass:"calls-view__phone-control"},[(_vm.isCallOvertime)?_c('div',{staticClass:"error-panel error-panel--theme-overtime"},[_c('img',{staticClass:"error-panel__img",attrs:{"src":require("@/assets/img/error-gif.gif"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t( "components.common.itIsLooksLikeYouHaveAnUnfinishedTask" ))+" 🤔 "+_vm._s(_vm.$t( "components.common.checkAllFieldsAndCloseTheTask" ))+" ")]):_vm._e(),_c('ShiftLayoutAppearAnimation',{attrs:{"height":"60","visible":true}},[_c('HomeTabs',{attrs:{"selected-view":_vm.card_stat,"user-data":_vm.clientFormInfo,"show-alarm":false},on:{"input":_vm.changeCard}})],1)],1),_c('div',[(_vm.isLoading)?_c('PageLoader'):(_vm.card_stat === 1)?_c('AboutClient',{ref:"aboutClientRef",attrs:{"commands":_vm.commandsList,"call-data":_vm.customerData,"client-form-info":_vm.clientFormInfo,"is-view-client-page":"","disable-hints":""},on:{"open:modal":_vm.openModal,"update:customer":_vm.updateCustomer,"reset:client":_vm.resetClientInfo}}):(_vm.card_stat == 2)?_c('HistoryProblems',{attrs:{"call-data":_vm.customerData,"client-form-info":_vm.clientFormInfo,"is-view-client-page":true}}):(
                            _vm.card_stat === 6 && _vm.customerData.selectedCustomer
                        )?_c('OutgoingHistory',{attrs:{"customer-id":_vm.customerData.selectedCustomer.toString(),"call-data":_vm.customerData}}):(_vm.card_stat == 4)?_c('MybankProduct',{attrs:{"processing-cards":_vm.processingCards,"user-data":_vm.userData,"client-form-info":_vm.clientFormInfo},on:{"process-cards":_vm.onProcessCards,"process-cards-delay":_vm.onProcessCardsDelay}}):(_vm.card_stat == 5)?_c('MonitoringProducts',{attrs:{"card-stat":_vm.card_stat,"common-data":_vm.commonData,"client-form-info":_vm.clientFormInfo,"user-data":_vm.userData}}):(_vm.card_stat === 7)?_c('AutoDebit',{attrs:{"processing-cards":_vm.processingCards,"client":_vm.clientFormInfo}}):_vm._e()],1)])])],_c('PrevCall',{ref:"prevCall"}),(_vm.modals.dontHold)?_c('DontHoldModal',{on:{"close-modal":function($event){_vm.modals.dontHold = false}}}):_vm._e(),(_vm.modals.creditProduct)?_c('CreditProductModal',{attrs:{"calculation-type":_vm.calculationType,"product-info":_vm.productInfo,"product-table":_vm.calculationTable,"call-data":_vm.customerCallModel,"slide":_vm.slide,"full-screen-fill":""},on:{"cancel:form":_vm.closeModal,"close-modal":_vm.closeModal,"submit:credit-product":function($event){return _vm.submitCreditProduct($event)},"submit:deposit-product":function($event){return _vm.submitDepositProduct($event)},"reset:step":function($event){_vm.slide = 'default'}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }