<template>
    <ModalWrapper #default="{close}" transparent>
        <div class="hold-modal">
            <p class="hold-modal__header">
                <AppIcon icon="call"/>
                {{ $t("components.common.callOnPause") }}
            </p>
            <div class="hold-modal__body">
                <p class="hold-modal__info">{{ $t("components.common.pleaseDoNotForwardTheCallWhileItIsPaused") }}</p>
                <AppButton class="hold-modal__button" @click="close">{{ $t("components.common.fine") }}</AppButton>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
export default {
    name: 'DontHoldModal',
    components: {AppIcon, AppButton, ModalWrapper}
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.hold-modal {
    background: var(--color-white);
    box-shadow: inset -4px 0px 0px var(--color-warning), inset 4px 0px 0px var(--color-warning);
    border-radius: 12px;
    font-family: $font-primary;
    width: 910px;
    &__header {
        display: flex;
        align-items: center;
        padding: 29px 32px 20px;
        border-bottom: 1px solid var(--color-border);
        margin: 0 4px 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: var(--color-warning);
        svg {
            width: 32px;
            height: 32px;
            margin-right: 15px;
        }
    }
    &__info {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }
    &__body {
        padding: 22px 32px 32px;
    }
    &__button {
        margin-left: auto;
        width: 125px;
    }
}
</style>
