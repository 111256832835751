<template>
    <transition name="agent-enter" mode="out-in">
        <article
            v-if="task_id && !loading"
            class="customer-task"
            :class="{
                ['customer-task--' + (opened ? 'opened' : 'closed')]: true,
                'in-row': inRows,
            }"
        >
            <template v-if="isTaskFound && !inRows">
                <div class="customer-task__item">
                    <p class="customer-task__header">
                        {{ $t("components.customerTask.taskId") }}
                    </p>
                    <p class="customer-task__content">
                        {{ task.id }}
                    </p>
                </div>
                <div class="customer-task__item">
                    <p class="customer-task__header">
                        {{ $t("components.customerTask.requestDate") }}
                    </p>
                    <p class="customer-task__content">
                        {{ date }}
                    </p>
                </div>
                <div class="customer-task__item">
                    <p class="customer-task__header">
                        {{ $t("components.customerTask.clientName") }}
                    </p>
                    <p class="customer-task__content">
                        {{ task.username }}
                    </p>
                </div>
                <div class="customer-task__item">
                    <p class="customer-task__header">
                        {{ $t("components.customerTask.bankProduct") }}
                    </p>
                    <p class="customer-task__content">
                        {{ productsString }}
                    </p>
                </div>
                <div class="customer-task__item">
                    <p class="customer-task__header">
                        {{ $t("components.customerTask.note") }}
                    </p>
                    <p class="customer-task__content">
                        {{
                            $t("components.customerTask.target", {
                                target: task.comment,
                            })
                        }}
                    </p>
                </div>
                <div class="customer-task__item">
                    <button
                        class="customer-task__open-button"
                        type="button"
                        @click.stop="switchOpen"
                    >
                        {{
                            $t(
                                "components.customerTask." +
                                    (opened ? "close" : "open")
                            )
                        }}
                        <app-icon icon="chevron-down" />
                    </button>
                </div>
            </template>
            <template v-else-if="inRows">
                <div class="customer-task__row">
                    <div class="customer-task__item">
                        <p class="customer-task__header">
                            {{ $t("components.customerTask.taskId") }}
                        </p>
                        <p class="customer-task__content">
                            {{ task.id }}
                        </p>
                    </div>
                    <div class="customer-task__item">
                        <p class="customer-task__header">
                            {{ $t("components.customerTask.requestDate") }}
                        </p>
                        <p class="customer-task__content">
                            {{ date }}
                        </p>
                    </div>
                    <div class="customer-task__item">
                        <p class="customer-task__header">
                            {{ $t("components.customerTask.clientName") }}
                        </p>
                        <p class="customer-task__content">
                            {{ task.username }}
                        </p>
                    </div>
                    <div class="customer-task__item">
                        <button
                            class="customer-task__open-button"
                            type="button"
                            @click.stop="switchOpen"
                        >
                            {{
                                $t(
                                    "components.customerTask." +
                                        (opened ? "close" : "open")
                                )
                            }}
                            <app-icon icon="chevron-down" />
                        </button>
                    </div>
                </div>
                <template v-if="opened">
                    <div class="customer-task__item" style="margin-top: 24px">
                        <p class="customer-task__header">
                            {{ $t("components.customerTask.bankProduct") }}
                        </p>
                        <p class="customer-task__content">
                            {{ productsString }}
                        </p>
                    </div>
                    <div class="customer-task__item" style="margin-top: 24px">
                        <p class="customer-task__header">
                            {{ $t("components.customerTask.note") }}
                        </p>
                        <p class="customer-task__content">
                            {{
                                $t("components.customerTask.target", {
                                    target: task.comment,
                                })
                            }}
                        </p>
                    </div>
                </template>
            </template>
            <div v-else class="customer-task__empty">
                {{ $t("components.customerTask.notFound") }}
            </div>
        </article>
    </transition>
</template>
<script>
import { get } from "@/helpers/api";
import { mapMutations } from "vuex";

export default {
    name: "CustomerTask",
    props: {
        task_id: {
            type: Number,
            default: null,
        },
        inRows: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "app-icon": () => import("@/components/partials/AppIcon.vue"),
    },
    data() {
        return {
            task: null,
            opened: false,
            products: [],
            isTaskFound: false,
            // TODO Add skeleton
            loading: false,
        };
    },
    computed: {
        productsString() {
            if (!this.task_id || this.loading) return "";

            return this.task.product_ids
                .map((id) => this.products.find((el) => el.id === +id).title)
                .join(", ");
        },
        date() {
            if (this.task?.date) {
                return new Date(this.task.date).toLocaleDateString("ru", {
                    hour: "numeric",
                    minute: "numeric",
                });
            }
            return "";
        },
    },
    created() {
        if (this.task_id) {
            this.loading = true;

            Promise.all([this.getTasks(), this.getProducts()]).finally(() => {
                this.loading = false;
            });
        }
    },
    methods: {
        ...mapMutations({
            addErrorNotification: "notifications/error",
        }),
        async getTasks() {
            try {
                const response = await get(
                    "/marketing-bot/tasks/" + this.task_id
                );

                if (response.data.success) {
                    this.task = response.data.data;
                    this.isTaskFound = true;
                    this.$emit("task-download", this.task);
                }
            } catch (e) {
                this.isTaskFound = false;
            }
        },
        async getProducts() {
            try {
                const response = await get("/products?flat=1");

                if (response.data.success) this.products = response.data.data;
            } catch (e) {
                this.addErrorNotification(
                    this.$t("errors.failedToLoadProducts")
                );
            }
        },
        switchOpen() {
            this.opened = !this.opened;
        },
    },
};
</script>
<style lang="scss" src="./customer-task.style.scss"></style>
