<template>
    <ModalWrapper
        class="credit-product-modal"
        ref="modal"
        :class="{
            'credit-product-modal--fullscreen': modalWindowFullscreen,
            'credit-product-modal--default-header': defaultHeader,
            'credit-product-modal--fill': fullScreenFill,
        }"
        custom-close
        @close-modal="onCloseModal"
        @overlay-click="onCloseModal"
        #default="{ close }"
    >
        <div class="credit-product-modal__window" ref="window">
            <div class="credit-product-modal__header">
                <div v-if="defaultHeader" class="product-title">
                    <AppIcon class="product-title__icon" icon="schoolbag" />
                    {{ $t("components.common.productInformation") }}
                </div>
                <AppButton
                    v-else
                    class="pl-4-a pr-5-a"
                    theme="blueish-a"
                    size="medium-extra"
                    @click="$emit('reset:step')"
                >
                    <AppIcon
                        icon="chevron-left-bold"
                        size="12"
                        class="mr-2-a"
                    />
                    {{ $t("components.common.comeBack") }}
                </AppButton>
                <CreditProductModalControls
                    class="credit-product-modal__controls"
                    @close="close"
                    @before-fullscreen="animateFullscreen"
                />
            </div>
            <template v-if="slide !== 'results'">
                <AppAccordionCoordinator
                    v-if="true"
                    class="credit-product-modal__body"
                    :preopened="preOpenedAccordionByType"
                    :items="Object.values(accordionItems)"
                >
                    <AppAccordion
                        :name="accordionItems.accordionTitle"
                        :persist="isOnlyDescriptionText"
                        icon="folder-close"
                    >
                        <AppText
                            class="product-text"
                            type="input-title"
                            v-html="accordionContent"
                        />
                    </AppAccordion>
                    <!--          <AppAccordion-->
                    <!--            :name="$t('components.common.mastercardTariff')"-->
                    <!--            icon="folder-close"-->
                    <!--          >-->
                    <!--            <CreditProductTable />-->
                    <!--          </AppAccordion>-->
                    <CreditCalculator
                        v-if="getCalculatorTypes['credit']"
                        v-model="creditForm"
                        :accordion-name="accordionItems.creditCalculator"
                        @cancel:form="$emit('cancel:form')"
                        @submit:form="$emit('submit:credit-product', $event)"
                        @reset:form="resetForm"
                    />
                    <DepositCalculator
                        v-if="getCalculatorTypes['deposit']"
                        v-model="depositForm"
                        :accordion-name="accordionItems.depositCalculator"
                        @cancel:form="$emit('cancel:form')"
                        @submit:form="$emit('submit:deposit-product', $event)"
                        @reset:form="resetForm"
                    />
                </AppAccordionCoordinator>
                <AppTabs
                    v-else
                    class="credit-product-tabs"
                    v-model="currentTab"
                    :items="autoCalculatorItems"
                    item-value="value"
                >
                    <template #body.calculator>
                        <AutoCalculator />
                    </template>
                    <template #body.locations>
                        <AutoLocations />
                    </template>
                    <template #body.prescoring>
                        <AutoPrescoring />
                    </template>
                </AppTabs>
            </template>
            <CalculatorResultsInfo
                v-else
                :creditForm.sync="creditForm"
                :depositForm.sync="depositForm"
                :product-table="productTable"
                :calculation-type="calculationType"
                :is-credit-calculator-type="isCreditCalculatorType"
                :is-deposit-calculator-type="isDepositCalculatorType"
                :accordion-items="accordionItems"
                @update:credit-form="creditForm = $event"
                @update:deposit-form="depositForm = $event"
                @cancel:form="$emit('reset:step')"
                @submit:credit-product="$emit('submit:credit-product', $event)"
                @submit:deposit-product="
                    $emit('submit:deposit-product', $event)
                "
                @reset:form="resetForm"
                @close="close"
            />
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppIcon from "../partials/AppIcon";
import AppAccordion from "../partials/AppAccordion";
import CreditCalculator from "../partials/credit-product/CreditCalculator";
import AppAccordionCoordinator from "../partials/AppAccordionCoordinator";
import AutoCalculator from "../partials/credit-product/AutoCalculator";
import AutoLocations from "../partials/credit-product/AutoLocations";
import AutoPrescoring from "../partials/credit-product/AutoPrescoring";
import CalculatorResultsInfo from "../partials/credit-product/CalculatorResultsInfo";
import AppText from "../partials/AppText";
import CreditProductTable from "../partials/credit-product/CreditProductTable";
import AppTabs from "../partials/AppTabs";
import { mapMutations, mapState } from "vuex";
import CreditProductModalControls from "../partials/credit-product/CreditProductModalControls";
import FullScreenModalMixin from "../../mixins/FullScreenModalMixin";
import AppButton from "../partials/AppButton";
import {
    CREDIT_CALCULATOR_FORM,
    DEPOSIT_CALCULATOR_FORM,
} from "@/data/components/partials/credit-product/CreditCalculator";
import DepositCalculator from "@/components/partials/credit-product/DepositCalculator.vue";

const modalWindowFullscreenStyles = Object.freeze({
    transform: "translateY(88px)",
    top: 0,
    left: 0,
});

const setModalWindowStyles = (modalWindow, styles) => {
    Object.entries(styles).forEach(([key, value]) => {
        modalWindow.style[key] =
            value + (typeof value === "number" ? "px" : "");
    });
};

export default {
    name: "CreditProductModal",
    emits: [
        "cancel:form",
        "submit:credit-product",
        "submit:deposit-product",
        "reset:step",
    ],
    props: {
        calculationType: {
            type: String,
            required: true,
            default: "",
        },
        productInfo: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        productTable: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        callData: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        slide: {
            type: String,
            required: true,
            default: "default",
        },
        fullScreenFill: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [FullScreenModalMixin],
    components: {
        DepositCalculator,
        AppButton,
        CreditProductModalControls,
        AppTabs,
        CreditProductTable,
        AppText,
        CalculatorResultsInfo,
        AutoPrescoring,
        AutoLocations,
        AutoCalculator,
        AppAccordionCoordinator,
        CreditCalculator,
        AppAccordion,
        AppIcon,
        ModalWrapper,
    },
    data() {
        return {
            currentTab: "calculator",
            autoCalculatorItems: [
                {
                    name: "КАЛЬКУЛЯТОР",
                    value: "calculator",
                },
                {
                    name: "Автосалоны",
                    value: "locations",
                },
                {
                    name: "Предскоринг",
                    value: "prescoring",
                },
            ],
            creditForm: new CREDIT_CALCULATOR_FORM(),
            depositForm: new DEPOSIT_CALCULATOR_FORM(),
        };
    },
    computed: {
        // ...mapState("callData", ["callModel"]),
        defaultHeader() {
            return this.slide !== "results";
        },
        accordionTitle() {
            return this.productInfo.title;
        },
        accordionContent() {
            const lang = this.callData?.lang ?? this.$i18n.locale;
            const productContentWithActiveLang =
                this.productInfo.translations.find(
                    (element) => element.lang === lang
                )?.description;

            return (
                productContentWithActiveLang ??
                this.productInfo.translations[0].description
            );
        },
        getCalculatorTypes() {
            const types = {
                credit: false,
                deposit: false,
            };

            this.productInfo.options.forEach((element) => {
                if (element.action === 1) {
                    types.credit = true;
                }
                if (element.action === 2) {
                    types.deposit = true;
                }
            });

            return types;
        },
        preOpenedAccordionByType() {
            if (this.getCalculatorTypes["credit"]) {
                return this.$t("components.common.creditCalculator");
            }
            if (this.getCalculatorTypes["deposit"]) {
                return this.$t("components.common.depositCalculator");
            }
            return "";
        },
        isCreditCalculatorType() {
            return this.calculationType === "credit";
        },
        isDepositCalculatorType() {
            return this.calculationType === "deposit";
        },
        accordionItems() {
            const items = {
                accordionTitle: this.accordionTitle,
            };
            if (this.getCalculatorTypes["credit"]) {
                items["creditCalculator"] = this.$t(
                    "components.common.creditCalculator"
                );
            }
            if (this.getCalculatorTypes["deposit"]) {
                items["depositCalculator"] = this.$t(
                    "components.common.depositCalculator"
                );
            }
            return items;
        },
        isOnlyDescriptionText() {
            return Object.values(this.accordionItems).length === 1;
        },
    },
    methods: {
        resetForm() {
            this.creditForm = new CREDIT_CALCULATOR_FORM();
            this.depositForm = new DEPOSIT_CALCULATOR_FORM();
        },
        animateFullscreen() {
            // TODO: Добавить поддержку чатов
            let f = this.animateIntoFullscreen;
            if (this.modalWindowFullscreen) {
                this.$refs.window.classList.remove(
                    "credit-product-modal__window--full-height"
                );
                f = this.animateFromFullscreen;
            }
            f();
        },
        animateIntoFullscreen() {
            const style = this.$refs.window.getBoundingClientRect();
            const modalWindow = this.$refs.window;

            const ms = modalWindow.style;
            ms.top = "0px";
            ms.left = "0px";
            ms.transform = `translate(${style.left}px, ${style.top}px)`;
            ms.width = style.width + "px";
            ms.position = "fixed";

            requestAnimationFrame(() => {
                modalWindow.classList.add(
                    "credit-product-modal__window--translate"
                );
                requestAnimationFrame(() => {
                    ms.transform = `translateY(${style.top}px)`;
                    ms.width = "100vw";
                    setTimeout(() => {
                        if (this.fullScreenFill) {
                            ms.transform = `translateY(0)`;
                        } else {
                            ms.transform = `translateY(88px)`;
                        }
                        setTimeout(() => {
                            modalWindow.classList.remove(
                                "credit-product-modal__window--translate"
                            );
                            modalWindow.classList.add(
                                "credit-product-modal__window--full-height"
                            );
                        }, 250);
                    }, 250);
                });
            });
        },
        animateFromFullscreen() {
            const modalWindow = this.$refs.window;
            const width = document.documentElement.offsetWidth;
            const height = document.documentElement.offsetHeight;
            const defaultPadding = 60;
            const defaultWidth = 1354;

            const padding = Math.max(
                (width - defaultWidth) / 2,
                defaultPadding
            );
            const ms = modalWindow.style;

            ms.top =
                Math.max((height - this.$refs.window.offsetHeight) / 2, 122) +
                "px";
            ms.left = padding + "px";
            ms.transform = `translate(-${padding}px, -${
                Math.max((height - this.$refs.window.offsetHeight) / 2, 122) -
                88
            }px)`;

            requestAnimationFrame(() => {
                modalWindow.classList.add(
                    "credit-product-modal__window--translate"
                );
                requestAnimationFrame(() => {
                    ms.width =
                        Math.min(defaultWidth, width - defaultPadding * 2) +
                        "px";
                    ms.transform = `translateY(-${
                        Math.max((height - modalWindow.offsetHeight) / 2, 122) -
                        88
                    }px)`;
                    setTimeout(() => {
                        ms.transform = "";
                        setTimeout(() => {
                            modalWindow.style = "";
                            modalWindow.classList.remove(
                                "credit-product-modal__window--translate"
                            );
                        }, 250);
                    }, 250);
                });
            });
        },
        onCloseModal() {
            if (this.modalWindowFullscreen) {
                this.modalWindowFullscreenToggle();
            }
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";
.credit-product-tabs {
    --tab-head-font: normal 700 16px/19px #{$font-primary};
    --tab-head-text-transform: uppercase;
    --tab-head-margin: 0px;
    --tab-separator-display: none;
    --tab-header-bg: #f4f4f4;
    --tab-head-sides: 36px;
    --tab-head-sides-top: 24px;
    --tab-head-sides-bottom: 23px;
    --tab-head-active-color: #2552c6;
    --tab-head-active-bg: #fff;
    --tab-underline-height: 3px;
}
.credit-product-modal {
    $self: &;
    --sides: 34px;
    --accordion-padding-sides-right: var(--sides);
    --accordion-padding-sides-left: var(--sides);
    --modal-offset-top: 122px;
    --modal-offset-bottom: 122px;
    --modal-window-padding-top: 0;
    --modal-window-padding-sides: 0;
    --modal-window-padding-bottom: 0;
    --modal-window-width: 1354px;
    --modal-window-max-width: 100%;
    --modal-animation-duration: 0.25s;
    --modal-animation-delay: 0.25s;
    &__window {
        --modal-window-height: calc(100vh - 122px * 2);
        position: relative;
        background-color: #fff;
        border-radius: 12px;
        &--translate {
            transition: top 0.25s, left 0.25s 0.25s, right 0.25s 0.25s,
                width 0.25s, border-radius 0.5s, transform 0.25s;
        }
    }
    &__controls {
        margin-left: auto;
        margin-right: -18px;
        margin-top: -36px;
    }
    &__header {
        transition: padding-left 0.25s, padding-right 0.25s;
        height: 84px;
        padding: 34px var(--sides);
        display: flex;
        align-items: center;
        border-radius: 12px 12px 0 0;
        background-color: #fff;
    }
    &__body {
        padding-bottom: 52px;
    }
    &--fullscreen {
        --modal-animation-delay: 0s;
        --modal-overlay: #f5f5f8;
        --window-margin: 0 auto auto;
        --sides: 64px;
        --tab-header-padding-left: 28px;
        #{$self}__controls {
            margin-right: -32px;
        }
        #{$self}__window {
            max-height: calc(100vh - 88px);
            overflow-y: auto;
            border-radius: 0;
        }
        #{$self}__header {
            z-index: 2;
            position: sticky;
            top: 0;
        }
    }
    &--default-header {
        #{$self}__header {
            border-bottom: 1px solid #e1e1e1;
        }
    }
}
.product-text {
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    white-space: pre-wrap;

    &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px !important;
        background: transparent !important;
    }
    &::-webkit-scrollbar-thumb {
        height: 30px !important;
        background: #2552c6;
        opacity: 0.5;
    }
}
.auto-calculator-error {
    --error-panel-sides: var(--sides);
    --error-panel-font: 600 16px/20px #{$font-secondary};
}
.auto-calculator {
    transition: padding-left 0.25s, padding-right 0.25s;
    padding: 42px var(--sides) 52px;
    display: flex;
    &__form-col {
        width: 65.89%;
    }
    &__auto-col {
        padding-left: 28px;
    }
}
.content-card {
    background: var(--card-bg, #fff);
    border-radius: var(--card-radius, 7px);
    padding: var(--card-padding-y, 24px) var(--card-padding-y, 34px);
}
.auto-location {
    $self: &;
    &__header {
        transition: padding-left 0.25s, padding-right 0.25s;
        padding: 32px var(--sides) 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__controls {
        align-self: flex-start;
    }
    &__footer {
        padding: 0 var(--sides) 52px;
        justify-content: flex-end;
        display: flex;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__table {
        z-index: 0;
        position: relative;
        --table-first-cell-padding-left: var(--sides);
        --table-last-cell-padding-right: var(--sides);
        &--max-height {
            --table-max-height: calc(var(--modal-window-height) - 268px);
        }
    }
    &__input {
        width: 410px;
    }
    &__body {
        padding-left: var(--sides);
        padding-right: var(--sides);
    }
    &__inline-accordion {
        --accordion-padding-sides-left: 0px;
        --accordion-padding-sides-right: 0px;
    }
    &--fullscreen {
        #{$self}__fullscreen-fixed {
            z-index: 1;
            position: sticky;
            top: 84px;
            left: 0;
            background-color: #fff;
            padding-bottom: 12px;
            margin-bottom: -12px;
            &--bottom {
                top: auto;
                bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                padding-top: 32px;
                margin-top: -25px;
                box-shadow: inset 0 1px 0 #e1e1e1;
            }
        }
    }
}
.hover-item {
    color: var(--hover-color, #717171);
    &:hover {
        color: var(--hover-color-active, #3a79f3);
    }
}

.credit-product-modal--fill.credit-product-modal--fullscreen {
    .credit-product-modal__window.credit-product-modal__window--full-height {
        max-height: 100vh;
        height: 100vh;
    }
}
</style>
