<template>
    <div class="phone-dropdown" v-on-click-away="closeDrop">
        <div class="phone-dropdown__body" ref="body">
            <div
                v-for="(item, index) in phones"
                class="phone-dropdown__item"
                :key="index"
                :class="{
                    'phone-dropdown__item--active': isPhoneActive(item.phone),
                    'phone-dropdown__item--focused': index === focusedPhone,
                    'phone-dropdown__item--disabled':
                        !item.phone && index === 0 && isMainPhoneDisabled,
                }"
            >
                <div
                    class="phone-dropdown__button"
                    @click="selectNumber(item.phone)"
                >
                    {{ getPhoneTypeLabel(index, item.type) }}
                </div>
                <input
                    id="first"
                    :value="item.phone"
                    :disabled="isMainPhoneDisabled && index === 0"
                    v-mask="'### ### ## ##'"
                    class="phone-dropdown__input"
                    autocomplete="off"
                    placeholder="--- --- -- --"
                    @input="updatePhone(index, $event)"
                    @focus.prevent.stop="focusInput(index)"
                    @blur="blurInput"
                />

                <!--                <div class="phone-dropdown__phone" @click.stop="call(item.phone)">-->
                <!--                    <AppIcon icon="phone"/>-->
                <!--                </div>-->
                <div
                    class="phone-dropdown__check"
                    @click="selectNumber(item.phone)"
                >
                    <AppIcon icon="check-round" />
                </div>
            </div>
        </div>
        <AppButton
            class="phone-dropdown__add-button"
            :disabled="addDisabled"
            @click="createPhone"
        >
            {{ $t("components.common.addANumber") }}
        </AppButton>
    </div>
</template>

<script>
import { directive as onClickAway } from "vue-clickaway";
import aPhoneTypesConfig from "../config/phoneTypes.ts";
import AppButton from "./partials/AppButton";
import AppIcon from "./partials/AppIcon";
import { EventBus } from "@/helpers/eventBus";

export default {
    components: { AppIcon, AppButton },
    directives: {
        onClickAway: onClickAway,
    },
    props: {
        phones: Array,
        incomingPhone: [String, Number],
        selectedPhone: [String, Number],
        isMainPhoneDisabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            allCustomerPhones: [],
            newPhoneAdded: false,
            focusedPhone: null,
        };
    },
    computed: {
        callNumber() {
            return this.selectedPhone || this.incomingPhone;
        },
        addDisabled() {
            const phonesLength = this.phones.length;
            return (
                phonesLength > 1 && this.phones[phonesLength - 1].phone === ""
            );
        },
    },
    watch: {
        phones: "scrollToAddedPhone",
    },
    methods: {
        scrollToAddedPhone() {
            if (this.newPhoneAdded) {
                this.$nextTick(() => {
                    this.newPhoneAdded = false;
                    let phoneRef = this.$refs.input[this.phones.length - 1];

                    // Если не удается найти последний инпут
                    if (!phoneRef) {
                        const bodyRef = this.$refs.body;
                        phoneRef =
                            bodyRef.children[
                                bodyRef.childElementCount - 1
                            ].querySelector("input");
                    }

                    phoneRef.scrollIntoView({ behavior: "smooth" });
                    phoneRef.focus();
                });
            }
        },
        getPhoneType(item) {
            const aMobilePrefix = [
                "99",
                "95",
                "77",
                "90",
                "91",
                "93",
                "94",
                "55",
                "33",
                "97",
                "88",
                "98",
            ];

            const aPrefixArrays =
                item.phone.length == 10
                    ? aMobilePrefix.map((item) => `0${item}`)
                    : aMobilePrefix;
            const iPrefixLength = item.phone.length == 10 ? 3 : 2;

            if (typeof item.type === "undefined") {
                item.type = aPrefixArrays.includes(
                    item.phone.substring(0, iPrefixLength)
                )
                    ? "m"
                    : "s";
            }

            return item;
        },
        closeDrop() {
            this.$emit("closeDrop");
        },
        createPhone() {
            if (this.addDisabled) {
                return;
            }
            this.$emit("input", this.phones.concat([{ id: 0, phone: "" }]));
            this.newPhoneAdded = true;
        },
        selectNumber(phone) {
            if (!phone) return;
            this.$emit("select-phone", phone);
            this.closeDrop();
        },
        focusInput(phone) {
            this.focusedPhone = phone;
        },
        blurInput() {
            this.focusedPhone = null;
        },
        getPhoneTypeLabel(index, type) {
            let message = "main";

            if (index !== 0) {
                message = "additional";
            } else if (
                Object.prototype.hasOwnProperty.call(aPhoneTypesConfig, type)
            ) {
                message = aPhoneTypesConfig[type];
            }

            return this.$t("components.common.phoneTypes." + message);
        },
        updatePhone(idx, event) {
            const value = event.target.value;

            const phones = [...this.phones];

            if (!value && phones.length > 2) {
                phones.splice(idx, 1);
            } else {
                phones[idx] = { ...this.phones[idx], phone: value };
            }

            this.$emit("input", phones);
        },
        isPhoneActive(phone) {
            return phone !== "" && phone === this.callNumber;
        },
        call(phone) {
            this.$router
                .push("/")
                .then(() => EventBus.$emit("outgoing-call", phone));
        },
    },
};
</script>

<style lang="scss">
@import "../assets/scss/variables";

.phone-dropdown {
    $self: &;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    position: absolute;
    top: calc(100% + 12px);
    z-index: 3;
    overflow: hidden;

    &__body {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 54 * 6px;
        margin-right: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0 18px;

        &--disabled {
            #{$self}__input,
            #{$self}__check,
            #{$self}__button {
                cursor: default;
            }
        }

        &--focused,
        &:hover {
            background-color: var(--color-white-bluesh);

            #{$self}__button {
                background-color: var(--color-white);
            }

            #{$self}__check {
                color: var(--color-primary);
                background-color: var(--color-white);
            }
        }

        &--active:hover,
        &--active {
            #{$self}__check {
                background-color: var(--color-primary);
                color: var(--color-white);
            }
        }
    }

    &__button {
        cursor: pointer;
        user-select: none;
        width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: var(--color-white-bluesh);
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: $font-secondary;
        letter-spacing: 0.005em;
        padding: 5px 10px 6px;
        border-radius: 8px;
    }

    &__input {
        background-color: transparent;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-secondary;
        letter-spacing: 0.005em;
        padding: 0 9px 0 14px;
        height: 54px;
        width: 10px;
        flex-grow: 1;
        outline: none;
    }

    &__check,
    &__phone {
        position: relative;
        cursor: pointer;
        user-select: none;
        width: 24px;
        height: 24px;
        padding: 7px;
        margin-left: 8px;
        background-color: #edf2ff;
        border-radius: 100%;
        color: #717171;

        svg {
            width: 10px;
            height: 10px;
            display: block;
        }

        &:before {
            display: block;
            content: "";
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
        }
    }
    &__phone {
        background-color: #00cc56;
        svg {
            path {
                fill: #fff !important;
            }
        }
    }

    &__add-button {
        width: calc(100% - 32px);
        margin: 16px;
    }
}
</style>
