<template>
    <table v-if="loading || data.length" class="product-info-table" :class="'product-info-table--' + type">
        <tbody>
        <template v-for="(block, idx) in items">
            <tr :key="idx + 'a'" class="product-info-table__row">
                <td v-for="(item, i) in block" :key="i" class="product-info-table__cell" :style="{ '--w': item.width || 'auto' }">
                    <template v-if="loading || loadingItems.includes(idx)">
                        <slot v-if="item.type === 'slot' && !item.placeholderType" :name="'slot.' + item.value" :item="{}" :loading="true"></slot>
                        <div v-else-if="item.type === 'simple'" class="placeholder" :class="'placeholder--' + (item.placeholderType || 'medium')"></div>
                        <ProblemDescriptionItem v-else class="product-info-table__item" :name="item.name" nowrap :icon="item.icon">
                            <template #value>
                                <div class="placeholder" :class="'placeholder--' + (item.placeholderType || 'medium')"></div>
                            </template>
                        </ProblemDescriptionItem>
                    </template>
                    <template v-else>
                        <slot v-if="item.type === 'slot'" :name="'slot.' + item.value" :item="data[idx]" :loading="false"></slot>
                        <div v-else-if="item.type === 'simple'" class="product-info-table__name">{{ item.value }}</div>
                        <ProblemDescriptionItem v-else class="product-info-table__item" :name="item.name" :value="item.value" nowrap :icon="item.icon" :theme="item.theme">
                            <template v-if="item.valueSlot" #value>
                                {{ item.valueSlot }}
                            </template>
                        </ProblemDescriptionItem>
                    </template>
                </td>
            </tr>
            <tr v-if="additionalParser && additionalParser(data[idx])" :key="idx + 'b'" class="product-info-table__row-body">
                <td v-for="(item, i) in additionalParser(data[idx])" :key="i + 'bc'" class="product-info-table__cell" :class="{'product-info-table__cell--normal-wrap': item.normalWrap}" :colspan="item.colspan">
                    <div v-if="item.type === 'simple'" class="product-info-table__name">{{ item.value }}</div>
                    <ProblemDescriptionItem v-else class="product-info-table__item" :name="item.name" :value="item.value" :nowrap="!item.normalWrap" :icon="item.icon" :theme="item.theme">
                        <template v-if="item.valueSlot" #value>
                            {{ item.valueSlot }}
                        </template>
                    </ProblemDescriptionItem>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
    <EmptyContent v-else :name="emptyName"/>
</template>

<script>
import ProblemDescriptionItem from "../../ProblemDescriptionItem";
import EmptyContent from "../../EmptyContent";

export default {
    name: 'ProductInfoTable',
    components: {EmptyContent, ProblemDescriptionItem},
    props: {
        type: {
            type: String,
            default: 'phone'
        },
        length: {
            type: Number,
            default: 5
        },

        data: {
            type: Array,
            required: true,
            default: () => []
        },
        parser: {
            type: Function,
            required: true
        },
        emptyName: {
            type: String,
        },

        additionalParser: Function,

        loading: Boolean,
        loadingItems: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        items() {
            if(this.loading) {
                return new Array(this.length).fill({}).map(this.parser);
            }
            return this.data.map(this.parser);
        },
        additionalItems() {
            if(this.additionalParser) {
                return this.data.map(this.additionalParser);
            }
        }
    },
}
</script>
