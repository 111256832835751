export const HUMO_LOGO = require('../assets/img/Humo.svg');
export const UZCARD_LOGO = require('../assets/img/uzcard.svg');
export const MASTER_LOGO = require('../assets/img/master.svg');
export const DEAD_LOGO = require('../assets/img/dead.svg');
export const NO_VENDOR = 'NO_VENDOR';
export const getCardLogo = (name) => {
    switch(name) {
        case 'mastercard': return MASTER_LOGO;
        case 'uzcard': return UZCARD_LOGO;
        case 'humo': return HUMO_LOGO;
        default: return DEAD_LOGO
    }
}
