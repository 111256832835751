var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-wrap",class:{
    'search-wrap--active': _vm.active || _vm.fixed,
    'search-wrap--menu-opened': _vm.menuOpened,
    'search-wrap--type-chat': _vm.isChat,
    'search-wrap--fixed': _vm.fixed,
     ['search-wrap--theme-' + _vm.theme]: _vm.theme
  }},[_c('form',{directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.close),expression:"close"}],staticClass:"search-wrap__field",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(!_vm.disableCategories)?_c('div',{staticClass:"search-wrap__category",on:{"click":function($event){_vm.menuOpened = !_vm.menuOpened}}},[_vm._v(" "+_vm._s(_vm.getTranslationOrKey(_vm.category))+" "),_c('AppIcon',{staticClass:"search-wrap__menu-indicator",attrs:{"icon":"chevron-down"}})],1):_vm._e(),_c('div',{staticClass:"search-wrap__field-wrap",class:{
        'ml-3': _vm.disableCategories
    }},[_c('MaskedInput',{ref:"input",staticClass:"search-wrap__input search-wrap__input--main",attrs:{"mask":_vm.mask,"value":_vm.value,"disabled":_vm.fixed ? _vm.$attrs.disabled : !_vm.active,"tabindex":_vm.fixed ? null : !_vm.active ? '-1' : null,"placeholder":_vm.getTranslationOrKey(_vm.placeholder),"type":"text"},on:{"focus":_vm.closeMenu,"input":_vm.onInput},nativeOn:{"keydown":function($event){return _vm.onKeydown.apply(null, arguments)}}}),(_vm.isDouble)?[_c('div',{staticClass:"search-wrap__separator"}),_c('MaskedInput',{ref:"inputAdditional",staticClass:"search-wrap__input",attrs:{"mask":_vm.maskAdditional,"value":_vm.valueAdditional,"disabled":_vm.fixed ? _vm.$attrs.disabled : !_vm.active,"tabindex":_vm.fixed ? null : !_vm.active ? '-1' : null,"placeholder":_vm.getTranslationOrKey(_vm.placeholderAdditional),"type":"text"},on:{"focus":_vm.closeMenu,"input":function($event){return _vm.onInput($event, true)}}})]:_vm._e()],2),_c('button',{staticClass:"search-wrap__button",attrs:{"type":"submit"}},[(_vm.loading)?_c('LoadingIcon'):_c('AppIcon',{attrs:{"icon":"search"}})],1),_c('transition',{attrs:{"name":"dialer"}},[(_vm.menuOpened)?_c('div',{directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"search-wrap__menu search-menu"},_vm._l((_vm.items),function(item){return _c('div',{key:item.short,staticClass:"search-menu__item",class:{'search-menu__item--active': item.value === _vm.type},on:{"click":function($event){return _vm.selectType(item.value)}}},[_vm._v(" "+_vm._s(_vm.$t(item.short))+" ")])}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }