export default {
    data() {
        return {
            delayTimeout: null
        };
    },
    beforeDestroy() {
        if(this.delayTimeout) {
            clearTimeout(this.delayTimeout);
            this.delayTimeout = null;
        }
    },
    methods: {
        parsePhone(phone, visible) {
            if(phone) {
                const regex = /\+?(998)?.*?(\d{2}).*(\d{3}).?(\d{2}).?(\d{2})/m;
                const match = phone.match(regex);
                if(match && !visible) {
                    return `0${match[2]}${match[3]}${match[4]}${match[5]}`;
                } else if(match) {
                    return `+998${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
                }
            }
            return null;
        },
        delay(cb, time = 700) {
            this.clearDelay();

            this.delayTimeout = setTimeout(() => {

                cb();
                this.delayTimeout = null;
            }, time);
        },
        clearDelay() {
            if(this.delayTimeout) {
                clearTimeout(this.delayTimeout);
            }
        },
        getCurrency(code, ru = false) {
            switch(code) {
                case '840': return ru ? 'Доллар' : 'USD';
                case '978': return ru ? 'Евро' : 'EUR';
                case '000':
                default: return ru ? 'Сум' : 'UZS';
            }
        },
        formatNumber(number = 0, isMoney) {
            const intl = new Intl.NumberFormat('ru-RU', {
                useGrouping: true,
                minimumFractionDigits: isMoney ? 2 : 0,
                maximumFractionDigits: isMoney ? 2 : 0
            });
            return intl.format(number);
        },
        formatDate(sDate) {
            const date = new Date(sDate);
            return date.toLocaleDateString("ru-RU", {
                "year": "numeric",
                "month": "numeric",
                "day": "numeric",
                "hour": "numeric",
                "minute": "numeric"
            });
        },
        stripDescription(string) {
            if (!string) {
                return '-';
            }
            const length = 40;
            let trimmedString = string.length > length ?
                string.substring(0, length - 3) + "..." :
                string;

            return trimmedString;
        },
        stripTags(originalString) {
            return originalString.replace(/(<([^>]+)>)/gi, "");
        },
        replaceUsername(text, name, operatorName) {
            if(!text) return text;
            let replacedText = text;
            if (name) {
                let username = name.substr( name.indexOf(" ") + 1);
                replacedText = replacedText.replaceAll('%customerName%', username);
            }
            if (operatorName) {
                replacedText = replacedText.replaceAll('%operatorName%', operatorName);
            }
            return replacedText
        },
    },
}
