<template>
    <div class="loan-modal-card-item">
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("components.transaction-details.card-number") }}
            </div>
            <div class="loan-modal-card-item__value">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.card_number | VMask("#### ##** **** ####") }}
                </template>
            </div>
        </div>
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("components.abs-modal.documentExpireDate") }}
            </div>
            <div class="loan-modal-card-item__value">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.card_valid | VMask("##/##") }}
                </template>
            </div>
        </div>
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("common.fio") }}
            </div>
            <div class="loan-modal-card-item__value">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.customer }}
                </template>
            </div>
        </div>
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("common.table.phoneNumber") }}
            </div>
            <div class="loan-modal-card-item__value">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.phone | VMask("## ### ## ##") }}
                </template>
            </div>
        </div>
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("common.table.status") }}
            </div>
            <div
                class="loan-modal-card-item__value"
                :style="{ color: statusColor }"
            >
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.status_name }}
                </template>
            </div>
        </div>
        <div class="loan-modal-card-item__col">
            <div class="loan-modal-card-item__label">
                {{ $t("common.cardBelongsTo") }}
            </div>
            <div class="loan-modal-card-item__value">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                ></div>
                <template v-else>
                    {{ item.owner_type }}
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loanModalCardItem",

    props: {
        item: {
            type: Object,
            required: true,
        },

        loading: {
            type: Boolean,
        },
    },

    computed: {
        statusColor() {
            if (this.item.status_name === "Утвержден") {
                return "#00CC56";
            }

            return "#949494";
        },
    },
};
</script>

<style lang="scss">
.loan-modal-card-item {
    padding: 24px 16px;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;

    font-weight: 500;
}

.loan-modal-card-item__label {
    font-size: 14px;
    font-weight: 500;
    color: #949494;
    margin-bottom: 6px;

    &::first-letter {
        text-transform: capitalize;
    }
}

.loan-modal-card-item__value {
    font-size: 16px;
    font-weight: 500;
}
</style>
