<template>
    <section class="admin-card" :class="{['admin-card--theme-' + theme]: theme, 'admin-card--body-empty': bodyEmpty, 'admin-card--animated': animated}">
        <div class="admin-card__header">
            <slot name="pre-heading"></slot>
            <transition name="opacity">
                <AppIcon v-if="icon" class="admin-card__icon" :icon="icon"/>
            </transition>
            <transition name="opacity">
                <h2 v-if="title" class="admin-card__heading">
                    {{ getTranslationOrKey(title) }}
                </h2>
            </transition>
            <slot name="header"></slot>
            <div class="admin-card__actions">
              <slot name="actions" :classNames="classNames" :openAccordion="open"/>
              <div v-if="isOpening" class="app-accordion__button accordion-button" :class="{'accordion-button--opened': opened}" @click="toggle">
                {{ opened ? $t('components.common.collapse') : $t('components.common.open') }} <AppIcon class="accordion-button__icon" icon="chevron-down"/>
              </div>
            </div>
        </div>
      <transition :css="false" @enter="enter" @leave="leave">
          <div v-show="opened" class="admin-card__wrap">
              <div class="admin-card__body"><slot :classNames="classNames"/></div>
          </div>
      </transition>
    </section>
</template>
<script>

import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
  name: "AdminCardAccordion",
  mixins: [ translationExists ],
  components: {
    AppIcon: () => import("@/components/partials/AppIcon.vue")
  },
  props: {
    title: [String, Object],
    theme: String,
    icon: String,
    bodyEmpty: Boolean,
    isOpening: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      opened: true,
      animated: null,
    }
  },
  computed: {
    classNames() {
      return {
        'large': 'admin-card__large',
      }
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    toggle() {
      this.opened = !this.opened;
    },
    enter(el, done) {
      this.animated = true;
      el.style.overflow = 'hidden';
      el.style.height = '0px';
      el.style.opacity = '0';
      requestAnimationFrame(() => {
        el.style.height = el.scrollHeight + 'px';
        el.style.opacity = '1';
        setTimeout(() => {
          done();
          this.animated = false;
          el.style.overflow = '';
          el.style.height = '';
          el.style.opacity = '';
        }, 250);
      });
    },
    leave (el, done) {
      this.animated = true;
      el.style.overflow = 'hidden';
      el.style.height = el.scrollHeight + 'px';
      el.style.opacity = '1';
      requestAnimationFrame(() => {
        el.style.height = '0px';
        el.style.opacity = '0';
        setTimeout(() => {
          done();
          this.animated = false;
          el.style.overflow = '';
          el.style.height = '';
          el.style.opacity = '';
        }, 250);
      });
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';
.admin-card {
    $self: &;
    font-family: $font-primary;
    background-color: var(--color-white);
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 4px;
    &--animated {
        #{$self}__wrap {
            transition: height .25s, opacity .25s;
        }
    }
    &__body {
        padding: 28px 49px;
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }
    &__large {
        margin-left: -49px;
        margin-right: -49px;
    }
    &__header {
        display: flex;
        align-items: center;
        padding: 28px 49px 27px;
        border-bottom: 1px solid var(--color-border);
    }
    &__icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        color: var(--color-gray-lighten);
        margin-right: 16px;
        flex-shrink: 0;
    }
    &__heading {
        margin: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        flex-shrink: 0;
    }
    &__actions {
        margin-left: auto;
        margin-top: -16px;
        display: flex;
        flex-wrap: nowrap;
        > * {
            margin-left: 16px;
            margin-top: 16px;
        }
    }
    &--theme-table {
        #{$self}__body {
            padding-top: 0;
        }
        #{$self}__header {
            border-bottom: 0;
        }
    }
    &--theme-problem,
    &--theme-client {
        margin-left: 0;
        margin-right: 0;
        border-radius: 12px;
        #{$self}__header {
            padding: 16px 34px 15px 34px;
            min-height: 76px;
        }
        #{$self}__heading {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
        }
        #{$self}__body {
            padding: 24px 34px 52px;
        }
        #{$self}__icon {
            width: 24px;
            height: 24px;
            color: var(--color-gray-light);
        }
        #{$self}__large {
            margin-left: -34px;
            margin-right: -34px;
        }
    }
    &--theme-client-accordion {
        margin-left: 0;
        margin-right: 0;
        border-radius: 12px;
        #{$self}__header {
            padding: 16px 34px 15px 34px;
            min-height: 76px;
        }
        #{$self}__heading {
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
        }
        #{$self}__body {
            padding: 0;
        }
        #{$self}__icon {
            width: 24px;
            height: 24px;
            color: var(--color-gray-light);
        }
        #{$self}__large {
            margin-left: -34px;
            margin-right: -34px;
        }
    }
    &--theme-problem {
        #{$self}__header {
            padding-bottom: 30px;
            border-bottom: 0;
        }
        #{$self}__body {
            padding-top: 0;
            padding-bottom: 0;
        }
        #{$self}__heading {
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
        }
    }
    &--theme-multi-client {
        margin-left: 0;
        margin-right: 0;
        #{$self}__header {
            padding: 28px 32px 19px 64px;
            border-bottom: 0;
        }
        #{$self}__body {
            padding-left: 64px;
            padding-top: 0;
            padding-bottom: 0;
        }
        #{$self}__large {
            margin-left: -64px;
        }
    }
    &--theme-inline-shadow {
        margin-left: 0;
        margin-right: 0;
        box-shadow: var(--shadow-card);
        border-radius: 12px;
        #{$self}__header {
            padding-top: 16px;
            padding-bottom: 15px;
            padding-left: 32px;
        }
        #{$self}__body {
            padding-left: 32px;
        }
    }
    &--body-empty {
        display: flex;
        flex-direction: column;
        #{$self}__body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            padding: 0;
        }
        #{$self}__large {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>
