const basePath = "components.common.clientTypes"

const juridicalStatus = [
    {
        id: 0,
        name: basePath + ".physical"
    },
    {
        id: 1,
        name: basePath + ".legal"
    }
];

export default juridicalStatus;
