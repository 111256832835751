<template>
    <div>
        <div class="auto-calculator-error error-panel error-panel--type-page">
            <AppIcon class="error-panel__icon" icon="info-circle"/>
            {{ $t("components.common.errorThereIsNoInformationAboutTheProductYouSelectedOrTheServerIsNotResponding") }}
        </div>
        <div class="auto-calculator">
            <div class="auto-calculator__form-col">
                <div class="product-title mb-6-a">
                    <AppIcon class="product-title__icon" icon="folder-close" size="24"/>
                    {{ $t("components.common.kiaCarLoan") }}
                </div>
                <AppText class="mb-8-a" :default-margin="false" type="input-title">
                    {{ $t("components.common.currentlyThereIsAPromotionForKiaCars") }}<br>
                    {{ $t("components.common.whichCarAreYouInterestedIn") }}
                </AppText>
                <div class="edit-application mb-13-a">
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.automobileModel") }}</AppText>
                        <AppSelectA v-model="model.a" :items="items"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.equipment") }}</AppText>
                        <AppSelectA v-model="model.a" :items="items"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.bodyColor") }}</AppText>
                        <AppSelectA v-model="model.a" :items="items"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.repaymentScheme") }}</AppText>
                        <AppSelectA v-model="model.a" :items="items"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.amount") }}</AppText>
                        <AppRange v-model="model.b" class="w-100" step="1000" :currency-text="$t('components.common.som')" :max="1000000000" :footer="['0', $t('components.common.oneBillion')]"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.anInitialFee") }}</AppText>
                        <AppRange v-model="model.b" class="w-100" :min="30" :max="100" :footer="['30%', '100%']"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="input-title">{{ $t("components.common.creditTerm") }}</AppText>
                        <AppRange v-model="model.b" class="w-100" :currency-text="$t('components.common.months')" :min="36" :max="60" :footer="['36', '48', '60']"/>
                    </div>
                    <div class="edit-application__item edit-application__item--half content-card calculations-data-card">
                        <AppText type="calculations-info">
                            <div class="d-flex justify-content-between mb-1-5-a">
                                <div>{{ $t("components.common.anInitialFee") }}</div>
                                <div class="ml-auto">30%</div>
                            </div>
                            <div class="d-flex justify-content-between mb-1-5-a">
                                <div>{{ $t("components.common.carPrice") }}</div>
                                <div class="ml-auto">329 900 000 {{ $t("components.common.som") }}</div>
                            </div>
                        </AppText>
                        <AppText type="calculations-result" class="d-flex justify-content-between" theme="min">
                            <div>{{ $t("components.common.prepaymentAmount") }}</div>
                            <div class="ml-auto">98 970 000 {{ $t("components.common.som") }}</div>
                        </AppText>
                    </div>
                </div>
                <hr class="separator mb-6-a">
                <div class="edit-application align-items-center">
                    <div class="edit-application__item edit-application__item--half">
                        <AppText type="auto-value" theme="min" tag="span">
                            {{ $t("components.common.amountOfCredit") }}
                        </AppText>
                        <AppText type="auto-price" theme="min" tag="span">
                            1 463 000 000 {{ $t("components.common.som") }}
                        </AppText>
                    </div>
                    <div class="edit-application__item edit-application__item--half">
                        <div class="block-wrap">
                            <AppButton class="mr-auto" type="button" square theme="white-outline" tabindex="-1">
                                <AppIcon icon="delete"/>
                            </AppButton>
                            <AppButton class="pl-6-a pr-6-a" type="button" theme="gray" tabindex="-1">
                                {{ $t("common.cancel") }}
                            </AppButton>
                            <AppButton type="button">
                                {{ $t("components.common.calculate") }}
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
            <AutoResult class="auto-calculator__auto-col" show-price/>
        </div>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import AppRange from "../AppRange";
import AppButton from "../AppButton";
import AutoResult from "./AutoResult";
import AppText from "../AppText";
import AppSelectA from "../AppSelectA";
export default {
    name: 'AutoCalculator',
    components: {AppSelectA, AppText, AutoResult, AppButton, AppRange, AppIcon},
    data() {
        return {
            model: {
                a: 'h',
                b: 0
            },
            items: [
                {
                    name: 'Hello',
                    value: 'h'
                },
                {
                    name: 'World',
                    value: 'w'
                }
            ]
        }
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.calculations-data-card {
    --card-bg: #EDF2FF;
    --card-padding-x: 16px;
    --card-padding-y: 14px;
    color: #717171;
}
</style>
