import { ChatRoom, CallRoom } from "../services/chat/ChatModel";
import { get } from "./api";
import { CustomerData } from "../services/customer/CustomerModel";
import app from "../main";
import { getModifiedUserData } from "./other";
import chatMessagesService from "../services/chat/ChatMessagesService";

export const getUserData = (customerId, chatData) =>
    chatData.customers.find((c) => c.id === customerId);
export const getCustomerByPhone = (phone, chatData, isChat = true) => {
    const [dataToUpdate, update] = useUpdateChat(chatData);
    dataToUpdate.customerFetching = true;
    update();
    return get("/customers/phone/" + phone.replace(/\s/g, ""))
        .then((response) => {
            if (response.data.success) {
                /**
                 * @type {CustomerData[]}
                 */
                initCustomers(response.data.data, chatData, isChat);
            } else {
                throw new Error();
            }
            return response;
        })
        .catch((e) => {})
        .finally(() => {
            setTimeout(() => {
                dataToUpdate.customerFetching = false;
                update();
            }, 150);
        });
};

export const pingUpdatedFields = (function () {
    let pingTimeout = null;
    return (fields, chatData) => {
        clearTimeout(pingTimeout);
        const [dataToUpdate, update] = useUpdateChat(chatData);
        dataToUpdate.customerFieldsUpdated = [
            chatData.customerFieldsUpdated,
            ...fields,
        ];
        update();
        pingTimeout = setTimeout(() => {
            dataToUpdate.customerFieldsUpdated = [];
            update();
        }, 8000);
    };
})();

export const clarifyCustomer = (function () {
    const clarifiedUsers = [];
    return (customerId, chatData) => {
        const bank_client_id = getUserData(
            chatData.selectedCustomer,
            chatData,
            customerId
        ).bank_client_id;

        if (bank_client_id && !clarifiedUsers.includes(bank_client_id)) {
            setSearchMessage(
                { message: "components.common.clarification", type: "loading" },
                chatData
            );

            get("/aibs/clarify-customer/" + bank_client_id)
                .then(({ data }) => {
                    if (data.success) {
                        clarifiedUsers.push(bank_client_id);
                        if (data.data.updated) {
                            delete data.data.updated;
                            delete data.data.updated_at;
                            const [dataToUpdate, update] =
                                useUpdateChat(chatData);

                            const customers = [...chatData.customers];
                            const idx = customers.findIndex(
                                (c) => c.bank_client_id === bank_client_id
                            );
                            customers.splice(idx, 1, {
                                ...customers[idx],
                                ...getModifiedUserData(
                                    data.data,
                                    chatData.data.phone
                                ),
                            });
                            const customer = customers.find(el => el.bank_client_id === bank_client_id);

                            dataToUpdate.customers = customers;
                            if (chatData.selectedCustomer === customerId) {
                                if (customer) {
                                    dataToUpdate.customerForm = customer;
                                }
                                pingUpdatedFields(
                                    Object.keys(data.data),
                                    chatData
                                );
                            }
                            update();
                        }
                    }
                })
                .finally(() => {
                    if (chatData.selectedCustomer === customerId) {
                        setSearchMessage(
                            {
                                message: "components.common.bankClient",
                                type: "success",
                            },
                            chatData
                        );
                    }
                });
        } else if (bank_client_id) {
            setSearchMessage(
                { message: "components.common.bankClient", type: "success" },
                chatData
            );
        }
    };
})();
export const selectCustomer = (customerId, chatData) => {
    const [dataToUpdate, update] = useUpdateChat(chatData);
    let currentCustomer = chatData.selectedCustomer;
    dataToUpdate.isNewClient = false;
    dataToUpdate.searchFailed = false;
    dataToUpdate.selectedCustomer = customerId;

    dataToUpdate.selectedProblem = null;
    dataToUpdate.isNewProblem = !chatData.customersProblems[customerId].length;

    const userData = { ...getUserData(customerId, chatData) };
    dataToUpdate.customerForm = userData;
    // Возвращает телефон, который нужно выбрать. Если выбранный телефон отсутствует у клиента, то используем входящий, иначе первый попавшийся
    const [sp, ip, cp] = [
        chatData.selectedPhone,
        chatData.data.phone,
        userData.customer_phones,
    ];
    const isCustomerHasSelectedPhone =
        sp && cp.findIndex(({ phone }) => phone === sp) > -1;
    if (isCustomerHasSelectedPhone) {
        dataToUpdate.selectedPhone = sp;
    } else if (ip) {
        dataToUpdate.selectedPhone = ip;
    } else if (cp.length) {
        dataToUpdate.selectedPhone = cp[1].phone;
    } else {
        dataToUpdate.selectedPhone = "";
    }

    if (customerId !== currentCustomer) {
        dataToUpdate.customerFieldsUpdated = [];
        update();
        if (chatData?.chatRoom) {
            dataToUpdate.oldMessages = [];
            dataToUpdate.stopFetchingOldMessages = false;
            dataToUpdate.messagesPage = 0;
            update();
            chatMessagesService.fetchOldMessages(chatData);
        }
    } else {
        // Такой else здесь чтобы не нагружать систему кучей коммитов
        update();
    }

    if (chatData.isNewProblem) {
        app.$store.commit("setProblemsLoading", true);
        get(`applications/active/${customerId}`)
            .then((res) => {
                dataToUpdate.customersProblems = {
                    ...chatData.customersProblems,
                    [customerId]: res.data.data,
                };
                if (customerId === chatData.selectedCustomer) {
                    dataToUpdate.isNewProblem = !res.data.data.length;
                }
                update();
            })
            .catch((e) =>
                app.$store.commit(
                    "notifications/error",
                    "errors.somethingWentWrong"
                )
            )
            .finally(() => {
                app.$store.commit("setProblemsLoading", false);
            });
    }

    clarifyCustomer(customerId, chatData);
};

export const [clearSearchMessage, setSearchMessage] = (function () {
    let searchMessageDelay = null;
    return [
        // clear
        /**
         * @param {ChatRoom} chatData
         */
        (chatData) => {
            const [data, update] = useUpdateChat(chatData);
            clearTimeout(searchMessageDelay);
            data.searchMessageType = "";
            data.searchMessage = "";
            update();
        },
        // set
        ({ message, type }, chatData) => {
            const [data, update] = useUpdateChat(chatData);
            clearTimeout(searchMessageDelay);
            if (message != "") {
                searchMessageDelay = setTimeout(() => {
                    data.searchMessageType = "";
                    data.searchMessage = "";
                    update();
                }, 8000);
            }
            data.searchMessageType = type;
            data.searchMessage = message;
            update();
        },
    ];
})();

export class BaseChatCommand {
    constructor(chatData) {
        /**
         * @type {ChatRoom|CallRoom}
         */
        this.chatData = chatData;
    }

    /**
     * @abstract
     */
    execute() {
        throw new Error("Must implicit method");
    }
}

/**
 * Возвращает объект для обновления данных, а также функцию, которая запустит обновление
 * После выполнения update, сбросывает объект для обновления
 * @param {ChatRoom} chatData
 * @return {[ChatRoom,()=>void]}
 */
export const useUpdateChat = (chatData) => {
    /**
     * @type {ChatRoom}
     */
    const updateData = {};
    const update = () => {
        if (updateData.identificationUrls) {
            updateData.identificationUrls = chatData.identificationUrls.concat(
                updateData.identificationUrls
            );
        }

        if (updateData.messages) {
            app.$store.commit("chat/addChatMessagesList", {
                chatRoom: chatData.chatRoom,
                messages: updateData.messages,
            });
            delete updateData.messages;
        }

        if (chatData?.chatRoom) {
            // Обновляем чаты
            app.$store.commit("chat/updateChat", {
                chatRoom: chatData.chatRoom,
                ...updateData,
            });
        } else {
            // Обновляем звонки
            app.$store.commit("callData/updateCall", {
                ...updateData,
            });
        }

        Object.keys(updateData).forEach((key) => {
            delete updateData[key];
        });
    };

    return [updateData, update];
};

export const initCustomers = (data, chatData, isChat) => {
    const [dataToUpdate, update] = useUpdateChat(chatData);

    if (data.length > 0) {
        dataToUpdate.customersProblems = {};
        dataToUpdate.problemsActions = {};
        dataToUpdate.customers = data.map((c) => {
            dataToUpdate.customersProblems[c.id] = [];
            dataToUpdate.problemsActions[c.id] = [];
            return new CustomerData(c, chatData.data.phone);
        });

        if (data.length === 1) {
            update();
            selectCustomer(data[0].id, chatData);
        } else {
            dataToUpdate.isNewClient = false;
            dataToUpdate.searchFailed = false;
            dataToUpdate.selectedCustomer = null;
            update();
        }
    } else {
        if (isChat) {
            dataToUpdate.isNewClient = false;
            dataToUpdate.searchFailed = true;
            dataToUpdate.selectedCustomer = null;
            update();
        }
    }
};

export const addCustomer = (customer, chatData) => {
    const [dataToUpdate, update] = useUpdateChat(chatData);

    dataToUpdate.customers = [
        ...chatData.customers,
        new CustomerData(customer, chatData.data.phone),
    ];
    dataToUpdate.customersProblems = {
        ...chatData.customersProblems,
        [customer.id]: [],
    };
    dataToUpdate.problemsActions = {
        ...chatData.problemsActions,
        [customer.id]: [],
    };

    update();
};
