const basePath = "components.common.genders"

const clientGender = [
    {
        name: basePath + ".male",
        id: 1
    },
    {
        name: basePath + ".female",
        id: 2
    }
];

export default clientGender;
