
import Vue from 'vue';
import LoadingIcon from "./LoadingIcon.vue";
export default Vue.extend({
    name: 'LoadingContent',
    components: {LoadingIcon},
    props: {
        loading: Boolean
    },
});
