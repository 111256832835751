<template>
    <div class="client-base-auto-debit">
        <AppTabs v-model="tab.active" :items="tabList" theme="loan">
            <template #body.cards>
                <AutoDebitCardStatsTable
                    :items="autoDebitByCard.list"
                    :loading="autoDebitByCard.loading"
                    :date-range="autoDebitByCard.dateRange"
                    :card-number="autoDebitByCard.cardNumber"
                    :loan-ids="customerLoanIds"
                    :is-call="isCall"
                    @startDate="onDebitCardStartDateChange"
                    @endDate="onDebitCardEndDateChange"
                    @card="onDebitCardCardChange"
                    @loanId="onLoanIdClick"
                    @update="loadAutoDebitByCard"
                />
            </template>
            <template #body.debit>
                <AutoDebitGeneralStatsTable
                    :items="autoDebit.list"
                    :loading="autoDebit.loading"
                    :date-range="autoDebit.dateRange"
                    :loan-ids="customerLoanIds"
                    :is-call="isCall"
                    @startDate="onAutoDebitStartDateChange"
                    @endDate="onAutoDebitEndDateChange"
                    @loanId="onLoanIdClick"
                    @update="loadAutoDebit"
                />
            </template>
        </AppTabs>

        <LoanModal
            v-if="modals.loan.status"
            ref="loanModal"
            :modals="modals"
            :values="{
                loanSchedule: 10,
                loanHistoryAccountPage: 10,
                getLoanValue: getLoanValue,
                getAccountValueById: getAccountValueById,
                getBranch: getBranch,
            }"
            :cards="customerCards"
            @close-modal="modals.loan.status = false"
        />
    </div>
</template>

<script>
import moment from "moment";
import { get } from "../../helpers/api";
import HUMO_LOGO from "../../assets/img/Humo.svg";
import UZCARD_LOGO from "../../assets/img/uzcard.svg";
import MASTER_LOGO from "../../assets/img/master.svg";
import VISA_LOGO from "../../assets/img/Visa.svg";
import UPI_LOGO from "../../assets/img/UnionPay.svg";

import AppTabs from "../partials/AppTabs.vue";
import AutoDebitGeneralStatsTable from "./auto-debit-general-stats-table.vue";
import AutoDebitCardStatsTable from "./auto-debit-card-stats-table.vue";
import LoanModal from "../LoanModal.vue";

export default {
    name: "ClientBaseAutoDebit",

    props: {
        processingCards: {
            type: Object,
            default: () => ({}),
        },

        client: {
            type: Object,
        },

        isCall: {
            type: Boolean,
        },
    },

    components: {
        AppTabs,
        AutoDebitGeneralStatsTable,
        AutoDebitCardStatsTable,
        LoanModal,
    },

    data: () => ({
        tab: {
            active: "cards",
        },

        autoDebit: {
            loading: false,
            list: [],
            dateRange: {
                start: "",
                end: "",
            },
        },
        autoDebitByCard: {
            loading: false,
            list: [],
            cardNumber: "",
            dateRange: {
                start: "",
                end: "",
            },
        },

        modals: {
            loan: {
                status: false,
                model: null,
                id: null,
                schedule: [],
                account: {
                    info: false,
                    dateBegin: moment()
                        .subtract(1, "month")
                        .format("DD.MM.YYYY"),
                    dateClose: moment().format("DD.MM.YYYY"),
                    history: [],
                },
                guar: [],
            },
        },

        customer: {
            loans: [],
            deposits: [],
            cards: [],
        },
    }),

    methods: {
        initFilterData() {
            const dateRange = this.getDefaultDateRange();
            this.autoDebit.dateRange.start = dateRange[0];
            this.autoDebit.dateRange.end = dateRange[1];
            this.autoDebitByCard.dateRange.start = dateRange[0];
            this.autoDebitByCard.dateRange.end = dateRange[1];

            this.loadAutoDebit();
        },

        loadAutoDebit() {
            this.autoDebit.loading = true;
            get("/aibs/loan-autopay-report", {
                params: {
                    dateStart: this.autoDebit.dateRange.start,
                    dateEnd: this.autoDebit.dateRange.end,
                    limit: 100,
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        this.autoDebit.list = response.data.data;
                    }

                    this.autoDebit.loading = false;
                })
                .catch(() => {
                    this.autoDebit.loading = false;
                });
        },

        onAutoDebitStartDateChange(val) {
            this.autoDebit.dateRange.start = val;
        },

        onAutoDebitEndDateChange(val) {
            this.autoDebit.dateRange.end = val;
        },

        loadAutoDebitByCard() {
            this.autoDebitByCard.loading = true;
            get("/aibs/loan-autopay-report-by-card", {
                params: {
                    dateStart: this.autoDebitByCard.dateRange.start,
                    dateEnd: this.autoDebitByCard.dateRange.end,
                    cardNumber: this.autoDebitByCard.cardNumber,
                    limit: 100,
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        this.autoDebitByCard.list = response.data.data;
                    }

                    this.autoDebitByCard.loading = false;
                })
                .catch(() => {
                    this.autoDebitByCard.loading = false;
                });
        },

        onDebitCardStartDateChange(val) {
            this.autoDebitByCard.dateRange.start = val;
        },

        onDebitCardEndDateChange(val) {
            this.autoDebitByCard.dateRange.end = val;
        },

        onDebitCardCardChange(val) {
            this.autoDebitByCard.cardNumber = val;
        },

        onLoanIdClick(id) {
            console.log(id);
            this.showModal(id);
        },

        getDefaultDateRange() {
            const today = new Date();

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0
            );

            return [
                firstDay.toLocaleDateString("ru"),
                lastDay.toLocaleDateString("ru"),
            ];
        },

        getLoanValue(account1, account2 = false) {
            let result;
            if (this.modals.loan.model !== undefined) {
                this.loanNumbers.forEach((k) => {
                    if (this.modals.loan.model["loan" + k] !== undefined) {
                        let acc = this.modals.loan.model["loan" + k];
                        let accSaldo = this.modals.loan.model["saldo" + k];
                        if (acc.substr(0, 5) === account1) {
                            result = accSaldo;
                        }
                    }
                });
            }
            return result ? this.formatSum(result, true) : 0;
        },

        getAccountValueById(id) {
            let result = false;
            if (this.modals["loan"].model["loan" + id] !== undefined) {
                result = this.modals.loan.model["saldo" + id];
            }
            return result ? this.formatSum(result, true) : 0;
        },

        getBranch(type) {
            let txt = "";
            if (type === "loan") {
                if (this.modals.loan.model.codeFilial)
                    txt += this.modals.loan.model.codeFilial;
                if (this.modals.loan.model.filialName)
                    txt += " (" + this.modals.loan.model.filialName + ")";
            } else if (type === "deposit") {
                if (this.modals.deposit.model.filialCode)
                    txt += this.modals.deposit.model.filialCode;
                if (this.modals.deposit.model.filialName)
                    txt += " (" + this.modals.deposit.model.filialName + ")";
            }
            return txt;
        },

        formatSum(value, cent = false) {
            let result;
            value = cent ? value / 100 : value;
            if (value !== undefined) {
                result = value.toString().replaceAll(" ", "");
                result = Number(result).toFixed(0);
                result = result
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                return result.replaceAll(",", " ");
            }
        },

        showModal(id) {
            this.modals.loan.model = null;
            this.modals.loan.id = null;
            this.modals.loan.schedule = [];
            let tempName = this.customer.loans.filter((item) => {
                if (item["loanId"] === id) return item;
            });

            if (tempName.length) {
                this.modals.loan.model = tempName[0];
                this.modals.loan.id = tempName[0]["loanId"];
                this.modals.loan.status = true;
            }
            this.goToAccountList();
        },

        goToAccountList() {
            this.modals.loan.account.info = false;
            this.modals.loan.account.dateBegin = moment()
                .subtract(1, "month")
                .format("DD.MM.YYYY");
            this.modals.loan.account.dateClose = moment().format("DD.MM.YYYY");
            this.modals.loan.account.history = [];
        },

        loadCustomerLoans() {
            if (this.client.bank_client_id && !this.customer.loans.length) {
                get("/aibs/loans?clientUid=" + this.client.bank_client_id)
                    .then((r) => {
                        this.loansLoading = false;
                        if (r.data.success) {
                            this.customer.loans = r.data.data;
                        }
                    })
                    .catch(() => {
                        this.loansLoading = false;
                    });
            }
        },
        loadCustomerCards() {
            if (this.client.bank_client_id) {
                this.$set(this.customer, "cards", {});
                get("/aibs/cards/" + this.client.bank_client_id)
                    .then((r) => {
                        if (r.data.success) {
                            this.$set(this.customer, "cards", r.data.data);
                        }
                    })
                    .catch(() => {});
            }
        },
    },

    computed: {
        tabList() {
            return [
                {
                    name: "common.autoDebitByCardNumber",
                    key: "cards",
                },
                {
                    name: "common.autoDebitGeneralStats",
                    key: "debit",
                },
            ];
        },

        customerCards() {
            const keys = Object.keys(this.customer.cards);
            if (!keys.length) return [];

            return keys.reduce((acc, key) => {
                const arr = this.customer.cards[key];
                let cards = [];
                if (arr) {
                    cards = arr.map((card) => {
                        let state;
                        let logo;

                        switch (key) {
                            case "HUMO":
                                state =
                                    card.stateCode === 0
                                        ? "active"
                                        : "disabled";
                                logo = HUMO_LOGO;
                                break;
                            case "INTERNATIONAL":
                                state =
                                    card.stateCode === 2
                                        ? "active"
                                        : "disabled";

                                switch (card.paySysDescription.toUpperCase()) {
                                    case "MASTERCARD": {
                                        logo = MASTER_LOGO;
                                        break;
                                    }
                                    case "VISA": {
                                        logo = VISA_LOGO;
                                        break;
                                    }
                                    case "UPI": {
                                        logo = UPI_LOGO;
                                        break;
                                    }
                                }
                                break;
                            case "UZCARD":
                                state =
                                    card.stateCode === "000"
                                        ? "active"
                                        : "disabled";
                                logo = UZCARD_LOGO;
                                break;
                        }
                        let typeName = key.toLowerCase();

                        if (key === "INTERNATIONAL") {
                            typeName = card.paySysDescription;
                        }

                        let obj = {
                            ...card,
                            type: key,
                            typeName,
                            state,
                            logo,
                        };

                        const cData = this.processingCards[card.cardNumber];
                        if (cData && cData.data && !cData.error) {
                            const d =
                                this.processingCards[card.cardNumber].data;
                            obj = {
                                ...obj,
                                stateName: d.statusDescription,
                                state: d.isActive ? "active" : "disabled",
                                cardBalance: d.balance,
                                isActionsPossible: d.isActionsPossible,
                                sms: d.sms,
                                phone: d.phone,
                                pincnt: d.pincnt,
                            };
                        }

                        return obj;
                    });
                }
                return acc.concat(cards);
            }, []);
        },

        customerLoanIds() {
            return this.customer.loans.map((el) => el.loanId);
        },
    },

    mounted() {
        this.initFilterData();

        setTimeout(() => {
            this.loadCustomerLoans();
            this.loadCustomerCards();
        }, 100);
    },
};
</script>
