<template>
    <label class="app-input-a" :class="{
            ['app-input-a--error']: error,
            ['app-input-a--disabled']: disabled,
            ['app-input-a--theme-' + theme]: theme,
            ['app-input-a--size-' + size]: size,
            'app-input-a--readonly': readonly,
            ...focusClass,
        }" @click="$emit('click')">
        <div v-if="prepend" v-resize.initial="onResize" class="app-input-a__slot app-input-a__slot--prepend" ref="prepend">
            <slot name="prepend"/>
        </div>
        <input :value="value" class="app-input-a__field" v-bind="parsedAttrs" v-on="parsedListeners" ref="input" :disabled="disabled || readonly" :style="inputStyle"
               @input="onInput"/>
        <div v-if="append || clearable" class="app-input-a__slot app-input-a__slot--append" ref="append">
            <div v-show="clearable && value.length" class="app-input-a__action spacer-button" @click="$emit('input', '')"><AppIcon icon="close" size="16"/></div>
            <slot name="append"/>
        </div>
    </label>
</template>
<script>
import AppIcon from "./AppIcon";
import {focusMixin} from "../../mixins/InputMixin";
import resize from "vue-resize-directive";

const d = (size) => `max(${size}px, var(--input-sides, 15px))`

export default {
    name: 'AppInputA',
    components: {AppIcon},
    mixins: [focusMixin],
    directives: {
        resize,
    },
    props: {
        error: Boolean,
        theme: String,
        disabled: Boolean,
        size: String,
        append: Boolean,
        prepend: Boolean,
        clearable: Boolean,
        readonly: Boolean,

        value: {
            type: [String, Number],
            default: ''
        },
    },
    inheritAttrs: false,
    data() {
        return {
            prependSize: 0,
            appendSize: 0,
            focused: false
        }
    },
    computed: {
        inputStyle() {
            return {
                'padding-left': d(this.prependSize),
                'padding-right': d(this.appendSize)
            };
        },
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            delete listeners.click;
            return {...listeners, ...this.focusListeners};
        },
    },
    watch: {
        'value': 'calculateAppendSize'
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        onInput(e) {
            if(this.disabled || this.readonly) return;

            this.$emit('input', e.target.value);
        },
        calculateAppendSize() {
            this.$nextTick(() => {
                if(this.$refs.append) {
                    this.appendSize = this.$refs.append.offsetWidth;
                }
                if(this.$refs.prepend) {
                    this.prependSize = this.$refs.prepend.offsetWidth;
                }
            });
        },
        onResize($val, $val2) {
            this.calculateAppendSize();
        },
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/animation.scss';
@import '../../assets/scss/variables';

.app-input-a {
    $self: &;
    border: var(--input-border, 1px solid #E1E1E1);
    background-color: var(--input-bg, #fff);
    border-radius: var(--input-radius, 4px);
    height: var(--input-height, 48px);
    position: relative;

    &__field {
        -webkit-appearance: none;
        -moz-appearance: textfield !important;
        letter-spacing: var(--input-letter-spacing, #{$input-letter-spacing});
        font: var(--input-font, #{$input-font});
        height: 100%;
        width: 100%;
        padding: 0 var(--input-sides, #{$input-sides});
        padding-right: max(var(--input-append-size, 0px), var(--input-sides, #{$input-sides}));
        border: 0;
        background-color: transparent;
        color: var(--input-color, #{$input-color});
        outline: none !important;
        @include placeholder {
            color: var(--input-placeholder, #{$input-placeholder-color});
        }
        &::-webkit-inner-spin-button {
            display: none;
        }
    }
    &__slot {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: var(--input-bg, #fff);
        border-radius: inherit;
        letter-spacing: var(--input-letter-spacing, #{$input-letter-spacing});
        font: var(--input-font, #{$input-font});
        color: var(--input-placeholder, #{$input-placeholder-color});
        &--append {
            right: 0;
            padding-right: var(--input-sides, #{$input-sides});
            padding-left: calc(var(--input-sides, #{$input-sides}) / 2);
        }
        &--prepend {
            left: 0;
            padding-left: var(--input-sides, #{$input-sides});
            padding-right: calc(var(--input-sides, #{$input-sides}) / 2);
        }
    }
    &__action {
        width: 16px;
        height: 16px;
        margin-right: 16px;
        color: var(--input-icon-color, #{$input-icon-color});
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &--disabled {
        --input-border: 1px solid #{$input-border-disabled};
        --input-bg: #{$input-bg-disabled};
        cursor: not-allowed;
    }
    &--readonly {
        #{$self}__field {
            user-select: none;
        }
    }
    &--error {
        --input-border: 1px solid #{$input-border-error};
        animation: error 0.5s 1 both;
    }
}
</style>
