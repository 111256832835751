<template>
    <div class="external-task-item">
        <div
            ref="content"
            class="external-task-item__content"
            :class="{ expanded: isExpanded }"
        >
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('external-task.system')"
                    :value="systemText"
                    :loading="loading"
                />
                <ExternalTaskCol
                    :label="$t('external-task.taskId')"
                    :value="`№${item.id}`"
                    :loading="loading"
                />
            </div>
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('common.form.project')"
                    :value="task.project?.name"
                    :loading="loading"
                />
                <ExternalTaskCol
                    :label="$t('common.form.tracker')"
                    :value="task.tracker?.name"
                    :loading="loading"
                />
            </div>
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('common.form.responsibleUser')"
                    :value="task.assigned_to?.name"
                    :loading="loading"
                />
                <ExternalTaskCol
                    :label="$t('common.form.problem-theme')"
                    :value="task.subject"
                    :loading="loading"
                />
            </div>
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('external-task.author')"
                    :value="task.author?.name"
                    :loading="loading"
                />
                <ExternalTaskCol
                    :label="$t('common.form.status')"
                    :value="task.status?.name"
                    :loading="loading"
                />
            </div>
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('external-task.createDate')"
                    :value="formatDate(task.created_on)"
                    :loading="loading"
                />
                <ExternalTaskCol
                    :label="$t('external-task.closeDate')"
                    :value="formatDate(task.updated_on)"
                    :loading="loading"
                />
            </div>
            <div class="external-task-item__row">
                <ExternalTaskCol
                    :label="$t('common.form.essence-of-the-problem')"
                    :value="task.description"
                    :loading="loading"
                />
            </div>

            <div class="external-task-item__custom-fields">
                <ExternalTaskCol
                    v-for="field in customFieldsList"
                    :key="field.id"
                    :label="field.name"
                    :value="field.value"
                    :loading="loading"
                />
            </div>

            <ExternalTaskComments :comments="commentList" />
        </div>
        <div
            class="external-task-item__action"
            :class="{ expanded: isExpanded }"
            @click="onExpansionClick"
        >
            {{ actionText }}
            <AppIcon icon="chevron-down" />
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";
import ExternalTaskCol from "./external-task-col.vue";
import ExternalTaskComments from "./external-task-comments.vue";
import { parseDate } from "@/helpers/dates"
import { get } from "../../../../helpers/api";

export default {
    name: "ExternalTaskItem",

    components: { AppIcon, ExternalTaskCol, ExternalTaskComments },

    props: {
        isExpanded: {
            type: Boolean,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        loading: false,

        task: {},
    }),

    methods: {
        onExpansionClick() {
            this.$emit("click", this.item);

            if (this.isExpanded) {
                this.contentDiv.scrollTop = 0;
            }

            if (this.task.id === undefined) {
                this.loadInfo();
            }
        },

        loadInfo() {
            this.loading = true;

            get(`/redmine/issues/${this.item.id}`)
                .then((response) => {
                    if (response.data.success && response.data.data.issue) {
                        this.task = response.data.data.issue;
                    }
                })
                .catch((e) => {
                    this.$store.commit("notifications/error", e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        formatDate(val) {
            return parseDate(val)
        },
    },

    computed: {
        actionText() {
            if (this.isExpanded) {
                return this.$t("external-task.close");
            }

            return this.$t("external-task.open");
        },

        systemText() {
            if (this.item && this.item.system) {
                return this.$t(`external-task.systemValue.${this.item.system}`);
            }
            return "-";
        },

        customFieldsList() {
            if (this.task.custom_fields) {
                return this.task.custom_fields;
            }
            return [];
        },

        contentDiv() {
            const div = document.createElement("div");
            return this.$refs.content ?? div;
        },

        commentList() {
            if (this.task.journals) {
                return this.task.journals;
            }
            return [];
        },
    },
};
</script>

<style lang="scss">
.external-task-item {
    padding: 12px 0;
    background: var(--color-white-bg);
    border-radius: 8px;
    position: relative;

    display: flex;
    align-items: flex-start;
}

.external-task-item__content {
    flex-grow: 1;
    padding: 12px 144px 12px 34px;
    height: 66px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 24px;

    &.expanded {
        height: 264px;
        overflow-y: scroll;
    }
}

.external-task-item__action {
    user-select: none;
    color: var(--color-primary);
    min-width: 110px;
    text-align: right;

    position: absolute;
    top: 24px;
    right: 34px;

    &:hover {
        cursor: pointer;
        color: var(--color-danger);
    }

    svg {
        width: 16px;
        margin-bottom: 2px;
    }

    &.expanded {
        svg {
            transform: rotate(180deg);
        }
    }
}

.external-task-item__row {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.external-task-item__col {
    flex: 1;
}

.external-task-item__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-gray-lighten);
    margin-bottom: 2px;
}

.external-task-item__value {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    .skeleton {
        height: 20px;
        width: 100%;

        background: var(--color-border-light);
    }
}

.external-task-item__custom-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}
</style>
