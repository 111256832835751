<template>
    <div class="external-task-list">
        <ExternalTaskItem
            v-for="task in taskList"
            :key="task.id"
            :is-expanded="activeTaskId === task.id"
            :item="task"
            @click="onTaskExpansion"
        />
    </div>
</template>

<script>
import ExternalTaskItem from "./external-task-item.vue";

export default {
    name: "ExternalTaskList",

    components: { ExternalTaskItem },

    props: {
        taskList: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        activeTaskId: 0,
    }),

    methods: {
        onTaskExpansion(task) {
            if (this.activeTaskId !== task.id) {
                this.activeTaskId = task.id;
            } else {
                this.activeTaskId = 0;
            }
        },
    },
};
</script>

<style lang="scss">
.external-task-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
</style>
