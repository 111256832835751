<template>
    <textarea class="app-textarea-a" :class="{
        'app-textarea-a--error': error,
        'app-textarea-a--disabled': disabled,
        ['app-textarea-a--size-' + size]: size,
        ...focusClass
    }" :value="value" v-bind="parsedAttrs" v-on="parsedListeners" :disabled="disabled" ref="input" :style="{height: height + 'px', minHeight : minHeight || 'auto'}" @input="onInput" @mousedown="onMouseDown"/>
</template>
<script>
import {focusMixin} from "../../mixins/InputMixin";

export default {
    name: 'AppTextareaA',
    inheritAttrs: false,
    mixins: [focusMixin],
    props: {
        error: Boolean,
        disabled: Boolean,
        size: String,
        minHeight: String,
        value: {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {
            height: 0,
            resized: false
        }
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return {...listeners, ...this.focusListeners};
        },
    },
    mounted() {
        this.setHeight();
    },
    watch: {
        value: 'setHeight',
    },
    methods: {
        onMouseDown(e) {
            const oldHeight = this.height;
            window.addEventListener('mouseup', () => {
                const newHeight = this.$refs.input.offsetHeight;
                this.resized = oldHeight !== newHeight && newHeight > 48;
            }, { once: true });
        },
        focus() {
            if(this.$refs.input) {
                this.$refs.input.focus({ preventScroll: true });
            }
        },
        setHeight() {
            if(this.resized) return;
            if(this.$refs.input) {
                this.height = 0;
                this.$nextTick(() => {
                    this.height = Math.min(this.$refs.input.scrollHeight, 248);
                });
            }
        },
        onInput(e) {
            this.$emit('input', e.target.value)
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/animation';
@import '../../assets/scss/variables';
.app-textarea-a {
    height: var(--input-height, 48px);
    min-height: 48px;
    resize: vertical;
    border-color: var(--color-border);

    width: 100%;
    padding: var(--input-sides-y, #{$input-sides}) var(--input-sides, #{$input-sides}) var(--input-sides-y, 11px);
    padding-right: max(var(--input-append-size, 0px), var(--input-sides, #{$input-sides}));
    border: var(--input-border, 1px solid #E1E1E1);
    border-radius: var(--input-radius, 4px);
    background-color: var(--input-bg, #fff);
    outline: none !important;

    letter-spacing: var(--input-letter-spacing, #{$input-letter-spacing});
    font-variant: tabular-nums;
    font: var(--input-font, #{$input-font});

    @include placeholder {
        color: var(--input-placeholder, #{$input-placeholder-color});
    }
    &::-webkit-inner-spin-button {
        display: none;
    }

    &--error {
        --input-border: 1px solid #{$input-border-error};
        animation: error 0.5s 1 both;
    }
    &--disabled {
        --input-border: 1px solid #{$input-border-disabled};
        --input-bg: #{$input-bg-disabled};
        cursor: not-allowed;
    }
}
</style>
