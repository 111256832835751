<template>
    <div class="no-client-monitoring">
        <img src="@/assets/img/monitoring-select-customer-image.webp" alt="" class="no-client-monitoring__image">
        <div class="no-client-monitoring__text">
            🤔 {{ $t("components.common.compare-data-not-success") }}
        </div>

    </div>
</template>
<script>
import AppInput from "../../AppInput";
import AppButton from "../../AppButton";
export default {
    name: "NoClientCompareData"
}
</script>
<style lang="scss">
.no-client-monitoring {
    padding: 48px 64px 68px;
    margin-top: 72px;
    margin-left: 64px;
    margin-right: 64px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__image {
        width: 128px;
        height: 128px;
        margin-bottom: 32px;
    }
    &__text {
        margin-bottom: 24px;
        max-width: 462px;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
    }
}
.client-search-form {
    display: flex;
    &__input {
        margin-right: 20px;
        width: 276px;
        position: relative;
    }
    &__placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        position: absolute;
        left: 18px;
        top: 14px;
    }
    &__field {
        width: 100%;
        padding-left: 64px;
    }
}
</style>
