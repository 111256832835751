<template>
    <div class="loan-info-row" :class="{'loan-info-row--clickable': clickable, 'loan-info-row--large': large}" @click.prevent="$emit('click')">
        <div class="loan-info-row__title font_acrom_regular">
            <span style="padding-right: 4px;">
                {{ getTranslationOrKey(title) }}
            </span>
        </div>
        <div class="loan-info-row__lines"></div>
        <div class="loan-info-row__value font_montserrat_bold">
            <span style="padding-left: 4px;">
                {{value || defaultValue}}
            </span>
        </div>
    </div>
</template>
<script>
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
    name: 'LoanInfoRow',
    mixins: [ translationExists ],
    props: {
        title: {
            type: [String, Number, Object],
            required: true
        },
        defaultValue: {
            type: String,
            default: '-'
        },
        value: [String, Number],
        clickable: Boolean,
        large: Boolean,
        link: Boolean
    }
}
</script>
<style lang="scss">
.loan-info-row {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    position: relative;
    font-weight: 500;

    &__title {
        flex-grow: 1;
        position: relative;
        z-index: 2;
    }
    &__value {
        text-align: right;
        position: relative;
        z-index: 2;
    }
    &--clickable {
        cursor: pointer;


        .loan-info-row__title {
            color: var(--color-primary);
            text-decoration: underline;
        }
    }
    &--large {
        font-size: 20px;
        line-height: 24px;
    }
}

.loan-info-row__lines {
    position: absolute;
    width: 100%;
    border-top: 1px dashed #E1E1E1;
    z-index: 1;
}

.loan-info-row__title,
.loan-info-row__value {

    span {
        background: white;
    }
}
</style>
