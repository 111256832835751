export enum SubjectTypeValue {
    Individual = 0,
    Entity = 1,
}

export enum SubjectTypeText {
    Individual = "common.individual",
    Entity = "common.entity",
}

interface ISubjectType {
    value: number;
    name: string;
}

export const subjectTypeArray: ISubjectType[] = [
    {
        value: SubjectTypeValue.Individual,
        name: SubjectTypeText.Individual,
    },
    {
        value: SubjectTypeValue.Entity,
        name: SubjectTypeText.Entity,
    }
];

export const subjectTypeObjectForTable: Record<number, string> = {
    [SubjectTypeValue.Individual]: SubjectTypeText.Individual,
    [SubjectTypeValue.Entity]: SubjectTypeText.Entity
};
