/**
 * checkbox functions
 * @type {{SEND_SMS: number}}
 */
const FUNCTIONS = {
    SEND_SMS : 1,
    SEND_SMS_CHAT_BOT : 3,
    SEND_SMS_MOB_APP : 4,
    SEND_EMAIL: 5,
    SEND_SMS_FFIN_SITE: 6
};
export default FUNCTIONS;
