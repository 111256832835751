<template>
    <div v-clickaway="clearIdentificationTempPhone" class="button-double button-double--chat" :class="{'button-double--focus': focused, 'button-double--active': visible}">
        <div class="button-double__front">
            <form @submit.prevent="onSubmit">
                <label class="phone-identification" :for="id">
                    <AppButton class="phone-identification__button" v-tooltip="$t('components.common.closePanel')" type="button" theme="danger" size="medium-extra" round @click="clearIdentificationTempPhone"><AppIcon icon="close-small" size="18"/></AppButton>
                    <span class="phone-identification__pre">+998</span>
                    <input v-model="identificationTempPhone" :id="id" class="phone-identification__input" v-mask="'## ### ## ##'" autocomplete="off" ref="identification-input"
                           autofocus
                           placeholder="XX XXX XX XX" @blur="focused = false" @focus="focused = true"/>
                    <AppButton class="phone-identification__button" v-tooltip="$t('components.common.identifyWithPhone')" type="submit" theme="success-flat" size="medium-extra" round><AppIcon icon="send-phone"/></AppButton>
                </label>
            </form>
        </div>
        <div class="button-double__back">
            <LoadingContent key="b" :loading="loading">
                <AppButton :theme="buttonTheme" size="medium-extra" v-tooltip="buttonTooltipText" round @click="open">
                    <AppIcon :icon="buttonIcon"/>
                </AppButton>
            </LoadingContent>
        </div>
    </div>

</template>
<script>
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import LoadingContent from "../LoadingContent";
import clickaway from '../../../directives/clickaway';
export default {
    name: 'PhoneIdentification',
    components: {LoadingContent, AppIcon, AppButton},
    directives: {
        clickaway
    },
    props: {
        id: {
            type: String,
            required: true
        },
        defaultValue: {
            type: String,
            default: ''
        },
        loading: Boolean,
        buttonTooltipText: {
            type: String,
            default: 'Открыть ввод телефона'
        },
        buttonIcon: {
            type: String,
            default: 'send-phone'
        },
        buttonTheme: String
    },
    data() {
        const pDefault = this.defaultValue.startsWith('0') ? this.defaultValue.slice(1) : this.defaultValue;
        return {
            pDefault,
            identificationTempPhone: pDefault,
            visible: false,
            focused: false
        }
    },
    methods: {
        open() {
            this.visible = true;
            this.focus();
        },
        focus() {
            this.$refs["identification-input"].focus()
        },
        clearIdentificationTempPhone() {
            this.identificationTempPhone = this.pDefault;
            this.visible = false;
        },
        onSubmit() {
            const phone = this.identificationTempPhone.split(' ').join('');
            if(phone.length !== 9) {
                return this.$store.commit('notifications/error', 'Пожалуйста, введите корректный телефон');
            }
            this.$emit('submit', phone);
            this.clearIdentificationTempPhone();
        },
    }
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.phone-identification {
    font-family: $font-secondary;
    background: #EDF2FF;
    border-radius: 50px;
    height: 44px;
    padding: 13px 0 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #949494;
    cursor: text;
    &__pre {
        margin-right: 5px;
        margin-left: 12px;
        width: 36px;
    }
    &__input {
        font-family: $font-secondary;
        width: 102px;
        margin-right: 22px;
        background-color: transparent;
        border: 0;
        padding: 0;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        height: 20px;
        outline: none;
        @include placeholder {
            color: inherit;
        }
    }
    &__button {
        margin: -13px 0 -12px;
        flex-shrink: 0;
    }
}
</style>
