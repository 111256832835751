interface ICalculatorForm {
    productAmount: number,
    loan_summa: number,
    loan_calc_type: number,
    loan_time_benefits: number,
    loan_time: number,
    loan_deposit: number,
    loan_percent: number
}

interface IDepositCalculatorForm {
    deposit_currency: string,
    deposit_summa: number,
    deposit_percent: number,
    deposit_term: number
}

export const CREDIT_CALCULATOR_FORM = (): ICalculatorForm => ({
    productAmount: 0,
    loan_summa: 0,
    loan_calc_type: 1,
    loan_time_benefits: 0,
    loan_time: 36,
    loan_deposit: 30,
    loan_percent: 30,
});

export const DEPOSIT_CALCULATOR_FORM = (): IDepositCalculatorForm => ({
    deposit_currency: "UZS", /* UZS,USD,EUR */
    deposit_summa: 0,
    deposit_percent: 19,
    deposit_term: 12 /* month or day:370 */
});


