import app from '../../main';
import ChatController, {LEAVE, MESSAGE} from "../ChatController";
import {get} from "../../helpers/api";
import {parseMessageFromHistory} from "../../helpers/chats";
import {useUpdateChat} from "../../helpers/customerHelpers";

let chatLoading = [];
class ChatMessagesService {
    listen() {
        ChatController.on(MESSAGE, this.onMessage);
        ChatController.on(LEAVE, this.onLeaveChat);
    }
    destroy() {
        ChatController.off(MESSAGE, this.onMessage);
        ChatController.off(LEAVE, this.onLeaveChat);
    }
    onMessage(message) {
        const chatData = app.$store.state.chat.chatsData[message.chatRoom];

        if(!chatData) {
            return;
        }

        const msg = {
            ...message,
            avatar: app.$store.state.auth.username === message.userName ? app.$store.state.operator.operator.photo ?? '/img/4.jpg' : null,
            is_customer: app.$store.state.auth.username !== message.userName,
            nick: message.userName,
            userName: message.userName === app.$store.state.auth.username ? app.$store.state.operator.operator.name : message.userName
        }
        for(let url of chatData.identificationUrls) {
            if(message.body.includes(url)) {
                msg.myidRequest = true;
                break;
            }
        }

        app.$store.commit('chat/addChatMessage', msg);
    }
    onLeaveChat(chatData) {
        const [dataToUpdate, update] = useUpdateChat(chatData);
        dataToUpdate.online = false;
        update();
    }
    fetchOldMessages(chatData) {
        if(chatLoading.includes(chatData.chatRoom) || chatData.stopFetchingOldMessages || !chatData.selectedCustomer) return;
        chatLoading.push(chatData.chatRoom);

        const customerId = chatData.selectedCustomer;
        const limit = 15;
        const skip = chatData.messagesPage * limit;

        get(`/chat-history/customer?customerId=${customerId}&limit=${limit}&skip=${skip}`)
            .then(res => {
                const updateData = {
                    messagesPage: chatData.messagesPage + 1,
                    oldMessages: [...chatData.oldMessages]
                };
                chatLoading.splice(chatLoading.findIndex(chatRoom => chatRoom === chatData.chatRoom), 1);

                if(skip + res.data.data.message.length >= res.data.data.count) {
                    updateData.stopFetchingOldMessages = true;
                }

                res.data.data.message.map(message => parseMessageFromHistory(message, chatData.data.userName)).forEach(msg => {
                    updateData.oldMessages.unshift(msg);
                })

                app.$store.commit('chat/updateChat', {
                    chatRoom: chatData.chatRoom,
                    ...updateData
                });
            });
    }
    inputChatMessage(message, chatData) {
        const [dataToUpdate, update] = useUpdateChat(chatData);
        dataToUpdate.messageInput = message;
        update();
    }
    sendChatMessageFromData(chatData) {
        const message = chatData.messageInput;
        if(!message) return;
        ChatController.sendMessage({
            message,
            chatRoom: chatData.chatRoom
        });
        const [dataToUpdate, update] = useUpdateChat(chatData);
        dataToUpdate.messageInput = '';
        update();
    }
}
export default new ChatMessagesService();
