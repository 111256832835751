var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"client-base-auto-debit"},[_c('AppTabs',{attrs:{"items":_vm.tabList,"theme":"loan"},scopedSlots:_vm._u([{key:"body.cards",fn:function(){return [_c('AutoDebitCardStatsTable',{attrs:{"items":_vm.autoDebitByCard.list,"loading":_vm.autoDebitByCard.loading,"date-range":_vm.autoDebitByCard.dateRange,"card-number":_vm.autoDebitByCard.cardNumber,"loan-ids":_vm.customerLoanIds,"is-call":_vm.isCall},on:{"startDate":_vm.onDebitCardStartDateChange,"endDate":_vm.onDebitCardEndDateChange,"card":_vm.onDebitCardCardChange,"loanId":_vm.onLoanIdClick,"update":_vm.loadAutoDebitByCard}})]},proxy:true},{key:"body.debit",fn:function(){return [_c('AutoDebitGeneralStatsTable',{attrs:{"items":_vm.autoDebit.list,"loading":_vm.autoDebit.loading,"date-range":_vm.autoDebit.dateRange,"loan-ids":_vm.customerLoanIds,"is-call":_vm.isCall},on:{"startDate":_vm.onAutoDebitStartDateChange,"endDate":_vm.onAutoDebitEndDateChange,"loanId":_vm.onLoanIdClick,"update":_vm.loadAutoDebit}})]},proxy:true}]),model:{value:(_vm.tab.active),callback:function ($$v) {_vm.$set(_vm.tab, "active", $$v)},expression:"tab.active"}}),(_vm.modals.loan.status)?_c('LoanModal',{ref:"loanModal",attrs:{"modals":_vm.modals,"values":{
            loanSchedule: 10,
            loanHistoryAccountPage: 10,
            getLoanValue: _vm.getLoanValue,
            getAccountValueById: _vm.getAccountValueById,
            getBranch: _vm.getBranch,
        },"cards":_vm.customerCards},on:{"close-modal":function($event){_vm.modals.loan.status = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }