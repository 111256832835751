<template>
    <div :class="{'client-card-a': !isChat}">
        <HistoryApplication v-if="callApplication" :is-chat="isChat" :application="callApplication"
                            :call-data="callData" :messages="callApplicationMessages"
                            @back-list="backProblems" @problem-action="problemAction"/>
        <AdminCard v-else-if="list.length" theme="problem" title="components.common.requestHistory">
            <template #default="{classNames}">
                <AppTable :class="classNames.large" class="mb-32" :head="historyHead" :data="list" theme="problem" @row-click="openApplication">
                    <template #cell.id="item">
                        <div v-if="loading" class="skeleton" :style="{height: '20px' }"></div>
                        <span v-else>
                            {{ item.item.id }}
                        </span>
                    </template>
                    <template #cell.theme="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px' }"></div>
                        <span v-else>
                            {{ item.products_name }}
                        </span>
                    </template>
                    <template #cell.type="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px'}"></div>
                        <span v-else>{{ item.type.title }}</span>
                    </template>
                    <template #cell.agent="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px'}"></div>
                        <span v-else>
                            {{ item.operator ? item.operator.first_name + " " + item.operator.last_name : '-' }}
                        </span>
                    </template>
                    <template #cell.description="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px'}"></div>
                        <span v-else>
                            {{ stripDescription(item.description) }}
                        </span>
                    </template>
                    <template #cell.status="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px'}"></div>
                        <ProblemTag v-else :status="item.status"/>
                    </template>
                    <template #cell.date="{item}">
                        <div v-if="loading" class="skeleton" :style="{height: '20px'}"></div>
                        <span v-else>
                            {{ formatDate(item.created_at) }}
                        </span>
                    </template>
                </AppTable>
                <div v-if="count > perPage" class="d-flex justify-content-end">
                    <b-pagination aria-control="" :value="currentPage" :total-rows="count"
                                  next-class="prev" prev-class='next' prev-text="" next-text="" :per-page="perPage" @input="onInputPage"/>
                </div>
            </template>
        </AdminCard>
        <div v-else class="empty-title">{{ $t("components.common.nothingFound") }}</div>
    </div>
</template>
<script>
import {get} from "../helpers/api";
import FilterDate from "../mixins/FilterDate";
import ListUtils from "../mixins/ListUtils";
import CallApplicationMixix from "../mixins/call-application-mixin";
import SelectedProblem from "./selectedProblem";
import PageLoader from "./partials/PageLoader";
import ProblemTag from "./partials/ProblemTag";
import AdminCard from "./partials/AdminCard";
import AppTable from "./partials/AppTable";
import ChatWindowBody from "./partials/chat/ChatWindowBody";
import AppIcon from "./partials/AppIcon";
import HistoryApplication from "./partials/HistoryApplication";
import {CallRoom} from "../services/chat/ChatModel";

export default {
    name: 'HistoryProblems',
    mixins: [FilterDate, ListUtils, CallApplicationMixix],
    components: {
        HistoryApplication,
        AppIcon,
        ChatWindowBody,
        AppTable,
        AdminCard,
        ProblemTag,
        PageLoader,
        SelectedProblem,
    },
    props: {
        callData: CallRoom,
        isChat: Boolean,
        clientFormInfo: {
          type: Object,
          default: () => ({})
        },
        isViewClientPage: {
          type: Boolean,
          default: false
        },
    },
    data() {
        return {
            list: [
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
            ],
            currentPage: 1,
            count: 0,
            perPage: 10,
            historyHead: [[
                {
                    name: 'common.table.number',
                    key: 'id',
                    width: '169px'
                },
                {
                    name: 'common.table.theme',
                    key: 'theme'
                },
                {
                    name: 'components.common.typeOfAppeal',
                    key: 'type'
                },
                {
                    name: 'common.table.operator',
                    key: 'agent'
                },
                {
                    name: 'common.table.note',
                    key: 'description'
                },
                {
                    name: 'common.table.status',
                    key: 'status',
                    width: '152px'
                },
                {
                    name: 'components.common.addTime',
                    key: 'date',
                    width: '240px'
                },
            ]],
            loading: false,
        }
    },
    created() {
        this.loadApplications();
    },
    methods: {
        loadApplications() {
            this.loading = true;
            const id = this.isViewClientPage ? this.clientFormInfo.id : this.callData.selectedCustomer;

            const query = {
                limit: this.perPage,
                skip: this.perPage * (this.currentPage - 1)
            };

            const queryString = Object.keys(query)
                .map(key => key + '=' + query[key])
                .join('&')

            get(`/applications/?customer_id=${id}&${queryString}`)
                .then((response) => {
                    if (response.data.success) {
                        this.list = response.data.data.data;
                        this.count = response.data.data.count;
                    }
                })
                .catch((e) => {
                    this.$store.commit('notifications/error', 'errors.somethingWentWrong');
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onInputPage(page) {
            this.currentPage = page;
            this.loadApplications();
        }
    },
}
</script>
