<!-- Компонент инкапсулирует вызов сервиса, чтобы не захламлять компоненты -->
<script>
import ABSCustomer from "../../../../services/customer/ABSCustomer";
import app from '../../../../main';

export default {
    functional: true,
    props: {
        bankClientId: [String, Number]
    },
    render(h,c) {
        return h('div', {
            ...c.data,
            on: {
                click() {
                    ABSCustomer.selectCustomer(c.props.bankClientId);
                    app.$store.commit('modals/openModal', 'absCustomer');
                }
            }
        }, c.children);
    }
}
</script>
