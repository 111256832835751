export const focusMixin = {
    data() {
        return {
            focused: false
        }
    },
    computed: {
        focusListeners() {
            return {
                focusin: () => this.focused = true,
                focusout: () => this.focused = false
            }
        },
        focusClass() {
            return {
                'button-focused': this.focused
            }
        }
    }
}
