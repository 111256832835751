<template>
  <div
      :class="{
      'search-wrap--active': active || fixed,
      'search-wrap--menu-opened': menuOpened,
      'search-wrap--type-chat': isChat,
      'search-wrap--fixed': fixed,
       ['search-wrap--theme-' + theme]: theme
    }"
      class="search-wrap"
  >
    <form class="search-wrap__field" @submit.prevent="submit" v-clickaway="close">
      <div v-if="!disableCategories" class="search-wrap__category" @click="menuOpened = !menuOpened">
        {{ getTranslationOrKey(category) }}
        <AppIcon class="search-wrap__menu-indicator" icon="chevron-down"/>
      </div>
      <div class="search-wrap__field-wrap" :class="{
          'ml-3': disableCategories
      }">
        <MaskedInput
            :mask="mask"
            :value="value"
            class="search-wrap__input search-wrap__input--main"
            :disabled="fixed ? $attrs.disabled : !active"
            :tabindex="fixed ? null : !active ? '-1' : null"
            :placeholder="getTranslationOrKey(placeholder)" type="text" ref="input"
            @focus="closeMenu" @input="onInput" @keydown.native="onKeydown"
        />
        <template v-if="isDouble">
          <div class="search-wrap__separator"></div>
          <MaskedInput
              :mask="maskAdditional"
              :value="valueAdditional"
              class="search-wrap__input"
              :disabled="fixed ? $attrs.disabled : !active"
              :tabindex="fixed ? null : !active ? '-1' : null"
              :placeholder="getTranslationOrKey(placeholderAdditional)"
              type="text" ref="inputAdditional"
              @focus="closeMenu"
              @input="onInput($event, true)"
          />
        </template>
      </div>
      <button class="search-wrap__button" type="submit">
        <LoadingIcon v-if="loading"/>
        <AppIcon v-else icon="search"/>
      </button>
      <transition name="dialer">
        <div v-if="menuOpened" class="search-wrap__menu search-menu" v-clickaway="closeMenu">
          <div
              v-for="item in items" :key="item.short" class="search-menu__item"
              :class="{'search-menu__item--active': item.value === type}"
              @click="selectType(item.value)"
          >
            {{ $t(item.short) }}
          </div>
        </div>
      </transition>
    </form>
  </div>
</template>
<script>
import clickaway from "../../directives/clickaway";
import AppIcon from "./AppIcon";
import LoadingIcon from "./LoadingIcon";
import {TheMask, tokens} from 'vue-the-mask';
import {translationExists} from "../../mixins/local/translationExists.mixin";

export default {
  components: {
    LoadingIcon, AppIcon, MaskedInput: {
      template: '<TheMask key="1" v-if="mask" :tokens="tokens" :value="value" :mask="mask" v-bind="$attrs" v-on="parsedListeners" @input="onInput($event)"/>' +
          '<input key="2" v-else :value="value" v-bind="$attrs" v-on="parsedListeners" @input="onInput($event.target.value)"/>',
      components: {
        TheMask
      },
      props: {
        value: String,
        mask: String
      },
      data() {
        return {
          tokens: {
            ...tokens,
            x: {
              pattern: /[0-9a-zA-Z]/,
              transform: v => v.toLocaleUpperCase()
            }
          }
        }
      },
      computed: {
        parsedListeners() {
          const listeners = {...this.$listeners};
          delete listeners.input;
          return listeners;
        }
      },
      methods: {
        onInput($event) {
          this.$emit('input', $event);
        }
      }
    }
  },
  directives: {
    clickaway
  },
  props: {
    isChat: Boolean,
    value: String,
    valueAdditional: String,
    error: Boolean,
    loading: Boolean,
    type: [String, Number],
    fixed: Boolean,
    theme: String,
    items: {
      type: Array,
      required: true
    },
    disableCategories: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      active: false,
      menuOpened: false,
    };
  },
  computed: {
    selectedItem() {
      if (!this.type == null) return null;
      return this.items.find(item => item.value == this.type);
    },
    category() {
      return this.selectedItem?.short || this.$t('common.category')
    },
    mask() {
      return this.selectedItem?.mask || ''
    },
    placeholder() {
      return this.selectedItem?.placeholder || this.$t('common.searchWithDots')
    },
    placeholderAdditional() {
      return this.selectedItem?.placeholderAdditional || ''
    },
    maskAdditional() {
      return this.selectedItem?.maskAdditional || ''
    },
    isDouble() {
      return this.selectedItem?.double;
    },
  },
  methods: {
    clearSearchError() {
      this.$emit('clear-error');
    },
    focusSearch() {
      this.$refs.input.$el.focus();
    },
    focusAdditional() {
      try {
        this.$refs.inputAdditional.$el.focus();
      } catch (e) {
        console.log('Нет второго поля');
      }
    },
    submit() {
      if (this.loading) return;

      if (this.fixed) {
        return this.$emit('submit');
      }

      if (this.active) {
        this.$emit('submit')
      } else {
        this.open();
      }
    },
    closeMenu() {
      this.menuOpened = false;
    },
    close() {
      if (this.fixed) return;
      this.active = false;
      this.$emit('input', '');
      this.$emit('input-additional', '');
      this.$emit('close');
      this.clearSearchError();
      this.closeMenu();
    },
    open() {
      if (this.fixed) return;
      this.active = true;
      this.$emit('open');
      setTimeout(this.focusSearch, 530);
    },

    selectType(type) {
      this.$emit('input', '');
      this.$emit('input-additional', '');
      this.$emit('type', type);
      this.closeMenu();
      this.focusSearch();
    },

    onInput(e, isSecond) {
      let value = e;
      let eventName = 'input';
      if (this.isDouble) {
        if (isSecond) {
          eventName += '-additional';
          if (value.length === 0 && this.valueAdditional.length > 0) {
            this.focusSearch();
          }
        }
        if (this.isDouble && value.length === 2) {
          this.focusAdditional();
        }
      }
      this.$emit(eventName, value);
      if (this.error) {
        this.clearSearchError();
      }
    },
    onKeydown(e) {
      if (e.code === 'Space' && this.isDouble) {
        this.focusAdditional();
      }
    }
  },
  mixins: [translationExists]
};
</script>

<style lang="scss">
@import '../../assets/scss/variables';

.search-wrap {
  $self: &;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  font-family: $font-secondary;
  height: 44px;
  width: 44px;
  transition: all .25s ease-in;

  &__field {
    height: 100%;
    min-width: 44px;
    width: 0;
    transition: all .25s ease-in;
    z-index: 1;
    top: 0;
    right: 0;
    background: var(--color-white-bluesh);
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    position: absolute;
  }

  &__field-wrap {
    display: flex;
      width: 100%;
  }

  &__separator {
    background-color: #E1E1E1;
    width: 1px;
    margin: 4px 18px 4px 0;
    flex-shrink: 0;
  }

  &__category {
    cursor: pointer;
    user-select: none;
    transition: opacity .25s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 4px;
    left: 4px;
    background: var(--color-white);
    border-radius: 50px;
    padding: 1px 10px 0px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--color-gray-lighten);
    opacity: 0;
    margin: 3px 10px 3px 3px;
    text-wrap: nowrap;
  }

  &__menu-indicator {
    transition: transform .25s;
  }

  &__input {
    transition: opacity .25s;
    opacity: 0;
    flex-grow: 1;
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-family: $font-secondary;
    outline: none;
    @include placeholder {
      color: var(--color-gray-lighten);
    }
  }

  &__button {
    background-color: transparent;
    border: 0;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 12px;
    width: 44px;
    height: 44px;
    color: var(--color-gray-lighten);

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__menu {
    z-index: 1;
    position: absolute;
    top: calc(100% - 4px);
    left: 4px;
    .search-menu__item {
        text-wrap: nowrap;
        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }
  }

  &--menu-opened {
    cursor: pointer;
    user-select: none;

    #{$self}__menu-indicator {
      transform: rotate(180deg);
    }

    #{$self}__category {
      background-color: #DEEAF5;
    }
  }

  &--active {
    width: 600px;
    transition: all .25s ease-out;
    #{$self}__field {
      overflow: visible;
      transition: all .25s ease-out;
      width: 100%;
    }

    #{$self}__input,
    #{$self}__category {
      transition-delay: .28s;
      opacity: 1;
    }
  }

  &--type-chat#{$self}--active {
    #{$self}__field {
      width: 100%;
      min-width: 386px;
    }
  }

  &--fixed {
    width: auto;

    #{$self}__field {
      position: static;
      width: 100%;
    }
  }

  &--theme-application {
    height: 48px;

    #{$self}__field {
      background: #EFEFEF;
      border: 1px solid #E1E1E1;
      border-radius: 12px;
      height: 100%;
    }

    #{$self}__category {
      border-radius: 10px;
      height: 40px;
      width: 152px;
    }

    #{$self}__menu {
      width: auto;
      top: 100%;
    }

    .search-menu__item {
      padding-bottom: 10px;

      &:hover,
      &--active {
        background-color: #F4F4F4;
      }
    }

    &#{$self}--menu-opened {
      #{$self}__category {
        background-color: #E1E1E1;
      }
    }
  }
}
</style>
