<template>
    <div v-if="isEnabled">
        <transition name="opacity">
            <app-button
                theme="gray"
                v-if="!isActive"
                v-show="buttonVisible"
                @click.prevent="toggleOpened(true)"
                class="redmine-button"
                :class="mini && 'redmine-button--mini'"
            >
                <template v-if="!mini">
                    {{ $t("components.common.create-redmine-task") }}
                </template>
                <img
                    v-else
                    v-tooltip="$t('components.common.create-redmine-task')"
                    :src="require('@/assets/svg/redmine.svg')"
                    alt="redmine"
                />
            </app-button>
        </transition>

        <transition name="dialer">
            <div v-if="isActive" class="redmine-form">
                <div class="d-flex align-items-center">
                    <h4>{{ title }}</h4>
                    <div
                        class="ml-auto cursor-pointer"
                        @click="toggleOpened(false)"
                        v-if="!hideDescription"
                    >
                        <AppIcon icon="close" />
                    </div>
                </div>
                <div
                    class="d-flex"
                    :class="{
                        'flex-nowrap': !mini,
                        'flex-wrap': mini,
                    }"
                >
                    <div class="d-flex" :class="mini ? 'fb-100' : 'fb-50'">
                        <div class="fb-50 d-flex flex-column mb-3 mr-4">
                            <label>
                                {{ $t("common.form.project") }}
                                <span
                                    class="tw-text-danger-10"
                                    v-if="customFields"
                                    >*</span
                                >
                            </label>
                            <div
                                v-if="loading.projects"
                                class="placeholder placeholder--large w-100 h-100"
                            />
                            <app-select
                                v-else
                                :error="validate && value.project === null"
                                v-model="model.project"
                                :items="projectList"
                                item-name="name"
                                item-value="id"
                                size="medium"
                                show-search
                                :filter-option="false"
                                :loading="projectsLoading"
                                @search="onProjectFilter"
                                @focus="onProjectFocus"
                                @change="projectChangeHandler"
                                @scrollend="getItems('projects')"
                            />
                        </div>
                        <div
                            class="fb-50 d-flex flex-column mb-3"
                            :class="!mini ? 'mr-4' : null"
                        >
                            <label>
                                {{ $t("common.form.tracker") }}
                                <span
                                    class="tw-text-danger-10"
                                    v-if="customFields"
                                    >*</span
                                >
                            </label>
                            <div
                                v-if="loading.projects"
                                class="placeholder placeholder--large w-100 h-100"
                            />
                            <app-select
                                v-else
                                :error="validate && value.tracker === null"
                                v-model="model.tracker"
                                :items="trackers"
                                item-name="name"
                                item-value="id"
                                size="medium"
                                @change="onTrackerChange"
                            />
                        </div>
                    </div>
                    <div class="d-flex" :class="mini ? 'fb-100' : 'fb-50'">
                        <div
                            class="d-flex flex-column mb-3"
                            :class="{
                                'fb-100': !isGroup,
                                'fb-50 mr-4': isGroup,
                            }"
                        >
                            <label>
                                {{ $t("common.form.responsible") }}
                                <span
                                    class="tw-text-danger-10"
                                    v-if="customFields"
                                    >*</span
                                >
                            </label>
                            <div
                                v-if="loading.responsible"
                                class="placeholder placeholder--large w-100 h-100"
                            />
                            <app-select
                                v-else
                                :error="validate && value.responsible === null"
                                v-model="model.responsible"
                                :items="userList"
                                :item-name="responsibleText"
                                item-value="id"
                                size="medium"
                                show-search
                                :filter-option="false"
                                :loading="usersLoading"
                                @search="onUserFilter"
                                @focus="onUserFocus"
                                @scrollend="getItems('responsibleList')"
                                @change="changeResponsibleHandler"
                            />
                        </div>
                        <div
                            v-if="isGroup"
                            class="fb-50 d-flex flex-column mb-3"
                        >
                            <label>
                                {{ $t("common.form.responsibleUser") }}
                            </label>
                            <div
                                v-if="loading.responsibleFromGroup"
                                class="placeholder placeholder--large w-100 h-100"
                            />
                            <app-select
                                v-else
                                v-model="model.responsibleFromGroup"
                                :items="responsibleFromGroup.items"
                                :item-name="['firstname', 'lastname']"
                                item-value="id"
                                size="medium"
                                @scrollend="getItems('responsibleFromGroup')"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="!hideDescription" class="d-flex flex-column">
                    <div class="d-flex flex-column mb-3">
                        <label>
                            {{ $t("common.form.problem-theme") }}
                            <span class="tw-text-danger-10" v-if="customFields"
                                >*</span
                            >
                        </label>
                        <AppInput
                            :error="validate && value.subject === null"
                            v-model="model.subject"
                            class="application-input"
                            size="extra-large"
                        />
                    </div>
                    <div class="d-flex flex-column mb-3">
                        <label>
                            {{ $t("common.form.essence-of-the-problem") }}
                            <span class="tw-text-danger-10" v-if="customFields"
                                >*</span
                            >
                        </label>
                        <AppTextarea
                            :error="validate && value.description === null"
                            v-model="model.description"
                            class="application-input"
                            size="extra-large"
                        />
                    </div>
                </div>

                <div
                    v-if="customFields && !customFiledsLoading"
                    class="redmine-custom-fields"
                >
                    <div
                        class="redmine-custom-fields__item"
                        v-for="customField in customFieldList"
                        :key="customField.id"
                    >
                        <div>
                            <label>
                                {{ customField.name }}
                                <span class="tw-text-danger-10">*</span>
                            </label>
                        </div>
                        <AppInput
                            v-if="customField.isSingle"
                            :error="validate && !customField.valid"
                            class="application-input"
                            size="extra-large"
                            :type="customField.isInt ? 'number' : 'text'"
                            v-model="customField.value"
                        />
                        <app-select
                            v-else-if="customField.isList"
                            :error="validate && !customField.valid"
                            v-model="customField.value"
                            :items="customField.options"
                            item-name="text"
                            item-value="value"
                            size="medium"
                        />
                        <AppDatePicker
                            v-else-if="customField.isDate"
                            class="w-100"
                            :error="validate && !customField.valid"
                            v-model="customField.value"
                            :allow-clear="true"
                            size="extra-large"
                            value-format="DD.MM.YYYY"
                            default-value=""
                        />
                    </div>
                </div>
                <div
                    class="redmine-custom-fields"
                    style="height: 112px"
                    v-if="customFiledsLoading && customFields"
                >
                    <div
                        class="placeholder placeholder--large w-100 h-100"
                        v-for="item in 4"
                        :key="item"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import AppSelect from "@/components/partials/AppSelect.vue";
import AppInput from "@/components/partials/AppInput.vue";
import AppButton from "@/components/partials/AppButton.vue";
import AppTextarea from "@/components/partials/AppTextarea.vue";
import AppIcon from "@/components/partials/AppIcon.vue";
import AppDatePicker from "@/components/partials/AppDatePicker.vue";

import { get } from "../helpers/api";
import debounce from "lodash/debounce";

export class PaginationList {
    items = [];
    limit = 0;
    total = 0;
    offset = 0;
    page = 1;

    constructor(limit = 25) {
        this.limit = limit;
    }

    fillFromData(data, field) {
        this.items = [...this.items, ...data[field]];
        this.items = this.items.filter(
            (obj, index, self) =>
                index ===
                self.findIndex(
                    (t) => t.id === obj.id && t.is_group === obj.is_group
                )
        );
        this.total = data.total_count;
        this.offset = data.offset;
    }

    get hasNext() {
        return this.items.length < this.total;
    }
}

class RedmineFromCustomFieldOptionModel {
    constructor(txt, val) {
        this.text = txt;
        this.value = val;
    }
}

class RedmineFromCustomFieldModel {
    constructor(val, name, id, type, options, required) {
        this.value = val;
        this.name = name;
        this.id = id;
        this.type = type;
        this.options = options;
        this.required = required;
    }

    get isSingle() {
        return this.type === "string" || this.type === "int";
    }
    get isList() {
        return this.type === "list" || this.type === "enumeration";
    }
    get isDate() {
        return this.type === "date";
    }
    get isInt() {
        return this.type === "int";
    }
    get valid() {
        return this.value && this.value.length > 0;
    }

    toJson() {
        return {
            id: this.id,
            value: this.value,
            name: this.name,
        };
    }
}

export class RedmineFormModel {
    project = null;
    tracker = null;
    responsible = null;
    subject = null;
    description = null;
    responsibleFromGroup = null;
    custom_fields = [];
    constructor() {}

    get valid() {
        return this.custom_fields
            .filter((el) => el.required)
            .every((el) => el.valid);
    }
}

export class RedmineFormLoading {
    projects = false;
    trackers = false;
    responsible = false;
    responsibleFromGroup = false;
    constructor() {}
}

export default {
    name: "RedmineForm",
    components: {
        AppIcon,
        AppTextarea,
        AppButton,
        AppInput,
        AppSelect,
        AppDatePicker,
    },
    props: {
        value: {
            type: Object,
            default: () => new RedmineFormModel(),
        },
        projects: {
            type: Object,
            default: () => new PaginationList(),
        },
        responsibleList: {
            type: Object,
            default: () => new PaginationList(),
        },
        responsibleFromGroup: {
            type: Object,
            default: () => new PaginationList(),
        },
        hideDescription: {
            type: Boolean,
            default: false,
        },
        defaultOpened: {
            type: Boolean,
            default: false,
        },
        mini: {
            type: Boolean,
            default: false,
        },
        customFields: {
            type: Boolean,
            default: false,
        },
        customerName: {
            type: String,
            default: "",
        },
        customerPhone: {
            type: String,
            default: "",
        },
        customerPinfl: {
            type: [String, Number],
            default: "",
        },
        customerPassport: {
            type: String,
            default: "",
        },
        loading: {
            type: Object,
            default: () => new RedmineFormLoading(),
        },
        buttonVisible: {
            type: Boolean,
            default: true,
        },
        validate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        this.onProjectFilter = debounce(this.onProjectFilter, 800);
        this.onUserFilter = debounce(this.onUserFilter, 800);
        return {
            isActive: this.defaultOpened,
            isGroup: false,
            trackers: [],
            customFieldList: [],
            customFiledsLoading: false,

            searchedProjects: [],
            projectsLoading: false,
            projectFocused: false,

            searchedUsers: [],
            usersLoading: false,
            usersFocused: false,
        };
    },
    model: {
        event: "input",
        prop: "value",
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set($val) {
                this.$emit("input", $val);
            },
        },
        isEnabled() {
            return process.env.VUE_APP_REDMINE_ENABLED === "true";
        },
        title() {
            return this.hideDescription
                ? this.$t("common.form.redmine-title")
                : this.$t("common.form.create-redmine-task");
        },
        projectList() {
            if (
                (this.searchedProjects.length > 0 || this.projectsLoading) &&
                !this.projectFocused
            ) {
                return this.searchedProjects;
            }
            return this.projects.items;
        },
        userList() {
            if (
                (this.searchedUsers.length > 0 || this.usersLoading) &&
                !this.usersFocused
            ) {
                return this.searchedUsers;
            }
            return this.responsibleList.items;
        },
    },
    methods: {
        getItems(name) {
            if (this[name].hasNext) {
                this.$emit("get-items", name);
            }
        },
        responsibleText(item) {
            if (item.is_group)
                return this.$t("components.common.group", { group: item.name });
            return item.name;
        },
        changeResponsibleHandler($value) {
            this.model.responsible = $value;

            const user = [
                ...this.responsibleList.items,
                ...this.searchedUsers,
            ].find((el) => el.id === $value);
            if (user) {
                this.isGroup = user.is_group ?? false;
            }
            this.model.responsibleFromGroup = null;
            this.$emit("get-users");
        },
        clearMembers() {
            this.model.responsible = null;
            this.model.responsibleFromGroup = null;
            this.isGroup = false;
        },
        toggleOpened($value) {
            this.isActive = $value;
            this.$emit("toggle-opened", this.isActive);
        },
        projectChangeHandler($event) {
            this.fillTrackers($event);
            this.$emit("get-members", $event);
            this.clearMembers();
        },
        fillTrackers(projectId) {
            const trackers = this.projects.items.find(
                (item) => item.id === projectId
            )?.trackers;
            this.trackers = trackers ? trackers : [];
        },

        onTrackerChange(val) {
            this.customFiledsLoading = true;
            get(`/redmine/custom-fields/${val}`)
                .then((response) => {
                    if (response.data.success && response.data.data) {
                        const list = Array.from(response.data.data);
                        const mappedList = list.map((el) => {
                            const options = el.possible_values
                                ? el.possible_values
                                : [];
                            let defaultValue = "";

                            if (
                                process.env
                                    .VUE_APP_REDMINE_CUSTOMER_NAME_FIELD ===
                                el.id.toString()
                            ) {
                                defaultValue = this.customerName;
                            } else if (
                                process.env
                                    .VUE_APP_REDMINE_CUSTOMER_PHONE_FIELD ===
                                el.id.toString()
                            ) {
                                if (
                                    this.customerPhone.length === 10 &&
                                    this.customerPhone.startsWith(
                                        process.env.VUE_APP_CISCO_PHONE_PREFIX
                                    )
                                ) {
                                    defaultValue = this.customerPhone.replace(
                                        process.env.VUE_APP_CISCO_PHONE_PREFIX,
                                        ""
                                    );
                                } else {
                                    defaultValue = this.customerPhone;
                                }
                            } else if (
                                process.env
                                    .VUE_APP_REDMINE_CUSTOMER_PINFL_FIELD ===
                                el.id.toString()
                            ) {
                                defaultValue = this.customerPinfl;
                            } else if (
                                process.env
                                    .VUE_APP_REDMINE_CUSTOMER_PASSPORT_FIELD ===
                                el.id.toString()
                            ) {
                                defaultValue = this.customerPassport;
                            }

                            return new RedmineFromCustomFieldModel(
                                defaultValue,
                                el.name,
                                el.id,
                                el.field_format,
                                options.map(
                                    (item) =>
                                        new RedmineFromCustomFieldOptionModel(
                                            item.label,
                                            item.value
                                        )
                                ),
                                el.is_required
                            );
                        });

                        this.customFieldList = mappedList;
                        this.value.custom_fields = mappedList;
                    }
                    this.customFiledsLoading = false;
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        this.$t("errors.somethingWentWrong")
                    );
                    this.customFiledsLoading = false;
                });
        },

        onProjectFilter(value) {
            if (value.length === 0) {
                this.projectFocused = false;
            }
            if (value.length < 3) return;
            this.projectsLoading = true;
            this.searchedProjects = [];
            this.projectFocused = false;
            get(`/redmine/search/projects?query=${value.toString()}`).then(
                (response) => {
                    if (response.data && response.data.success) {
                        this.searchedProjects = response.data.data.projects;
                    }

                    this.projectsLoading = false;
                }
            );
        },
        onProjectFocus() {
            if (this.searchedProjects.length > 0) {
                this.projectFocused = true;
            }
        },

        onUserFilter(value) {
            if (value.length === 0) {
                this.usersFocused = false;
            }
            if (value.length < 3) return;
            this.usersLoading = true;
            this.searchedUsers = [];
            this.usersFocused = false;
            get(
                `/redmine/search/users/${
                    this.value.project
                }?query=${value.toString()}`
            ).then((response) => {
                if (response.data && response.data.success) {
                    this.searchedUsers = response.data.data.members;
                }

                this.usersLoading = false;
            });
        },
        onUserFocus() {
            if (this.searchedUsers.length > 0) {
                this.usersFocused = true;
            }
        },
    },
    watch: {
        "loading.projects"($val) {
            if (!(!$val && this.value.project)) return;

            this.fillTrackers(this.value.project);
        },

        "value.project"(val) {
            if (val === null) {
                this.customFieldList = [];
                this.trackers = [];
            } else {
                if (this.value.tracker && this.customFieldList.length === 0) {
                    this.onTrackerChange(this.value.tracker);
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.redmine-form {
    border-radius: 4px;
    background-color: var(--color-gray-lighten-016);
    padding: 16px 24px;
    width: 100%;
    margin-bottom: 15px;
}

.fb {
    &-25 {
        flex-basis: 25%;
    }

    &-50 {
        flex-basis: 50%;
    }

    &-100 {
        flex-basis: 100%;
    }
}
.redmine-button {
    background-color: #e7f1fd;
    color: var(--color-primary-new);
    margin-bottom: -88px;
    &--mini {
        padding: 10px 13px !important;
        background: transparent;
        border: 2px solid var(--color-primary-new);
    }
}
</style>
<style>
.redmine-form .ant-select-selection {
    border-radius: 4px !important;
}

.redmine-custom-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}
</style>
