<template>
    <SearchForm
        v-model="query"
        :value-additional="queryAdditional"
        :type="type"
        :items="itemsList"
        :is-chat="isChat"
        :loading="loading"
        ref="search"
        @input-additional="queryAdditional = $event"
        @type="type = $event"
        @submit="startSearch"
        @open="$emit('open')"
        @close="onClose"
    />
</template>
<script>
import SearchForm from "./partials/SearchForm";

export default {
    components: {SearchForm},
    props: {
        isChat: Boolean,
        loading: Boolean
    },
    data() {
        return {
            type: "1",
            query: '',
            queryAdditional: '',
            active: false,
            menuOpened: false
        };
    },
    methods: {
        close() {
            this.$refs.search.close();
        },
        clearError() {
            this.$emit('error', '');
        },
        startSearch() {
            let {query, type, queryAdditional} = this;
            if (!type) {
                this.$emit('error', "components.searchForm.selectType");
            }
            if (!query) {
                this.$emit('error', "components.searchForm.selectRequest");
            }
            if(!query || !type) {
                return;
            }
            if(queryAdditional) {
                query = query + '|' + queryAdditional;
            }

            this.$emit('search', {
                type,
                query
            });
        },
        onClose() {
            this.$emit('close');
            this.clearError();
        }
    },
    computed: {
        itemsList() {
            return [
                {
                    short: "components.searchForm.phone",
                    value: '1',
                    placeholder: "--- --- -- --",
                    mask: "##########"
                },
                {
                    short: "components.searchForm.passport",
                    value: '2',
                    placeholder: "AZ --- -- --",
                    mask: "AA #######"
                },
                {
                    short: "components.searchForm.card",
                    value: '3',
                    placeholder: "---- ---- ---- ----",
                    mask: "#### #### #### ####"
                },
                {
                    short: "components.searchForm.internationalPassport",
                    value: '4',
                    placeholder: this.$t("components.searchForm.series"),
                    placeholderAdditional: this.$t("components.searchForm.number"),
                    mask: 'xx',
                    maskAdditional: '########',
                    double: true
                },
                {
                    short: "components.searchForm.militaryID",
                    value: '5',
                    placeholder: this.$t("components.searchForm.series"),
                    placeholderAdditional: this.$t("components.searchForm.number"),
                    mask: 'xx',
                    maskAdditional: '########',
                    double: true
                },
                {
                    short: "common.pinfl",
                    value: '6',
                    placeholder: "--------------",
                    mask: '##############',
                },
            ]
        }
    }
};
</script>
