<template>
    <div class="credit-product-modal-controls">
        <div class="credit-product-modal-controls__item hover-item" @click="modalWindowFullscreenToggle">
            <AppIcon :key="+modalWindowFullscreen" :icon="modalWindowFullscreen ? 'fullscreen-close' : 'fullscreen-open'" size="24"/>
        </div>
        <div class="credit-product-modal-controls__item hover-item" @click="close"><AppIcon icon="close" size="24"/></div>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import FullScreenModalMixin from "../../../mixins/FullScreenModalMixin";
export default {
    name: 'CreditProductModalControls',
    components: {AppIcon},
    mixins: [FullScreenModalMixin],
    methods: {
        close() {
          this.$emit('close')
        }
    }
}
</script>
<style lang="scss">
.credit-product-modal-controls {
    display: flex;
    align-items: center;
    &__item {
        margin-left: 20px;
    }
}
</style>
