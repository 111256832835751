<template>
    <div class="external-task-item__col">
        <div class="external-task-item__text">
            {{ $t(label) }}
        </div>
        <div class="external-task-item__value">
            <div v-if="loading" class="skeleton" />
            <template v-else>
                {{ value ?? "-" }}
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExternalTaskCol",

    props: {
        label: {
            type: String,
            default: "",
        },

        value: {
            type: String,
            default: "",
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
