import sAPIUrl from "../config/api";

export function parseMessageFromHistory(message, username) {
    return {
        isEvent: false,
        body: message.message,
        chatRoom: null,
        createdAt: new Date(message.message_date * 1000),
        userName: message.agent ? message.agent.name : username,
        nick: message.agent ? message.agent.nick : username,
        avatar: message.agent && message.agent.avatar ? `${sAPIUrl}storage/${message.agent.avatar}` : null,
        is_customer: message.is_customer
    }
}

export const transpileScript = (function() {
    const lettersRu = Object.freeze({
        'ия': 'ia', 'дж': 'j', 'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f',
        'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ь': '\'', ' ы': 'y', 'ъ': '', 'э': 'e', 'ю': 'yu', 'я': 'ya',
    });
    const lettersEn = Object.freeze({
        'ia': 'ия', 'ye': 'е', 'yo': 'ё', 'yu': 'ю', 'ya': 'я', 'yi': 'и', '\'': 'ь', 'ch': 'ч', 'sh': 'ш', 'sch': 'щ', 'zh': 'ж', 'ts': 'ц',
        'a': 'а', 'b': 'б', 'v': 'в', 'g': 'г', 'd': 'д', 'e': 'е', 'z': 'з', 'i': 'и', 'j': 'дж', 'y': 'й', 'k': 'к', 'l': 'л', 'm': 'м', 'n': 'н',
        'o': 'о', 'p': 'п', 'r': 'р', 's': 'с', 't': 'т', 'u': 'у', 'f': 'ф', 'h': 'х', 'c': 'к',
    });
    return (scriptMessage, operator) => {
        let message = scriptMessage.replace(/%operatorName%/gm, '');
        const matchEn = message.match(/([a-z]+)/gmi);
        const matchRu = message.match(/([а-я]+)/gmi);
        let letters;
        if(matchRu && matchEn) {
            letters = matchEn.length > matchRu.length ? lettersRu : lettersEn;
        } else {
            letters = matchRu ? lettersEn : lettersRu;
        }
        let operatorName = operator;

        try {
            operatorName = operator.split(' ')[0].trim().toLowerCase();
            if(operatorName.length === 0) return operatorName;
        } catch(e) {
            return scriptMessage;
        }

        Object.entries(letters).forEach(letter => {
            if(letter[0] === letter[1]) return;
            while(operatorName.indexOf(letter[0]) > -1) {
                operatorName = operatorName.replace(letter[0], letter[1]);
            }
        });

        operatorName = operatorName[0].toUpperCase() + operatorName.slice(1);

        return scriptMessage.replace('%operatorName%', operatorName);
    }
})();
export const getChatNavbarList = (chatsData) => {
    const arr = [...Object.values(chatsData)];
    if(arr.length > 5) {
        return arr;
    } else {
        const len = 5  - arr.length;
        for(let i = 0; i < len; i++) {
            arr.push(i + 'a');
        }
    }
    return arr;
}

export const getUserDataFromCallData = (callModel) => {
    try {
        return callModel.customers.find(c => c.id === callModel.selectedCustomer) || {};
    } catch(e) {
        return {};
    }
}
export const getCustomerProblemsFromCallData = (callModel) => {
    return callModel?.customersProblems?.[callModel?.selectedCustomer];
}
