<template>
  <div
    v-if="messages.length"
    class="chat-history"
    :class="{'chat-history--default-height': !isChat}"
  >
    <div class="chat-history__form">
      <SelectedProblem
        is-chat
        :can-send-sms="false"
        :data="application"
        :call-data="callData"
        :disabled="disabled"
        @back-list="$emit('back-list', $event)"
        @problem-action="$emit('problem-action', $event)"
      />
    </div>

    <AdminCard
      class="chat-history__chat"
      theme="client"
      body-empty
      icon="conversation"
      title="open-apps-form.chatHistory"
    >
      <div class="chat-history__chat-body chat-window chat-window--only-view">
        <ChatWindowBody
          class="chat-history__chat-window-body"
          :no-avatar-key="callData.chatRoom || callData.selectedCustomer"
          :messages="messages"
        />
      </div>
    </AdminCard>
  </div>
  <SelectedProblem
    v-else
    :call-data="callData"
    :can-send-sms="false"
    :data="application"
    :disabled="disabled"
    @open:modal="$emit('open:modal', $event)"
    @problem-action="$emit('problem-action', $event)"
    @back-list="$emit('back-list', $event)"
  />
</template>
<script>
import SelectedProblem from "../selectedProblem";
import AdminCard from "./AdminCard";
import ChatWindowBody from "./chat/ChatWindowBody";
import {CallRoom} from "../../services/chat/ChatModel";
import {getUserDataFromCallData} from "../../helpers/chats";
import CustomerTask from "../admin/Customer/customer-task/customer-task.component.vue"
export default {
    name: "HistoryApplication",
    components: {ChatWindowBody, AdminCard, SelectedProblem, CustomerTask},
    props: {
        messages: Array,
        isChat: Boolean,
        disabled: Boolean,
        callData: {
            type: [CallRoom, Object],
            required: true
        },
        application: {
            type: Object,
            required: true
        }
    },
    computed: {
        userData() {
            return getUserDataFromCallData(this.callData);
        },
        taskId() {
          if (this.callData?.data?.task_id > 0) {
            return this.callData?.data?.task_id
          }
          return false
        }
    }
};
</script>
<style lang="scss">
.chat-history {
    $self: &;
    display: flex;
    &__form {
        width: calc(920 / 1792 * 100% - 20px);
        margin-right: 20px;
    }
    &__chat {
        width: 920 / 1792 * 100%;
        height: calc(100vh - var(--chat-layout-start-offset) - var(--user-panel-height) - var(--user-panel-offset) - var(--home-tabs-height) - 16px);
        position: sticky;
        top: calc(var(--user-panel-height) + var(--user-panel-offset) + var(--home-tabs-height));
    }
    &__chat-body {
        flex-grow: 1;
        border-radius: 0 0 12px 12px !important;
        height: 100px;
    }
    &--default-height {
        #{$self}__chat {
            height: auto;
        }
        #{$self}__chat-window-body {
            height: 100px;
            flex-grow: 1;
        }
    }
}
</style>
