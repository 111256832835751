<template>
    <div v-if="card.isActionsPossible" class="active-operations-buttons">
        <AppButton
            v-if="
                activeOperationsCardActions[card.typeName] &&
                activeOperationsCodes.pin in
                    activeOperationsCardActions[card.typeName]
            "
            :class="{
                'active-operations-button__button': !short,
                'mr-4-a': true,
            }"
            :square="short"
            theme="primary-outline"
            v-tooltip="$t('components.common.resetPin')"
            @click="$emit('action', activeOperationsCodes.pin)"
        >
            <AppIcon :class="{ 'mr-2-a': !short }" icon="card-pin" size="20" />
            {{ short ? "" : $("components.common.resetPin") }}
        </AppButton>
        <AppButton
            v-if="
                card.state === 'active' &&
                activeOperationsCardActions[card.typeName] &&
                activeOperationsCodes.block in
                    activeOperationsCardActions[card.typeName]
            "
            key="block"
            :class="{ 'active-operations-button__button': !short }"
            :square="short"
            theme="primary-outline"
            v-tooltip="$t('components.common.blockCard')"
            @click="$emit('action', activeOperationsCodes.block)"
        >
            <AppIcon :class="{ 'mr-2-a': !short }" icon="lock-alt" size="20" />
            {{ short ? "" : $t("components.common.blockCard") }}
        </AppButton>
        <AppButton
            v-else-if="
                activeOperationsCardActions[card.typeName] &&
                activeOperationsCodes.unblock in
                    activeOperationsCardActions[card.typeName]
            "
            key="unblock"
            :class="{ 'active-operations-button__button': !short }"
            :square="short"
            theme="primary-outline"
            v-tooltip="$t('components.common.unblockCard')"
            @click="$emit('action', activeOperationsCodes.unblock)"
        >
            <AppIcon
                :class="{ 'mr-2-a': !short }"
                icon="lock-open-alt"
                size="20"
            />
            {{ short ? "" : $t("components.common.unblockCard") }}
        </AppButton>
    </div>
</template>
<script>
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import { activeOperationsCodes } from "../../../store/modules/activeOperations";
import activeOperationsMixin from "../../../mixins/activeOperationsMixin";
export default {
    name: "ActiveOperationsButtons",
    components: { AppIcon, AppButton },
    mixins: [activeOperationsMixin],
    props: {
        short: Boolean,
    },
};
</script>
<style lang="scss">
.active-operations-buttons {
    display: flex;
    align-items: center;
    &__button {
        padding-left: 18px;
        padding-right: 18px;
    }
}
</style>
