<template>
    <section class="waiting-image waiting-image--fullpage">
        <div class="waiting-image__wrap">
            <img src="../../assets/img/waiting-error.gif" alt="" class="waiting-image__object">
            <div class="waiting-image__text-wrap">
                <p class="waiting-image__text"> {{ $t("components.common.everythingWentWrongDoNotPanic") }} 😎</p>
                <p class="waiting-image__settings">{{ $t("components.common.inTheMeantimeLetsTry") }} <span @click="$emit('reload-click')">{{ $t("components.common.reloadPage") }}</span></p>
            </div>
        </div>
    </section>
</template>
<script>
import '../../assets/scss/components/WaitingImage.scss';

export default {
    name: 'ErrorImage'
}
</script>
