<template>
    <div>
        <div
            class="view-problem-layout"
            :class="{ 'view-problem-layout--type-chat': isChat }"
        >
            <div class="view-problem-layout__form">
                <AdminCard
                    theme="client"
                    :title="title"
                    icon="message-alt-edit"
                >
                    <template v-if="isEditable && !isChat" #header>
                        <NavTabs
                            class="ml-32"
                            :value="model.type_id"
                            :items="dictionary"
                            item-value="id"
                            item-name="title"
                            @input="loadProduct"
                        />
                    </template>
                    <template
                        v-if="
                            data && langsIcon[data.language_id] && !isEditable
                        "
                        #actions
                    >
                        <div v-if="isChat" class="lang-icon">
                            <img
                                :src="langsIcon[data.language_id]"
                                alt="flag"
                            />
                        </div>
                        <div v-else class="ml-auto lang-badge">
                            <div class="lang-badge__icon lang-icon">
                                <img
                                    :src="langsIcon[data.language_id]"
                                    alt="flag"
                                />
                            </div>
                            {{ langsTitle[data.language_id] }}
                        </div>
                    </template>
                    <template #default="{ classNames }">
                        <InputWrapForm
                            :model="model"
                            :rules="rules"
                            class="create-problem-a"
                            :class="{
                                'create-problem-a--type-short':
                                    isChat || problemsId,
                            }"
                            #default="{ errors }"
                            @submit="saveData"
                        >
                            <div
                                v-if="!isEditable"
                                class="create-problem-a__info-list"
                                :class="classNames.large"
                            >
                                <div
                                    class="block-wrap block-wrap--offset-large"
                                >
                                    <ProblemDescriptionItem
                                        icon="border-all"
                                        name="open-apps-form.typeOfAppeal"
                                        :value="data.type_name"
                                    />
                                    <ProblemDescriptionItem
                                        icon="ghost"
                                        name="common.table.operator"
                                        :value="data.operator_name"
                                    />
                                    <ProblemDescriptionItem
                                        icon="time"
                                        name="common.time"
                                        :value="
                                            parsedDateAndTimeFromString(
                                                data.created_at
                                            )
                                        "
                                    />
                                    <ProblemDescriptionItem
                                        icon="game"
                                        name="common.table.status"
                                        :value="data.status_name"
                                    />
                                </div>
                            </div>
                            <div v-else-if="isChat" class="tw-mb-6">
                                <InputWrapLabel
                                    label="open-apps-form.typeOfAppeal"
                                    required
                                />
                                <NavTabs
                                    class="tw-w-fit"
                                    :value="model.type_id"
                                    overflow
                                    :items="dictionary"
                                    theme="chat"
                                    item-value="id"
                                    item-name="title"
                                    @input="loadProduct"
                                />
                            </div>

                            <div class="create-problem-a__form">
                                <div
                                    class="create-problem-a__item create-problem-a__item--large"
                                >
                                    <InputWrapLabel
                                        label="open-apps-form.themeOfAppeal"
                                        required
                                    >
                                        <PopularProblemOptionsComponent
                                            v-if="
                                                model.type_id &&
                                                isCreate &&
                                                !isChat
                                            "
                                            v-model="model.theme_ids"
                                            class="popular-theme-wrap"
                                            :popular-options="popularOptions"
                                            @change="updateThemes"
                                        />
                                    </InputWrapLabel>
                                    <InputWrapField :error="errors.theme_ids">
                                        <AppTreeSelect
                                            v-model="model.theme_ids"
                                            :error="!!errors.theme_ids"
                                            :options="
                                                model.type_id
                                                    ? treeOptions.list
                                                    : []
                                            "
                                            :flatten-items="
                                                treeOptions.flatOptions
                                            "
                                            :disabled="!isEditable"
                                            :loading="isProductLoading"
                                            @change="updateThemes"
                                            @more-click="openModal"
                                        />
                                    </InputWrapField>
                                </div>
                                <div
                                    v-if="branches.length > 0"
                                    class="create-problem-a__item"
                                >
                                    <InputWrapLabel
                                        label="open-apps-form.branch"
                                    />
                                    <AppSelect
                                        class="application-input"
                                        size="medium"
                                        v-model="model.branch_id"
                                        :items="branches"
                                        item-name="name"
                                        item-value="value"
                                        :disabled="!isEditable"
                                        placeholder="common.selectFromList"
                                        show-search
                                        :filter-option="onBranchFilter"
                                        @change="saveUserFormSelection"
                                    />
                                </div>
                                <div class="create-problem-a__item">
                                    <InputWrapLabel
                                        label="common.statusOfAppeal"
                                        required
                                    />
                                    <InputWrapField :error="errors.status">
                                        <AppSelectA
                                            v-model="model.status"
                                            :error="!!errors.status"
                                            :items="statusOptionsList"
                                            size="large"
                                            placeholder="common.selectFromList"
                                            :disabled="disabledProblemStatus"
                                            @input="saveUserFormSelection"
                                        />
                                    </InputWrapField>
                                </div>
                                <div
                                    class="create-problem-a__item create-problem-a__item--large"
                                >
                                    <InputWrapLabel
                                        label="common.table.howDidTheClientFindOut"
                                    />
                                    <AppSelectA
                                        v-model="model.marketing_source"
                                        :items="marketing_source"
                                        :disabled="!isEditable"
                                        placeholder="common.selectSource"
                                        @input="saveUserFormSelection"
                                    />
                                </div>
                                <div
                                    class="create-problem-a__item create-problem-a__item--large"
                                >
                                    <InputWrapLabel label="common.table.note" />
                                    <AppTextareaA
                                        v-model="model.description"
                                        :disabled="!isEditable"
                                        :placeholder="
                                            $t('common.table.writeNoteWithDots')
                                        "
                                        min-height="48"
                                        @change="saveUserFormSelection"
                                    />
                                </div>
                            </div>
                            <CustomerTask
                                v-if="data?.task_id > 0"
                                :task_id="data.task_id"
                                in-rows
                            />
                            <ExternalTaskList
                                v-if="!isEditable"
                                :task-list="data?.external_tasks ?? []"
                            />
                            <div class="tw-mb-6">
                                <PinsAccordion
                                    v-for="option in selectedProductOptions"
                                    :key="option.id"
                                    :disabled="!isEditable"
                                    :data="option"
                                    :short="!!(isChat || problemsId)"
                                    :value="model.options[option.id]"
                                    @input="onOptionsInput(option.id, $event)"
                                />
                            </div>
                            <div class="d-flex flex-wrap">
                                <redmine-form
                                    v-if="isCreate"
                                    class="w-100"
                                    @get-members="
                                        redmineFormLoading.responsible = true;
                                        redmine.tracker = null;
                                        fetchRedmineUsersAndGroups();
                                        clearMembers();
                                    "
                                    @get-items="fetchRedmineItems"
                                    @get-users="
                                        redmineFormLoading.responsibleFromGroup = true;
                                        fetchRedmineUsers();
                                        clearUsers();
                                    "
                                    @toggle-opened="redmineIsActive = $event"
                                    v-model="redmine"
                                    :projects="projects"
                                    :responsible-list="responsibleList"
                                    :responsible-from-group="
                                        responsibleFromGroup
                                    "
                                    :mini="isChat"
                                    :loading="redmineFormLoading"
                                    custom-fields
                                    :customer-name="
                                        callData.customerForm?.username
                                    "
                                    :customer-phone="callData.selectedPhone"
                                    :customer-pinfl="
                                        callData.customerForm?.pinfl
                                    "
                                    :customer-passport="
                                        callData.customerForm?.passport_no
                                    "
                                    :button-visible="model.theme_ids.length > 0"
                                    :validate="validateRedmine"
                                />
                                <div
                                    class="create-problem-a__actions d-inline-flex"
                                >
                                    <AppButton
                                        v-if="isProblemSaved"
                                        theme="success-flat"
                                        @click.prevent
                                    >
                                        {{ $t("common.saved") }}...
                                    </AppButton>
                                    <template v-else>
                                        <AppButton
                                            v-if="showSMSButton && isCreate"
                                            type="button"
                                            theme="white-outline"
                                            :class="{ 'mr-auto': !isCreate }"
                                            @click="sendSMSToClient"
                                        >
                                            {{
                                                isChat
                                                    ? $t(
                                                          "common.table.sendToChat"
                                                      )
                                                    : $t(
                                                          "common.table.sendToSms"
                                                      )
                                            }}
                                        </AppButton>
                                        <AppButton
                                            v-else-if="
                                                showEmailButton && isCreate
                                            "
                                            type="button"
                                            theme="white-outline"
                                            :class="{ 'mr-auto': !isCreate }"
                                            @click="sendEmailToClient"
                                        >
                                            {{
                                                isChat
                                                    ? $t("common.sendToEmail")
                                                    : $t(
                                                          "common.sendToEmailNotification"
                                                      )
                                            }}
                                        </AppButton>
                                        <AppButton
                                            v-if="showCancelButton"
                                            type="button"
                                            theme="gray"
                                            @click="cancel"
                                        >
                                            {{
                                                !disabledProblemStatus
                                                    ? $t("common.cancel")
                                                    : $t("common.back")
                                            }}
                                        </AppButton>
                                        <AppButton
                                            v-if="!disabledProblemStatus"
                                            type="submit"
                                        >
                                            {{
                                                change_problem_loading
                                                    ? $t(
                                                          "common.pleaseWaitWithDots"
                                                      )
                                                    : $t("common.save")
                                            }}
                                        </AppButton>
                                    </template>
                                </div>
                            </div>
                        </InputWrapForm>
                    </template>
                </AdminCard>
            </div>
            <div v-if="problemsId" class="view-problem-layout__comments">
                <AdminCard
                    theme="client"
                    title="common.comments"
                    icon="conversation"
                    body-empty
                >
                    <div
                        class="comments-wrap"
                        :class="{ 'comments-wrap--type-chat': isChat }"
                    >
                        <div class="comments-wrap__body" ref="comments_body">
                            <div
                                v-for="comment in application_comments"
                                :key="comment.id"
                                class="comment-item comments-wrap__item"
                                :class="{
                                    'comment-item--theme-primary-dark':
                                        comment.isSystem,
                                }"
                            >
                                <img
                                    class="comment-item__avatar"
                                    :src="comment.avatar"
                                    alt=""
                                />
                                <div class="comment-item__title">
                                    {{ comment.name
                                    }}<span>{{
                                        parsedDateAndTimeFromString(
                                            comment.created_at
                                        )
                                    }}</span>
                                </div>
                                <div
                                    class="comment-item__value"
                                    v-html="comment.comment"
                                />
                            </div>
                        </div>
                        <form
                            v-if="!disabled && !isEditable"
                            class="comments-wrap__form comments-form"
                            @submit.prevent="sendComment"
                        >
                            <AppTextareaA
                                v-model="comment"
                                :placeholder="$t('common.yourMessage')"
                                class="comments-form__input"
                                @keyup.ctrl.enter.prevent="sendComment"
                            />
                            <AppButton
                                class="comments-form__button"
                                round
                                type="submit"
                            >
                                <AppIcon icon="send-filled" />
                            </AppButton>
                        </form>
                    </div>
                </AdminCard>
            </div>
        </div>
        <CreditModal
            v-if="isSelectedVisibleProductModal"
            :call-data="callData"
            :product-info="productInfo"
            ref="creditModal"
            @close="isSelectedVisibleProductModal = false"
        />
        <CreditTable
            v-show="isVisibleCreditTable"
            ref="credittable"
            @back="back"
        />
    </div>
</template>

<script>
import FilterDate from "../mixins/FilterDate";
import { CallRoom } from "../services/chat/ChatModel";
import { mapGetters, mapState } from "vuex";
import { qqLang, ruLang, uzLang } from "../config/lang";
import {
    getCustomerProblemsFromCallData,
    getUserDataFromCallData,
} from "../helpers/chats";
import TreeProblemOptions from "../services/TreeProblemOptions";
import PopularThemes from "../services/PopularProblemOptions";
import { get, getAvatarUrl, post, put } from "../helpers/api";
import ChatMessagesService from "../services/chat/ChatMessagesService";
import AdminCard from "./partials/AdminCard";
import NavTabs from "./partials/NavTabs";
import InputWrapForm from "./partials/form/InputWrapForm";
import ProblemDescriptionItem from "./partials/ProblemDescriptionItem";
import InputWrapLabel from "./partials/form/InputWrapLabel";
import InputWrapField from "./partials/form/InputWrapField";
import PopularProblemOptionsComponent from "./partials/home/PopularProblemOptions";
import AppTreeSelect from "./partials/AppTreeSelect";
import AppSelectA from "./partials/AppSelectA";
import AppSelect from "./partials/AppSelect";
import AppTextareaA from "./partials/AppTextareaA";
import PinsAccordion from "./partials/home/PinsAccordion";
import AppButton from "./partials/AppButton";
import AppIcon from "./partials/AppIcon";
import {
    RedmineFormModel,
    PaginationList,
    RedmineFormLoading,
} from "@/components/redmine-form.vue";
const CreditModal = () => import("./creditModal");
const CreditTable = () => import("./creditTable");
import userAvatar from "../assets/img/user.svg";
import systemAvatar from "../assets/img/system-user.svg";
import FUNCTIONS from "../config/functions";
import RedmineForm from "@/components/redmine-form.vue";
import CustomerTask from "./admin/Customer/customer-task/customer-task.component.vue";
import ExternalTaskList from "./admin/Customer/external-task/external-task-list.vue";

const CHECKBOX = 0;
const TEXTFIELD = 3;
export default {
    name: "SelectedProblem",
    components: {
        RedmineForm,
        CreditTable,
        CreditModal,
        AppIcon,
        AppButton,
        PinsAccordion,
        AppTextareaA,
        AppSelectA,
        AppSelect,
        AppTreeSelect,
        PopularProblemOptionsComponent,
        InputWrapField,
        InputWrapLabel,
        ProblemDescriptionItem,
        InputWrapForm,
        NavTabs,
        AdminCard,
        CustomerTask,
        ExternalTaskList,
    },
    mixins: [FilterDate],
    props: {
        data: Object,
        isChat: Boolean,
        callData: {
            type: [CallRoom, Object],
            default: () => ({}),
        },
        canSendSms: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        taskId: {
            type: [String, Number, Boolean],
            default: false,
        },
        taskInfo: {
            type: Object,
            default: () => ({}),
        },
        isProblemSaved: {
            type: Boolean,
            default: false,
        },
        isClientBase: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            change_problem_loading: false,
            isVisibleCreditTable: "",
            isSelectedVisibleProductModal: false,
            productInfo: {},
            model: {
                theme_ids: [],
                marketing_source: "",
                status: "",
                type_id: "",
                branch_id: "",
                description: "",
                options: {},
                phone: null,
            },
            rules: {
                theme_ids: { required: true },
                type_id: { required: true },
                status: { required: true },
            },
            isProductLoading: false,

            productActionsLoaded: {},
            productsData: {},
            comment: "",
            application_comments: [],
            projects: new PaginationList(),
            responsibleList: new PaginationList(),
            responsibleFromGroup: new PaginationList(),
            redmine: new RedmineFormModel(),
            redmineFormLoading: new RedmineFormLoading(),
            redmineIsActive: false,
            validateRedmine: false,
        };
    },
    created() {
        const savedData = this.$store.state.callData.apealForm;
        if (savedData.type_id === 0) {
            this.setThemesFromTask();
        }
        if (this.problemsId) {
            this.initModel();
        } else {
            this.fetchRedmineProjects();
        }
        this.$store.dispatch("fetchLangsById");

        if (savedData.branch_id) this.model.branch_id = savedData.branch_id;
        if (savedData.status) this.model.status = savedData.status;
        if (savedData.marketing_source)
            this.model.marketing_source = savedData.marketing_source;
        if (savedData.description)
            this.model.description = savedData.description;
        if (savedData.type_id) {
            this.loadProduct(
                savedData.type_id,
                Array.from(savedData.theme_ids)
            );
        }
    },
    beforeDestroy() {
        this.treeOptions.destroy();
    },
    computed: {
        ...mapState({
            dictionary: (state) => state.dictionary,
            marketing_source: (state) => {
                return state.marketing_sources.map((source) => ({
                    value: source.id,
                    name: source.title,
                }));
            },
            branches: (state) => {
                return (
                    state.bankBranches?.result?.map((branch) => ({
                        value: branch.id,
                        name: branch.mfo + " " + branch.name,
                    })) ?? []
                );
            },
            calcDeposits: (state) => state.calculators.deposit,
            calcCredit: (state) => state.calculators.credits,
            sendToChatBot: (state) => state.calculators.chatBot,
            sendFfinSite: (state) => state.calculators.ffinSite,
            sendApplicationsLink: (state) => state.calculators.applications,
            sendEmailLink: (state) => state.calculators.email,
        }),
        ...mapState("problems", ["createdProblems", "nonEditableProblems"]),
        ...mapGetters({
            status_obj: "applicationStatusesForSelect",
            applicationStatusList: "applicationStatusList",
            status_obj_outbound: "applicationStatusesForOutbound",
            status_obj_incoming: "applicationStatusesForIncoming",
        }),
        statusOptionsList() {
            if (this.isOutboundAppeal) {
                return this.status_obj_outbound;
            } else if (this.isIncomingAppeal) {
                return this.status_obj_incoming;
            }
            return this.status_obj;
        },
        isOutboundAppeal() {
            if (this.data) {
                return this.data.call_type === 1;
            }
            return false;
        },
        isIncomingAppeal() {
            if (this.data) {
                return this.data.call_type === 0;
            }
            return false;
        },
        isOutboundCall() {
            return this.applicationStatusList.type === "outbound";
        },
        langsById() {
            return this.$store.state.langsById;
        },
        langsIcon() {
            let o = {};
            Object.keys(this.langsById).forEach((key) => {
                switch (this.langsById[key].code) {
                    case "qq":
                        o[key] = qqLang;
                        break;
                    case "ru":
                        o[key] = ruLang;
                        break;
                    default:
                        o[key] = uzLang;
                }
            });
            return o;
        },
        langsTitle() {
            let o = {};
            Object.keys(this.langsById).forEach((key) => {
                o[key] = this.langsById[key].name;
            });
            return o;
        },
        lang() {
            return (
                this.callData.lang ||
                this.langsById[this.data.language_id]?.code
            );
        },
        // language_id() {
        //     if(this.callData.language_id) return this.callData.language_id;
        //     return null;
        // },
        userData() {
            return getUserDataFromCallData(this.callData);
        },
        isCreate() {
            return !this.problemsId;
        },
        showCancelButton() {
            if (
                this.isOutboundCall &&
                (this.isCreate ||
                    this.createdProblems.includes(this.problemsId)) &&
                !this.isClientBase
            ) {
                return false;
            }
            return (
                !this.isCreate ||
                (this.isCreate &&
                    getCustomerProblemsFromCallData(this.callData)?.length)
            );
        },
        treeOptions() {
            return new TreeProblemOptions();
        },
        popularOptions() {
            return new PopularThemes(this.treeOptions);
        },
        call_number() {
            return this.callData.selectedPhone || this.callData.data.phone;
        },
        customer_id() {
            return this.callData.selectedCustomer;
        },
        disabledProblemStatus() {
            if (this.isCreate) return false;
            if (this.disabled) return true;
            if (this.isOutboundAppeal && !this.isEditable) return true;
            return (
                !this.isEditable &&
                this.isLockedStatus &&
                !this.nonEditableProblems.includes(this.data.id)
            );
        },
        isEditable() {
            return (
                !this.disabled &&
                (this.problemsId
                    ? this.createdProblems.includes(this.problemsId)
                    : true)
            );
        },
        problemsId() {
            return this.data ? this.data.id : null;
        },
        isLockedStatus() {
            return this.data && this.data.status === 1;
        },
        selectedProductOptions() {
            return this.model.theme_ids
                .map((id) => {
                    const checkboxes = [];
                    const textfields = [];
                    (this.productActionsLoaded[id] || []).forEach((product) => {
                        if (
                            product.action === CHECKBOX &&
                            !Object.values(FUNCTIONS).includes(product.function)
                        ) {
                            checkboxes.push(product);
                        } else if (product.action === TEXTFIELD) {
                            textfields.push(product);
                        }
                    });
                    return {
                        ...this.productsData[id],
                        checkboxes,
                        textfields,
                        actionsLength: checkboxes.length + textfields.length,
                    };
                })
                .filter((o) => o.actionsLength);
        },
        showEmailButton() {
            if (!this.isEditable) return false;
            return this.sendEmailLink;
        },
        showSMSButton() {
            if (!this.isEditable) return false;
            return (
                this.canSendSms &&
                (this.calcDeposits.length ||
                    this.calcCredit.length ||
                    this.sendToChatBot ||
                    this.sendApplicationsLink ||
                    this.sendFfinSite)
            );
        },
        title() {
            return this.isCreate
                ? "common.appeal"
                : {
                      text: "common.appealNo",
                      keys: { number: this.problemsId },
                  };
        },
        hasRedmineInfo() {
            return this.redmineIsActive;
        },
        formData() {
            const data = {
                type_id: this.model.type_id,
                product_ids: this.model.theme_ids,
                marketing_id: this.model.marketing_source,
                status: this.model.status,
                comment: this.model.description,
                call_number: this.callData.selectedPhone,
                branch_id: this.model.branch_id,
                customer_id: this.customer_id,
                source_id: this.callData.data.sourceId,
                lang: this.lang,
            };

            if (this.taskId) {
                Object.assign(data, { task_id: this.taskId });
            }

            if (this.hasRedmineInfo) {
                Object.assign(data, {
                    redmine: {
                        project_id: this.redmine.project,
                        tracker_id: this.redmine.tracker,
                        user_id:
                            this.redmine.responsibleFromGroup ||
                            this.redmine.responsible,
                        subject: this.redmine.subject,
                        description: this.redmine.description,
                        custom_fields: this.redmine.custom_fields.map((el) =>
                            el.toJson()
                        ),
                    },
                });
            }

            if (Object.keys(this.model.options).length) {
                // eslint-disable-next-line no-unused-vars
                data.options = Object.entries(this.model.options)
                    .filter(([_, options]) => Object.keys(options).length)
                    .map(([productId, options]) => ({
                        productId: +productId,
                        options: Object.entries(options).map(
                            ([id, text_value]) => {
                                const o = { id };
                                if (text_value !== true) {
                                    o.text_value = text_value;
                                }
                                return o;
                            }
                        ),
                    }));
            }

            return data;
        },
    },
    watch: {
        "model.theme_ids": [
            "recalculateOptionsModel",
            {
                handler: "getProductOptions",
                immediate: true,
            },
        ],
        problemsId($value) {
            if (!$value) {
                return;
            }

            this.getAppComments($value);
        },
    },
    methods: {
        initModel() {
            this.model.status = this.data.status;
            this.model.marketing_source = this.data.marketing_id || "";
            this.model.description = this.data.description || "";
            this.model.branch_id = this.data.branch_id || "";
            this.model.phone = this.data.phone.phone;
            this.model.options = this.data.application_options.reduce(
                (acc, option) => {
                    if (!acc[option.product_id]) {
                        acc[option.product_id] = {};
                    }
                    acc[option.product_id][option.option_id] =
                        option.option.action === CHECKBOX
                            ? true
                            : option.text_value;
                    return acc;
                },
                {}
            );

            const themes_selected = this.data.product_ids.map((item) => {
                return item.product_id;
            });
            this.loadProduct(this.data.type_id, themes_selected);
            this.getAppComments(this.problemsId);
        },
        updateThemes(id) {
            const option = this.treeOptions.flatOptions.find(
                (item) =>
                    item.id ===
                    this.model.theme_ids[this.model.theme_ids.length - 1]
            );

            this.redmineFormLoading.projects = true;
            this.redmineFormLoading.responsible = true;
            this.projects = new PaginationList();
            this.responsibleList = new PaginationList();
            this.responsibleFromGroup = new PaginationList();

            if (option?.metadata) {
                this.redmine.project = +option.metadata.redmine_project_id;
                this.redmine.tracker = +option.metadata.redmine_tracker_id;
                this.redmine.responsible = +option.metadata.redmine_user_id;
                this.redmine.responsibleFromGroup = null;
            } else {
                this.redmine = new RedmineFormModel();
            }

            this.fetchRedmineProjects(this.redmine.project);
            this.fetchRedmineUsersAndGroups(this.redmine.responsible);

            if (this.model.theme_ids.includes(id)) {
                this.popularOptions.updateTheme(id);
            }

            this.saveUserFormSelection();
        },
        onOptionsInput(productId, options) {
            if (this.isEditable) {
                this.model.options = {
                    ...this.model.options,
                    [productId]: options,
                };
            }
        },
        sendEmailToClient() {
            if (this.disabled) return;
            if (!this.userData.email) {
                this.$store.commit(
                    "notifications/error",
                    "errors.clientDoesNotHaveEmail"
                );
                return;
            }
            const formData = {
                mail: this.userData.email,
                customer_id: this.customer_id,
            };
            this.$store.commit("putEmailLink", false);
            if (!this.showSMSButton) {
                this.$store.commit("clearCalcParams", true);
            }
            post("/send-mail", formData);
        },
        sendSMSToClient() {
            if (this.disabled) return;
            if (!this.call_number) {
                this.$store.commit(
                    "notifications/error",
                    "errors.clientDoesNotSelectPhoneNumber"
                );
                return;
            }

            const formData = {
                phone: this.call_number.match(/\d+/g).join(""),
                credits: this.calcCredit,
                deposits: this.calcDeposits,
                chat: +this.sendToChatBot,
                is_digital: this.isChat,
                ffinSite: +this.sendFfinSite,
                application: +this.sendApplicationsLink,
                lang: this.lang,
            };
            this.$store.commit("clearCalcParams", true);
            const chatData =
                this.$store.state.chat.chatsData[
                    this.$store.state.chat.activeChatId
                ];
            post("/send-sms", formData).then(({ data }) => {
                if (this.isChat) {
                    ChatMessagesService.inputChatMessage(
                        data.links.join(" "),
                        chatData
                    );
                }
            });
        },
        openModal(id) {
            if (!id) return;

            this.$emit("open:modal", id);
        },
        getAppComments(id) {
            get(`/applications/${id}/comments`).then((response) => {
                if (response.data.success) {
                    this.application_comments = response.data.data.map(
                        (comment) => {
                            const c = {
                                id: comment.id,
                                name:
                                    comment.operator.first_name +
                                    " " +
                                    comment.operator.last_name,
                                created_at: comment.created_at,
                                comment: comment.comment,
                                avatar: comment.operator.avatar
                                    ? getAvatarUrl(comment.operator.avatar)
                                    : userAvatar,
                            };
                            if (comment.operator.username === "system") {
                                c.name = this.$t("common.systemComment");
                                c.avatar = systemAvatar;
                                c.isSystem = true;
                            }
                            return c;
                        }
                    );
                }
            });
        },
        sendComment() {
            if (this.disabled) return;
            const formData = {
                comment: this.comment,
                application_id: this.problemsId,
            };
            post("/applications/comment", formData)
                .then((res) => {
                    if (res.data.success) {
                        this.$emit("problem-action", formData.application_id);
                        this.getAppComments(formData.application_id);
                        this.comment = "";
                    }
                })
                .catch((e) => (this.change_problem_loading = false));
        },
        saveData() {
            if (this.change_problem_loading || this.disabled) return;
            if (this.formData.redmine) {
                if (
                    this.formData.redmine.project_id === null ||
                    this.formData.redmine.tracker_id === null ||
                    this.formData.redmine.user_id === null ||
                    this.formData.redmine.subject === null ||
                    this.formData.redmine.description === null ||
                    !this.redmine.custom_fields.every((el) => el.valid)
                ) {
                    this.validateRedmine = true;
                    return;
                }
            }

            this.change_problem_loading = true;

            let url = `/applications/${this.problemsId}`;
            let method = put;

            const isCreate = this.isCreate;
            if (isCreate) {
                url = "/applications";
                method = post;
            }

            const isEditable = this.isEditable;

            method(url, this.formData)
                .then((res) => {
                    if (res.data.success) {
                        this.$emit("problem-action", res.data.data.id);
                        if (!isEditable) {
                            this.$store.commit(
                                "problems/addNonEditableProblem",
                                res.data.data.id
                            );
                        }
                        this.$emit("update", {
                            data: res.data.data,
                            mode: isCreate ? "create" : "edit",
                        });
                        if (isCreate) {
                            this.$store.commit(
                                "problems/addProblem",
                                res.data.data.id
                            );
                        }
                        this.cancel();
                        if (
                            this.hasRedmineInfo &&
                            !res.data.data.external_tasks
                        ) {
                            this.$store.commit("notifications/add", {
                                type: "error",
                                message: this.$t(
                                    "errors.redmine-credentials-not-found"
                                ),
                            });
                        }
                    } else {
                        throw new Error();
                    }
                })
                .catch((e) => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                })
                .finally(() => (this.change_problem_loading = false));
            this.$store.commit("callData/resetAppealFormData");
        },
        cancel() {
            this.$store.commit("callData/resetAppealFormData");
            this.$emit("back-list");
            if (this.canSendSms) {
                this.$store.commit("clearCalcParams");
            }
        },
        async loadProduct(id, selected = []) {
            try {
                if (id === this.model.type_id) {
                    return;
                }

                this.model.type_id = id;
                this.isProductLoading = true;

                const response = await this.treeOptions.loadOptions(id);

                if (response) {
                    this.productsData = this.treeOptions.flatOptions.reduce(
                        (acc, { id, title }) => {
                            acc[id] = { id, title };
                            return acc;
                        },
                        {}
                    );

                    this.model.theme_ids = selected;
                }
            } catch (e) {
                this.$store.commit(
                    "notifications/error",
                    this.$t("errors.somethingWentWrong")
                );
            } finally {
                this.isProductLoading = false;
            }
        },
        back() {
            this.isVisibleCreditTable = false;
            this.isSelectedVisibleProductModal = true;
            this.$refs.creditModal.openModal();
        },

        recalculateOptionsModel() {
            this.model.options = this.model.theme_ids.reduce((acc, id) => {
                if (this.model.options[id]) {
                    acc[id] = this.model.options[id];
                }
                return acc;
            }, {});
        },
        async getProductOptions() {
            try {
                const needLoad = this.model.theme_ids.filter(
                    (id) => !this.productActionsLoaded.hasOwnProperty(id)
                );
                if (!needLoad.length) {
                    return;
                }
                const data = await Promise.all(
                    needLoad.map((id) => get(`/products/${id}/options`))
                );
                needLoad.forEach((id, idx) => {
                    this.$set(
                        this.productActionsLoaded,
                        id,
                        data[idx].data.data
                    );
                });
            } finally {
                this.checkSmsProductFunctions();
            }
        },
        checkSmsProductFunctions() {
            // Очищаем все данные по смскам, кроме кредитов и депозитов
            this.$store.commit("clearCalcParamsNoCreditsDeposits");

            // Получаем все опции по выбранным темам, выбираем только смски и в массиве оставляем функцию
            const themedOptions = this.model.theme_ids
                .reduce((acc, id) => {
                    return [...acc, ...(this.productActionsLoaded[id] || [])];
                }, [])
                .filter((option) => option.function >= 3)
                .map((item) => item.function);

            if (themedOptions.includes(FUNCTIONS.SEND_SMS_CHAT_BOT)) {
                this.$store.commit("putChatBotLink", true);
            }

            if (themedOptions.includes(FUNCTIONS.SEND_SMS_FFIN_SITE)) {
                this.$store.commit("putFfinSiteLink", true);
            }

            if (themedOptions.includes(FUNCTIONS.SEND_SMS_MOB_APP)) {
                this.$store.commit("putApplicationsLink", true);
            }

            if (themedOptions.includes(FUNCTIONS.SEND_EMAIL)) {
                this.$store.commit("putEmailLink", true);
            }
        },
        async fetchRedmineProjects(id) {
            const query = Object.assign(
                {},
                {
                    offset: this.projects.offset,
                },
                id
                    ? {
                          append_id: id,
                      }
                    : {}
            );

            try {
                const { data } = await get(
                    "redmine/projects?" + new URLSearchParams(query).toString()
                );
                this.projects.fillFromData(data.data, "projects");
            } catch (e) {
            } finally {
                this.redmineFormLoading.projects = false;
            }
        },
        async fetchRedmineUsersAndGroups(id) {
            const query = Object.assign(
                {},
                {
                    offset: this.responsibleList.offset,
                },
                id
                    ? {
                          append_id: id,
                      }
                    : {}
            );
            if (!this.redmine.project) {
                this.redmineFormLoading.responsible = false;
                return;
            }

            try {
                const { data } = await get(
                    "redmine/members/" +
                        this.redmine.project +
                        "?" +
                        new URLSearchParams(query).toString()
                );
                this.responsibleList.fillFromData(data.data, "members");
            } catch (e) {
            } finally {
                this.redmineFormLoading.responsible = false;
            }
        },
        async fetchRedmineUsers(id) {
            const query = Object.assign(
                {},
                {
                    offset: this.responsibleFromGroup.offset,
                    group_id: this.redmine.responsible,
                },
                id
                    ? {
                          append_id: id,
                      }
                    : {}
            );
            if (!this.redmine.responsible) {
                this.redmineFormLoading.responsibleFromGroup = false;
                return;
            }

            try {
                const { data } = await get(
                    "redmine/users" +
                        "?" +
                        new URLSearchParams(query).toString()
                );
                this.responsibleFromGroup.fillFromData(data.data, "users");
            } catch (e) {
            } finally {
                this.redmineFormLoading.responsibleFromGroup = false;
            }
        },
        async fetchRedmineItems(name) {
            if (name === "projects") {
                this.projects.offset += 25;
                return this.fetchRedmineProjects();
            } else if (name === "responsibleList") {
                this.responsibleList.offset += 25;
                return this.fetchRedmineUsersAndGroups();
            } else if (name === "responsibleFromGroup") {
                this.responsibleFromGroup.offset += 25;
                return this.fetchRedmineUsers();
            }
        },
        clearMembers() {
            this.responsibleList = new PaginationList();
            this.clearUsers();
        },
        clearUsers() {
            this.responsibleFromGroup = new PaginationList();
        },
        setThemesFromTask() {
            if (!this.taskId || !this.taskInfo || this.problemsId) return;
            const products = this.taskInfo.product_ids.map((item) => +item);
            this.model.theme_ids = products;
            this.loadProduct(1, products);
        },
        onBranchFilter(input, option) {
            const branch = this.branches.find((el) => el.value === option.key);
            if (branch) {
                return branch.name.toLowerCase().includes(input.toLowerCase());
            }

            return true;
        },
        saveUserFormSelection() {
            this.$store.commit("callData/setAppelFormData", {
                theme_ids: Array.from(this.model.theme_ids),
                branch_id: this.model.branch_id,
                status: this.model.status,
                marketing_source: this.model.marketing_source,
                description: this.model.description,
                type_id: this.model.type_id,
            });
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.comment-item {
    $self: &;
    position: relative;
    padding-left: 44px;

    &__avatar {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    &__title {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-black);

        span {
            display: inline-block;
            margin-left: 8px;
            font-size: 14px;
            color: var(--color-gray-lighten);
            font-family: $font-secondary;
        }
    }

    &__value {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-gray-light);
        font-family: $font-secondary;
        word-break: break-word;
        hyphens: auto;
    }

    &--theme-primary-dark {
        #{$self}__value {
            color: #2552c6;
        }
    }
}
.view-problem-layout {
    $self: &;
    display: flex;
    &__form {
        flex-grow: 1;
        width: 100px;
    }
    &__comments {
        display: flex;

        width: calc(872 / 1792 * 100% - 20px);
        margin-left: 20px;
        > * {
            flex-grow: 1;
        }
    }
    &--type-chat {
        flex-wrap: wrap;
        #{$self}__form,
        #{$self}__comments {
            width: 100%;
            margin-left: 0;
        }
        #{$self}__comments {
            margin-top: 20px;
        }
    }
}
.comments-wrap {
    $self: &;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &__body {
        padding: 24px 34px;
        flex-grow: 1;
        height: 10px;
        overflow-y: scroll;
    }
    &__item {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &--type-chat {
        #{$self}__body {
            min-height: 200px;
            max-height: 500px;
            height: auto;
        }
    }
}
.comments-form {
    display: flex;
    align-items: flex-end;
    padding: 16px 34px;
    border-top: 1px solid var(--color-border);
    &__input {
        margin-bottom: 0 !important;
        padding-top: 12px;
        padding-left: 20px;
        background: var(--color-white-bluesh);
        border: 0;
        border-radius: 12px;
        font-weight: 500;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
        width: 10px;
        flex-grow: 1;
        min-height: 48px !important;
        resize: none;
        color: var(--color-dark);
    }
    &__button {
        flex-shrink: 0;
        margin-left: 16px;
    }
}
.popular-theme-wrap {
    margin-top: -3px;
    margin-bottom: -3px;
    margin-left: 8px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    > * {
        width: 100%;
    }
}
.lang-badge {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    padding: 8px 14px;
    background: #f4f4f4;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #717171;
    &__icon {
        margin-right: 10px;
    }
}
</style>
