const LoanMixins = {
    methods: {
        getParsedDate(str) {
            const day = str.slice(0,2);
            const month = str.slice(3, 5) - 1;
            const year = str.slice(6);

            return new Date(year, month, day);
        },

        getToScrollId(arr, dateName) {
            const date = new Date();

            const searchingDateStr = date.toLocaleDateString().slice(3);
            const searchingDate = this.getParsedDate(date.toLocaleDateString());

            let i = Math.round(arr.length * 0.5);
            if(!arr[i]) return -1;

            let tempDate = arr[i][dateName].slice(3);

            let workCount = 0;
            let min = 0;
            let max = arr.length;

            // workCount в качестве защиты, чтобы не уходить в бесконечный цикл
            while(tempDate !== searchingDateStr) {
                if(workCount >= 1000) {
                    break;
                }
                workCount++;

                if(searchingDate < this.getParsedDate(arr[i][dateName])) {
                    max = i;
                } else {
                    min = i;
                }
                i = Math.floor((max - min) / 2) + min;

                if(arr[i]) {
                    tempDate = arr[i][dateName].slice(3);
                } else {
                    break;
                }
            }

            if(!arr[i]) {
                i = 0
            }

            setTimeout(() => {
                this.$refs.modal.$el.scrollTo({
                    top: this.$refs['row' + i].getBoundingClientRect().top + this.$refs.modal.$el.scrollTop - 202,
                    behavior: 'smooth'
                });
            }, 301)
        },
    }
}
export default LoanMixins;
