<template>
    <component :is="tag" class="app-checkbox-a spacer-button" :class="[`app-checkbox-a--theme-${theme}`, {'app-checkbox-a--selected': isChecked && disabled}]">
        <input v-if="!disabled" class="app-checkbox-a__input" :checked="isChecked" v-bind="parsedAttrs" v-on="parsedListeners" :type="type" :autoFocus="autoFocus" ref="input"
               @focusin="$emit('focusin', $event)" @focusout="$emit('focusout', $event)" @change="onSelect"/>
        <div class="app-checkbox-a__indicator">
            <AppIcon icon="check-round" size="10"/>
        </div>
    </component>
</template>
<script>
import AppIcon from "./AppIcon";

export const selectCheckboxItem = (value, val) => {
    let result = val;
    if(Array.isArray(value)) {
        result = [...value];
        const idx = result.indexOf(val);
        if(idx > -1) {
            result.splice(idx, 1);
        } else {
            result.push(val);
        }
    } else if(typeof value === 'boolean') {
        result = !value;
    } else if(value === result) {
        result = '';
    }
    return result;
}

export const isSelectedCheckboxItem = (value, val) => {
    return Array.isArray(value) ? value.includes(val) : val === value;
}

export default {
    name: 'AppCheckboxA',
    components: {AppIcon},
    inheritAttrs: false,
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: {
            type: [String, Number, Boolean, Array],
            required: true
        },
        val: {
            type: [String, Number],
            required: true
        },
        type: {
            type: String,
            default: 'checkbox',
        },
        tag: {
            type: String,
            default: 'label'
        },
        theme: {
            type: String,
            default: 'round'
        },
        autoFocus: Boolean,
        disabled: Boolean
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            delete listeners.focusin;
            delete listeners.focusout;
            return listeners;
        },
        isChecked() {
            return Array.isArray(this.value) ? this.value.includes(this.val) : this.value === true || this.value === this.val;
        }
    },
    mounted() {
        if(this.autoFocus) {
            this.focus();
        }
    },
    methods: {
        onSelect() {
            this.$emit('input', selectCheckboxItem(this.value, this.val));
        },
        focus() {
            if(this.$refs.input) {
                this.$refs.input.focus();
            }
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.app-checkbox-a {
    $self: &;

    width: 24px;
    height: 24px;
    position: relative;
    &__input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    &__indicator {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #717171;
    }
    &--selected &__indicator,
    &__input:checked + &__indicator {
        border: 1px solid #397AF5;
        background-color: #397AF5;
        color: #fff;
    }
    &__input:focus + &__indicator {
        box-shadow: $input-focus-shadow;
    }

    &--theme-square {
        #{$self}__indicator {
            border: 1px solid #CACACA;
            border-radius: 4px;
            color: transparent;

            svg {
                width: 14px;
                height: 14px;
                stroke: currentColor;
            }
        }

        #{$self}__input:checked + &__indicator {
            background-color: #397AF5;
            color: #fff;
        }
    }
}
</style>
