import {AGENT_CHAT_STATE, CHAT_EVENT_CAUSE} from "@contact-center/asakabank-cisco-finesse/dist/lib/finesse";
import LangController from "./LangController";
import app from "@/main";

export const CAN_CHAT = 'can-chat';
export const STATE_CHANGE = 'state-change';
export const INCOMING_CHAT = 'incoming-chat';
export const ACCEPTED_CHAT = 'accepted-chat';
export const MESSAGE = 'message';
export const SYSTEM_EVENT = 'system-event';
export const ERROR = 'error';
export const SWITCH_ERROR_CHAT = 'switch-error-chat';
export const LEAVE = 'leave';
export const CLEAR_INCOMING_CHAT = 'clear-incoming-chat';
export const LOST_REQUEST = 'lost-request';
export const RESERVED = 'lost-request';
export const STATE_CHECK = 'state-check';

export const CHAT_OVERLOADED = 'overloaded';

const reversedAgentChatState = Object.entries(AGENT_CHAT_STATE).reduce((acc, [key, value]) => ({...acc, [value]: key}), {});

export class EventEmitter {
    constructor(events) {
        this.events = {destroy: [], ...events.reduce((acc, curr) => ({...acc, [curr]: []}), {})};
    }

    on(eventName, cb) {
        if (typeof cb !== 'function') {
            throw new Error('cb должно быть функцией');
        }
        const eventObject = this.events[eventName];

        eventObject.push(cb);
    }

    off(eventName, cb) {
        const cbIndex = this.events[eventName].findIndex(event => event === cb);
        if (cbIndex > -1) {
            this.events[eventName].splice(cbIndex, 1);
        }
    }

    destroy() {
        this.emit('destroy');
        this.events = {};
    }

    /**
     * @protected
     * @param eventName
     */
    emit(eventName, data) {
        this.events[eventName].forEach(event => event(data));
    }
}
class ChatError extends Error {
    constructor(message, error) {
        super(message);
        console.error('Chat error', error);
    }
}

function ChatQueue() {
    let queue = [];

    return {
        add(chatObj) {
            if(!queue.map(chat => chat.dialogID).includes(chatObj.dialogID)) {
                queue.push(chatObj);
            }
        },
        remove(dialogID) {
            const idx = queue.findIndex(chat => chat.dialogID === dialogID)
            if(idx > -1) {
                queue.splice(idx, 1);
            } else {
                throw new Error('Нет чата с таким id ' + dialogID);
            }
        },
        getFirst() {
            return queue[0];
        },
        clear() {
            queue = [];
        },
        get length() {
            return queue.length;
        },
        get all() {
            return queue;
        }
    }
}
const incomingChatQueue = new ChatQueue();
const activeChatQueue = new ChatQueue();

const anonymUser = 'Неизвестный клиент';
export const ChatMessage = (() => {
    let idCounter = 0;
    return function (message) {
        let name = message.nick || anonymUser;
        if(typeof name === 'string') {
            name = name.trim();
        }
        return {
            isEvent: message.isEvent,
            body: message.textContent,
            chatRoom: message.room,
            createdAt: new Date(),
            userName: name,
            avatar: null,
            id: idCounter++
        }
    }
})();
function IncomingChatRoom(message) {
    let name = message.userName || anonymUser;
    if(typeof name === 'string') {
        name = name.trim();
    }
    return {
        userName: name,
        chatRoom: message.chatRoom,
        dialogID: message.dialogID,
        source: message.userSource,
        phone: message.userPhone,
        lang: message.lang in LangController.langsList ? message.lang : LangController.defaultLang,
        token: message.token,
        avatar: null,
        dialogVars: message.dialogVars ?? []
    };
}

export default new (class extends EventEmitter {
    #agentInstance;
    #chatChannel = null;
    #isAcceptingChat = false;

    #operatorState = AGENT_CHAT_STATE.NOT_READY;

    #roomPresences = null;
    #roomMessages = null;
    #roomConnectionError = null;

    #destroyTimeout = null;

    #isReserved = false;

    #checkWorking = false;
    #chatStateCheckID = null;

    constructor() {
        // Весь список возможных событий для чатов известен заранее, здесь добавляется новый, чтобы система
        // не проверяла постоянно, может ли она использовать определенные события
        super([CAN_CHAT, STATE_CHANGE, INCOMING_CHAT, ACCEPTED_CHAT, ERROR, LEAVE, MESSAGE, SYSTEM_EVENT,
            SWITCH_ERROR_CHAT, CLEAR_INCOMING_CHAT, LOST_REQUEST, STATE_CHECK]);
    }

    // Проксирую агент инстанс, чтобы в случае чего можно было допилить его в одном месте
    get agentInstance() {
        return this.#agentInstance;
    }

    #chatStateCheck() {
        this.#chatStateCheckID = null;
        return this.agentInstance.finesse.ChatStateCheck();
    }

    reloadChat() {
        this.#checkWorking = true;
        this.#chatStateCheck()
            .then(id => {
                this.#chatStateCheckID = id;
            });
    }

    initInstance(agentInstance) {
        console.log('Init agent instance for chat');
        this.#agentInstance = agentInstance;

        this.#chatStateCheck().then((id) => {
            console.log('Chat state check', id);
            this.#chatStateCheckID = id;

            const that = this;

            this.agentInstance.xmpp.chatAgentInfo$.subscribe({
                next(x) {
                    console.log('Chat agent info', x);
                    if (x.requestId === that.#chatStateCheckID && !x.error) {
                        console.log('Operator can chat');
                        if(that.#checkWorking) {
                            that.#checkWorking = false;
                            if(that.#operatorState === AGENT_CHAT_STATE.READY) {
                                that.emit(STATE_CHECK);
                                that.setOperatorReady();
                            }
                        } else {
                            that.emit(CAN_CHAT);
                        }
                    } else if(x.requestId === that.#chatStateCheckID && x.error) {
                        that.#checkWorking = false;
                        that.emit(ERROR, new ChatError('chatStateCheck')); // строку chatStateCheck не менять
                    }

                    const state = x.state;

                    that.emit(STATE_CHANGE, reversedAgentChatState[state]);
                    that.#operatorState = x.state;

                    console.log('Operator state is', reversedAgentChatState[state])

                    if(activeChatQueue.length >= 5 && x.state !== AGENT_CHAT_STATE.NOT_READY) {
                        console.log('Operator has 5 chats');
                        that.setOperatorNotReady();
                    }

                    switch(state) {
                        case AGENT_CHAT_STATE.READY:
                            const incoming = that.getIncomingChatFromQueue();
                            if(incoming) {
                                that.emit(INCOMING_CHAT, incoming);
                            } else if(that.#isReserved) {
                                console.log('After Reserved');
                                that.setOperatorReady();
                                that.#isReserved = false;
                            }
                            break;
                        case AGENT_CHAT_STATE.NOT_READY:
                            incomingChatQueue.clear();
                            that.emit(CLEAR_INCOMING_CHAT);
                            that.#destroyChatConnectionIfNeed();
                            if(that.#isAcceptingChat) {
                                that.#isAcceptingChat = false;
                            }
                            if(that.#isReserved) {
                                that.#isReserved = false;
                            }
                            if(activeChatQueue.length >= 5) {
                                that.emit(STATE_CHANGE, CHAT_OVERLOADED);
                            }
                            break;
                        case AGENT_CHAT_STATE.LOST_REQUEST:
                            that.setOperatorNotReady();
                            that.emit(LOST_REQUEST);
                            that.emit(STATE_CHANGE, reversedAgentChatState[AGENT_CHAT_STATE.NOT_READY]);
                            break;
                        case AGENT_CHAT_STATE.RESERVED:
                            that.#isReserved = true;
                    }
                }
            });

            console.log('Subscribe incoming chat');
            this.agentInstance.xmpp.incomingChat$.subscribe({
                next(x) {
                    console.log('Incoming chat', x);
                    if (x.eventCause === CHAT_EVENT_CAUSE.CHAT_REQUEST) {
                        console.log('Incoming chat is absolutely new');
                        const chatRoom = new IncomingChatRoom(x)
                        incomingChatQueue.add(chatRoom);
                        that.emit(INCOMING_CHAT, chatRoom);
                        app.$store.commit('system/setter', { key: "sidebarOpened", value: false });
                        if (that.#destroyTimeout) {
                            that.#clearDestroyTimer();
                        }
                    }
                },
                error(e) {
                    that.emit(ERROR, new ChatError('Ошибка во входящих чатах', e));
                }
            });
        });
    }

    #changeOperatorState(state) {
        console.log('Chat before change state', state);
        if(this.#operatorState === AGENT_CHAT_STATE.RESERVED) {
            console.log('Operator is reserved, so we can\'t change state' )
            return;
        }
        return this.agentInstance.finesse.ChangeAgentChatState(AGENT_CHAT_STATE[state]).then((res) => {
            // в случае успеха res будет содержать id запроса, результат которого придет в канал  chatAgentInfo
            if (res !== '') {
                console.log('Chat complete change state', state);
                return res;
            }
        }).catch((e) => {
            this.emit(ERROR, new ChatError('Ошибка в смене состояния оператора', e));
        })
    }
    setOperatorReady() {
        if(activeChatQueue.length >= 5) return;
        this.#changeOperatorState('READY')
    }
    setOperatorNotReady() {
        this.#changeOperatorState('NOT_READY')
    }

    getIncomingChatFromQueue() {
        return incomingChatQueue.getFirst();
    }

    #createChatConnection(chat) {
        console.log('Chat create connection');
        this.agentInstance.chatSetting = {
            domain: process.env.VUE_APP_CISCO_CCP_DOMAIN, //домен UCCX CCP, в нашем случае не меняем
            password: chat.token, //токен для подключения полученный в последнем запросе на чат
            username: chat.dialogID // id диалога полученный в последнем запросе на чат
        };
        this.#chatChannel = this.agentInstance.chat;
    }
    #destroyChatConnectionIfNeed() {
        if (this.#chatChannel && this.#roomPresences && !activeChatQueue.getFirst() && !this.#destroyTimeout) {
            console.log('Don\t need to destroy chat')
            this.#destroyChatConnection();
        }
    }
    #destroyChatConnection() {
        console.log('Chat connection destroy');
        this.#roomPresences.unsubscribe();
        this.#roomMessages.unsubscribe();
        this.#roomConnectionError?.unsubscribe();
        this.#roomMessages = null;
        this.#roomPresences = null;
        this.#chatChannel = null;
        this.agentInstance.clearChatInstance();
    }
    #startDestroyTimer() {
        console.log('Start destroy timer');
        this.#destroyTimeout = setTimeout(() => {
            console.log('Destroy timer complete');
            this.#destroyTimeout = null;
            this.#destroyChatConnectionIfNeed();
        }, 30000);
    }
    #clearDestroyTimer() {
        console.log('Clear destroy timer');
        clearTimeout(this.#destroyTimeout);
        this.#destroyTimeout = null;
    }

    acceptChat(incomingChat) {
        if (this.#isAcceptingChat || !incomingChat) return;

        const that = this;
        this.#isAcceptingChat = true;
        try {
            incomingChatQueue.remove(incomingChat.dialogID);
        } catch(e) {
            new ChatError('Ошибка при удалении входящего чата', e);
        }
        console.log('Chat before accept', incomingChat);

        this.agentInstance.finesse.AcceptChat(incomingChat.dialogID).then((res) => {
            if (res !== '') {
                // console.log('Chat accepted', incomingChat);

                const joinConferenceAfterAcceptChat = () => {
                    that.#chatChannel.joinConference(incomingChat.chatRoom, that.agentInstance.agent.username);
                    that.emit(ACCEPTED_CHAT, incomingChat);
                    that.emit(MESSAGE, new ChatMessage({
                        isEvent: true,
                        room: incomingChat.chatRoom,
                        nick: incomingChat.userName,
                        textContent: 'Вы подключены к клиенту'
                    }));
                    that.#isAcceptingChat = false;
                    activeChatQueue.add(incomingChat);
                    if(activeChatQueue.length < 5) {
                        // console.log('Set operator ready after accept chat');
                        that.setOperatorReady();
                    } else if(activeChatQueue.length > 5) {
                        // console.log('Set operator not ready if many chats');
                        that.setOperatorNotReady();
                    }
                }

                if (!that.#chatChannel) {
                    that.#createChatConnection(incomingChat);

                    that.#chatChannel.Connect().then((res) => {
                        // console.log('Chat channel new');
                        that.#roomMessages = that.#chatChannel.roomMessages$.subscribe({
                            next(x) {
                                // console.log("Room message", x);
                                if (!x.isEvent) {
                                    console.log("Room message is from user");
                                    that.emit(MESSAGE, new ChatMessage(x));
                                }
                            }
                        });
                        that.#roomPresences = that.#chatChannel.roomPresences$.subscribe({
                            next(x) {
                                // console.log("Room system message", x);
                                if (x.isLeaveChat && x.isEvent) {
                                    that.emit(LEAVE, {chatRoom: x.room});
                                    that.emit(MESSAGE, new ChatMessage({
                                        ...x,
                                        textContent: 'Клиент вышел из чата, вы можете завершить чат'
                                    }));
                                }
                            }
                        });
                        that.#roomConnectionError = that.#chatChannel.connectionError$.subscribe({
                            next(x) {
                                console.log("Chat room connection error");
                                activeChatQueue.all.forEach(el => {
                                    console.log("LEAVE CHAT ON CONNECTION ERROR");
                                    that.emit(LEAVE, { chatRoom: el.chatRoom });
                                });
                                that.#destroyChatConnection();
                            }
                        })

                        joinConferenceAfterAcceptChat();
                    }).catch((e) => {
                        that.#isAcceptingChat = false;
                        that.emit(ERROR, new ChatError('Ошибка в принятии чата', e));
                    });
                } else {
                    console.log('Chat channel old');
                    joinConferenceAfterAcceptChat();
                }
                that.#clearDestroyTimer();
            } else {
                throw new Error();
            }
        }).catch(e => {
            that.#isAcceptingChat = false;
            that.#destroyChatConnectionIfNeed();
            that.emit(ERROR, new ChatError('Не удается подключиться к чату', e));
        });
    }

    leaveChat(chatRoom) {
        this.#chatChannel?.leaveChatRoom(chatRoom.chatRoom, this.agentInstance.agent.username);
        return this.agentInstance.finesse.LeaveChatRoom(chatRoom.dialogID)
            .then((res) => {
                console.log('Leave chat', chatRoom, res);
                const chatLength = activeChatQueue.length;
                activeChatQueue.remove(chatRoom.dialogID);
                if (!activeChatQueue.getFirst()) {
                    this.#startDestroyTimer();
                }
                if(chatLength === 5) {
                    this.setOperatorReady();
                }
            })
    }

    sendMessage(formData) {
        console.log('Chat send message');
        try {
            return this.#chatChannel.sendMessage(formData.chatRoom, formData.message);
        } catch(e) {
            this.emit(ERROR, new ChatError('chatStateCheck')); // строку chatStateCheck не менять
        }
    }
})
