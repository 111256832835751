<template>
    <a-input
        v-model="model"
        v-bind="parsedAttrs"
        v-on="parsedListeners"
        class="app-input"
        :class="classes"
        ref="input"
        :disabled="disabled || readonly"
        :size="computedSize"
        @change="onInput($event, true)"
        @input="onInput"
    >
        <template v-if="$slots.addonAfter" #addonAfter>
            <slot name="addonAfter"></slot>
        </template>
        <template v-if="$slots.suffix" #suffix>
            <slot name="suffix" />
        </template>
    </a-input>
</template>
<script>
export default {
    name: 'AppInput',
    model: {
      prop: "value",
      event: "input"
    },
    props: {
        filterValue: Function,
        error: Boolean,
        theme: String,
        disabled: Boolean,
        readonly: Boolean,
        size: String,
        round: String,
        textType: String,
        value: {
            type: [String, Number],
            default: ''
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
        computedSize() {
            const acceptedSizes = ['large', 'default', 'small'];

            if (acceptedSizes.includes(this.size)) {
                return this.size
            }

            return "default"
        },
        classes() {
            return {
                ['app-input--error']: this.error,
                ['app-input--disabled']: this.disabled,
                ['app-input--readonly']: this.readonly,
                ['app-input--theme-' + this.theme]: this.theme,
                ['app-input--text-' + this.textType]: this.textType,
                ['app-input--round-' + this.round]: this.round,
                ['app-input--size-' + this.size]: this.size,
            }
        }
    },
    methods: {
        focus() {
            try {
                this.$refs.input.$el.querySelector('input').focus();
            } catch(e) {
            }
        },
        onInput(e, change) {
            if(this.disabled || this.readonly) return;

            let newValue = e.target.value;
            if(this.filterValue) {
                newValue = this.filterValue(newValue);
            }

            this.$nextTick(() => {
                if(this.filterValue) {
                    e.target.value = this.value;
                } else {
                    this.$refs.input.setValue(this.value);
                }
            });

            if(change) {
                this.$emit('change', newValue)
            } else {
                this.$emit('input', newValue)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/animation.scss';
@import '../../assets/scss/variables';
.app-input {
    border-color: var(--color-border);
    .ant-input-suffix {
        left: auto !important;
        width: 24px;
        height: 24px;
        right: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &--disabled {
        border: 1px solid var(--color-border);
        background: var(--color-border-lighten);
    }
    &--readonly {
        cursor: default !important;
        color: var(--color-dark) !important;
    }
    &--error {
        border: 1px solid #e55353;
        animation: error 0.5s 1 both;
    }
    &--size-extra-large {
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
    }
    &--theme-transparent {
        border: 0;
        &:focus {
            border: transparent;
            outline: 0;
            box-shadow: none;
        }
    }
    &--theme-gray {
        background: #EBEBEF;
        border-color: #EBEBEF;

        @include placeholder {
            color: #949494;
        }
    }
    &--text-medium {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    &--round-medium {
        border-radius: 12px;
    }
}
</style>

<style lang="scss">
.app-input {
    input::placeholder {
        font-size: 16px;
        font-weight: 500;
    }
}
</style>
