<template>
    <div class="bank-user-tags block-wrap block-wrap--offset-micro block-wrap--nowrap">
        <div v-if="value" class="bank-user-tags__value">{{ value }}</div>
        <template v-for="tag in tags">
            <div v-if="tag.icon" class="bank-user-tags__tag tag-icon" :class="{['tag-icon--theme-' + tag.theme]: tag.theme}">
                <AppIcon :icon="tag.icon"/>
            </div>
            <div v-else class="bank-user-tags__tag id-tag" :class="{['id-tag--theme-' + tag.theme]: tag.theme, 'id-tag--size-small': !full }">{{ tag.value }}</div>
        </template>
    </div>
</template>
<script>
import AppIcon from "./partials/AppIcon";
export default {
    name: 'BankUserTags',
    components: {AppIcon},
    props: {
        value: String,
        full: Boolean,
        userData: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        tags() {
            const user = this.userData;
            const tags = [];

            if(!user || !user.id) return tags;

            if(this.full) {
                tags.unshift({
                    value: user.bank_client_id ? 'идентифицирован' : 'не идентифицирован',
                    theme: user.bank_client_id ? '' : 'gray'
                });
            }
            if(!user.bank_client_id) {
                return tags;
            }
            tags.push({
                value: user.is_abs_active ? 'активный' : 'не активный',
                theme: user.is_abs_active ? 'primary' : 'gray'
            });
            if(!this.full) {
                tags.unshift({
                    icon: 'checked',
                });
            }

            return tags;
        }
    }
}
</script>
<style lang="scss">
.tag-icon {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #00CC56;
    color: #fff;
    padding: 4px;
    svg {
        display: block;
        width: 10px;
        height: 10px;
    }
    &--theme-gray {
        background-color: #F4F4F4;
        color: #949494;
    }
    &--theme-danger {
        background-color: var(--color-danger);
    }
}
.id-tag {
     background: var(--color-success);
     padding: 1px 6px 0;
     height: 16px;
     border-radius: 50px;
     width: fit-content;
     white-space: nowrap;
     font-size: 10px;
     line-height: 12px;
     color: var(--color-white);
     &--theme-gray {
         color: var(--color-gray-lighten);
         background: var(--color-border-lighten);
     }
     &--theme-primary {
         background: #397AF5;
     }
     &--size-small {
         height: 18px;
         font-size: 12px;
         line-height: 14px;
    }
    &--theme-danger {
        background-color: var(--color-danger);
    }
 }
.bank-user-tags {
    align-items: center;
    &__value {
        max-width: max-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__tag {
        text-transform: lowercase;
    }
}
</style>
