<template>
    <div
        class="client-card-a"
        :class="{
            'client-card-a--chat': isChat,
            'client-card-a--loading': customerFetching,
        }"
    >
        <LoadingIcon class="client-card-a__loading-icon" />
        <ScriptMessage
            v-if="!disableHints && unknownScriptsVisible"
            v-model="scriptMessagesSelection.unknown"
            :call-data="callData"
            :data="unknownScripts"
        />
        <ScriptMessage
            v-else-if="!disableHints && knownScriptsVisible"
            v-model="scriptMessagesSelection.known"
            :call-data="callData"
            :data="knownScripts"
        />
        <ScriptMessage
            v-else-if="!disableHints && selectClientScriptsVisible"
            v-model="scriptMessagesSelection.selectClient"
            :call-data="callData"
            :data="selectClientScripts"
        />

        <div
            v-if="(selectedCustomer || isNewClient) && isViewClientPage"
            class="customer-form client-view-page"
        >
            <admin-card-accordion
                class="mb-32"
                theme="client"
                :icon="sectionIcon"
                :title="sectionFormTitle"
                :body-empty="
                    !(isNewClient || selectedCustomer) && isViewClientPage
                "
            >
                <template #actions>
                    <app-button theme="gray" @click="redirectTo('/customers')">
                        {{ $t("components.common.back") }}
                    </app-button>
                </template>
                <create-form
                    ref="create-card"
                    v-model="customerForm"
                    title="open-apps-form.clientInfo"
                    :company-enum="companyEnum"
                    :client-gender="clientGender"
                    :actions="['submit', 'cancel']"
                    @submit:form="updateCustomerFromViewPage"
                    @select-phone="selectPhoneHandler"
                    @reset="onClientReset"
                />
            </admin-card-accordion>
        </div>
        <AdminCard
            v-else
            class="mb-32"
            theme="client"
            :icon="sectionIcon"
            :title="sectionTitle"
            :body-empty="!(isNewClient || selectedCustomer)"
        >
            <template #actions>
                <transition name="opacity">
                    <div
                        v-if="searchMessage"
                        class="search-message"
                        :class="{
                            'search-message--is-chat': isChat,
                            ['search-message--theme-' + searchType]: searchType,
                            'search-message--opened': isSearchOpened,
                            'search-message--moved': !isNewClient,
                        }"
                    >
                        <LoadingIcon
                            v-if="searchType === 'loading'"
                            class="search-message__icon"
                        />
                        {{ searchMessage }}
                    </div>
                </transition>
                <AppButton
                    v-if="!isNewClient"
                    theme="blueish"
                    size="medium-extra"
                    round
                    @click="onCreateClient"
                >
                    <AppIcon icon="user-add-outline" />
                </AppButton>
                <Search
                    :is-chat="isChat"
                    @search="searchUser"
                    @open="onOpenSearch"
                    @close="onCloseSearch"
                    @error="setSearchMessage($event, true)"
                    ref="search"
                />
            </template>
            <template #default="{ classNames }">
                <div v-if="searchFail" class="no-client">
                    <img
                        class="no-client__image"
                        src="@/assets/img/chat/no-client.gif"
                        alt=""
                    />
                    <div class="no-client__title">
                        {{
                            $t(
                                "components.common.theClientWasNotFoundALovedOneWasLost"
                            )
                        }}
                        <b>{{
                            $t(
                                "components.common.weNeedToAcceptHimIntoTheFamily"
                            )
                        }}</b>
                    </div>
                    <div class="block-wrap">
                        <AppButton
                            class="no-client__button"
                            theme="primary-dark-outline-a"
                            size="medium-extra"
                            round-corner
                            @click="onCreateClient"
                        >
                            {{ $t("components.common.creatAClient") }}
                        </AppButton>
                        <AppButton
                            v-if="isChat"
                            class="no-client__button"
                            theme="primary-dark"
                            size="medium-extra"
                            round-corner
                            @click="$emit('identification')"
                        >
                            {{ $t("components.common.toIdentify") }}
                        </AppButton>
                    </div>
                </div>
                <ClientForm
                    v-else-if="selectedCustomer || isNewClient"
                    ref="client-form"
                    :customer="customerForm"
                    :type="isChat ? 'chat' : selectedCustomer ? 'edit' : null"
                    :loading="customerLoading"
                    :user-data="userData"
                    :selected-phone="selectedPhone"
                    :incoming-phone="incomingPhone"
                    :customer-fields-updated="callData.customerFieldsUpdated"
                    @input="inputCustomer"
                    @select-phone="selectPhone"
                    @submit-gtsp="loadCustomerInfoFromGTSP"
                    @cancel="cancelCreateCustomer"
                    @submit="saveCustomer"
                />
                <MultiClientList
                    v-else
                    :class="classNames.large"
                    :customers-list="customersList"
                    :is-chat="isChat"
                    @select-customer="selectCustomer"
                />
            </template>
        </AdminCard>

        <template v-if="selectedCustomer && !searchFail">
            <ScriptMessage
                v-if="!disableHints && createProblemScriptsVisible"
                v-model="scriptMessagesSelection.createProblem"
                :call-data="callData"
                :data="createProblemScripts"
            />
            <!--        <EditApplicationForm/>-->
            <PageLoader v-if="loading" />
            <template v-else>
                <div v-if="selectedProblem || isNewProblem">
                    <SelectedProblem
                        :is-chat="isChat"
                        :data="selectedProblemData"
                        :call-data="callData"
                        is-client-base
                        @problem-action="onProblemAction"
                        @back-list="backProblemsFromSelected"
                        @update="updateProblemData"
                        @open:modal="openModal"
                    />
                </div>
                <PrevProblem
                    v-else-if="problemsList.length > 0"
                    :is-chat="isChat"
                    :problems="problemsList"
                    @create="createProblem"
                    @select-problem="selectProblem"
                />
            </template>
        </template>
        <ScriptMessage
            v-if="!disableHints && byeScriptsVisible"
            v-model="scriptMessagesSelection.bye"
            :call-data="callData"
            :data="byeScripts"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import SelectedProblem from "@/components/selectedProblem.vue";
import PrevProblem from "@/components/prevProblem.vue";
import Search from "@/components/search";
import clientCategories from "@/config/clientCategories";
import AppIcon from "@/components/partials/AppIcon";
import AdminCard from "@/components/partials/AdminCard";
import AppButton from "@/components/partials/AppButton";
import ScriptMessage from "@/components/partials/ScriptMessage";
import PageLoader from "@/components/partials/PageLoader";
import ProblemDescriptionItem from "@/components/partials/ProblemDescriptionItem";
import ClientForm from "@/components/ClientForm";
import FilterDate from "@/mixins/FilterDate";
import ListUtils from "@/mixins/ListUtils";
import LoadingIcon from "@/components/partials/LoadingIcon";
import BankUserTags from "@/components/BankUserTags";
import MultiClientList from "@/components/partials/home/about-client/MultiClientList";
import CreateForm from "@/components/admin/Customer/Create/Form.vue";
import clientGender from "@/config/clientGender";
import { subjectTypeArray } from "@/config/subjectType";
import AdminCardAccordion from "@/components/AdminCardAccordion.vue";
// import EditApplicationForm from "./partials/home/EditApplicationForm";

const emptyScriptMessagesSelection = {
    known: "",
    unknown: "",
    selectClient: "",
    bye: "",
    createProblem: "",
};

export default {
    name: "AboutClient",
    components: {
        AdminCardAccordion,
        CreateForm,
        MultiClientList,
        // EditApplicationForm,
        BankUserTags,
        LoadingIcon,
        ClientForm,
        ProblemDescriptionItem,
        PageLoader,
        ScriptMessage,
        AppButton,
        AdminCard,
        AppIcon,
        SelectedProblem,
        PrevProblem,
        Search,
    },
    mixins: [FilterDate, ListUtils],
    emits: ["open:modal", "update:customer", "create:customer"],
    props: {
        isChat: Boolean,
        commands: {
            type: Object,
            required: true,
        },
        callData: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        clientFormInfo: {
            type: Object,
            default: () => ({}),
        },
        isViewClientPage: {
            type: Boolean,
            default: false,
        },
        disableHints: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            scriptMessagesSelection: { ...emptyScriptMessagesSelection },

            isSearchOpened: false,
        };
    },
    computed: {
        ...mapState({
            unknownScripts: (state) => state.unknown,
            knownScripts: (state) => state.known,
            selectClientScripts: (state) => state.select_client_script,
            byeScripts: (state) => state.bye_script,
            createProblemScripts: (state) => state.createApp,
            loading: (state) => state.problemsLoading,
        }),

        companyEnum() {
            return subjectTypeArray;
        },
        clientGender() {
            return clientGender;
        },
        // Скрипты start
        unknownScriptsVisible() {
            return !this.isChat && this.isNewClient && !!this.unknownScripts;
        },
        knownScriptsVisible() {
            return !this.isChat && this.selectedCustomer && !!this.knownScripts;
        },
        selectClientScriptsVisible() {
            return (
                !this.isChat &&
                !this.selectedCustomer &&
                this.isMultiClient &&
                !!this.selectClientScripts
            );
        },
        byeScriptsVisible() {
            return !this.isChat && this.selectedCustomer && !!this.byeScripts;
        },
        createProblemScriptsVisible() {
            return !this.isChat && !!this.createProblemScripts;
        },
        // Скрипты end

        customerLoading() {
            return this.callData?.customerLoading;
        },
        searchMessage() {
            return this.callData?.searchMessage;
        },
        searchType() {
            return this.callData?.searchMessageType;
        },
        customersList() {
            return this.callData?.customers ?? [];
        },
        incomingSource() {
            return this.callData?.data.sourceId;
        },
        selectedPhone() {
            return this.callData?.selectedPhone;
        },
        incomingPhone() {
            return this.callData?.data.phone;
        },
        lang() {
            return this.callData?.lang;
        },
        selectedCustomer() {
            if (this.isViewClientPage) {
                return this.clientFormInfo.id;
            }

            return this.callData?.selectedCustomer;
        },
        isNewClient() {
            return this.callData?.isNewClient;
        },
        searchFail() {
            return this.callData?.searchFailed;
        },
        customerForm() {
            if (
                this.isViewClientPage &&
                Object.keys(this.clientFormInfo).length > 0
            ) {
                return this.clientFormInfo;
            }

            return this.callData?.customerForm;
        },
        customerFetching() {
            return this.callData?.customerFetching;
        },

        userData() {
            return (
                this.callData.customers.find(
                    (c) => c.id === this.selectedCustomer
                ) || {}
            );
        },

        selectedProblem() {
            return this.callData?.selectedProblem;
        },
        selectedProblemData() {
            if (!this.selectedProblem) return null;
            return this.callData?.customersProblems?.[
                this.selectedCustomer
            ]?.find(({ id }) => id === this.selectedProblem);
        },
        isNewProblem() {
            return (
                this.callData?.isNewProblem ||
                (!this.problemsList.length && !this.selectedProblem)
            );
        },
        problemsList() {
            return (
                this.callData?.customersProblems?.[this.selectedCustomer] || []
            );
        },
        sectionFormTitle() {
            return this.customerForm.id
                ? "open-apps-form.clientInfo"
                : "customer.createUser";
        },
        sectionTitle() {
            if (this.isSearchOpened && this.isChat) return null;
            return this.isNewClient
                ? "customer.createUser"
                : this.selectedCustomer
                ? "customer.editUser"
                : "customer.selectUser";
        },
        sectionIcon() {
            if (this.isSearchOpened && this.isChat) return null;
            return this.isNewClient
                ? "user-add-outline"
                : this.selectedCustomer
                ? "user"
                : "street-view";
        },
        isMultiClient() {
            if (this.isViewClientPage) {
                return false;
            }

            return this.customersList?.length > 1;
        },
        isBackToCustomer() {
            return this.isMultiClient && this.selectedCustomer;
        },
    },
    watch: {
        callData: {
            handler(value) {
                if (!value?.customers?.length && value?.data?.phone) {
                    // this.searchCustomerByPhone(value?.data?.phone);
                }
            },
            immediate: true,
        },
    },
    methods: {
        selectPhoneHandler($val) {
            const arr = this.customerForm.customer_phones;
            const index = arr.findIndex((el) => el.phone === $val);
            if (index !== -1 && index !== 0) {
                const foundObj = arr[index];
                arr.splice(index, 1);
                arr.unshift(foundObj);
                this.selectPhone($val);
            }
        },
        redirectTo(route) {
            this.$router.push(route);
        },
        openModal($event) {
            this.$emit("open:modal", $event);
        },
        onOpenSearch() {
            this.isSearchOpened = true;
            this.clearSearchMessage();
        },
        onCloseSearch() {
            this.isSearchOpened = false;
        },
        setSearchMessage(message, searchType = "") {
            searchType = searchType === true ? "error" : searchType;
            this.commands.setSearchMessage.execute(message, searchType);
        },
        clearSearchMessage() {
            this.commands.setSearchMessage.execute("", "");
        },
        onCreateClient() {
            this.commands.startCreateCustomer.execute();
            this.scriptMessagesSelection = { ...emptyScriptMessagesSelection };
        },
        selectCustomer(id) {
            this.commands.selectCustomer.execute(id);
            this.scriptMessagesSelection = { ...emptyScriptMessagesSelection };
        },
        saveCustomer() {
            if (this.customerForm.id) {
                this.commands.updateCustomer.execute();
            } else {
                this.commands.createCustomer.execute();
            }
        },
        updateCustomerFromViewPage() {
            this.$emit("update:customer");
        },
        updateFormInitialData() {
            if (
                this.$refs["create-card"] &&
                this.$refs["create-card"].updateInitialData
            ) {
                this.$refs["create-card"].updateInitialData();
            }
        },
        searchCustomerByPhone(phone) {
            this.commands.searchCustomerByPhone.execute(phone);
        },
        searchUser(d) {
            this.commands.searchCustomer.execute(d);
            if (this.$refs.search) {
                this.$refs.search.close();
            }
        },
        inputCustomer(data) {
            this.commands.inputForm.execute(data);
        },
        selectPhone(phone) {
            this.commands.selectPhone.execute(phone);
        },
        backToCustomer() {
            this.commands.backCustomers.execute();
        },
        cancelCreateCustomer() {
            this.commands.cancelCreateCustomer.execute();
        },
        loadCustomerInfoFromGTSP() {
            this.commands.loadGTSPCustomer.execute();
        },
        // problems
        selectProblem(id) {
            this.commands.selectProblem.execute(id);
        },
        createProblem() {
            this.commands.startCreateProblem.execute();
        },
        updateProblemData({ data }) {
            this.commands.updateProblem.execute(data);
        },
        backProblemsFromSelected() {
            this.commands.returnProblems.execute();
        },
        onProblemAction(problemId) {
            this.commands.problemAction.execute(problemId);
        },
        filterData(item) {
            if (item.client_type == "0" || item.client_type == null) {
                return "--";
            }
            let data = clientCategories.find((el) => el.id == item.client_type);
            return data?.name;
        },
        onClientReset() {
            this.$emit("reset:client")
        }
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/modules/pages/Customer/CustomerForm.scss";

.back-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-primary-light);
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    position: relative;
    svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
    }
    &:hover {
        color: var(--color-primary);
    }
}
.client-form {
    $self: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -49px;
    &__item {
        width: calc(25% - 112px);
        margin-top: 49px;
        background: var(--color-white);
        border-radius: 12px;
        padding: 5px 9px 9px 16px;
        position: relative;
        &:hover {
            box-shadow: 0px 2px 8px var(--shadow-avatar-01);
        }
        &--large {
            width: 36.92%;
        }
        &--small {
            width: 12.44%;
            min-width: 140px;
        }
        &--medium {
            width: 20.61%;
        }
        &--normal {
            width: 14.06%;
        }
        &--disabled {
            box-shadow: none !important;
            padding: 0;
        }
        &--error {
            #{$self}__label {
                color: var(--color-danger-dark);
            }
        }
    }
    &__label {
        font-family: $font-secondary;
        margin: 0 0 6px !important;
        color: var(--color-dark-light);
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    &__input {
        width: 100%;
        .ant-select-selection__rendered,
        .ant-select-selection,
        &,
        &[disabled],
        .ant-input {
            font-family: $font-secondary;
            margin: 0;
            height: 24px;
            padding: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--color-black);
            background-color: var(--color-white) !important;
        }
    }
    &__separator {
        width: 100%;
    }
    &__footer {
        margin-top: 49px;
        margin-left: auto;
    }
    &__phone {
        position: absolute;
        z-index: 1;
        left: 0;
        top: calc(100% + 11px);
    }
}
.multi-client-table {
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    &__name {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }
    &__tag-wrap {
        margin-left: -4px;
        margin-top: -4px;
        margin-bottom: 4px;
    }
    &__tag {
        margin-top: 4px;
        margin-left: 4px;
        flex-shrink: 0;
    }
    &__info {
        width: 10px;
        white-space: nowrap;
    }
    td {
        border-bottom: 1px solid var(--color-border);
        padding-top: 20px;
        padding-bottom: 19px;
        padding-right: 92px;
        &:first-child {
            padding-left: 34px;
        }
        &:last-child {
            padding-right: 34px;
        }
    }
    tr:last-child td {
        border-bottom: 0;
        padding-bottom: 20px;
    }
    &__button {
        margin-left: auto;
    }
    &--chat {
        td {
            padding-right: 52px;
        }
    }
}
.create-client-button {
    padding: 9px;
    height: 40px;
    background: var(--color-white);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    &:active,
    &:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}
.no-client {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 44px;
    &__img {
        width: 128px;
        height: 128px;
    }
    &__title {
        margin-bottom: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
}
.search-message {
    $self: &;
    --translate: -466px;
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;
    letter-spacing: 0.005em;
    padding-right: 12px;
    padding-left: 12px;
    box-shadow: 0 0 8px #fff;
    color: #00cc56;
    transform: translateX(0);
    width: 1px;
    flex-shrink: 0;
    white-space: nowrap;
    direction: rtl;
    display: flex;
    align-items: center;
    &__icon {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        flex-shrink: 0;
    }
    &--theme-error {
        color: #ff4921;
    }
    &--theme-loading {
        color: #949494;
    }
    &--is-chat {
        --translate: -346px;
    }
    &--is-chat#{$self}--moved {
        --translate: -276px;
    }
    &--opened {
        display: block;
        transform: translateX(var(--translate));
    }
    &--normal {
        padding-left: 0;
        padding-right: 0;
        direction: ltr;
        width: auto;
        box-shadow: none;
    }
}
</style>
