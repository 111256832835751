const delayMixin = (key) => {
    const delayKey = key + 'Delay';
    const startKey = key + 'StartDelay';
    const clearKey = key + 'ClearDelay';

    return {
        data() {
            return {
                [delayKey]: null
            };
        },
        computed: {
            [key + 'DelayActive']() {
                return !!this[delayKey];
            }
        },
        beforeDestroy() {
            this[clearKey]();
        },
        methods: {
            [startKey](cb, time = 700) {
                this[clearKey]();

                this[delayKey] = setTimeout(() => {
                    if(cb) {
                        cb();
                    }
                    this[delayKey] = null;
                }, time);
            },
            [clearKey]() {
                if(this[delayKey]) {
                    clearTimeout(this[delayKey]);
                    this[delayKey] = null;
                }
            },
        }
    }
};

export default delayMixin;
