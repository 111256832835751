<template>
  <div class="auto-location" :class="{'auto-location--fullscreen': modalWindowFullscreen}">
    <CreditCalculator
        v-if="isCreditCalculatorType"
        v-model="creditForm"
        :accordion-name="accordionItems.creditCalculator"
        @cancel:form="$emit('cancel:form')"
        @submit:form="$emit('submit:credit-product', $event)"
        @reset:form="$emit('reset:form')"
    />
    <DepositCalculator
        v-if="isDepositCalculatorType"
        v-model="depositForm"
        :accordion-name="accordionItems.depositCalculator"
        @cancel:form="$emit('cancel:form')"
        @submit:form="$emit('submit:deposit-product', $event)"
        @reset:form="$emit('reset:form')"
    />
    <!--        <AppAccordion v-show="!modalWindowFullscreen || calcVisible" ref="calc" class="auto-location__body auto-location__inline-accordion mb-6-a" name="components.common.repaymentScheduleParameters" icon="graph" :persist="modalWindowFullscreen">-->
    <!--            <div class="edit-application">-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.carCost") }}</AppText>-->
    <!--                    <AppInputA v-model="model.a" append class="w-100" disabled>-->
    <!--                        <template #append> {{ $t("components.common.som") }}</template>-->
    <!--                    </AppInputA>-->
    <!--                </div>-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.anInitialFee") }}</AppText>-->
    <!--                    <AppRange v-model="model.b" min="30" :footer="['30%', '100%']" footer-solid/>-->
    <!--                </div>-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.downPaymentAmount") }}</AppText>-->
    <!--                    <AppRange v-model="model.b" min="0" max="1000000000" step="1000" :footer="['0', $t('components.common.oneBillion')]" footer-solid/>-->
    <!--                </div>-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.amountOfCredit") }}</AppText>-->
    <!--                    <AppInputA v-model="model.a" prepend append class="w-100" disabled>-->
    <!--                        <template #append>{{ $t("components.common.som") }}</template>-->
    <!--                        <template #prepend>1{{ $t("components.common.som") }}</template>-->
    <!--                    </AppInputA>-->
    <!--                </div>-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.annualRate") }}</AppText>-->
    <!--                    <AppRange v-model="model.b" disabled :footer="['0%', '100%']" footer-solid/>-->
    <!--                </div>-->
    <!--                <div class="edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <AppText type="input-title">{{ $t("components.common.creditTerm") }}</AppText>-->
    <!--                    <AppRange v-model="model.b" min="36" max="60" :footer="['36', '48', '60']" :currency-text="$t('components.common.months')" footer-solid/>-->
    <!--                </div>-->
    <!--                <div class="ml-auto edit-application__item edit-application__item&#45;&#45;medium">-->
    <!--                    <div class="block-wrap">-->
    <!--                        <AppButton class="mr-auto" type="button" square theme="white-outline" tabindex="-1">-->
    <!--                            <AppIcon icon="delete"/>-->
    <!--                        </AppButton>-->
    <!--                        <AppButton class="pl-6-a pr-6-a" type="button" theme="gray" tabindex="-1">-->
    <!--                            {{ $t("components.common.сancel") }}-->
    <!--                        </AppButton>-->
    <!--                        <AppButton type="button">-->
    <!--                            {{ $t("components.common.calculate") }}-->
    <!--                        </AppButton>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </AppAccordion>-->
    <!--        <div class="auto-location__body auto-location__fullscreen-fixed">-->
    <!--            <div class="content-card calculator-results-card mb-5-a">-->
    <!--                <div class="block-wrap">-->
    <!--                    <div class="flex-grow-1">-->
    <!--                        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ $t("components.common.automobile") }}</AppText>-->
    <!--                        <AppText type="auto-value" theme="dark-2">{{ $t("components.common.chevroletOnyx") }}</AppText>-->
    <!--                    </div>-->
    <!--                    <div class="flex-grow-1">-->
    <!--                        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ $t("components.common.monthlyAmountPayments") }}</AppText>-->
    <!--                        <AppText type="auto-value" theme="dark-2">5,397,141.64</AppText>-->
    <!--                    </div>-->
    <!--                    <div class="flex-grow-1">-->
    <!--                        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ $t("components.common.totalPaymentAmount") }}</AppText>-->
    <!--                        <AppText type="auto-value" theme="dark-2">194,297,098.96</AppText>-->
    <!--                    </div>-->
    <!--                    <div class="flex-grow-1">-->
    <!--                        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ $t("components.common.overpayment") }}</AppText>-->
    <!--                        <AppText type="auto-value" theme="dark-2">54,871,098.96</AppText>-->
    <!--                    </div>-->
    <!--                    <div :class="{'flex-grow-1': modalWindowFullscreen}">-->
    <!--                        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ $t("components.common.averageMonthlySalary") }}</AppText>-->
    <!--                        <AppText type="auto-value" theme="primary-2">6,746,427.05</AppText>-->
    <!--                    </div>-->
    <!--                    <AppButton v-if="modalWindowFullscreen" @click="toggleCalcVisibility">{{ $t("components.common.change") }}</AppButton>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <AppTable
        class="auto-location__table mb-6-a"
        :head="resultsHead"
        :data="tableData"
        theme="calculator"
        :max-rows="modalWindowFullscreen ? null : 11"
    />
    <!--        <div class="auto-location__fullscreen-fixed auto-location__fullscreen-fixed&#45;&#45;bottom">-->
    <!--            <div class="auto-location__footer">-->
    <!--                <div class="block-wrap">-->
    <!--                    <AppInputA v-model="model.a" class="auto-location__input" prepend clearable>-->
    <!--                        <template #prepend>+998</template>-->
    <!--                    </AppInputA>-->
    <!--                    <AppButton>{{ $t("components.common.sendSms") }}</AppButton>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script>
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppAccordion from "../AppAccordion";
import AppInputA from "../AppInputA";
import AppRange from "../AppRange";
import AppText from "../AppText";
import AppTable from "../AppTable";
import CreditProductModalControls from "./CreditProductModalControls";
import FullScreenModalMixin from "../../../mixins/FullScreenModalMixin";
import CreditCalculator from "@/components/partials/credit-product/CreditCalculator.vue";
import {
  CREDIT_CALCULATOR_TABLE_HEADER,
  DEPOSIT_CALCULATOR_TABLE_HEADER,
  CREDIT_ADDITIONAL_CALCULATOR_TABLE_HEADER,
  DEPOSIT_ADDITIONAL_CALCULATOR_TABLE_HEADER
} from "@/data/components/partials/credit-product/CalculatorResultsInfo";
import DepositCalculator from "@/components/partials/credit-product/DepositCalculator.vue";
import {DateFormatter} from "@/helpers/dates";

export default {
  name: 'CalculatorResultsInfo',
  emits: ["cancel:form", "reset:form", "submit:credit-product", "submit:deposit-product", "update:credit-form", "update:deposit-form"],
  components: {
    DepositCalculator,
    CreditCalculator,
    CreditProductModalControls, AppTable, AppText, AppRange, AppInputA, AppAccordion, AppIcon, AppButton,
  },
  mixins: [FullScreenModalMixin],
  props: {
    productTable: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    accordionItems: {
      type: Object,
      required: true,
      default: () => ({
        creditCalculator: "",
        depositCalculator: ""
      }),
    },
    calculationType: {
      type: String,
      required: true,
      default: "unknown",
    },
    isCreditCalculatorType: {
      type: Boolean,
      required: true,
      default: false,
    },
    isDepositCalculatorType: {
      type: Boolean,
      required: true,
      default: false,
    },
    creditForm: {
      type: Object,
      required: true,
      default: () => ({})
    },
    depositForm: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      model: {
        a: '',
        b: 0
      },
      calcVisible: false
    }
  },
  computed: {
    creditFormModel: {
      get() {
        return this.creditForm;
      },
      set(value) {
        this.$emit('update:credit-form', value);
      }
    },
    depositFormModel: {
      get() {
        return this.depositForm;
      },
      set(value) {
        this.$emit('update:deposit-form', value);
      }
    },
    tableData() {
        return Object.values(this.productTable.table).map((row, index) => ({id: index + 1, ...row, pay_date: new DateFormatter(row.pay_date).format("DD/MM/YYYY"), }));
    },
    resultsHead() {
      switch (this.calculationType) {
        case "credit":
          return [CREDIT_CALCULATOR_TABLE_HEADER, CREDIT_ADDITIONAL_CALCULATOR_TABLE_HEADER(this.productTable)];
        case "deposit":
          return [DEPOSIT_CALCULATOR_TABLE_HEADER, DEPOSIT_ADDITIONAL_CALCULATOR_TABLE_HEADER(this.productTable)];
        default:
          return [];
      }
    },
  },
  methods: {
    toggleCalcVisibility() {
      this.calcVisible = !this.calcVisible;
      if (this.calcVisible) {
        this.$nextTick(() => {
          this.$refs.calc.$el.scrollIntoView();
        });
      }
    }
  }
}
</script>
<style lang="scss">
.calculator-results-card {
  --card-bg: #EDF2FF;
}
</style>
