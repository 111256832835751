<template>
    <transition :name="horizontal ? 'width-enter' : 'height-enter'" :duration="durationInner">
        <div v-if="visible" :style="style">
            <slot/>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'ShiftLayoutAppearAnimation',
    props: {
        duration: {
            type: String,
            default: 'normal'
        },
        width: [String, Number],
        height: [String, Number],
        horizontal: Boolean,
        visible: Boolean,
    },
    computed: {
        durationInner() {
            switch(this.duration) {
                case 'medium': return 800;
                default: return 500;
            }
        },
        style() {
            return {
                '--width': (this.width || this.height) + 'px',
                '--height': (this.height || this.width) + 'px',
                '--l-t': this.durationInner * 0.65 + 'ms',
                '--s-t': this.durationInner * 0.35 + 'ms',
            }
        }
    }
}
</script>
<style lang="scss">
.width-enter {
    &-enter-active, &-leave-active {
        position: relative;
        transition: opacity var(--l-t) var(--s-t);
        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
        &:before {
            transition: width var(--l-t);
            position: static;
            display: block;
            content: '';
            height: var(--height);
        }
    }
    &-leave-active {
        transition: opacity var(--l-t);
        &:before {
            transition: width var(--l-t) var(--s-t);
        }
    }
    &-enter-to, &-leave {
        opacity: 1;
        &:before {
            width: var(--width);
        }
    }
    &-enter, &-leave-to {
        opacity: 0;
        &:before {
            width: 0px;
        }
    }
}
.height-enter {
    &-enter-active, &-leave-active {
        position: relative;
        transition: opacity var(--l-t) var(--s-t);
        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &:before {
            transition: height var(--l-t);
            position: static;
            display: block;
            content: '';
            width: var(--width);
            height: 0;
        }
    }
    &-leave-active {
        transition: opacity var(--l-t);
        &:before {
            transition: height var(--l-t) var(--s-t);
        }
    }
    &-enter-to, &-leave {
        opacity: 1;
        &:before {
            height: var(--height);
        }
    }
    &-enter, &-leave-to {
        opacity: 0;
        &:before {
            height: 0px;
        }
    }
}
</style>
