<template>
    <div v-if="comments.length > 0" class="external-task-comments">
        <div class="external-task-comments__label">
            {{ $t("common.comments") }}
        </div>
        <div class="external-task-comments__box">
            <div
                class="external-task-comments__comment"
                v-for="comment in comments"
                :key="comment.id"
            >
                <div class="external-task-comments__user">
                    {{ comment.user?.name ?? "-" }}
                    <div class="external-task-comments__date">
                        {{ formatDate(comment.created_on) }}
                    </div>
                </div>
                <div class="external-task-comments__text">
                    {{ parseComment(comment.notes) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { parseDate } from "@/helpers/dates";

export default {
    name: "ExternalTaskComments",

    props: {
        comments: {
            type: Array,
        },
    },

    methods: {
        formatDate(val) {
            return parseDate(val);
        },

        parseComment(notes) {
            if (typeof notes === "string") {
                return notes;
            }
            return "-";
        },
    },
};
</script>

<style lang="scss">
.external-task-comments__label,
.external-task-comments__date {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-gray-lighten);
}

.external-task-comments__label {
    margin-bottom: 2px;
}

.external-task-comments__box {
    background: white;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 8px;

    height: 152px;
    overflow-y: scroll;
}

.external-task-comments__comment {
    padding: 8px;
}

.external-task-comments__user {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}
</style>
