<template>
    <div :class="[$style['header-nav-tabs'], { [$style['header-nav-tabs--theme-' + theme]]: theme, [$style['header-nav-tabs--overflow']]: overflow }]" ref="container">
        <template v-if="overflow">
            <div v-if="activeOverflow" :disabled="!canScrollLeft" :class="$style['header-nav-tabs__arrow-left']" @click="scrollLeft">
                <AppIcon icon="chevron-down" size="20"/>
            </div>
            <div :class="$style['header-nav-tabs__scroller-wrap']" ref="scroller-wrap">
                <transition name="opacity">
                    <div v-if="backName" :class="$style['header-nav-tabs__back']" :style="{'--width': backWidth + 'px', '--back-translate': backTranslate + 'px'}">
                        <transition name="tabs-opacity">
                        <span :key="backName">
                            {{ backName }}
                        </span>
                        </transition>
                    </div>
                </transition>
                <div :class="$style['header-nav-tabs__scroller']" ref="scroller" :style="{'--os': scrolled + 'px' }">
                    <div v-for="item in items"
                         :key="item[itemValue]"
                         :class="[ $style['header-nav-tabs__item'], {[$style['header-nav-tabs__item--active']]: item[itemValue] === value}]"
                         :ref="'item.' + item[itemValue]"
                         @click="select(item[itemValue])"
                    >{{ item[itemName] }}</div>
                </div>
            </div>
            <div v-if="activeOverflow" :disabled="!canScrollRight" :class="$style['header-nav-tabs__arrow-right']" @click="scrollRight">
                <AppIcon icon="chevron-down" size="20"/>
            </div>
        </template>
        <template v-else>
            <div :class="$style['header-nav-tabs__back']" :style="{'--width': backWidth + 'px', '--back-translate': backTranslate + 'px'}"></div>
            <div v-for="item in items"
                 :key="item[itemValue]"
                 :class="[ $style['header-nav-tabs__item'], {[$style['header-nav-tabs__item--active']]: item[itemValue] === value}]"
                 :ref="'item.' + item[itemValue]"
                 @click="select(item[itemValue])"
            >
                <slot :name="'item.' + item[itemValue]">
                    {{ item[itemName] }}
                </slot>
            </div>
        </template>
    </div>
</template>
<script>
import AppIcon from "./AppIcon";
export default {
    name: 'NavTabs',
    components: {AppIcon},
    props: {
        value: [String, Number],
        theme: String,
        overflow: Boolean,
        items: {
            type: Array,
            default: () => []
        },
        itemValue: {
            type: String,
            default: 'key'
        },
        itemName: {
            type: String,
            default: 'name'
        },
    },
    data () {
        return {
            backWidth: 0,
            backTranslate: 0,
            scrolled: 0,
            activeOverflow: false,
            canScrollLeft: false,
            canScrollRight: false
        }
    },
    computed: {
        backName() {
            if(!this.value) return;
            return this.items.find(e => e[this.itemValue] === this.value)?.[this.itemName] || '';
        }
    },
    watch: {
        value: 'updateBack',
        items() {
            if(this.overflow) {
                this.activeOverflow = this.$refs.scroller.offsetWidth - this.$refs["scroller-wrap"].offsetWidth > 0;
            }
        },
        scrolled: ['checkIfCanScrollLeft', 'checkIfCanScrollRight']
    },
    mounted() {
        this.updateBack(this.value);

        if(this.overflow && this.$refs.scroller && this.$refs["scroller-wrap"]) {
            this.activeOverflow = this.$refs.scroller.offsetWidth - this.$refs["scroller-wrap"].offsetWidth > 0;
            this.checkIfCanScrollLeft();
            this.checkIfCanScrollRight();
        }
    },
    methods: {
        updateBack(value) {
            let item = this.$refs['item.' + value];
            if(item) {
                item = item[0];
                const bounds = item.getBoundingClientRect();
                this.backWidth = bounds.width;

                if(this.overflow) {
                    const scrollWrapBounds = this.$refs["scroller-wrap"].getBoundingClientRect();
                    let offset = 0;
                    if(bounds.left < scrollWrapBounds.left) {
                        offset = scrollWrapBounds.left - bounds.left;
                        this.scrolled = this.scrolled + offset;
                        return this.backTranslate = bounds.left - this.$refs['scroller-wrap'].getBoundingClientRect().left + offset;
                    } else if(bounds.right > scrollWrapBounds.right) {
                        offset = bounds.right - scrollWrapBounds.right;
                        this.scrolled = this.scrolled - offset;
                        return this.backTranslate = bounds.left - this.$refs['scroller-wrap'].getBoundingClientRect().left - offset;
                    } else {
                        this.backTranslate = bounds.left - this.$refs['scroller-wrap'].getBoundingClientRect().left;
                    }
                } else {
                    this.backTranslate = bounds.left - this.$refs['container'].getBoundingClientRect().left;
                }
            }
        },

        checkIfCanScrollLeft() {
            this.canScrollLeft = this.scrolled !== 0;
        },
        checkIfCanScrollRight() {
            this.canScrollRight = Math.abs(this.scrolled) + this.$refs["scroller-wrap"].offsetWidth < this.$refs.scroller.offsetWidth;
        },

        select(itemKey) {
            this.$emit('input', itemKey);
        },

        getScrollWidth() {
            const scrollWrapWidth = this.$refs["scroller-wrap"].offsetWidth;
            const scrollWidth = this.$refs.scroller.offsetWidth;
            return Math.min(scrollWrapWidth - 50, scrollWidth - scrollWrapWidth)
        },
        scrollLeft() {
            this.checkIfCanScrollLeft();
            if(!this.canScrollLeft) return;

            let willScroll = this.getScrollWidth();
            if(Math.abs(this.scrolled) < willScroll) {
                willScroll = Math.abs(this.scrolled);
            }
            this.scrolled = this.scrolled + willScroll;
            this.backTranslate = this.backTranslate + willScroll;
        },
        scrollRight() {
            this.checkIfCanScrollRight();
            if(!this.canScrollRight) return;

            const scrollWrapWidth = this.$refs["scroller-wrap"].offsetWidth;
            const scrollWidth = this.$refs.scroller.offsetWidth;

            let willScroll = this.getScrollWidth();

            if(willScroll >= scrollWidth - (Math.abs(this.scrolled) + scrollWrapWidth)) {
               willScroll = willScroll - (willScroll + Math.abs(this.scrolled) + scrollWrapWidth - scrollWidth);
            }

            this.scrolled = this.scrolled - willScroll;
            this.backTranslate = this.backTranslate - willScroll;
        }
    }
}
</script>
<style lang="scss">
.tabs-opacity {
    &-enter-active, &-leave-active {
        transition: opacity .25s;
    }
    &-enter, &-leave-to {
        opacity: 0;
    }
    &-leave-to {
        position: absolute;
    }
}
</style>
<style lang="scss" module>
@import '../../assets/scss/variables';
.header-nav-tabs {
    $self: &;
    background: var(--color-border-lighten);
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;

    &__back {
        transition: transform .25s, width .25s;
        background: var(--color-primary-light);
        height: 100%;
        border-radius: 12px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(var(--back-translate));
        width: var(--width);
        color: #fff;
    }

    &__item {
        display: flex;
        align-items: center;
        transition: background .25s, color .25s, font-weight .25s;
        cursor: pointer;
        user-select: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding-left: 16px;
        padding-right: 16px;
        height: 39px;
        color: var(--color-gray-lighten);
        position: relative;

        &:hover {
            color: var(--color-black);
        }

        &--active {
            color: var(--color-white);

            &:hover {
                color: var(--color-white);
            }
        }
    }

    &--overflow {
        display: flex;
        max-width: 100%;
        #{$self}__arrow-left,
        #{$self}__arrow-right {
            cursor: pointer;
            user-select: none;
            padding: 0 14px;
            display: flex;
            align-items: center;
            height: 100%;
            color: var(--color-gray-lighten);
            background: linear-gradient(270deg, var(--color-border-lighten) 65%, var(--color-border-lighten) 67%, transparent);
            z-index: 1;
            border-radius: 0 12px 12px 0;
            margin-left: -16px;
            svg {
                transform: rotate(-90deg);
            }
            &[disabled] {
                color: rgba(#949494, 0.3);
                z-index: 0;
            }
        }
        #{$self}__arrow-left {
            background: linear-gradient(90deg, var(--color-border-lighten) 65%, var(--color-border-lighten) 67%, transparent);
            border-radius: 12px 0 0 12px;
            margin-right: -16px;
            margin-left: 0;
            svg {
                transform: rotate(90deg);
            }
        }
        #{$self}__scroller {
            display: flex;
            align-items: center;
            height: 100%;
            transition: transform .25s;
            width: fit-content;
            transform: translateX(var(--os));
        }
        #{$self}__scroller-wrap {
            position: relative;
            overflow: hidden;
            max-width: calc(100% + 32px);
        }
        #{$self}__back {
            display: flex;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            z-index: 1;
        }
    }

    &--theme-chat {
        #{$self}__item {
            padding-top: 1px;
            height: 48px;
        }
    }

    &--theme-page {
        border-radius: 50px;

        #{$self}__item {
            height: 48px;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            padding-left: 24px;
            padding-right: 24px;
        }

        #{$self}__back {
            background: linear-gradient(90deg, #2675EC 0%, #609BF5 100%);
            box-shadow: 0px 2px 1px #8FBCFF;
            top: 4px;
            transform: translateX(calc(var(--back-translate) + 4px));
            width: calc(var(--width) - 8px);
            height: calc(100% - 8px);
        }
    }

    &--theme-layout {
        padding: 4px;
        border-radius: 50px;
        #{$self}__back {
            background: #FFFFFF;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
            border-radius: 50px;
            height: 40px;
            top: 4px;
        }
        #{$self}__item {
            height: 40px;
            border-radius: 50px;
            padding-left: 24px;
            padding-right: 24px;
            color: #454142;
            &--active {
                color: #717171;
            }
        }
    }
}
</style>
