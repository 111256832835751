<template>
    <div class="app-range" :class="{'app-range--footer-solid': footerSolid, 'app-range--focused': focused}">
        <AppInputA class="app-range__input" type="number" :disabled="disabled" append :value="value" @input="onInput">
            <template #append>
                <div class="app-range__currency">{{ currencyText }}</div>
            </template>
        </AppInputA>
        <input :value="value" tabindex="-1" class="app-range__track" :disabled="disabled" type="range" :min="min" :max="max" :step="step" :style="{'--v': (value - min) / (max - min) * 100 + '%' }"
               v-on="focusListeners" @input="onInputRange">
        <div class="app-range__footer">
            <span v-for="item in footerArray">{{ item }}</span>
        </div>
    </div>
</template>
<script>
import AppInputA from "./AppInputA";
import {focusMixin} from "../../mixins/InputMixin";
export default {
    name: 'AppRange',
    components: {AppInputA},
    mixins: [focusMixin],
    model: {
        prop: 'value'
    },
    props: {
        currencyText: {
            type: String,
            default: ''
        },
        footer: Array,
        value: {
            type: [String, Number],
            required: true
        },
        min: {
            type: [Number, String],
            default: 0
        },
        max: {
            type: [Number, String],
            default: 100
        },
        step: {
            type: [Number, String],
            default: 1
        },
        footerSolid: {
            type: Boolean,
            default: true
        },
        disabled: Boolean
    },
    computed: {
        footerArray() {
            if(!this.footer) {
                return [this.min, this.max];
            }
            return this.footer;
        }
    },
    methods: {
        onInputRange(event) {
            this.onInput(parseInt(event.target.value));
        },
        onInput(value) {
            if(this.disabled) return;
            value = Math.max(Math.min(this.max, value), this.min);
            this.$emit('input', value);
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.app-range {
    $self: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &__input {
        width: 100%;
    }
    &__currency {
        pointer-events: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        font-family: $font-secondary;
        color: #949494;
        position: absolute;
        top: 14px;
        right: 15px;
    }
    &__track {
        position: absolute;
        bottom: -6.5px;
        left: 4px;
        right: 4px;
        height: 14px;
        -webkit-appearance: none;
        background: transparent;
        width: calc(100% - 8px);
        outline: none !important;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: #FFFFFF;
            border: 3px solid #397AF5;
            height: 14px;
            width: 14px;
            border-radius: 50px;
            cursor: pointer;
        }
        &::-webkit-slider-runnable-track {
            width: 100%;
            background: linear-gradient(to right, #2552C6 var(--v), transparent var(--v));
            background-repeat: no-repeat;
            background-position: 0 5.5px;
            background-size: 100% 3px;
            cursor: pointer;
        }
        &::-moz-range-thumb {
            background: #FFFFFF;
            border: 3px solid #397AF5;
            height: 8px;
            width: 8px;
            border-radius: 50px;
            cursor: pointer;
        }
        &::-moz-range-progress {
            cursor: pointer;
            background-color: #2552C6;
        }
        &::-moz-range-track {
            width: 100%;
            background: transparent;
            cursor: pointer;
        }
    }
    &__footer {
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        padding-left: 1px;
        padding-right: 5px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
    }
    &--footer-solid {
        padding-bottom: 20px;
        height: 68px;
        #{$self}__footer {
            bottom: 0;
        }
        #{$self}__track {
            bottom: 13px;
        }
    }
    &--focused {
        #{$self}__track::-webkit-slider-thumb {
            box-shadow: var(--input-focus-shadow, $input-focus-shadow);
        }
        #{$self}__track::-moz-range-thumb {
            box-shadow: var(--input-focus-shadow, $input-focus-shadow);
        }
    }
}
</style>
