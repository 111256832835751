const STORAGE_KEY = 'popular_problem_ids';
import Vue from "vue";
const idsCount = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');

class PopularProblemsService {
    list = Vue.observable({ value: [] })

    constructor(options) {
        this._treeOptions = options;
        this._updateList = this._updateList.bind(this);
        this._treeOptions.on('update', this._updateList)
        this._treeOptions.on('destroy', () => {
            this._treeOptions.off('update', this._updateList)
        });
    }
    getList() {
        // Эта конструкция делает this.list реактивным
        return this.list.value;
    }
    _updateList() {
        const results = [];
        let idx = 0;
        const addedNames = [];
        for(let key in idsCount) {
            const item = this._treeOptions.flatOptions.find(el => el.id == key);
            if(item && item.children.length === 0 && !addedNames.includes(item.title)) {
                results.push({
                    id: item.id,
                    title: item.title,
                    count: idsCount[key]
                });
                idx++;
            }
            if(idx >= 3) {
                break;
            }
        }
        results.sort((a, b) => b.count - a.count);
        Vue.set(this.list, 'value', results);
    }

    updateTheme(themeId) {
        if(isNaN(parseInt(themeId))) return;

        idsCount[themeId] = (idsCount[themeId] || 0) + 1;

        this.saveThemes();
    }
    updateThemes(newThemes) {
        newThemes.forEach(theme => {
            let count = idsCount[theme] || 0;
            idsCount[theme] = ++count;
        });
        this.saveThemes();
    }

    saveThemes() {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(idsCount));
    }
}

export default PopularProblemsService;
