import { render, staticRenderFns } from "./MybankProduct.vue?vue&type=template&id=92ce5868&"
import script from "./MybankProduct.vue?vue&type=script&lang=js&"
export * from "./MybankProduct.vue?vue&type=script&lang=js&"
import style0 from "./MybankProduct.vue?vue&type=style&index=0&id=92ce5868&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports