<template>
    <div
        class="app-tabs"
        :class="{
            'app-tabs--leave': isLeave,
            'app-tabs--enter': isEnter,
            ['app-tabs--theme-' + theme]: theme
    }">
        <div
            class="app-tabs__header"
            :class="{'app-tabs__header--sliced-width': sliceWidth}"
        >
            <div
                v-for="(item, idx) in items"
                :key="item[itemValue]"
                class="app-tabs__head"
                :class="{
                    'app-tabs__head--active': item[itemValue] === activeKey,
                    'app-tabs__head--disabled': disabled
                }"
                @click="select(item[itemValue], idx)"
            >
                {{ $t(item[itemName]) }}
            </div>
            <AppSelect
                v-if="enableOperations"
                class="app-tabs__operations"
                :class="{'app-tabs__operations--readonly': disabled}"
                :items="operationsItems"
                @input="selectOperation"
                :title="$t('components.operations.title')"
                item-value="name"
                size="large"
                text
            >
                <template #item-name="{item}">
                    <AppIcon :icon="item.icon" class="mr-2"/>
                    <span>{{$t("components.operations." + item.name)}}</span>
                </template>
            </AppSelect>
        </div>
        <div class="app-tabs__body" ref="body">
            <div class="app-tabs__tab" ref="tab">
                <keep-alive :include="keepInclude">
                    <slot :name="'body.' + activeBody"/>
                </keep-alive>
            </div>
        </div>
    </div>
</template>
<script>
import AppSelect from "@/components/partials/AppSelect.vue";
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    name: 'AppTabs',
    components: {AppIcon, AppSelect},
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        },
        itemName: {
            type: String,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'key'
        },
        theme: String,
        keepInclude: {
            type: [String, Array],
            default: ''
        },
        enableOperations: {
            type: [Boolean, Number],
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        sliceWidth: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLeave: false,
            isEnter: false,
            isAnimating: false,
            activeKey: this.value,
            activeBody: this.value,
            tabHeight: 0,
            observer: null
        }
    },
    computed: {
        operationsItems() {
            return [
                {
                    icon: "clear",
                    name: "clear-user-cache"
                },
                {
                    icon: "key",
                    name: "allow-password-recovery"
                },
                {
                    icon: "lock-open",
                    name: "unblock-user"
                },
                {
                    icon: "id-card",
                    name: "reset-user-pinfl"
                },
                {
                    icon: "user-x",
                    name: "reset-user-identification"
                },
            ]
        }
    },
    watch: {
        value(itemKey) {
            if(itemKey !== this.activeKey) {
                this.select(itemKey);
            }
        }
    },
    mounted() {
        this.$refs.body.style.height = this.$refs.body.firstChild.scrollHeight + 'px';

        this.observer = new MutationObserver(() => {
            this.$refs.body.style.height = this.$refs.body.firstChild.scrollHeight + 'px';
        });
        this.observer.observe(this.$refs.body, {
            childList: true,
            subtree: true
        })
    },
    beforeDestroy() {
        if(this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    },
    methods: {
        select(itemKey) {
            if(itemKey === this.activeKey || this.isAnimating) return;

            this.isAnimating = true;

            this.activeKey = itemKey;
            this.isLeave = true;
            setTimeout(() => {
                this.isLeave = false;
                this.activeBody = this.activeKey;
                this.$emit('change', itemKey);
                this.$nextTick(() => {
                    this.isEnter = true;
                });
            }, 300);
            setTimeout(() => {
                this.isAnimating = false;
                this.isEnter = false;
            }, 600);
        },
        selectOperation($val) {
            this.$emit("select-operation", $val)
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';

.app-tabs {
    $self: &;

    &__header {
        border-bottom: var(--tab-header-border-bottom, 1px solid #cacaca);
        background-color: var(--tab-header-bg, transparent);
        display: flex;
        padding-left: var(--tab-header-padding-left, 0px);
        padding-right: var(--tab-header-padding-right, 0px);
        flex-wrap: wrap;

        &--sliced-width {
            width: calc(100% - 400px);
        }
    }

    &__head, &__operations {
        cursor: pointer;
        user-select: none;
        transition: color .6s;
        padding: var(--tab-head-sides-top, 0px) var(--tab-head-sides, 13px) var(--tab-head-sides-bottom, 13px);
        font: var(--tab-head-font, normal 400 20px/24px #{$font-secondary});
        text-transform: var(--tab-head-text-transform, none);
        margin-right: var(--tab-head-margin, 51px);
        background-color: var(--tab-head-bg, transparent);
        position: relative;
        color: var(--tab-head-color, #949494);
        height: var(--tab-head-height, auto);
        white-space: nowrap;

        &:before {
            transition: opacity .6s;
            opacity: 0;
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: var(--tab-underline-height, 4px);
            background: currentColor;
            border-radius: 5px;
        }

        &:after {
            content: '';
            display: var(--tab-separator-display, block);
            position: absolute;
            right: var(--tab-separator-position-right, -30px);
            bottom: var(--tab-separator-position-bottom, 22px);
            width: var(--tab-separator-size, 6px);
            height: var(--tab-separator-size, 6px);
            background: var(--tab-separator-background, #3c3c3c);
            border-radius: var(--tab-separator-radius, 100%);
        }

        &--active {
            color: var(--tab-head-active-color, #3A79F3);
            background-color: var(--tab-head-active-bg, transparent);

            &:before {
                opacity: 1;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .7;
        }

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }
    }

    &__operations {
        font-weight: 500;
        padding: 10px 18px 0;
        color: var(--color-primary);

        &::before {
            content: none;
        }

        min-width: 250px;

        .ant-select-selection {
            display: flex;
            flex-direction: row-reverse;

            &::before {
                right: auto;
                position: relative;
                margin-right: auto;
                top: 40%;
            }

            &__rendered {
                margin-left: 0;
                line-height: 25px;
            }
        }

        .ant-select-selection-selected-value {
            margin: 0;
        }

        &--readonly {
            pointer-events: none;
            opacity: .7;
        }
    }

    &__body {
        transition: height .25s;
        width: 100%;
    }

    &__tab {
        width: 100%;
    }

    &--leave, &--enter {
        overflow: hidden;

        #{$self}__body {
            overflow: hidden;
        }
    }

    &--leave {
        #{$self}__tab {
            animation: tabLeave .3s 1 forwards;
        }
    }

    &--enter {
        #{$self}__tab {
            animation: tabEnter .3s 1 forwards;
        }
    }

    &--theme-statistics {
        #{$self}__header {
            padding-left: 51px;
        }
    }

    &--theme-processing,
    &--theme-loan {
        --tab-header-padding-left: 64px;
        --tab-header-padding-right: 64px;
        margin-top: 52px;
        margin-bottom: 52px;

        #{$self}__header {
            border-bottom: 0;
            padding-bottom: 32px;
        }

        #{$self}__tab {
            padding-left: 64px;
            padding-right: 64px;
        }

        #{$self}__head {
            transition: color .6s linear;
            padding: 10px 18px;
            margin-right: 14px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--color-gray-lighten);
            border-radius: 50px;
            z-index: 0;

            &:after {
                display: none;
            }

            &:before {
                z-index: -1;
                transition: opacity .6s;
                opacity: 0;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 50px;
                background-color: var(--color-primary-dark);
            }

            &--active {
                color: var(--color-white);

                &:before {
                    opacity: 1;
                }
            }
        }
    }

    &--theme-processing {
        margin-top: 0;
        margin-bottom: 0;
        --tab-header-padding-left: 42px;
        --tab-header-padding-right: 42px;

        #{$self}__header {
            padding-bottom: 32px;
        }

        #{$self}__tab {
            padding-left: 0;
            padding-right: 0;
        }

        #{$self}__head {
            font-family: $font-primary;
            font-size: 16px;
            line-height: 19px;
            color: var(--color-primary);

            &--active {
                color: var(--color-white);
            }
        }
    }

    &--theme-deposit {
        --tab-header-padding-left: 35px;

        #{$self}__head {
            padding-top: 4px;
            padding-bottom: 11px;

            &:after {
                bottom: 50%;
                margin-bottom: -3px;
            }
        }
    }

    &--theme-guar {
        --tab-header-padding-left: 35px;

        #{$self}__header {
            background: var(--color-white-bluesh);
        }

        #{$self}__head {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: $font-primary;
            color: var(--color-gray-lighten);
            text-transform: uppercase;

            &:after {
                display: none;
            }

            &--active {
                color: var(--color-primary);
            }
        }
    }

    &--theme-loan-modal {
        --tab-header-padding-left: 0;
        --tab-header-padding-right: 0;

        #{$self}__header {
            padding-bottom: 0;
            background: #F4F4F4;
            border-top: 1px solid #E1E1E1;
            border-bottom: 1px solid #E1E1E1;
        }

        #{$self}__head {
            padding: 24px 42px;
            background: white;
            border-radius: 0;

            &::before {
                border-radius: 0;
                height: 3px;
                top: 100%;
                transform: translateY(-100%);
            }

            &--active {
                color: var(--color-primary);
            }
        }
    }
}

@keyframes tabLeave {
    0% {
        transform: none;
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes tabEnter {
    0% {
        transform: translate(100%);
    }
    100% {
        transform: none;
    }
}
</style>
