<template>
  <div class="client-card-a">
    <HistoryApplication
      v-if="callApplication"
      :application="callApplication"
      :call-data="callData"
      :messages="callApplicationMessages"
      @back-list="backProblems"
    />
    <AdminCard
      v-else-if="list.length > 0"
      theme="problem"
      title="components.common.outgoingHistory"
    >
      <template #default="{ classNames }">
        <AppTable
          class="mb-32"
          theme="problem"
          :class="classNames.large"
          :head="historyHead"
          :data="list"
          @row-click="openApplication"
        >
          <template #cell.id="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{ item.item?.id }}
            </span>
          </template>
          <template #cell.theme="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{ item.item?.products_name }}
            </span>
          </template>
          <template #cell.type="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{ item.item.type?.title }}
            </span>
          </template>
          <template #cell.agent="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{
                item.item.operator?.first_name +
                " " +
                item.item.operator?.last_name
              }}
            </span>
          </template>
          <template #cell.description="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{ stripDescription(item.item.description) }}
            </span>
          </template>
          <template #cell.status="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <ProblemTag v-else outbound :status="item.item.status" />
          </template>
          <template #cell.date="item">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <span v-else>
              {{ formatDate(item.item.created_at) }}
            </span>
          </template>
        </AppTable>
        <div
          v-if="itemsTotal > itemsPerPage"
          class="d-flex justify-content-end"
        >
          <b-pagination
            aria-control=""
            :value="page"
            :total-rows="itemsTotal"
            next-class="prev"
            prev-class="next"
            prev-text=""
            next-text=""
            :per-page="itemsPerPage"
            @input="onPagination"
          />
        </div>
      </template>
    </AdminCard>
    <div v-else class="empty-title">
      {{ $t("components.common.nothingFound") }}
    </div>
  </div>
</template>

<script>
import AdminCard from "./partials/AdminCard";
import AppTable from "./partials/AppTable";
import ProblemTag from "./partials/ProblemTag";

import ListUtils from "../mixins/ListUtils";
import CallApplicationMixix from "../mixins/call-application-mixin";
import { get } from "../helpers/api";
import { CallRoom } from "../services/chat/ChatModel";
import HistoryApplication from "./partials/HistoryApplication.vue"

export default {
  name: "OutgoingHistory",

  props: {
    customerId: {
      type: String,
      required: true,
    },
    callData: CallRoom,
  },

  components: { AdminCard, AppTable, ProblemTag, HistoryApplication },

  mixins: [ListUtils, CallApplicationMixix],

  data: () => ({
    loading: false,
    list: [],
    historyHead: [
      [
        {
          name: "common.table.number",
          key: "id",
          width: "169px",
        },
        {
          name: "common.table.theme",
          key: "theme",
        },
        {
          name: "components.common.typeOfAppeal",
          key: "type",
        },
        {
          name: "common.table.operator",
          key: "agent",
        },
        {
          name: "common.table.note",
          key: "description",
        },
        {
          name: "common.table.status",
          key: "status",
          width: "152px",
        },
        {
          name: "components.common.addTime",
          key: "date",
          width: "240px",
        },
      ],
    ],

    itemsPerPage: 10,
    itemsTotal: 10,
    page: 1,
  }),

  methods: {
    fetchData() {
      this.loading = true;
      const query = {
        limit: this.itemsPerPage,
        skip: this.itemsPerPage * (this.page - 1),
      };

      get(
        `/applications/?customer_id=${this.customerId}&call_type=1&limit=${query.limit}&skip=${query.skip}`
      )
        .then((response) => {
          if (response.data.success && response.data.data.data) {
            this.list = response.data.data.data;
            this.itemsTotal = response.data.data.count;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    formatDate(sDate) {
      const date = new Date(sDate);
      return date.toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },

    onPagination(page) {
      this.page = page;
      this.fetchData();
    }
  },

  mounted() {
    for (let index = 0; index < 10; index++) {
      this.list.push({});
    }
    this.fetchData();
  },
};
</script>
