
import Vue, { provide, ref, Ref, onMounted } from 'vue';
import type { InjectionKey } from 'vue'
interface AppAccordionCoordinatorProps {
  preopened?: string;
  items: string[];
}
export const accordionOpenedKey = Symbol() as InjectionKey<Ref<string[]>>;
export const accordionSetOpenedKey = Symbol() as InjectionKey<(k: string) => void>;

export default Vue.extend({
  name: 'AppAccordionCoordinator',
  props: {
    preopened: String,
    items: {
      type: Array as () => string[],
      required: true,
      default: () => []
    }
  },
  setup(props: AppAccordionCoordinatorProps) {
    const opened: Ref<string[]> = ref(props.preopened ? [props.preopened] : []);

    function updateOpened(k: string): void {
      const currentIndex: number = props.items.indexOf(k);
      const isOpened: boolean = opened.value.includes(k);

      if (isOpened) {
        opened.value = getNextOpened(currentIndex, props.items, opened.value);
      } else {
        opened.value.push(k);
      }
    }

    function getNextOpened(currentIndex: number, items: string[], opened: string[]): string[] {
      opened.splice(opened.indexOf(items[currentIndex]), 1);
      if (opened.length === 0) {
        return [(currentIndex < items.length - 1) ? items[currentIndex + 1] : items[Math.max(0, currentIndex - 1)]];
      }
      return opened;
    }

    provide(accordionOpenedKey, opened);
    provide(accordionSetOpenedKey, updateOpened);

    onMounted(() => {
      opened.value = props.preopened ? [props.preopened] : [];
    });

    return { opened };
  }
});
