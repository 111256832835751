var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problem-description-item",class:{
        'problem-description-item--nowrap': _vm.nowrap,
        'problem-description-item--no-icon': !_vm.icon && !_vm.image,
        ['problem-description-item--theme-' + _vm.theme]: _vm.theme,
        ['problem-description-item--type-' + _vm.type]: _vm.type,
        'problem-description-item--clickable': _vm.clickable,
        'problem-description-item--overflow': _vm.overflow,
        'problem-description-item--loading': _vm.loading,
}},[_c('div',{staticClass:"problem-description-item__icon-wrap"},[(_vm.image)?_c('img',{staticClass:"problem-description-item__icon",attrs:{"src":require('../../assets/img/' +  _vm.image),"alt":""}}):_vm._e(),(_vm.icon)?_c('AppIcon',{staticClass:"problem-description-item__icon",attrs:{"icon":_vm.icon}}):_vm._e(),_c('div',{staticClass:"problem-description-item__icon-additional"},[_vm._t("icon-additional")],2)],1),_c('div',{staticClass:"problem-description-item__title"},[_vm._t("name",function(){return [_vm._v(_vm._s(_vm.$t(_vm.name)))]}),(_vm.theme === 'warning-icon')?_c('AppIcon',{staticClass:"problem-description-item__warning-icon",attrs:{"icon":"warning-circle"}}):_vm._e()],2),_c('div',{staticClass:"problem-description-item__value",on:{"click":_vm.onValueClick}},[_vm._t("value",function(){return [_vm._v(_vm._s(_vm.loading ? _vm.loadingText : _vm.displayValue))]}),(_vm.copyIcon)?_c('button',{staticClass:"problem-description-item__value-btn",on:{"click":_vm.onCopy}},[_c('AppIcon',{staticClass:"problem-description-item__value-btn-icon",attrs:{"icon":"copy-icon"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }