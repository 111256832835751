import {
    BackCustomersCommand, CancelCreateCustomerCommand,
    SelectCustomerCommand, StartCreateCustomerCommand
} from "./customer-commands/selectCustomerCommands";
import {
    CreateCustomerCommand,
    InputFormCommand, LoadGTSPCustomerCommand,
    SelectPhoneCommand,
    SetSearchMessageCommand, UpdateCustomerCommand
} from "./customer-commands/customerFormCommands";
import {
    ProblemActionCommand,
    ReturnProblemsCommand,
    SelectProblemCommand,
    StartCreateProblemCommand, UpdateProblemCommand
} from "./customer-commands/problemsCommands";
import {SearchCustomer, SearchCustomerByPhoneCommand} from "./customer-commands/customerFetchCommands";

export class ClientBaseCustomerCommands {
    constructor(chatData) {
        this.searchCustomerByPhone = new SearchCustomerByPhoneCommand(chatData);
        this.searchCustomer = new SearchCustomer(chatData);
        this.selectCustomer = new SelectCustomerCommand(chatData);
        this.setSearchMessage = new SetSearchMessageCommand(chatData);
        this.startCreateCustomer = new StartCreateCustomerCommand(chatData);
        this.backCustomers = new BackCustomersCommand(chatData);
        this.cancelCreateCustomer = new CancelCreateCustomerCommand(chatData);
        this.inputForm = new InputFormCommand(chatData);
        this.selectPhone = new SelectPhoneCommand(chatData);
        this.createCustomer = new CreateCustomerCommand(chatData);
        this.updateCustomer = new UpdateCustomerCommand(chatData);
        this.loadGTSPCustomer = new LoadGTSPCustomerCommand(chatData);
        this.selectProblem = new SelectProblemCommand(chatData);
        this.startCreateProblem = new StartCreateProblemCommand(chatData);
        this.updateProblem = new UpdateProblemCommand(chatData);
        this.returnProblems = new ReturnProblemsCommand(chatData);
        this.problemAction = new ProblemActionCommand(chatData);
    }
}
