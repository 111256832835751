<template>
    <AppAccordion class="pins-accordion" :name="data.title" theme="pins" icon="checked-circle-outlined" open-on-start>
        <template #header-other>
            <div class="message-counter message-counter--theme-shy message-counter--size-medium">{{ valuesLength }}</div>
        </template>
        <div v-if="disabled" class="tw-text-base tw-leading-6 tw-font-bold tw-font-secondary tw-text-secondary-60 tw-mb-4">
            <span v-for="item in selectedValues" class="tw-inline-block tw-mr-1.5"><span>•</span> {{ item }}</span>
        </div>
        <div v-else class="block-wrap block-wrap--offset-macro tw-mb-4">
            <div v-for="item in data.checkboxes" :key="item.id" class="popular-theme popular-theme--size-medium"
                 :class="{ 'popular-theme--active': isCheckboxSelected(item) }" @click="onInput(item)">
                {{ item.name }}
            </div>
        </div>
        <div class="pins-accordion-form" :class="{'pins-accordion-form--short': short}">
            <div v-for="item in data.textfields" :key="item.id" class="pins-accordion-form__item">
                <InputWrapLabel :label="item.name"/>
                <AppInputA :value="value[item.id]" :disabled="disabled" @input="onTextInput(item.id, $event)"/>
            </div>
        </div>
    </AppAccordion>
</template>
<script>
import AppIcon from "../AppIcon";
import AppAccordion from "../AppAccordion";
import AppInputA from "../AppInputA";
import InputWrapLabel from "../form/InputWrapLabel";
export default {
    name: 'PinsAccordion',
    components: {InputWrapLabel, AppInputA, AppAccordion, AppIcon},
    props: {
        data: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            default: () => ({})
        },

        name: String,
        disabled: Boolean,
        short: Boolean
    },
    computed: {
        valuesLength() {
            return Object.values(this.value).filter(v => v === true).length;
        },
        selectedValues() {
            let result = [];
            for(let item of this.data.checkboxes) {
                if(item.id in this.value && this.value[item.id] === true) {
                    result.push(item.name);
                }
            }
            if(!result.length) {
                return ['Нет выбранных опций'];
            }
            return result;
        }
    },
    methods: {
        onInput(item) {
            if(this.disabled) return;
            const value = {...this.value};
            if(item.id in value) {
                delete value[item.id];
            } else {
                value[item.id] = true;
            }
            this.$emit('input', value);
        },
        onTextInput(id, text) {
            if(this.disabled) return;
            const value = {...this.value};
            value[id] = text;
            this.$emit('input', value);
        },
        isCheckboxSelected(item) {
            return item.id in this.value;
        }
    }
}
</script>
<style lang="scss">
.pins-accordion {
    --accordion-padding-body-top: 0;
    &__header-pins {
        position: relative;
        top: -3px;
    }
}
.pins-accordion-form {
    $self: &;
    margin-left: -28px;
    margin-top: -23px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    &__item {
        width: calc(100% / 4 - 28px);
        margin-left: 28px;
        margin-top: 23px;
    }
    &:not(#{$self}--short) {
        #{$self}__item {
            &:nth-child(6n+5) {
                width: calc(100% / 2 - 28px);
                & + #{$self}__item {
                    width: calc(100% / 2 - 28px);
                }
            }
        }
    }
    &--short {
        #{$self}__item {
            width: calc(100% / 2 - 28px);
            flex-grow: 1;
            &:nth-child(5n) {
                width: calc(100% - 28px);
            }
        }
    }
}
</style>
