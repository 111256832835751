<template>
    <div class="auto-location">
        <div class="auto-location__header">
            <div class="product-title">
                <AppIcon class="product-title__icon" icon="location-place"/>
                {{ $t("components.common.carDealershipLocations") }}
            </div>
            <AppInputA v-model="model.search" class="ml-auto auto-location__input" clearable :placeholder="$t('components.common.search')" append>
                <template #append>
                    <div class="spacer-button">
                        <AppIcon icon="search" size="19" class="color-gray d-block"/>
                    </div>
                </template>
            </AppInputA>
        </div>
        <AppTable class="auto-location__table auto-location__table--max-height mb-2-a" :head="locationsHead" :data="locationsData" theme="locations" @row-click="onRowClick">
            <template #head.select="{cell}">
                <div class="text-center">
                    {{ cell.name }}
                </div>
            </template>
            <template #cell.select="{item}">
                <div class="auto-location__row">
                    <AppCheckboxA v-model="model.checked" :val="item.phone" name="location"/>
                </div>
            </template>
        </AppTable>
        <hr class="separator mb-6-a">
        <div class="auto-location__footer">
            <div class="block-wrap">
                <AppInputA v-model="model.search" class="auto-location__input" placeholder="+998" clearable/>
                <AppButton>{{ $t("components.common.sendSms") }}</AppButton>
            </div>
        </div>
    </div>
</template>
<script>
import AppIcon from "../AppIcon";
import AppInput from "../AppInput";
import AppTable from "../AppTable";
import AppButton from "../AppButton";
import AppInputA from "../AppInputA";
import AppCheckboxA, {selectCheckboxItem} from "../AppCheckboxA";
export default {
    name: 'AutoLocations',
    components: {AppCheckboxA, AppInputA, AppButton, AppTable, AppInput, AppIcon},
    data() {
        return {
            model: {
                search: '',
                checked: []
            },
            locationsHead: [[
                {
                    name: 'Выбор',
                    key: 'select',
                    width: '129px'
                },
                {
                    name: 'Адрес',
                    key: 'address'
                },
                {
                    name: 'Название',
                    key: 'name',
                    width: '25%'
                },
                {
                    name: 'Контакт',
                    key: 'contact',
                    width: '25%'
                }
            ]],
            locationsData: new Array(30).fill(0).map(e => ({
                name: '“KIA Sergeli” (ООО Roodell)',
                contact: 'Шерали - 998998919727',
                address: 'Toshkent shahar, Sergeli tumani. TXAY, A.Navoiy mavzesi',
                phone: Math.random()
            }))
        }
    },
    methods: {
        onRowClick(e) {
            this.model.checked = selectCheckboxItem(this.model.checked, e.item.phone);
        }
    }
}
</script>
