<template>
    <div :class="[$style['app-table'], { [$style['app-table--theme-' + theme]]: theme, [$style['app-table--no-scroll']]: noScroll, [$style['app-table--expandable']]: expandable }]" :style="tableStyle">
        <table :class="$style['app-table__table']">
            <thead :class="$style['app-table__table--thead']" ref="head">
            <tr v-for="(row, idx) in head" :key="idx">
                <th v-for="cell in row"
                    :key="cell.key"
                    :class="[$style['app-table__cell'], $style['app-table__cell--head'], {
                        [$style['app-table__cell--' + cell.size]]: cell.size,
                    }]"
                    :colspan="cell.colspan"
                    :style="getCellWidth(cell)"
                >
                    <slot :name="'head.' + cell.key" :cell="cell">
                        {{ getTranslationOrKey(cell.name) }}
                    </slot>
                </th>
                <th v-if="idx === 0 && expandable" :class="[$style['app-table__cell'], $style['app-table__cell--expandable'], $style['app-table__cell--head']]"></th>
            </tr>
            </thead>
            <tbody ref="table">
            <template v-for="(item, rowIndex) in data">
                <tr :key="rowIndex" :class="$style['app-table__row']" @click="$emit('row-click', { item: item, idx: rowIndex })">
                    <td v-for="key in dataKeys" :key="key" :class="[$style['app-table__cell'], $style['app-table__cell--body']]">
                        <slot :name="'cell.' + key" :item="item" :row-index="rowIndex">{{ item[key] }}</slot>
                    </td>
                    <td v-if="expandable" :class="[$style['app-table__cell'], $style['app-table__cell--body'], $style['app-table__cell--expandable']]">
                        <AppButton square size="medium-b" :theme="isOpened(item) ? 'primary-outline-a' : null" @click="toggleOpened(item)">
                            <AppIcon :class="{'product-info-icon--active': isOpened(item)}" icon="chevron-down" size="16"/>
                        </AppButton>
                    </td>
                </tr>
                <template v-if="isOpened(item)">
                    <tr v-for="rows in additionalRows" :class="[$style['app-table__row'], $style['app-table__row--additional']]">
                        <td v-for="cell in rows" :key="cell.key" :class="[$style['app-table__cell'], $style['app-table__cell--body']]" :colspan="cell.colspan">
                            <slot :name="'cell.' + cell.key" :item="item" :row-index="rowIndex">{{ item[cell.key] }}</slot>
                        </td>
                        <td :class="[$style['app-table__cell'], $style['app-table__cell--body']]"></td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>
    </div>
</template>
<script>
import AppButton from "./AppButton";
import AppIcon from "./AppIcon";
import {translationExists} from "@/mixins/local/translationExists.mixin";
export default {
    name: 'AppTable',
    components: {AppIcon, AppButton},
    mixins: [translationExists],
    props: {
        data: {
            type: Array,
            required: true
        },
        head: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
        },
        equalCells: Boolean,
        noScroll: Boolean,
        additionalRows: Array,
        maxRows: [String, Number]
    },
    data() {
        return {
            opened: [],
            maxHeight: null
        }
    },
    computed: {
        dataKeys() {
            return this.head[0].map(head => head.key)
        },
        expandable() {
            return this.additionalRows
        },
        tableStyle() {
            if(this.maxHeight) {
                return {'--table-max-height': this.maxHeight};
            } else {
                return {}
            }
        }
    },
    watch: {
        maxRows: 'calculateMaxHeight'
    },
    mounted() {
        this.calculateMaxHeight();
    },
    methods: {
        calculateMaxHeight() {
            if(this.maxRows && this.$refs.table) {
                let height = 0;
                Array.from(this.$refs.table.children).slice(0, this.maxRows).forEach(el => height += el.clientHeight);
                this.maxHeight = height + this.$refs.head.clientHeight + 'px';
            } else {
                this.maxHeight = null;
            }
        },
        isOpened(item) {
            return this.opened.includes(item);
        },
        toggleOpened(item) {
            if(this.isOpened(item)) {
                this.opened.splice(this.opened.indexOf(item), 1);
            } else {
                this.opened.push(item);
            }
        },
        getCellWidth(cell) {
            const width = cell.width || null;
            return this.equalCells ? {width: 1 / this.head[0].length * 100 + '%'} : {width};
        }
    }
}
</script>
<style lang="scss" module>
@import '../../assets/scss/variables';
.app-table {
    $self: &;
    --table-cell-height: 48px;
    overflow: auto;
    font-family: $font-primary;
    max-height: var(--table-max-height);
    &__table {
        min-width: 100%;
      &--thead {
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
    &__cell {
        padding: 10px 32px 10px 0;
        height: var(--table-cell-height);
        width: auto;
        min-width: 120px;

        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        &:first-child {
            padding-left: var(--table-first-cell-padding-left, 16px);
        }
        &:last-child {
            padding-right: var(--table-last-cell-padding-right, 10px);
        }
        &--body {
            font-family: $font-secondary;
            font-weight: 500;
            min-width: 0;
        }
        &--large {
            width: 35%;
            min-width: 240px;
        }
        &--head {
            box-shadow: inset 0px -1px 0px var(--color-border);
            white-space: nowrap;
            text-transform: uppercase;
            background-color: #fff;
            z-index: 1;
        }
        &--short {
            width: 1%;
            min-width: 0;
        }
        &--expandable {
            width: 10px;
            min-width: 0;
            padding-right: 36px !important;
        }
    }
    &__row:nth-child(even):not(&__row--additional) &__cell {
        background: var(--color-white-dark);
    }
    &__row--additional &__cell {
        background-color: var(--color-white);
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
    }
    &__row:hover:not(&__row--additional) &__cell {
        background: var(--color-white-bluesh);
    }

    &--theme-results,
    &--theme-locations,
    &--theme-statistics {
        --table-cell-height: 56px;
        color: var(--color-gray);
        #{$self}__cell {
            &--body {
                font-family: $font-primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.005em;
                padding-top: 17px;
                padding-bottom: 14px;
            }
            &--head {
                padding-top: 20px;
                padding-bottom: 16px;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-transform: none;
                color: var(--color-dark);
            }
        }
        #{$self}__row {
            overflow: hidden;
            position: relative;
        }
    }
    &--theme-calculator {
        --table-cell-height: 56px;
        color: var(--color-gray);
        #{$self}__table--thead tr:nth-child(2) th {
          background: #EDF2FF;
        }

        #{$self}__cell {
            &--body {
                font-family: $font-primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.005em;
                padding-top: 17px;
                padding-bottom: 14px;
            }
            &--head {
                padding-top: 20px;
                padding-bottom: 16px;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                text-transform: none;
                color: var(--color-dark);
            }
        }
        #{$self}__row {
            overflow: hidden;
            position: relative;
        }
    }
    &--theme-locations,
    &--theme-statistics {
        --table-first-cell-padding-left: 28px;
    }
    &--theme-results {
        --table-cell-height: 56px;
        color: var(--color-dark);
        border-bottom: 1px solid #E1E1E1;
        #{$self}__cell {
            text-align: right;
            &:first-child {
                text-align: left;
            }
            &--head {
                color: var(--color-gray-light);
            }
        }
        #{$self}__row:first-child {
            #{$self}__cell {
                background-color: #EDF2FF;
                font-weight: 700;
            }
        }
    }
    &--theme-calculator {
      --table-cell-height: 56px;
      color: var(--color-dark);
      border-bottom: 1px solid #E1E1E1;
      #{$self}__cell {
        text-align: right;
        &:first-child {
          text-align: left;
        }
        &--head {
          color: var(--color-gray-light);
        }
      }
      #{$self}__row:first-child {
        #{$self}__cell {
          font-weight: 700;
        }
      }
    }
    &--theme-statistics {
        --table-cell-height: 58px;
        #{$self}__cell {
            background: var(--color-white) !important;
        }
    }
    &--theme-admin-page {
        --table-first-cell-padding-left: 49px;
        --table-last-cell-padding-right: 60px;
    }
    &--theme-report {
        --table-first-cell-padding-left: 64px;
        --table-last-cell-padding-right: 64px;
        #{$self}__cell:first-child {
            text-align: left;
        }
    }
    &--theme-problem {
        --table-cell-height: 56px;
        --table-first-cell-padding-left: 37px;
        --table-last-cell-padding-right: 34px;
        #{$self}__cell {
            text-align: left;
            &--head {
                padding-top: 0;
                padding-bottom: 17px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: var(--color-dark);
                text-transform: none;
            }
            &--body {
                font-family: $font-primary;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }
    &--theme-card-disabled,
    &--theme-card {
        --table-first-cell-padding-left: 42px;
        --table-last-cell-padding-right: 42px;
        #{$self}__cell {
            background: var(--color-white);
            &--head {
                background: #EDF2FF;
                border-bottom: 0;
                padding-top: 14px;
                padding-bottom: 15px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                text-transform: none;
            }
        }
    }
    &--theme-card-disabled {
        #{$self}__cell--head {
            background: #F4F4F4;
            color: #949494;
        }
    }
    &--theme-problem-list {
        --table-first-cell-padding-left: 34px;
        --table-last-cell-padding-right: 34px;
        #{$self}__cell {
            text-align: left;

            &--head {
                text-transform: none;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                padding-top: 18px;
                padding-bottom: 17px;
                color: var(--color-dark);
            }
        }
    }
    &--theme-filial {
        --table-first-cell-padding-left: 42px;
        --table-last-cell-padding-right: 40px;
        #{$self}__cell {
            &--head {
                padding-top: 0;
                padding-bottom: 16px;
                text-transform: none;
            }
            &--body {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }
    &--theme-problem-chat {
        --table-first-cell-padding-left: 34px;
        --table-last-cell-padding-right: 34px;
        #{$self}__cell {
            text-align: left;
            &--body {
                padding-top: 18px;
                padding-bottom: 18px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.005em;
            }
            &--head {
                padding-top: 18px;
                padding-bottom: 17px;
                border-bottom: 1px solid var(--color-border);
                font-weight: 700;
                text-transform: capitalize;
            }
        }
    }
    &--no-scroll {
        overflow: visible;
    }
}
</style>
