import moment from "moment";
import { formatTime } from "@/helpers/dates";

function parseTime(time) {
    return (time - 10 < 0 ? "0" : "") + time;
}

function validateInteger(val) {
    if (val) {
        const int = Number.parseInt(val)
        if (!Number.isNaN(int)) {
            return int
        }
    }

    return 0
}

export default {
    filters: {
        timeFromMS(value, showHours) {
            const val = validateInteger(value)
            const seconds = Math.floor(val / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);

            const parsedSeconds = parseTime(seconds % 60);
            const parsedMinutes = parseTime(minutes % 60);
            const parsedHours = parseTime(hours) + ":";
            return (
                (showHours ? parsedHours : "") +
                `${parsedMinutes}:${parsedSeconds}`
            );
        },
    },
    methods: {
        getChatDate(date) {
            const intl = new Intl.DateTimeFormat("ru-RU", {
                day: "2-digit",
                month: "long",
                year: "numeric",
            });
            return intl.format(date);
        },
        parseDate(date) {
            const intl = new Intl.DateTimeFormat("ru-RU", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return intl.format(date);
        },
        parsedDateAndTimeFromString: (date) => {
            return new Date(date).toLocaleDateString("ru-RU", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            });
        },
        formatDateMoment(date, format = "DD.MM.YYYY HH:mm") {
            return moment(date).format(format);
        },
        formatTimeStamp(timeStamp, format = "DD.MM.YYYY HH:mm") {
            return moment.unix(timeStamp).format(format);
        },
        formatTime,
    },
};
