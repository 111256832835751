<template>
    <div v-if="data" class="script-message">
        <p class="script-message__text">{{ text }}</p>
        <div v-if="showButtons" class="script-message__buttons">
            <AppButton class="script-message__button" size="small-a" theme="primary-dark" @click="selectButton('no')">{{ translations[lang].no }}</AppButton>
            <AppButton class="script-message__button" size="small-a" theme="white-flat" @click="selectButton('yes')">{{ translations[lang].yes }}</AppButton>
        </div>
    </div>
</template>
<script>
import AppButton from "./AppButton";
import ListUtils from "../../mixins/ListUtils";
import {CallRoom} from "../../services/chat/ChatModel";
import {getUserDataFromCallData} from "../../helpers/chats";
export default {
    name: 'ScriptMessage',
    components: {AppButton},
    mixins: [ListUtils],
    model: {
        prop: 'selected',
        event: 'select'
    },
    props: {
        data: Object,
        selected: String,
        callData: CallRoom
    },
    data() {
        return {
            translations: {
                uz: {
                    yes: 'ҳа',
                    no: 'йўқ'
                },
                ru: {
                    yes: 'Да',
                    no: 'Нет'
                },
                qq: {
                    yes: 'ҳа',
                    no: 'йўқ'
                }
            },
        }
    },
    computed: {
        operatorName() {
            return this.$store.state.operator.operator.name;
        },
        text() {
            const { username } = getUserDataFromCallData(this.callData);
            return this.replaceUsername(this.selected ? this.data[this.selected]['text_' + this.lang] : this.data['text_' + this.lang], username, this.operatorName);
        },
        lang() {
            if (this.callData.lang === "qr" && this.data["text_qq"]) {
                return "qq";
            }
            return this.callData.lang;
        },
        showButtons() {
            return !!this.data.is_need_question && !this.selected;
        }
    },
    methods: {
        selectButton(state) {
            if(!this.showButtons) return false;

            this.$emit('select', state);
        },
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';

.script-message {
    margin: 32px 0;
    display: flex;
    align-items: center;
    background: var(--color-white-bluesh);
    border: 1px solid var(--color-primary-dark);
    border-radius: 24px 24px 24px 4px;
    padding: 18px 19px 18px 41px;
    width: fit-content;
    max-width: 80%;
    min-height: 72px;
    &__text {
        margin: 0;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: var(--color-primary-dark);
        font-family: $font-secondary;
    }
    &__buttons {
        margin-left: 32px;
        display: flex;
    }
    &__button {
        width: 68px;
        margin-left: 12px;
    }
}
</style>
