<template>
    <div
        class="home-tabs"
        :class="{
            'home-tabs-alarm': showAlarm,
            'home-tabs-alarm--full': !sidebarOpened && showAlarm,
        }"
    >
        <div
            v-for="item in navList"
            class="home-tabs__item"
            :class="{
                'home-tabs__item--active': item.key === selectedView,
            }"
            @click="$emit('input', item.key)"
        >
            {{ $t(item.name) }}
        </div>
        <transition name="agent-buttons">
            <div
                v-if="incomingClient && (isHomePage || showAlarm)"
                class="home-tabs__incoming"
            >
                {{ incomingClient.name }}
                <AppButton
                    class="home-tabs__incoming-button"
                    theme="success-flat"
                    size="light"
                    @click="changeLayout"
                >
                    {{ incomingClient.button }}
                    <AppIcon icon="chevron-right-bold" size="12" />
                </AppButton>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { getPageNav } from "../../../helpers/other";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";

export default {
    name: "HomeTabs",
    components: { AppIcon, AppButton },
    model: {
        event: "input",
        prop: "selectedVIew",
    },
    props: {
        selectedView: {
            type: [Number, String],
            required: true,
        },
        userData: {
            type: Object,
            default: () => ({}),
        },
        showAlarm: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(["isChat"]),
        ...mapState("callData", ["incomingCall"]),
        ...mapState("chat", ["incomingChat"]),
        sidebarOpened() {
            return this.$store.state.system.sidebarOpened;
        },
        navList() {
            if (this.showAlarm || Object.keys(this.userData).length === 0) {
                return [];
            }
            return getPageNav(this.userData);
        },
        incomingClient() {
            let type = "";
            if (this.showAlarm) {
                type = this.incomingCall ? "звонок" : "чат";
            } else {
                if (this.isChat && this.incomingCall) {
                    type = "звонок";
                } else if (!this.isChat && this.incomingChat) {
                    type = "чат";
                } else {
                    return null;
                }
            }
            return {
                name: `Внимание входящий ${type}, перейдите на вкладку, чтобы`,
                button: `Принять ${type}`,
            };
        },
        isHomePage() {
            return this.$route.name === "ClientCard";
        },
    },
    methods: {
        changeLayout() {
            if (this.showAlarm) {
                this.onAlarm();
            } else {
                this.$store.commit("changeChatLayout", !this.isChat);
            }
        },

        onAlarm() {
            if (!this.incomingCall) {
                this.$store.commit("changeChatLayout", true);
            }
            this.$router.push({ name: "ClientCard" });
        },
    },
};
</script>

<style lang="scss">
.home-tabs.home-tabs-alarm {
    position: fixed;
    top: 101px;
    left: 182px;
    transition: all 0.25s;

    width: calc(100% - 182px);
}
.home-tabs.home-tabs-alarm--full {
    left: 48px;
    width: calc(100% - 48px);
}
</style>
