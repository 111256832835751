<template>
    <table class="multi-client-table" :class="{'multi-client-table--chat': isChat}">
        <tbody>
        <tr v-for="(item, index) in customersList" :key="index">
            <td>
                <div class="multi-client-table__tag-wrap">
                    <BankUserTags :user-data="item" full/>
                </div>
                <div class="multi-client-table__name">
                    {{ item.username }}
                </div>
            </td>
            <td v-if="!isChat" class="multi-client-table__info">
                <ProblemDescriptionItem name="components.common.clientCategory" :value="filterData(item)" :icon="isChat ? null : 'user-check'"/>
            </td>
            <td class="multi-client-table__info">
                <ProblemDescriptionItem name="components.common.clientCode" :value="item.code || '-'" :icon="isChat ? null : 'code'"/>
            </td>
            <td class="multi-client-table__info">
                <ProblemDescriptionItem name="components.common.dateOfChange" :value="parsedDateAndTimeFromString(item.updated_at)" :icon="isChat ? null : 'calendar-edit'"/>
            </td>
            <td>
                <AppButton v-if="isChat" class="multi-client-table__button" theme="primary-outline" size="medium-extra" round @click="selectCustomer(item.id)">
                    <AppIcon icon="pencil" class="application-input-icon application-input-icon--medium"/>
                </AppButton>
                <AppButton v-else class="multi-client-table__button" theme="primary-outline" @click="selectCustomer(item.id)">{{ $t("components.common.edit") }}</AppButton>
            </td>
        </tr>
        </tbody>
    </table>
</template>
<script>
import BankUserTags from "../../../BankUserTags";
import ProblemDescriptionItem from "../../ProblemDescriptionItem";
import clientCategories from "@/config/clientCategories";
import AppButton from "../../AppButton";
import AppIcon from "../../AppIcon";
export default {
    name: 'MultiClientList',
    components: {AppIcon, AppButton, ProblemDescriptionItem, BankUserTags},
    props: {
        isChat: Boolean,
        customersList: {
            type: Array,
            required: true
        },
    },
    methods: {
        filterData(item) {
            if (item.client_type == "0" || item.client_type == null) {
                return "--";
            }
            let data = clientCategories.find(el => el.id == item.client_type);
            return data ? this.$t(data.name): "--";
        },
        selectCustomer(customerId) {
            this.$emit('select-customer', customerId);
        },
        parsedDateAndTimeFromString(date) {
            return new Date(date).toLocaleDateString("ru-RU", {
                "year": "numeric",
                "month": "numeric",
                "day": "numeric",
                "hour": "numeric",
                "minute": "numeric"
            })
        }
    }
}
</script>
