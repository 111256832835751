const basePath = "components.common.clientCategories"

const clientCategories = [
    {
        name: basePath + ".salary",
        id: 1
    },
    {
        name: basePath + ".client",
        id: 2
    },
    {
        name: basePath + ".potential",
        id: 3
    },
    {
        name: basePath + ".partner",
        id: 4
    }
];
export default clientCategories
