<template>
    <a-modal width="910px" class="error_modal" v-model="visible" :footer="false" :closable="false">
        <div class="error_modal_top d-flex  justify-content-between align-items-center">
            <div class="d-flex   align-items-center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 32C7.1632 32 0 24.8368 0 16C0 7.1632 7.1632 0 16 0C24.8368 0 32 7.1632 32 16C32 24.8368 24.8368 32 16 32ZM14.4 20.8V24H17.6V20.8H14.4ZM14.4 8V17.6H17.6V8H14.4Z"
                        fill="#D70C17"/>
                </svg>
                <p class="mb-0 ml-15">
                    {{ isChat ? $t('components.common.missedChat') : $t('components.common.missedCall') }}
                </p>
            </div>
            <span>{{ $t("components.common.today") }} {{ timeNow }}</span>
        </div>
        <div class="error_modal_body">
            <p>{{ $t("components.common.skipLess") }} {{ isChat ? $t('components.common.chats') : $t('layout.header.calls') }}, {{ $t("components.common.thisIsReflectedInYourKpi") }} 🤓</p>
            <a-button style="height: 48px !important" class="my_btn download" type="primary" @click="visible = false"> {{ $t("components.common.fine") }}</a-button>
        </div>
    </a-modal>
</template>

<script>
import {EventBus} from "../helpers/eventBus";
import moment from "moment";

export default {
    data() {
        return {
            visible: false,
            timeNow: '',
            isChat: false
        };
    },
    methods: {
        openModal() {
            this.visible = true;
        },
        closeModal(e) {
            let condition = !this.$el.contains(e?.target) && !e?.target.classList.contains('close_icon') && !e?.target.classList.contains('ant-select-dropdown-menu-item') && !e?.target.classList.contains('back_to_credit')
            if (e?.target) {
                if (condition) {
                    if (this.visible == true) {
                        this.visible = false
                        this.isChat = false
                    }
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeModal)
    },
    created() {
        EventBus.$on('system-agent-lost-call', (e) => {
            this.isChat = e.isChat;
            this.visible = true;
            this.timeNow = moment().format('HH:mm');
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeModal);
        EventBus.$off('system-agent-lost-call');
    },
};
</script>

<style lang="scss">
.error_modal {
    .ant-modal-content {
        border: 1px solid var(--color-danger);
        box-sizing: border-box;
        border-radius: 12px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 0;
    }

    .error_modal_top {
        padding: 32px;
        padding-bottom: 24px;
        border: 1px solid var(--color-border);

        p {
            font-size: 32px;
            line-height: 38px;
            color: var(--color-danger);
        }

        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--color-dark-light);
        }
    }

    .error_modal_body {
        display: flex;
        flex-direction: column;
        padding: 32px;
        padding-top: 20px;

        p {
            margin-bottom: 50px;
            font-size: 24px;
            line-height: 34px;
        }

        button {
            align-self: flex-end;
        }
    }
}
</style>
