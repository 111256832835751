var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"app-input-a",class:{
        ['app-input-a--error']: _vm.error,
        ['app-input-a--disabled']: _vm.disabled,
        ['app-input-a--theme-' + _vm.theme]: _vm.theme,
        ['app-input-a--size-' + _vm.size]: _vm.size,
        'app-input-a--readonly': _vm.readonly,
        ..._vm.focusClass,
    },on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.prepend)?_c('div',{directives:[{name:"resize",rawName:"v-resize.initial",value:(_vm.onResize),expression:"onResize",modifiers:{"initial":true}}],ref:"prepend",staticClass:"app-input-a__slot app-input-a__slot--prepend"},[_vm._t("prepend")],2):_vm._e(),_c('input',_vm._g(_vm._b({ref:"input",staticClass:"app-input-a__field",style:(_vm.inputStyle),attrs:{"disabled":_vm.disabled || _vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.onInput}},'input',_vm.parsedAttrs,false),_vm.parsedListeners)),(_vm.append || _vm.clearable)?_c('div',{ref:"append",staticClass:"app-input-a__slot app-input-a__slot--append"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clearable && _vm.value.length),expression:"clearable && value.length"}],staticClass:"app-input-a__action spacer-button",on:{"click":function($event){return _vm.$emit('input', '')}}},[_c('AppIcon',{attrs:{"icon":"close","size":"16"}})],1),_vm._t("append")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }