<template>
    <div class="empty-content">
        {{ getTranslationOrKey(text) }}
    </div>
</template>
<script>
import {translationExists} from "@/mixins/local/translationExists.mixin";

export default {
  name: 'EmptyContent',
  mixins: [ translationExists ],
  props: {
        name: String,
        title: String
    },
    computed: {
        text() {
            return this.title ? this.title : `😔 ${this.getTranslationOrKey(this.name)} ${this.$t('common.notFoundShort')}`
        }
    }
}
</script>
<style lang="scss">
.empty-content {
    padding: 38px 42px;
    background: #FFFFFF;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
</style>
