<template>
    <ModalWrapper
        class="deposit-modal loan-modal"
        :class="modalCssClass"
        @scroll="onScroll"
        ref="modal"
        custom-close
        #default="{ close }"
    >
        <div class="deposit-modal__window">
            <div class="loan-modal__controls">
                <div @click="toggleFullscreen">
                    <AppIcon
                        :icon="
                            fullscreen ? 'fullscreen-close' : 'fullscreen-open'
                        "
                        size="24"
                        style="color: #717171"
                    />
                </div>
                <div @click="close">
                    <AppIcon icon="close" size="24" />
                </div>
            </div>
            <LoanModalHeader
                :class="{ bordered: !!modals.loan.account.info }"
                :model="modals.loan.model"
                :format-sum="$parent.formatSum"
                :currency="currency"
                :values="values"
            />
            <div class="loan-modal__main">
                <AppTabs
                    class="loan-modal__tabs-body"
                    :class="appTabsCssClass"
                    v-model="creditTab"
                    :items="creditTabs"
                    @change="onTabChange"
                    theme="processing"
                >
                    <template #body.1>
                        <div class="deposit-modal__body">
                            <template v-if="!modals.loan.account.info">
                                <div class="block-wrap loan-modal__block">
                                    <ProblemDescriptionItem
                                        name="components.common.product"
                                        :value="modals.loan.model.productId"
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        name="components.common.amountOfCredit"
                                        :value="
                                            modals.loan.model.amount &&
                                            $parent.formatSum(
                                                modals.loan.model.amount,
                                                true
                                            )
                                        "
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        name="components.common.loanStatus"
                                        :value="modals.loan.model.stateDesc"
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        name="components.common.channelOfAppeal"
                                        :value="
                                            modals.loan.model.onOf === 'Online'
                                                ? $t(
                                                      'components.common.viaMobileApplication'
                                                  )
                                                : $t(
                                                      'components.common.offline'
                                                  )
                                        "
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        v-if="modals.loan.model.followPayDate"
                                        name="components.common.nextPaymentDay"
                                        :value="modals.loan.model.followPayDate"
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        v-if="modals.loan.model.cardNumber"
                                        :name="
                                            modals.loan.model.creditType ===
                                            '54'
                                                ? 'components.common.theLoanWasIssuedTo'
                                                : 'components.common.attachedMap'
                                        "
                                        :value="
                                            hiddenCard(
                                                modals.loan.model.cardNumber
                                            )
                                        "
                                        :clickable="!!cardInternal"
                                        @click-value="onCardClick"
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        v-if="modals.loan.model.loanCardNumber"
                                        name="components.common.attachedMap"
                                        :value="
                                            hiddenCard(
                                                modals.loan.model.loanCardNumber
                                            )
                                        "
                                        :clickable="!!loanCardInternal"
                                        @click-value="onLoanCardClick"
                                        type="card"
                                    />
                                    <ProblemDescriptionItem
                                        name="components.common.paymentDay"
                                        :value="modals.loan.model.followPayDate"
                                        type="card"
                                    />
                                </div>
                                <div
                                    class="deposit-modal__title loan-modal__title"
                                >
                                    {{
                                        $t(
                                            "components.common.generalInformationOnAccounts"
                                        )
                                    }}
                                </div>
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('1')"
                                    :title="{
                                        text: 'components.common.mainLoanAccount',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById('1'),
                                        },
                                    }"
                                    :value="values.getAccountValueById('1')"
                                    clickable
                                    @click="$parent.openAccountById('1')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('2')"
                                    :title="{
                                        text: 'components.common.clientsCurrentAccountForRepaymentOfLoanInterest',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById('2'),
                                        },
                                    }"
                                    :value="values.getAccountValueById('2')"
                                    clickable
                                    @click="$parent.openAccountById('2')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('102')"
                                    :title="{
                                        text: 'components.common.additionalClientCurrentAccountForRepaymentOfLoanInterestAndLoan',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById(
                                                    '102'
                                                ),
                                        },
                                    }"
                                    :value="values.getAccountValueById('102')"
                                    clickable
                                    @click="$parent.openAccountById('102')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('3')"
                                    :title="{
                                        text: 'components.common.interestAccount',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById('3'),
                                        },
                                    }"
                                    :value="values.getAccountValueById('3')"
                                    clickable
                                    @click="$parent.openAccountById('3')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('5')"
                                    :title="{
                                        text: 'components.common.overdueAccount',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById('5'),
                                        },
                                    }"
                                    :value="values.getAccountValueById('5')"
                                    clickable
                                    @click="$parent.openAccountById('5')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('7')"
                                    :title="{
                                        text: 'components.common.accountForAccrualOfInterestOnOverdueCourt',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById('7'),
                                        },
                                    }"
                                    :value="values.getAccountValueById('7')"
                                    clickable
                                    @click="$parent.openAccountById('7')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('46')"
                                    :title="{
                                        text: 'components.common.accountOfInterestAccruedButNotPaidOnTime',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById(
                                                    '46'
                                                ),
                                        },
                                    }"
                                    :value="values.getAccountValueById('46')"
                                    clickable
                                    @click="$parent.openAccountById('46')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('118')"
                                    :title="{
                                        text: 'components.common.interestAccruedUnderTheAgreementButDeferred',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById(
                                                    '118'
                                                ),
                                        },
                                    }"
                                    :value="values.getAccountValueById('118')"
                                    clickable
                                    @click="$parent.openAccountById('118')"
                                />
                                <LoanInfoRow
                                    class="mb-3"
                                    v-if="$parent.getLoanAccountById('22')"
                                    :title="{
                                        text: 'components.common.penaltiesForLateRepaymentOfAccruedInterest',
                                        keys: {
                                            account:
                                                $parent.getLoanAccountById(
                                                    '22'
                                                ),
                                        },
                                    }"
                                    :value="values.getAccountValueById('22')"
                                    clickable
                                    @click="$parent.openAccountById('22')"
                                />

                                <hr />

                                <LoanInfoRow
                                    class="mb-3"
                                    title="components.common.today"
                                    :value="formatNumber(totalToday)"
                                />
                                <LoanInfoRow
                                    title="components.common.maximumPaymentAmount"
                                    :value="formatNumber(totalMax)"
                                />
                            </template>
                            <div v-else>
                                <AppButton
                                    size="medium"
                                    theme="blueish-a"
                                    @click="$parent.goToAccountList('loan')"
                                >
                                    {{ $t("components.common.comeBack") }}
                                </AppButton>
                                <div class="block-wrap align-items-end mb-32">
                                    <ProblemDescriptionItem
                                        class="mr-auto"
                                        name="components.common.accountNumber"
                                        :value="modals.loan.account.info"
                                        type="card"
                                    />
                                    <div>
                                        <label class="d-block">{{
                                            $t("components.common.with")
                                        }}</label>
                                        <AppDatePicker
                                            @keyup.enter="
                                                $parent.loadAccountHistory(
                                                    'loan'
                                                )
                                            "
                                            v-mask="'##.##.####'"
                                            value-format="DD.MM.YYYY"
                                            size="large"
                                            v-model="
                                                modals.loan.account.dateBegin
                                            "
                                        />
                                    </div>
                                    <div>
                                        <label class="d-block">{{
                                            $t("components.common.by")
                                        }}</label>
                                        <AppDatePicker
                                            @keyup.enter="
                                                $parent.loadAccountHistory(
                                                    'loan'
                                                )
                                            "
                                            v-mask="'##.##.####'"
                                            value-format="DD.MM.YYYY"
                                            size="large"
                                            v-model="
                                                modals.loan.account.dateClose
                                            "
                                        />
                                    </div>
                                    <AppButton
                                        size="medium"
                                        :disabled="$parent.loading"
                                        @click.prevent="
                                            $parent.loadAccountHistory()
                                        "
                                    >
                                        {{
                                            $parent.loading
                                                ? $t("components.common.wait")
                                                : $t("components.common.apply")
                                        }}
                                    </AppButton>
                                </div>
                                <div
                                    class="loan-modal__empty"
                                    v-if="
                                        !$parent.spinner &&
                                        !modals.loan.account.history.length
                                    "
                                >
                                    <img
                                        src="../assets/img/monitoring-select-customer-image.webp"
                                        alt=""
                                    />
                                    <h2>
                                        {{
                                            $t("components.common.nothingFound")
                                        }}
                                    </h2>
                                </div>
                                <AppTable
                                    v-if="loanDetailsTableData.length > 0"
                                    class="deposit-modal__large loan-modal-account-table"
                                    :head="accountHistoryHead"
                                    :data="loanDetailsTableData"
                                    theme="admin-page"
                                >
                                    <template #cell.numberTrans="{ item }">
                                        <div
                                            v-if="$parent.spinner"
                                            class="skeleton"
                                            :style="{ height: '20px' }"
                                        />
                                        <template v-else>
                                            {{ item.numberTrans }}
                                        </template>
                                    </template>
                                    <template #cell.debit="{ item }">
                                        <div
                                            v-if="$parent.spinner"
                                            class="skeleton"
                                            :style="{ height: '20px' }"
                                        />
                                        <template v-else>
                                            {{ $parent.formatSum(item.debit) }}
                                        </template>
                                    </template>
                                    <template #cell.credit="{ item }">
                                        <div
                                            v-if="$parent.spinner"
                                            class="skeleton"
                                            :style="{ height: '20px' }"
                                        />
                                        <template v-else>
                                            {{ $parent.formatSum(item.credit) }}
                                        </template>
                                    </template>
                                    <template #cell.date="{ item }">
                                        <div
                                            v-if="$parent.spinner"
                                            class="skeleton"
                                            :style="{ height: '20px' }"
                                        />
                                        <template v-else>
                                            {{ item.date }}
                                        </template>
                                    </template>
                                    <template #cell.purpose="{ item }">
                                        <div
                                            v-if="$parent.spinner"
                                            class="skeleton"
                                            :style="{ height: '20px' }"
                                        />
                                        <template v-else>
                                            {{ item.purpose }}
                                        </template>
                                    </template>
                                </AppTable>
                                <div
                                    class="mt-20"
                                    v-if="
                                        modals.loan.account.history.length > 10
                                    "
                                >
                                    <p class="mb-0">
                                        <input
                                            class="paginate_input"
                                            @change="
                                                changeGrafik(
                                                    $event,
                                                    'loanHistoryAccountPage'
                                                )
                                            "
                                            v-model="
                                                values.loanHistoryAccountPage
                                            "
                                            type="text"
                                            autofocus
                                        />
                                        из
                                        <span>{{
                                            modals.loan.account.history.length
                                        }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #body.3>
                        <div
                            v-if="
                                loanSpinner === false &&
                                modals.loan.schedule.length === 0
                            "
                            class="deposit-modal__body d-flex justify-content-center"
                        >
                            <h2 class="text-danger font-italic">
                                {{ $t("components.common.notFound") }}
                            </h2>
                        </div>
                        <AppTable
                            v-if="scheduleData.length > 0"
                            :head="scheduleHead"
                            :data="scheduleData"
                            theme="admin-page"
                            class="loan-modal-table"
                        >
                            <template #cell.repaymentDate="{ item, rowIndex }">
                                <div
                                    v-if="loanSpinner"
                                    class="skeleton"
                                    :style="{ height: '20px' }"
                                ></div>
                                <div v-else :ref="'row' + rowIndex">
                                    {{ item.repaymentDate || "-" }}
                                </div>
                            </template>
                            <template #cell.amount="{ item }">
                                <div
                                    v-if="loanSpinner"
                                    class="skeleton"
                                    :style="{ height: '20px' }"
                                ></div>
                                <template v-else>
                                    {{
                                        item.amount
                                            ? $parent.formatSum(
                                                  item.amount,
                                                  true
                                              )
                                            : "-"
                                    }}
                                </template>
                            </template>
                            <template #cell.interestOnTermDebt="{ item }">
                                <div
                                    v-if="loanSpinner"
                                    class="skeleton"
                                    :style="{ height: '20px' }"
                                ></div>
                                <template v-else>
                                    {{
                                        item.interestOnTermDebt
                                            ? $parent.formatSum(
                                                  item.interestOnTermDebt,
                                                  true
                                              )
                                            : "-"
                                    }}
                                </template>
                            </template>
                            <template #cell.recommendedAmount="{ item }">
                                <div
                                    v-if="loanSpinner"
                                    class="skeleton"
                                    :style="{ height: '20px' }"
                                ></div>
                                <template v-else>
                                    {{
                                        item.recommendedAmount
                                            ? $parent.formatSum(
                                                  item.recommendedAmount,
                                                  true
                                              )
                                            : "-"
                                    }}
                                </template>
                            </template>
                            <template #cell.saldo="{ item }">
                                <div
                                    v-if="loanSpinner"
                                    class="skeleton"
                                    :style="{ height: '20px' }"
                                ></div>
                                <template v-else>
                                    {{
                                        item.saldo
                                            ? $parent.formatSum(
                                                  item.saldo,
                                                  true
                                              )
                                            : "-"
                                    }}
                                </template>
                            </template>
                        </AppTable>
                    </template>
                    <template #body.4>
                        <div
                            v-if="
                                loanSpinner === false &&
                                modals.loan.guar.length === 0
                            "
                            class="deposit-modal__body d-flex justify-content-center"
                        >
                            <h2 class="text-danger font-italic">
                                {{ $t("components.common.notFound") }}
                            </h2>
                        </div>
                        <div v-else>
                            <AppTabs
                                v-model="guarModel"
                                :items="modals.loan.guar"
                                item-name="guarTypeDescription"
                                item-value="guarType"
                                theme="loan-modal"
                            >
                                <template
                                    v-for="guar of modals.loan.guar"
                                    v-slot:[body+guar.guarType]
                                >
                                    <div
                                        :key="guar.guarTypeDescription"
                                        class="loan-modal__ensuring"
                                    >
                                        <template v-for="item in guarList">
                                            <ProblemDescriptionItem
                                                v-if="
                                                    guar[item.value] ||
                                                    guar[item.otherValue]
                                                "
                                                :key="item.value"
                                                class="loan-modal__ensuring-item"
                                                :name="item.name"
                                                :value="getValue(item, guar)"
                                                type="card"
                                            />
                                        </template>
                                    </div>
                                </template>
                            </AppTabs>
                        </div>
                    </template>
                    <template #body.schedule-fact>
                        <div
                            v-if="
                                scheduelFact.loading === false &&
                                scheduelFact.data.length === 0
                            "
                            class="deposit-modal__body d-flex justify-content-center"
                        >
                            <h2 class="text-danger font-italic">
                                {{ $t("components.common.notFound") }}
                            </h2>
                        </div>
                        <AppTable
                            v-if="scheduleFactData.length > 0"
                            :head="scheduelFact.header"
                            :data="scheduleFactData"
                            theme="admin-page"
                            class="loan-modal-table"
                        >
                            <template #head.mainDebt>
                                <div>Дата погашения</div>
                                <div>основного долга</div>
                            </template>
                            <template #cell.mainDebt="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.date_osn }}
                                </div>
                            </template>
                            <template #head.mainDebtSumm>
                                <div>Сумма погашения</div>
                                <div>основного долга</div>
                            </template>
                            <template #cell.mainDebtSumm="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.sum_osn }}
                                </div>
                            </template>
                            <template #head.mainDebtPaid>
                                <div>Сумма оплачено</div>
                                <div>основного долга</div>
                            </template>
                            <template #cell.mainDebtPaid="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.sum_osn_fact }}
                                </div>
                            </template>
                            <template #head.percent>
                                <div>Дата погашения</div>
                                <div>процентов</div>
                            </template>
                            <template #cell.percent="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.date_perc || "-" }}
                                </div>
                            </template>
                            <template #head.percentSumm>
                                <div>Сумма погашения</div>
                                <div>процентов</div>
                            </template>
                            <template #cell.percentSumm="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.sum_perc }}
                                </div>
                            </template>
                            <template #head.percentPaid>
                                <div>Сумма оплачено</div>
                                <div>процентов</div>
                            </template>
                            <template #cell.percentPaid="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else>
                                    {{ item.sum_perc_fact }}
                                </div>
                            </template>
                            <template #head.mainDebtPrediction>
                                <div>прогноз погашения</div>
                                <div>основного долга</div>
                            </template>
                            <template #cell.mainDebtPrediction="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <template v-else>
                                    {{ item.sum_osn_preditction }}
                                </template>
                            </template>
                            <template #head.nextMonthAmount>
                                <div>сумма перешедшая</div>
                                <div>на след месяц</div>
                            </template>
                            <template #cell.nextMonthAmount="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <div v-else style="color: #ff2e00">
                                    {{ item.next_month_dept }}
                                </div>
                            </template>
                            <template #cell.total="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <template v-else>
                                    {{ item.sum_by_schedule }}
                                </template>
                            </template>
                            <template #cell.remaining="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <template v-else>
                                    {{ item.loan_debt_balance }}
                                </template>
                            </template>
                            <template #head.payedOnTime>
                                <div>оплатил</div>
                                <div>вовремя</div>
                            </template>
                            <template #cell.payedOnTime="{ item }">
                                <template v-if="scheduelFact.loading">
                                    <div
                                        class="skeleton"
                                        :style="{ height: '20px' }"
                                    ></div>
                                </template>
                                <template v-else>
                                    <div
                                        v-if="item.payed_in_time"
                                        style="color: var(--color-success)"
                                    >
                                        Да
                                    </div>
                                    <div
                                        v-else
                                        style="color: var(--color-danger)"
                                    >
                                        Нет
                                    </div>
                                </template>
                            </template>
                        </AppTable>
                    </template>

                    <template #body.assigned-cards>
                        <div class="load-modal__assigned-cards">
                            <div class="load-modal__assigned-cards-heading">
                                Карты
                            </div>
                            <div class="load-modal__assigned-cards-list">
                                <template v-for="(item, i) in assignedCardData">
                                    <hr v-if="i !== 0" />
                                    <LoanModalCardItem
                                        :item="item"
                                        :loading="assignedCard.loading"
                                    />
                                </template>
                            </div>
                        </div>
                    </template>
                </AppTabs>
            </div>
        </div>
    </ModalWrapper>
</template>

<script>
import AppTable from "./partials/AppTable";
import ModalWrapper from "./partials/ModalWrapper";
import ProblemDescriptionItem from "./partials/ProblemDescriptionItem";
import AppDatePicker from "./partials/AppDatePicker";
import AppButton from "./partials/AppButton";
import PageLoader from "./partials/PageLoader";
import AppTabs from "./partials/AppTabs";
import ListUtils from "../mixins/ListUtils";
import LoanInfoRow from "./modals/partials/LoanInfoRow";
import AppIcon from "./partials/AppIcon";
import LoanMixins from "../mixins/LoanMixins";
import LoanModalCardItem from "./LoanModalCardItem.vue";
import LoanModalHeader from "./modals/partials/LoanModalHeader.vue";

import { get } from "../helpers/api";

export default {
    components: {
        AppIcon,
        LoanInfoRow,
        AppTabs,
        PageLoader,
        AppButton,
        AppDatePicker,
        ProblemDescriptionItem,
        ModalWrapper,
        AppTable,
        LoanModalHeader,
        LoanModalCardItem,
        LoanModalHeader,
    },
    props: {
        modals: {
            type: Object,
        },
        values: {
            type: Object,
        },
        cards: Array,
    },
    mixins: [ListUtils, LoanMixins],
    data() {
        return {
            fullscreenAnim: false,
            fullscreen: false,
            headerActive: false,
            body: "body.",
            serviseToggle: "",
            loanSpinner: "",
            creditTab: "1",
            creditTabs: [
                {
                    name: "Общая информация по кредиту",
                    key: "1",
                },
                {
                    name: "График",
                    key: "3",
                },
                {
                    name: "Погашение факт",
                    key: "schedule-fact",
                },
                {
                    name: "Обеспечение",
                    key: "4",
                },
                {
                    name: "Карты прикрепленные к автосписанию",
                    key: "assigned-cards",
                },
            ],
            accountHistoryHead: [
                [
                    {
                        name: "Номер",
                        key: "numberTrans",
                    },
                    {
                        name: "Сумма дебет",
                        key: "debit",
                    },
                    {
                        name: "Сумма кредит",
                        key: "credit",
                    },
                    {
                        name: "Дата",
                        key: "date",
                    },
                    {
                        name: "Примечание",
                        key: "purpose",
                        width: "35%",
                    },
                ],
            ],
            scheduleHead: [
                [
                    {
                        name: "Дата",
                        key: "repaymentDate",
                    },
                    {
                        name: "ОСНОВНОЙ ДОЛГ",
                        key: "amount",
                    },
                    {
                        name: "ПРОЦЕНТЫ",
                        key: "interestOnTermDebt",
                    },
                    {
                        name: "СУММА ДЛЯ ОПЛАТЫ",
                        key: "recommendedAmount",
                    },
                    {
                        name: "САЛЬДО",
                        key: "saldo",
                    },
                ],
            ],
            guarModel: null,
            guarList: [
                {
                    name: "ID",
                    value: "guarId",
                },
                {
                    name: "Тип",
                    value: "guarType",
                },
                {
                    name: "Валюта",
                    value: "currency",
                    parseValue: this.getCurrency,
                },
                {
                    name: "Сумма",
                    value: "summa",
                },
                {
                    name: "Нотариальный счет",
                    value: "notarialNumber",
                },
                {
                    name: "Дата договора",
                    value: "notarialDate",
                },
                {
                    name: "Название обеспечения",
                    value: "guarName",
                },
                {
                    name: "Владелец",
                    value: "ownerDesc",
                },
                {
                    name: "Паспорт",
                    value: "ownerInn",
                    otherValue: "guarInn",
                },
                {
                    name: "Страховщик",
                    value: "insuranceName",
                },
                {
                    name: "ИНН Страховщика",
                    value: "insuranceInn",
                },
            ],
            scheduelFact: {
                data: [],
                loading: false,

                header: [
                    [
                        {
                            name: "основной долг",
                            key: "mainDebt",
                        },
                        {
                            name: "сумма ОД",
                            key: "mainDebtSumm",
                        },
                        {
                            name: "оплачено ОД",
                            key: "mainDebtPaid",
                        },
                        {
                            name: "прогноз погашения ОД",
                            key: "mainDebtPrediction",
                        },
                        {
                            name: "проценты",
                            key: "percent",
                        },
                        {
                            name: "сумма",
                            key: "percentSumm",
                        },
                        {
                            name: "оплачено",
                            key: "percentPaid",
                        },
                        {
                            name: "сумма перешедшая на след месяц",
                            key: "nextMonthAmount",
                        },
                        {
                            name: "всего",
                            key: "total",
                        },
                        {
                            name: "остаток",
                            key: "remaining",
                        },
                        {
                            name: "оплатил вовремя",
                            key: "payedOnTime",
                        },
                    ],
                ],
            },

            assignedCard: {
                loading: false,
                data: [],
            },
        };
    },
    computed: {
        modalCssClass() {
            return {
                fullscreen: this.fullscreen,
            };
        },

        loanCardInternal() {
            return this.findCard(this.modals.loan.model.loanCardNumber);
        },

        cardInternal() {
            return this.findCard(this.modals.loan.model.cardNumber);
        },

        currency() {
            return this.getCurrency(this.modals.loan.model.currency);
        },

        totalToday() {
            const aAccountsIds = ["3", "5", "7", "46"];

            if (
                ["24", "34", "59"].includes(this.modals.loan.model.creditType)
            ) {
                aAccountsIds.push("22");
            }

            let iTotal = 0;
            aAccountsIds.forEach((item) => {
                const sAmount = this.values.getAccountValueById(item) + "";
                iTotal += parseInt(sAmount.replace(/\s/g, ""));
            });

            return iTotal;
        },

        totalMax() {
            const aAccountsIds = ["1", "118"];
            if (this.modals.loan.model.creditType === "54") {
                aAccountsIds.splice(1, 1);
            }

            let iTotal = 0;

            aAccountsIds.forEach((item) => {
                const sAmount = this.values.getAccountValueById(item) + "";
                iTotal += parseInt(sAmount.replace(/\s/g, ""));
            });

            iTotal += this.totalToday;

            return iTotal;
        },

        skeletonData() {
            const list = [];
            for (let index = 0; index < 10; index++) {
                list.push({ id: index });
            }
            return list;
        },

        scheduleFactData() {
            if (this.scheduelFact.loading) {
                return this.skeletonData;
            }

            return this.scheduelFact.data;
        },

        scheduleData() {
            if (this.loanSpinner) {
                return this.skeletonData;
            }

            return this.modals.loan.schedule;
        },

        loanGuarData() {
            if (this.loanSpinner) {
                return this.skeletonData;
            }

            return this.modals.loan.guar;
        },

        assignedCardData() {
            if (this.assignedCard.loading) {
                return this.skeletonData;
            }
            return this.assignedCard.data;
        },

        appTabsCssClass() {
            return {
                hidden: !!this.modals.loan.account.info,
            };
        },

        loanDetailsTableData() {
            if (this.$parent.spinner) {
                const list = [];
                for (let index = 0; index < 10; index++) {
                    list.push({ id: index });
                }
                return list;
            }

            return this.$parent.loanHistoryPage;
        },
    },
    // mounted() {
    //     window.addEventListener('keydown', this.openDetailShortKey);
    // },
    // beforeDestroy() {
    //     window.removeEventListener('keydown', this.openDetailShortKey);
    // },
    watch: {
        "modals.loan.guar": {
            immediate: true,
            handler(guar) {
                if (guar.length) {
                    this.guarModel = guar[0].guarType;
                }
            },
        },
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        hiddenCard(cardNumber) {
            return `${cardNumber.slice(0, 6)}******${cardNumber.slice(12)}`;
        },
        findCard(cardNumber) {
            const cards = this.cards || [];
            return cards.find((card) => card.cardNumber === cardNumber);
        },
        onLoanCardClick() {
            if (!this.loanCardInternal) return;
            this.$emit("card-click", this.loanCardInternal);
        },
        onCardClick() {
            if (!this.cardInternal) return;
            this.$refs.modal.close();
            this.$emit("card-click", this.cardInternal);
        },
        scrollToCurrentYear() {
            this.getToScrollId(this.modals.loan.schedule, "repaymentDate");
        },
        onTabChange(ev) {
            this.$parent.modalTabChanged("loan", ev);
            this.headerActive = false;
            if (ev == 3) {
                if (this.modals.loan.schedule.length) {
                    this.scrollToCurrentYear();
                } else {
                    const unwatch = this.$watch(
                        () => this.modals.loan.schedule,
                        () => {
                            this.scrollToCurrentYear();
                            unwatch();
                        }
                    );
                }
            }

            if (ev === "schedule-fact") {
                this.loadScheduleFact();
            }

            if (ev === "assigned-cards") {
                this.loadAssignedCard();
            }
        },
        onScroll(ev) {
            if (this.creditTab == 3) {
                this.headerActive = ev.target.scrollTop > 164;
            }
        },
        getValue(item, guar) {
            const value = guar[item.otherValue]
                ? guar[item.otherValue]
                : guar[item.value];
            return item.parseValue ? item.parseValue(value) : value;
        },
        openDetailShortKey(e) {
            if (e.ctrlKey && e.code === "KeyK") {
                this.$emit("detail");
            }
        },
        changeGrafik(event, type) {
            if (type === "loanSchedule") {
                this.$parent.loanSchedule = event.target.value;
                if (
                    this.$parent.loanSchedulePagition.length <
                    event.target.value
                ) {
                    event.target.value =
                        this.$parent.loanSchedulePagition.length;
                    this.$parent.loanSchedule =
                        this.$parent.loanSchedulePagition.length;
                }
            }
            if (type === "loanHistoryAccountPage") {
                this.$parent.loanHistoryAccountPage = event.target.value;
                if (this.$parent.loanHistoryPage.length < event.target.value) {
                    event.target.value = this.$parent.loanHistoryPage.length;
                    this.$parent.loanHistoryAccountPage =
                        this.$parent.loanHistoryPage.length;
                }
            }
        },

        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
        },

        loadScheduleFact() {
            if (this.scheduelFact.data.length > 0) return;

            this.scheduelFact.loading = true;
            get(`/aibs/loan-pay-schedule/${this.modals.loan.id}`).then(
                (response) => {
                    if (response.data.success) {
                        this.scheduelFact.data = response.data.data;
                    }

                    this.scheduelFact.loading = false;
                }
            );
        },

        loadAssignedCard() {
            if (this.assignedCard.data.length > 0) return;

            this.assignedCard.loading = true;
            get(`/aibs/loan-auto-payment-cards/${this.modals.loan.id}`).then(
                (response) => {
                    if (response.data.success) {
                        this.assignedCard.data = response.data.data;
                    }

                    this.assignedCard.loading = false;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.card_dropdown {
    min-height: 400px;
}

.servise_toggle {
    &.active {
        color: var(--color-black) !important;
    }
}

.my_table {
    overflow: scroll;

    .purpose_text {
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;
        max-width: 100%;
        white-space: pre-wrap !important;
    }

    .last_child {
        padding-left: 20px;
    }
}

.form-control:disabled {
    background: unset !important;
}

.card_modal {
    .ant-modal-body {
        padding: 0;
    }

    .modal_header {
        padding: 32px 64px;
    }
}

.agrement {
    span {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray-lighten);

        &.active {
            color: var(--color-primary);
        }
    }

    img {
        margin-right: 18px;
        display: inline-block;
    }
}

.page_info {
    margin-right: 33px;

    p {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray);

        span {
            font-size: 16px;
            line-height: 19px;
            color: var(--color-gray);
        }
    }
}

.modal_table_scroll {
    width: 90vw;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    tbody {
        display: flex;
        flex-direction: column;
        width: 90vw;

        tr {
            width: 90vw;
            display: flex;
            align-items: center;
            min-height: 48px;
            height: auto !important;

            td {
                width: 12%;

                &:first-child {
                    padding-left: 64px !important;
                }

                &:last-child {
                    padding-right: 64px !important;
                }
            }
        }
    }
}

thead {
    display: flex;
    width: 90vw;

    tr {
        width: 90vw;
        display: flex;
        align-items: center;

        th {
            width: 12%;

            &:first-child {
                padding-left: 64px !important;
            }

            &:last-child {
                padding-right: 64px !important;
            }
        }
    }
}

.tab_span {
    &.last {
        &:before {
            display: none;
        }
    }
}

.is_error {
    p {
        font-size: 20px;
    }
}

.clickable_account {
    cursor: pointer;
    font-weight: bold !important;

    &:hover * {
        color: var(--color-primary-dark) !important;
    }
}

.loan-modal__block {
    padding: 24px 34px;
    background: #edf2ff;

    align-items: center;
    column-gap: 72px;
    row-gap: 24px;

    margin: 0 0 32px;
    border-radius: 8px;

    & > * {
        margin: 0;
    }
}

.loan-modal__title {
    margin: 0 0 24px;
    padding: 0;

    border-top: none;
    font-size: 22px;
}
</style>

<style lang="scss">
.loan-modal {
    --modal-window-padding-top: 0;
    --modal-window-padding-sides: 0;
    --modal-window-padding-bottom: 36px;

    transition: all 0.3s ease-in-out;
    align-items: center;
    justify-content: center;

    .app-tabs__body {
        height: unset !important;
    }

    &.fullscreen {
        --modal-window-width: 100%;
        --modal-window-max-width: 100%;
        --modal-window-height: 100%;
        --modal-window-radius: 0;

        --modal-offset-top: 0;
        --modal-offset-sides: 0;
        --modal-offset-bottom: 0;
        --window-margin: 0;

        .loan-modal-table {
            height: calc(100vh - 270px);
        }

        .loan-modal-account-table {
            height: calc(100vh - 420px);
        }

        .load-modal__assigned-cards {
            height: unset;
        }
    }

    & > div {
        transition: all 0.3s ease-in-out;
    }
}

.loan-modal-table {
    height: 460px;
}

.loan-modal-account-table {
    height: 340px;
}

.loan-modal__controls {
    padding: 16px 16px 0;

    display: flex;
    justify-content: flex-end;
    gap: 24px;

    & > * {
        cursor: pointer;
    }
}

.loan-modal__tabs-body {
    min-height: 531px;

    &.hidden {
        .app-tabs__header {
            display: none;
        }
    }
}

.loan-modal__ensuring {
    padding: 24px 42px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

.loan-modal__ensuring-item {
    padding: 24px 0 0;
}

.load-modal__assigned-cards {
    padding: 0 42px;
    overflow-y: auto;
    height: 460px;
}

.load-modal__assigned-cards-heading {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 24px;
}

.load-modal__assigned-cards-list {
    border-radius: 16px;
    border: 1px solid #e1e1e1;

    hr {
        margin: 0;
        border-color: #e1e1e1;
    }
}

.loan-modal__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    img {
        width: 128px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }
}
</style>
