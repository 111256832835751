<script>
import { translationExists } from "@/mixins/local/translationExists.mixin";

const status = ["common.close", "common.atWork", "common.sentMessage"];
const outboundStatuses = [
  "common.thirdParty",
  "common.clientAsksForReCall",
  "common.interestedClient",
  "common.notInterestedClient",
];
export const statusColors = ["red", "yellow", "green"];

export default {
  name: "ProblemTag",
  props: {
    status: Number,
    type: String,
    outbound: Boolean
  },
  computed: {
    theme() {
      const color = statusColors[this.status - 1];
      return `status-button--theme-${color || "red"}`;
    },
    text() {
        if (this.outbound) {
            return outboundStatuses[this.status - 1] ?? this.$t("common.unknownStatus");
        }
      return status[this.status - 1] ?? this.$t("common.unknownStatus");
    },
  },
  mixins: [translationExists],
  render(h) {
    const className = [
      "status-button",
      this.theme,
      this.type ? `status-button--type-${this.type}` : "",
    ];

    return h("div", { class: className }, [
      this.getTranslationOrKey(this.text),
    ]);
  },
};
</script>
