class MoneyFormatter {
  private formatter: Intl.NumberFormat;

  constructor() {
    this.formatter = new Intl.NumberFormat("ru-RU", { style: "decimal" });
  }

  moneyFormat(value: number | string, convertToNumber: boolean = false): string {
    if (typeof value !== "number" && !convertToNumber) {
      return value;
    }

    const convertedNumber: number = typeof value === 'number' ? value : Number(value);
    return this.formatter.format(convertedNumber);
  }
}

export default MoneyFormatter;
