<template>
    <div class="problem-description-item" :class="{
            'problem-description-item--nowrap': nowrap,
            'problem-description-item--no-icon': !icon && !image,
            ['problem-description-item--theme-' + theme]: theme,
            ['problem-description-item--type-' + type]: type,
            'problem-description-item--clickable': clickable,
            'problem-description-item--overflow': overflow,
            'problem-description-item--loading': loading,
    }">
        <div class="problem-description-item__icon-wrap">
            <img v-if="image" class="problem-description-item__icon" :src="require('../../assets/img/' +  image)" alt=""/>
            <AppIcon v-if="icon" class="problem-description-item__icon" :icon="icon"/>
            <div class="problem-description-item__icon-additional">
                <slot name="icon-additional"/>
            </div>
        </div>
        <div class="problem-description-item__title">
            <slot name="name">{{ $t(name) }}</slot>
            <AppIcon v-if="theme === 'warning-icon'" class="problem-description-item__warning-icon" icon="warning-circle"/>
        </div>
        <div class="problem-description-item__value" @click="onValueClick">
            <slot name="value">{{ loading ? loadingText : displayValue }}</slot>
            <button v-if="copyIcon" class="problem-description-item__value-btn" @click="onCopy">
                <AppIcon class="problem-description-item__value-btn-icon" icon="copy-icon" />
            </button>
        </div>
    </div>
</template>
<script>
import AppIcon from "./AppIcon";
export default {
    name: 'ProblemDescriptionItem',
    components: {AppIcon},
    props: {
        name: [String, Number],
        value: [String, Number],
        icon: String,
        image: String,
        theme: String,
        nowrap: Boolean,
        type: String,
        clickable: Boolean,
        overflow: Boolean,
        loading: Boolean,
        right: Boolean,
        loadingText: {
            type: String,
            default: 'Ждем, ждем, а потом ждем'
        },
        copyIcon: Boolean,
        maskCard: Boolean,
    },
    methods: {
        onValueClick() {
            if(this.loading || !this.clickable) return;
            this.$emit('click-value')
        },

        onCopy() {
            try {
                window.navigator.clipboard.writeText(this.value)
                this.$store.commit("notifications/add", {
                    type: "success",
                    message: this.$t("common.cardNumberCopied")
                })
            } catch (error) {
                this.$store.commit("notifications/add", {
                    type: "error",
                    message: this.$t("common.cardNumberNotCopied")
                })
            }
        }
    },

    computed: {
        displayValue() {
            if (this.maskCard && /^\d+$/.test(this.value.toString())) {
                return this.value.toString().replace(/\d{4}?(?=...)/g, '$& ')
            }
            return this.value
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.problem-description-item {
    $self: &;
    position: relative;
    padding-left: 36px;
    &__icon-wrap {
        position: absolute;
        margin-top: -12px;
        top: 50%;
        left: 0;
        width: 24px;
        height: 24px;
    }
    &__icon {
        display: block;
        width: 100%;
        height: 100%;
        color: var(--color-gray-lighten);
    }
    &__warning-icon {
        width: 16px;
        height: 16px;
        margin-left: 5px;
        margin-top: -2px;
    }
    &__icon-additional {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &__title {
        margin: 0 0 4px;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-gray-lighten);
        white-space: nowrap;
    }
    &__value {
        font-family: $font-secondary;
        margin: 0;
        font-size: 16px;
        line-height: 19px;
        min-height: 20px;

        display: flex;
        align-items: center;
        gap: 12px;
    }
    &--right {
        padding-left: 0;
        padding-right: 36px;
        #{$self}__icon-wrap {
            left: auto;
            right: 0;
        }
    }
    &--loading {
        #{$self}__value:after {
            display: inline-block;
            animation: preloaderAnimation 1s infinite;
            content: '...';
            width: 16px;
        }
    }
    &--type-card {
        font-family: $font-secondary;
        #{$self}__title {
            margin-bottom: 6px;
            line-height: 20px;
            color: var(--color-gray-lighten);
        }
        #{$self}__value {
            font-weight: 500;
            line-height: 20px;
            color: var(--color-gray);
        }
    }
    &--type-phone {
        padding-left: 56px;
        #{$self}__icon-wrap {
            margin-top: -22px;
            width: 44px;
            height: 44px;
            border-radius: 100%;
            background-color: var(--color-border-lighten);
        }
        #{$self}__icon {
            width: 52px;
            height: 52px;
            margin-left: -4px;
            margin-top: -9px;
        }
        #{$self}__title {
            margin-bottom: 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        #{$self}__value {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
    }
    &--type-user-phone {
        padding-left: 54px;
        #{$self}__icon-wrap {
            margin-top: -21px;
            width: 42px;
            height: 42px;
            border-radius: 100%;
            background-color: var(--color-border-lighten);
        }
        #{$self}__icon {
            margin-top: 9px;
            margin-left: 9px;
            width: 24px;
            height: 24px;
        }
        #{$self}__title {
            margin: 0 0 2px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: var(--color-primary-dark);
        }
        #{$self}__value {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        #{$self}__value,
        #{$self}__title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        &#{$self}--theme-success {
            #{$self}__icon-wrap {
                background-color: var(--color-white-greenish);
            }
            #{$self}__icon {
                color: var(--color-success);
            }
            #{$self}__value {
                color: inherit;
            }
        }
        &#{$self}--theme-danger {
            #{$self}__icon-wrap {
                background-color: var(--color-white-reddish);
            }
            #{$self}__icon {
                color: var(--color-danger);
            }
            #{$self}__value {
                color: inherit;
            }
        }
    }
    &--type-card-heading {
        #{$self}__title {
            margin-bottom: 0;
            font-family: $font-secondary;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #949494;
        }
        #{$self}__value {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
        }
    }
    &--no-icon {
        padding-left: 0;
        #{$self}__icon {
            display: none;
        }
    }
    &--nowrap {
        #{$self}__value {
            white-space: nowrap;
        }
    }
    &--overflow {
        #{$self}__value {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 500px;
        }
    }
    &--theme-main {
        #{$self}__title {
            color: var(--color-primary);
        }
    }
    &--theme-success {
        #{$self}__value {
            color: var(--color-success);
        }
    }
    &--theme-danger {
        #{$self}__value {
            color: var(--color-danger);
        }
    }
    &--theme-full-danger {
        #{$self}__title,
        #{$self}__icon,
        #{$self}__value {
            color: var(--color-danger);
        }
    }
    &--theme-overtime {
        #{$self}__title,
        #{$self}__icon,
        #{$self}__value {
            color: var(--color-overtime);
        }
    }
    &--theme-warning-icon {
        #{$self}__title {
            color: #F2B600;
        }
    }
    &--clickable {
        #{$self}__value {
            cursor: pointer;
        }
        #{$self}__value:hover {
            color: var(--color-primary);
        }
    }
}

.problem-description-item__value-btn {
    border: 0;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.problem-description-item__value-btn-icon [stroke] {
    stroke: grey;
}
</style>
