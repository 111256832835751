export const dateToDateTime = (dateValue: any) => {
    if (!dateValue) return '-'

    const date = new Date(dateValue)
    const dateIntl = new Intl.DateTimeFormat('ru-RU', {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    }).format(date)

    const finishDate = dateIntl.replace(',', '')

    return finishDate
}
export const parsedDateAndTimeFromString = (sDate: string) => {
    const date = new Date(new Date(sDate).getTime() * 1000);
    return date.toLocaleDateString("ru-RU", {
        "year": "numeric",
        "month": "numeric",
        "day": "numeric",
        "hour": "numeric",
        "minute": "numeric"
    });
}
export const formatTime = (sDate: string) => {
    const date = new Date(sDate);
    return date.toLocaleTimeString("ru-RU", {
        "hour": "numeric",
        "minute": "numeric"
    });
}

export const parseDate = (val: string) => {
    const date = new Date(val);
    return date.toLocaleString("ru");
}

export class DateFormatter {
    private readonly date: string;

    constructor(date: string) {
        this.date = date;
    }

    format(formatString: string): string {
        const date = new Date(this.date);

        if (isNaN(date.getTime())) {
            return this.date;
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        // Заменяем плейсхолдеры в формате на соответствующие значения
        const formattedDate = formatString
            .replace('DD', day)
            .replace('MM', month)
            .replace('YYYY', year);

        return formattedDate;
    }
}
