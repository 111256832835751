<template>
    <div
        class="home-page"
        ref="wrap"
        :style="{
            '--home-connection-error-height':
                connectionError && activeChat ? '54px' : '0px',
            '--home-subscribe-height': hasSubscribe ? '54px' : '0px',
        }"
    >
        <ErrorImage
            v-if="connectionError && !activeChat"
            @reload-click="reloadPage"
        />
        <template v-else>
            <transition name="slide-calls">
                <div v-show="!isChat" class="calls-view" ref="calls">
                    <div class="calls-view__phone-control">
                        <div
                            v-if="isCallOvertime"
                            class="error-panel error-panel--theme-overtime"
                        >
                            <img
                                class="error-panel__img"
                                src="@/assets/img/error-gif.gif"
                                alt=""
                            />
                            {{
                                $t(
                                    "components.common.itIsLooksLikeYouHaveAnUnfinishedTask"
                                )
                            }}
                            🤔
                            {{
                                $t(
                                    "components.common.checkAllFieldsAndCloseTheTask"
                                )
                            }}
                        </div>
                        <ShiftLayoutAppearAnimation height="60" :visible="true">
                            <HomeTabs
                                :selected-view="card_stat"
                                :user-data="clientFormInfo"
                                :show-alarm="false"
                                @input="changeCard"
                            />
                        </ShiftLayoutAppearAnimation>
                    </div>

                    <div>
                        <PageLoader v-if="isLoading" />
                        <AboutClient
                            ref="aboutClientRef"
                            v-else-if="card_stat === 1"
                            :commands="commandsList"
                            :call-data="customerData"
                            :client-form-info="clientFormInfo"
                            is-view-client-page
                            disable-hints
                            @open:modal="openModal"
                            @update:customer="updateCustomer"
                            @reset:client="resetClientInfo"
                        />
                        <HistoryProblems
                            v-else-if="card_stat == 2"
                            :call-data="customerData"
                            :client-form-info="clientFormInfo"
                            :is-view-client-page="true"
                        />
                        <OutgoingHistory
                            v-else-if="
                                card_stat === 6 && customerData.selectedCustomer
                            "
                            :customer-id="
                                customerData.selectedCustomer.toString()
                            "
                            :call-data="customerData"
                        />
                        <MybankProduct
                            v-else-if="card_stat == 4"
                            :processing-cards="processingCards"
                            :user-data="userData"
                            :client-form-info="clientFormInfo"
                            @process-cards="onProcessCards"
                            @process-cards-delay="onProcessCardsDelay"
                        />
                        <MonitoringProducts
                            v-else-if="card_stat == 5"
                            :card-stat="card_stat"
                            :common-data="commonData"
                            :client-form-info="clientFormInfo"
                            :user-data="userData"
                        />
                        <AutoDebit
                            v-else-if="card_stat === 7"
                            :processing-cards="processingCards"
                            :client="clientFormInfo"
                        />
                    </div>
                </div>
            </transition>
        </template>
        <PrevCall ref="prevCall" />
        <DontHoldModal
            v-if="modals.dontHold"
            @close-modal="modals.dontHold = false"
        />

        <CreditProductModal
            v-if="modals.creditProduct"
            :calculation-type="calculationType"
            :product-info="productInfo"
            :product-table="calculationTable"
            :call-data="customerCallModel"
            :slide="slide"
            full-screen-fill
            @cancel:form="closeModal"
            @close-modal="closeModal"
            @submit:credit-product="submitCreditProduct($event)"
            @submit:deposit-product="submitDepositProduct($event)"
            @reset:step="slide = 'default'"
        />
    </div>
</template>
<script>
import HistoryProblems from "@/components/historyProblems.vue";
import OutgoingHistory from "@/components/outgoing-history.vue";
import MybankProduct from "@/components/MybankProduct.vue";
import { mapState } from "vuex";
import PrevCall from "@/components/PrevCall.vue";
import DontHoldModal from "@/components/modals/DontHoldModal";
import PageLoader from "@/components/partials/PageLoader";
import ErrorImage from "@/components/partials/ErrorImage";
import AboutClient from "@/components/admin/Customer/Update/AboutClient.vue";
import ChatController, { CAN_CHAT } from "@/services/ChatController";
import { get, post, put } from "@/helpers/api";
import { EventBus } from "@/helpers/eventBus";
import ShiftLayoutAppearAnimation from "@/components/partials/animation/ShiftLayoutAppearAnimation";
import ListUtils from "@/mixins/ListUtils";
import MonitoringProducts from "@/components/MonitoringProducts.vue";
import AutoDebit from "@/components/client-base/auto-debit.vue";
import HomeTabs from "@/components/partials/home/HomeTabs";
import FullScreenModalMixin from "@/mixins/FullScreenModalMixin";
import CreditProductModal from "@/components/modals/CreditProductModal.vue";
import app from "@/main";
import { CallRoom } from "@/services/chat/ChatModel";
import { getCustomerByPhone } from "@/components/admin/Customer/Helpers/customerHelpers";
import { ClientBaseCustomerCommands } from "@/components/admin/Customer/Helpers/CustomerController";

export default {
    name: "UpdateCustomer",
    components: {
        CreditProductModal,
        HomeTabs,
        MonitoringProducts,
        ShiftLayoutAppearAnimation,
        AboutClient,
        ErrorImage,
        PageLoader,
        DontHoldModal,
        HistoryProblems,
        MybankProduct,
        PrevCall,
        OutgoingHistory,
        AutoDebit,
    },
    mixins: [ListUtils, FullScreenModalMixin],
    data() {
        return {
            clientPhoneNumber: "",
            modals: {
                dontHold: false,
                creditProduct: false,
            },
            processingCards: {},
            productInfo: {},
            calculationTable: {},
            clientFormInfo: {},
            clientFormInfoInitial: {},
            calculationType: "",
            slide: "default",
        };
    },
    computed: {
        ...mapState([
            "isLoading",
            "isCalling",
            "card_stat",
            "connectionError",
            "canChat",
            "isChat",
            "isIncomingCall",
            "isCallOvertime",
        ]),
        ...mapState("callData", ["customerCallModel"]),
        userData() {
            try {
                return (
                    this.customerCallModel.customers.find(
                        (c) => c.id === this.customerCallModel.selectedCustomer
                    ) || {}
                );
            } catch (e) {
                return {};
            }
        },
        hasSubscribe() {
            return !!this.$store.state.teamMessages.messages.length;
        },
        activeChat() {
            return this.$store.state.chat.activeChatId;
        },
        incomingChat() {
            return this.$store.state.chat.incomingChat;
        },
        lang() {
            if (!this.customerCallModel) return "";
            return this.customerCallModel.lang;
        },
        commonData() {
            if (!this.customerCallModel) {
                return {
                    lang: "",
                    phone: "",
                };
            }
            return {
                lang: this.customerCallModel.lang,
                phone:
                    this.customerCallModel.selectedPhone ||
                    this.customerCallModel.data.phone,
            };
        },
        commandsList() {
            return new ClientBaseCustomerCommands(
                this.$store.state.callData.customerCallModel
            );
        },
        customerData() {
            return this.$store.state.callData?.customerCallModel ?? {};
        },
    },
    watch: {
        $route() {
            this.$destroy();
            this.$mount();
        },
        modalWindowFullscreen(value) {
            const calls = this.$refs.calls;
            if (value) {
                calls.classList.add("calls-view--fullscreen");
            } else {
                setTimeout(() => {
                    calls.classList.remove("calls-view--fullscreen");
                }, 250);
            }
        },
    },
    methods: {
        closeModal() {
            if (this.modalWindowFullscreen) {
                this.modalWindowFullscreenToggle();
            }

            this.slide = "default";
            this.modals.creditProduct = false;
        },
        openModal(id) {
            get(`/products/${id}`)
                .then((res) => {
                    if (res.data.success) {
                        this.productInfo = res.data.data;

                        this.modals.creditProduct = true;
                    }
                })
                .catch((_) => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
        submitCreditProduct(form) {
            post("/calculator/credit", form)
                .then((res) => {
                    this.calculationType = "credit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
        submitDepositProduct(form) {
            post("/calculator/deposit", form)
                .then((res) => {
                    this.calculationType = "deposit";
                    this.calculationTable = res.data.data;
                    this.slide = "results";
                })
                .catch(() => {
                    this.$store.commit(
                        "notifications/error",
                        "errors.somethingWentWrong"
                    );
                });
        },
        setFormValuesFromRoute() {
            const { id } = this.$route.params;

            if (id) {
                this.loadCustomer(parseInt(id));
            }
        },
        async loadCustomer(id) {
            try {
                this.is_loading = true;

                const response = await get(`/customers/${id}`);
                if (response.data.success) {
                    this.clientFormInfo = response.data.data;
                    if (this.clientFormInfo.client_type === 0) {
                        this.clientFormInfo.client_type = null;
                    }

                    const copy = Object.assign({}, this.clientFormInfo);
                    if (this.clientFormInfo.customer_phones) {
                        copy.customer_phones =
                            this.clientFormInfo.customer_phones.map((el) =>
                                Object.assign({}, el)
                            );
                    }
                    this.clientFormInfoInitial = copy;

                    // исправляем баг с неправильно приклееным 0 в номере телефона
                    const phone = this.fixPhoneNumber(
                        response.data.data.customer_phones[0]?.phone
                    );
                    const callData = {
                        phone,
                        call_time: "",
                        called_number: "1180",
                        language: this.$i18n.locale,
                        isOut: false,
                    };
                    this.$store.commit(
                        "callData/setCustomerCall",
                        new CallRoom(callData)
                    );
                    getCustomerByPhone(
                        "",
                        this.customerCallModel,
                        this.$route.params.id
                    ).then(() => {
                        this.$store.commit("getLoading", false);
                    });
                }
            } catch (e) {
                this.$router.push("/pages/404");
            } finally {
                this.is_loading = false;
            }
        },
        updateCustomer() {
            put("/customers/" + this.clientFormInfo.id, this.clientFormInfo)
                .then((response) => {
                    if (response.data.success) {
                        this.clientFormInfo = response.data.data;
                        app.$store.commit("notifications/error", {
                            title: "requestsStatuses.success.index",
                            text: "customer.customerSuccessUpdated",
                            type: "success",
                        });
                        this.loadCustomer(this.clientFormInfo.id);
                    } else {
                        throw new Error("Error on update customer");
                    }
                })
                .catch(() => {
                    app.$store.commit("notifications/error", {
                        title: "requestsStatuses.failed.index",
                        text: "errors.onCustomerUpdate",
                        type: "error",
                    });
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$refs.aboutClientRef.updateFormInitialData();
                    }, 10);
                });
        },
        changeLayout() {
            this.$store.commit("changeChatLayout", true);
        },
        checkUserCanChat() {
            this.$store.commit("userCanChat");
        },
        changeCard(value) {
            this.$store.commit("getcard_stat", value);
        },
        reloadPage() {
            document.location.reload();
        },
        clearState() {
            this.$store.commit("clearSearchError");
            this.$store.commit("setSearchEnd");
            this.$store.commit("getcard_stat", 1);
            this.processingCards = {};
        },

        onProcessCardsDelay(cards) {
            cards.forEach((card) => {
                const { cardNumber } = card;
                this.$set(this.processingCards, cardNumber, {
                    ...(this.processingCards[cardNumber] || {}),
                    loading: true,
                });
            });
            this.delay(() => {
                this.onProcessCards(cards);
            }, 10000);
        },
        onProcessCards(cards) {
            cards.forEach((card) => {
                const { cardNumber, type, dateExpiry } = card;

                this.$set(this.processingCards, cardNumber, {
                    ...(this.processingCards[cardNumber] || {}),
                    loading: true,
                });
                get(
                    `/processing/${type.toLowerCase()}?card=${cardNumber}&valid=${dateExpiry}`
                )
                    .then(({ data }) => {
                        if (data.success) {
                            this.$set(this.processingCards, cardNumber, {
                                ...this.processingCards[cardNumber],
                                data: data.data,
                            });
                        } else {
                            throw new Error();
                        }
                    })
                    .catch(() => {
                        this.$set(this.processingCards, cardNumber, {
                            ...this.processingCards[cardNumber],
                            data: {},
                            error: true,
                        });
                    })
                    .finally(() => {
                        this.$set(this.processingCards, cardNumber, {
                            ...this.processingCards[cardNumber],
                            loading: false,
                        });
                    });
            });
        },
        fixPhoneNumber(sPhoneNumber) {
            const phonePrefix = process.env.VUE_APP_CISCO_PHONE_PREFIX ?? "0";

            if (null !== sPhoneNumber.match(/^\+?998\d{9}/i)) {
                sPhoneNumber = sPhoneNumber.replace(/^\+?998/i, "");
            }
            if (
                phonePrefix === "0" &&
                null !== sPhoneNumber.match(/^(?!0)\d{5}0\d{4}$/i)
            ) {
                sPhoneNumber = sPhoneNumber.replace(
                    /^((?!0)\d{5})(0)(\d{4})$/i,
                    "$2$1$3"
                );
            }
            if (sPhoneNumber.length === 9) {
                sPhoneNumber = phonePrefix + sPhoneNumber;
            }

            return sPhoneNumber;
        },
        resetClientInfo() {
            const copy = Object.assign({}, this.clientFormInfoInitial);
            if (this.clientFormInfoInitial.customer_phones) {
                copy.customer_phones =
                    this.clientFormInfoInitial.customer_phones.map((el) =>
                        Object.assign({}, el)
                    );
            }
            this.clientFormInfo = copy;
        },
    },
    mounted() {
        this.clearState();
        this.setFormValuesFromRoute();
        ChatController.on(CAN_CHAT, this.checkUserCanChat);
    },
    beforeRouteLeave(to, from, next) {
        // this.$store.commit("getCall", false);
        // this.$store.commit("setIsCallOvertime", false);
        // this.$store.commit("checkCalling", false);
        this.$store.commit("callData/setCustomerCall", null);
        this.$store.commit("callData/resetAppealFormData");
        this.changeCard(1);
        next();
    },
    beforeDestroy() {
        ChatController.off(CAN_CHAT, this.checkUserCanChat);
        EventBus.$off("call-ended-customers");
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/animation";
:root {
    --error-panel-sides: 64px;
    --error-panel-font: normal 500 18px/21px #{$font-primary};
}
.message {
    padding-top: 32px;
}
.home-page {
    position: relative;
    overflow: hidden;
    height: calc(100vh - var(--header-height) - var(--home-subscribe-height));
    &__error-panel {
        z-index: 5;
        position: sticky;
        top: 0;
    }
}
.calls-view {
    $self: &;
    height: calc(100% - var(--home-connection-error-height));
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    &__phone-control {
        background: var(--color-white-bg);
        z-index: 3;
        position: sticky;
        top: 0;
        width: 100%;
    }
    &--fullscreen {
        > *:not(#{$self}__phone-control) {
            display: none;
        }
        #{$self}__phone-control {
            position: static;
            z-index: auto;
        }
    }
}
.error-panel {
    display: flex;
    align-items: center;
    background: var(--color-danger-dark);
    box-shadow: var(--shadow-panel);
    padding: 0 var(--error-panel-sides);
    height: 54px;
    color: var(--color-white-08);
    font: var(--error-panel-font);
    &__img {
        width: 50px;
        height: 50px;
        margin-right: 12px;
    }
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    &__link {
        cursor: pointer;
        user-select: none;
        text-decoration: underline;
        color: var(--color-white);
        &:hover {
            text-decoration: none;
        }
    }
    &--theme-overtime {
        color: #454142;
        background: var(--color-overtime-light);
    }
}
.slide-chat {
    &-enter-active,
    &-leave-active {
        transition: transform 0.4s;
        position: absolute;
        top: var(--home-connection-error-height);
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-enter-active {
        transition-delay: 0.2s;
    }
    &-enter,
    &-leave-to {
        transform: translateX(100%);
    }
}
.slide-calls {
    &-enter-active,
    &-leave-active {
        transition: transform 0.4s;
        position: absolute;
        top: var(--home-connection-error-height);
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-enter-active {
        transition-delay: 0.2s;
    }
    &-enter,
    &-leave-to {
        transform: translateX(-100%);
    }
}
.home-tabs {
    --active-bg-color: #ed1d24;
    --active-text-color: #454142;
    width: 100%;
    display: flex;
    padding-left: 64px;
    padding-right: 64px;
    background-color: var(--color-white);
    height: 60px;
    padding-bottom: 1px solid var(--color-border-lighten);
    &__item {
        cursor: pointer;
        user-select: none;
        padding: 20px 24px 22px;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: var(--color-gray-lighten);
        position: relative;
        margin-bottom: -1px;
        &:after {
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &:hover {
            color: #454142;
            background-color: #f7f9ff;
        }
        &--active {
            color: var(--active-text-color);
            &:after {
                background: var(--active-bg-color);
            }
        }
    }
    &__incoming {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #454142;
    }
    &__incoming-button {
        margin-left: 12px;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;
        z-index: 0;
        svg {
            margin-left: 4px;
            margin-bottom: -2px;
        }
        &:after {
            content: "";
            z-index: -2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: calc(-50% + -14px);
            margin-top: -25px;
            width: calc(100% + 28px);
            height: 50px;
            border-radius: inherit;
            background-color: inherit;
            opacity: 0.3;
            animation: pulse 1.5s infinite ease-out both;
        }
    }
}
</style>
