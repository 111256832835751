var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.close),expression:"close"}],ref:"select",staticClass:"app-select-a",class:{
    'app-select-a--error': _vm.error,
    'app-select-a--opened': _vm.visible,
    'app-select-a--disabled': _vm.disabled,
    ['app-select-a--theme-' + _vm.theme]: _vm.theme,
    ['app-select-a--size-' + _vm.size]: _vm.size,
    ..._vm.focusClass,
},attrs:{"tabindex":"0"},on:{"keydown":_vm.onKeypress,"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},_vm.focusListeners),[_c('AppInputA',{staticClass:"app-select-a__field",attrs:{"error":_vm.error,"disabled":_vm.disabled,"append":"","placeholder":_vm.getTranslationOrKey(_vm.placeholder),"value":_vm.getTranslationOrKey(_vm.selectedName),"tabindex":_vm.visible ? -1 : 0,"readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.clearable)?_c('div',{staticClass:"app-select-a__icon spacer-button",on:{"click":function($event){$event.stopPropagation();_vm.model = ''}}},[_c('AppIcon',{attrs:{"icon":"close","size":"16"}})],1):_vm._e(),_c('div',{staticClass:"app-select-a__icon app-select-a__icon--arrow"},[_c('AppIcon',{key:_vm.search ? 'a' : 'b',attrs:{"icon":_vm.search ? 'refresh-line' : 'chevron-down'}})],1)]},proxy:true}])}),_c('div',{staticStyle:{"position":"absolute","left":"0","right":"0","top":"0","bottom":"0"}}),(!_vm.disabled)?_c('transition',{attrs:{"name":"dialer"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"menu",staticClass:"app-select-a__menu"},_vm._l((_vm.items),function(item,idx){return _c('li',{key:item[_vm.itemValue],staticClass:"app-select-a__menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.onSelect(item[_vm.itemValue])},"mouseover":function($event){_vm.cursor = idx}}},[_c('div',{staticClass:"app-select-a__item",class:{'app-select-a__item--active': _vm.model === item[_vm.itemValue], 'app-select-a__item--cursor': idx === _vm.cursor}},[_c('AppCheckboxA',{staticClass:"app-select-a__checkbox",attrs:{"val":item[_vm.itemValue],"value":_vm.model,"type":"radio","disabled":"","tag":"div"}}),_vm._v(" "+_vm._s(_vm.getTranslationOrKey(item[_vm.itemName]))+" ")],1)])}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }