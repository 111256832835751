<template>
    <div>
        <img src="/img/zhdun.png" alt="" class="auto-result-image mb-6-a">
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.carName')" value="Kia K5 Classic special"/>
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.priceFromTo')" value="329 900 00 components.common.som / 495 900 000 components.common.som"/>
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.engine')" value="2.0 MPt / 150 л.с., Бензин"/>
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.transmission')" value="Автомат (6АТ)"/>
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.driveUnit')" value="Передний"/>
        <AutoResultTitle class="mb-4-a" :title="$t('components.common.moreDetails')" value="Диск R16, боковая подушка безопасности, камера заднего вида"/>
        <div v-if="showPrice">
            <AppText class="mb-1-a" type="auto-title" theme="gray-1">{{ $t("components.common.minimumCarPrice") }}</AppText>
            <AppText type="auto-price" theme="primary-1">329 900 000 {{ $t("components.common.som") }}</AppText>
        </div>
    </div>
</template>
<script>
import AutoResultTitle from "./AutoResultTitle";
import AppText from "../AppText";
export default {
    name: 'AutoResult',
    components: {AppText, AutoResultTitle},
    props: {
        showPrice: Boolean
    }
}
</script>
<style lang="scss">
.auto-result-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    display: block;
}
</style>
