<template>
    <a-date-picker
        v-model="model"
        v-bind="parsedAttrs"
        v-on="parsedListeners"
        size="large"
        class="app-datepicker"
        :class="{
            ['app-datepicker--theme-' + theme]: theme,
            ['app-datepicker--size-' + size]: size,
            'app-datepicker--error': error
        }"
        :format="format"
        :showToday="showToday"
        :value-format="valueFormatInternal"
        :placeholder="$t(placeholder)"
    />
</template>
<script>
import moment from "moment";

export default {
    name: 'AppDatePicker',
    props: {
        showTime: Boolean,
        theme: String,
        valueFormat: String,
        defaultValue: String,
        size: String,
        error: Boolean,
        placeholder: {
            type: String,
            default: 'common.chooseDate'
        },
        value: {
            type: [String, Number],
            default: ''
        },
        showToday: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        valueFormatInternal() {
            return this.valueFormat ? this.valueFormat : this.showTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
        },
        format() {
            return this.showTime ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'
        },
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
        model: {
            get() {
                return this.value && !this.valueFormat ? moment(this.value) : this.value;
            },
            set(val) {
                this.$emit('input', val || this.defaultValue);
            }
        }
    },
    watch: {
        "$i18n.locale"($val) {
            moment.locale($val)
        }
    },
    created() {
        moment.locale(this.$i18n.locale)
    }
}
</script>
<style lang="scss">
.app-datepicker {
    .ant-input {
        padding-right: 32px !important;
        border-color: var(--color-border);

        &[disabled] {
            background: var(--color-border-lighten) !important;
            color: var(--color-gray-lighten);
        }
    }

    .anticon svg {
        display: block;
    }

    &--theme-transparent {
        .ant-input {
            border: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }

    &--size-extra-large {
        .ant-input {
            height: 48px;
        }
    }

    &--error .ant-input {
        border: 1px solid #e55353;
        animation: error 0.5s 1 both;
    }
}
</style>
