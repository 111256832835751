<template>
    <AppAccordion class="deposit-calculator-accordion" :name="accordionName" icon="calculator">
        <div class="edit-application">
            <div class="edit-application__item edit-application__item--half">
              <AppText type="input-title">{{ $t("components.homePage.depositCalculator.amount") }}</AppText>
              <AppInputA v-model="form.deposit_summa" append style="height: 48px !important; width: 100%" :currency-text="$t('components.common.som')">
                <template #append>
                    <div class="app-range__currency">{{ $t(`common.currencies.${form.deposit_currency}`) }}</div>
                </template>
              </AppInputA>
            </div>
            <div class="edit-application__item edit-application__item--half">
              <AppText type="input-title">{{ $t("components.homePage.depositCalculator.currency") }}</AppText>
              <AppSelectA v-model="form.deposit_currency" class="application-input" size="large" :items="depositDirectoryList" item-name="title" item-value="value"/>
            </div>
            <div class="edit-application__item edit-application__item--half">
              <AppText type="input-title">{{ $t("components.homePage.depositCalculator.rate") }}</AppText>
              <AppRange v-model="form.deposit_percent" :currency-text="$t('components.common.percent')" :min="1" :max="100" :footer="['1%', '100%']"/>
            </div>
            <div class="edit-application__item edit-application__item--half">
              <AppText type="input-title">{{ $t("components.homePage.depositCalculator.term") }}</AppText>
              <AppRange v-model="form.deposit_term" :currency-text="$t('components.common.months')" :min="1" :max="60" :footer="['1', '60']"/>
            </div>

        </div>
        <template #footer-outside>
            <div class="credit-calculator-form">
                <div class="edit-application align-items-center">
                    <div class="edit-application__item edit-application__item--medium">
                        <AppText type="input-title" theme="min" tag="span">
                            {{ $t("components.common.amountOfDeposit") }}:
                        </AppText>
                        <AppText type="calculations-result" class="d-block" theme="min" tag="span">
                            {{ depositTotalAmount }} {{ $t(`common.currencies.${form.deposit_currency}`) }}
                        </AppText>
                    </div>
                    <div class="edit-application__item edit-application__item--medium"></div>
                    <div class="edit-application__item edit-application__item--medium">
                        <div class="block-wrap">
                          <div class="block-wrap__buttons">
                            <AppButton class="block-wrap__buttons-reset" type="button" square theme="white-outline" tabindex="-1" @click="emit('reset:form')">
                                <AppIcon icon="delete"/>
                            </AppButton>
                            <AppButton class="pl-6-a pr-6-a" type="button" theme="gray" tabindex="-1" @click="emit('cancel:form')">
                                {{ $t("components.common.сancel") }}
                            </AppButton>
                            <AppButton type="button" @click="calculate">
                                {{ $t("components.common.calculate") }}
                            </AppButton>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AppAccordion>
</template>
<script>
import AppAccordion from "../AppAccordion";
import AppRange from "../AppRange";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import AppInputA from "@/components/partials/AppInputA.vue";
import MoneyFormatter from "@/helpers/MoneyFormatter";
import AppSelectA from "@/components/partials/AppSelectA.vue";
import AppSelect from "@/components/partials/AppSelect.vue";
import {DEPOSIT_DIRECTORY_LIST} from "@/config/deposits";

export default {
    name: 'DepositCalculator',
    emits: ["update:form", "cancel:form", "reset:form", "submit:form"],
    components: {AppSelect, AppSelectA, AppInputA, AppText, AppIcon, AppButton, AppRange, AppAccordion},
    props: {
      value: {
        type: Object,
        required: true,
        default: () => ({})
      },
      accordionName: {
        type: String,
        required: true,
        default: ""
      }
    },
    model: {
      prop: "value",
      event: "update:form"
    },
    computed: {
        depositDirectoryList() {
          return DEPOSIT_DIRECTORY_LIST;
        },
        repaymentScheme() {
          return {
            differential: 1,
            annuity: 2
          }
        },
        form: {
          get() {
              return this.value;
          },
          set(value) {
              this.$emit('update:form', value);
          }
        },
        moneyFormatter() {
            return new MoneyFormatter();
        },
        depositTotalAmount() {
          const total = Math.round(this.form.deposit_summa);
          return this.moneyFormatter.moneyFormat(total);
        }

    },
    methods: {
        emit(name, value) {
            this.$emit(name, value)
        },
        calculate() {
          if (this.$v.$invalid) {
            this.$store.commit('notifications/error', 'errors.incorrectEnteredFormValues');
            return;
          }
          this.emit('submit:form', this.form)
        },
    },
    validations: {
      form: {
        deposit_summa: {
          required($val) {
            return !!$val && $val >= 100;
          },
        },
        deposit_currency: {
          required($val) {
            return !!$val;
          },
        },
        deposit_percent: {
          required($val) {
            return !!$val;
          },
        },
        deposit_term: {
          required($val) {
            return !!$val && $val > 0;
          },
        }
      }
    },
}
</script>
<style lang="scss">
@import '../../../assets/scss/variables';
.credit-calculator-form {
    padding-left: var(--sides);
    padding-right: var(--sides);
}
.deposit-calculator-accordion {
    --accordion-padding-body-bottom: 44px;
}
.credit-calculator-text {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 600;
    &__bold {
        font-weight: 700;
        margin-left: 12px;
    }
}
.edit-application {
  display: flex;
  flex-wrap: wrap;
  margin-left: -28px;
  margin-top: -24px;
  &__item {
    width: calc(25% - 28px);
    margin-left: 28px;
    margin-top: 24px;
    &--half {
      width: calc(50% - 28px);
    }
    &--medium {
      width: calc(100% / 3 - 28px);
    }
    &--large {
      width: calc(75% - 28px);
    }
    &--full {
      width: calc(100% - 28px);
    }

    &-btns {
      display: flex;
      gap: 16px;
    }

      &-icon {
        margin-right: 12px;
        &:not([fill=none]) {
          fill: none;
        }
        [stroke] {
          stroke: #CACACA;
        }
        [fill]:not([fill=none]) {
          fill: none;
        }

          &.active {
            &:not([fill=none]) {
              fill: #0E70EB;
            }
            [stroke] {
              stroke: #FFFFFF;
            }
            [fill]:not([fill=none]) {
              fill: #0E70EB;
            }
          }
      }
  }
}
</style>
