<template>
    <div>
        <AppText class="mb-0-5-a" type="auto-title" theme="gray-1">{{ title }}</AppText>
        <AppText class="mb-0-a" type="input-title">{{ value }}</AppText>
    </div>
</template>
<script>
import AppText from "../AppText";
export default {
    components: {AppText},
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    }
}
</script>
