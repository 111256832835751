<template>
    <div class="app-select-a" :class="{
        'app-select-a--error': error,
        'app-select-a--opened': visible,
        'app-select-a--disabled': disabled,
        ['app-select-a--theme-' + theme]: theme,
        ['app-select-a--size-' + size]: size,
        ...focusClass,
    }"
         tabindex="0"
         ref="select"
         v-on="focusListeners"
         v-clickaway="close"
         @keydown="onKeypress"
         @click.stop="toggle"
    >
        <AppInputA class="app-select-a__field" :error="error" :disabled="disabled" append :placeholder="getTranslationOrKey(placeholder)" :value="getTranslationOrKey(selectedName)" :tabindex="visible ? -1 : 0" readonly>
            <template #append>
                <div v-if="clearable" class="app-select-a__icon spacer-button" @click.stop="model = ''"><AppIcon icon="close" size="16"/></div>
                <div class="app-select-a__icon app-select-a__icon--arrow"><AppIcon :key="search ? 'a' : 'b'" :icon="search ? 'refresh-line' : 'chevron-down'"/></div>
            </template>
        </AppInputA>
      <div style="position:absolute; left:0; right:0; top:0; bottom:0;"></div>
        <transition v-if="!disabled" name="dialer">
            <ul v-show="visible" class="app-select-a__menu" ref="menu">
                <li v-for="(item, idx) in items" :key="item[itemValue]" class="app-select-a__menu-item" @click.stop="onSelect(item[itemValue])" @mouseover="cursor = idx">
                    <div class="app-select-a__item" :class="{'app-select-a__item--active': model === item[itemValue], 'app-select-a__item--cursor': idx === cursor}">
                        <AppCheckboxA class="app-select-a__checkbox" :val="item[itemValue]" :value="model" type="radio" disabled tag="div"/>
                        {{ getTranslationOrKey(item[itemName]) }}
                    </div>
                </li>
            </ul>
        </transition>
    </div>
</template>
<script>
import AppInputA from "./AppInputA";
import AppCheckboxA, {isSelectedCheckboxItem, selectCheckboxItem} from "./AppCheckboxA";
import clickaway from "../../directives/clickaway";
import AppIcon from "./AppIcon";
import {focusMixin} from "../../mixins/InputMixin";
import {translationExists} from "@/mixins/local/translationExists.mixin";
export default {
    name: 'AppSelectA',
    components: {AppIcon, AppCheckboxA, AppInputA},
    emits: ["input"],
    mixins: [focusMixin, translationExists],
    directives: {
        clickaway
    },
    props: {
        value: {
            type: [String, Number, Array],
            default: ''
        },
        items: {
            type: [Array, Object],
            required: true
        },
        itemValue: {
            type: String,
            default: 'value'
        },
        itemName: {
            type: String,
            default: 'name'
        },
        error: Boolean,
        theme: String,
        disabled: Boolean,
        size: String,
        clearable: Boolean,
        placeholder: String
    },
    model: {
        prop: "value",
        event: "input"
    },
    data() {
        return {
            visible: false,
            cursor: 0,
            search: '',
            searchDelay: null
        }
    },
    computed: {
        selectedName() {
            if(Array.isArray(this.value)) {
                return this.items.filter(item => isSelectedCheckboxItem(this.value, item[this.itemValue])).map(item => item[this.itemName]).join(', ');
            }
            return this.items.find(item => item[this.itemValue] === this.value)?.[this.itemName];
        },
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
        multiple() {
            return Array.isArray(this.model)
        },
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        toggle() {
            if(this.visible) {
                this.close();
            } else {
                this.open();
            }
        },
        close() {
            this.visible = false;
        },
        open() {
            if(this.disabled) return;
            this.visible = true;
            this.selectCursor();
        },
        selectCursor(e, isIndex) {
            if(isIndex) {
                this.cursor = e;
            } else if(e) {
                this.cursor = this.items.findIndex(el => el[this.itemValue] === e);
            } else if(this.model) {
                this.cursor = this.items.findIndex(el => isSelectedCheckboxItem(this.model, el[this.itemValue]));
            }
            if(this.cursor > -1) {
                const elem = this.$refs.menu.querySelectorAll('li')[this.cursor];
                const menu = this.$refs.menu;
                if(elem.offsetTop <= menu.scrollTop) {
                    elem.scrollIntoView();
                } else if(menu.scrollTop + menu.offsetHeight <= elem.offsetTop + elem.offsetHeight) {
                    elem.scrollIntoView({ block: 'end' });
                }
            } else if(this.cursor < 0) {
                this.cursor = 0;
            }
        },
        selectCursorFromSearch(keyboardEvent) {
            const langRegex = /^[a-zа-я0-9,.=+-]$/gi;
            if(keyboardEvent.key.match(langRegex) || keyboardEvent.code === 'Space' && this.search.length) {
                clearTimeout(this.searchDelay);
                this.search += keyboardEvent.key;
                const idx = this.items.findIndex(el => el[this.itemName].toLowerCase().startsWith(this.search.toLowerCase()));
                if(idx > -1) {
                    this.selectCursor(idx, true);
                }
                this.searchDelay = setTimeout(() => {
                    this.search = '';
                }, 500);
                return true;
            }
            return false;
        },
        onSelect(e) {
            if(this.disabled) return;

            if(this.clearable || this.multiple) {
                this.model = e;
            } else if(!isSelectedCheckboxItem(this.model, e)) {
                this.model = selectCheckboxItem(this.model, e);
            }

            if(!this.multiple) {
                this.close();
            }

            this.$nextTick(() => {
                this.selectCursor(e);
            });
        },
        onKeypress(e) {
            if(this.selectCursorFromSearch(e)) { return }

            if(e.code === 'Tab') return this.close();

            if(['Space', 'Enter', 'ArrowUp', 'ArrowDown', 'NumpadEnter'].includes(e.code)) {
                e.preventDefault();
            } else {
                return
            }

            if(e.code === 'ArrowUp' || e.code === 'ArrowLeft') {
                this.cursor--;
            } else if(e.code === 'ArrowDown' || e.code === 'ArrowRight') {
                this.cursor++;
            }

            if(this.cursor < 0) {
                this.cursor = this.items.length - 1;
            } else if(this.cursor === this.items.length) {
                this.cursor = 0;
            }

            if(e.code === 'Space' || e.code === 'Enter' || e.code === 'NumpadEnter' || !this.multiple) {
                this.onSelect(this.items[this.cursor][this.itemValue]);
            }
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.app-select-a {
    $self: &;
    position: relative;
    border-radius: 4px;
    outline: none !important;
    cursor: pointer;
    &__field {
        width: 100%;
        cursor: pointer;
        * {
            cursor: pointer;
        }
    }
    &__menu {
        z-index: 3;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        right: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        max-height: 364px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__item {
        width: 100%;
        padding: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #212121;
        font-family: $font-secondary;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 52px;
        cursor: pointer;
        user-select: none;
        background: linear-gradient(to right, #EDF2FF, #EDF2FF) no-repeat;
        background-position: -100vw 0;
        transition: background-position .25s;
        &--cursor {
            background-position: 100%;
        }
    }
    &__checkbox {
        pointer-events: none;
        display: inline-block;
        margin-right: 8px;
        flex-shrink: 0;
    }
    &__icon {
        z-index: 1;
        color: #949494;
        width: 16px;
        height: 16px;
        transition: transform .25s;
        margin-left: 8px;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__clicker {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    &--opened {
        #{$self}__icon--arrow {
            transform: rotate(180deg);
        }
    }
    &--disabled {
        #{$self}__field {
            cursor: default;
            * {
                cursor: default;
            }
        }
    }
}
</style>
