import { getUserDataFromCallData, parseMessageFromHistory } from "../helpers/chats"
import { get } from "../helpers/api"

const callApplicationMixix = {
  data() {
    return {
      callApplication: null,
      callApplicationMessages: [],
      callApplicationEdited: false,
    };
  },
  methods: {
    backProblems() {
      this.callApplication = null;
      if (this.callApplicationEdited && this.loadApplications) {
        this.loadApplications();
        this.callApplicationEdited = false;
      }
    },

    problemAction($event) {
      this.$emit("problem-action", $event);
      this.callApplicationEdited = true;
    },

    openApplication($event) {
      const id = $event.item.id;
      this.callApplication = this.list.find((application) => application.id === id);
      this.loadApplicationMessages(id);
    },

    loadApplicationMessages(applicationId) {
      this.callApplicationMessages = [];
      this.loading = true;
      const username = this.userData.username || this.callData.data.userName;
      get(
        `/chat-history/application?customerId=${this.callData.selectedCustomer}&applicationId=${applicationId}`
      )
        .then((response) => {
          this.callApplicationMessages = response.data.data.message.map((message) =>
            parseMessageFromHistory(message, username)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    userData() {
        return getUserDataFromCallData(this.callData);
    }
},
};

export default callApplicationMixix;
