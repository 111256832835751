<template>
    <div class="customer-form__card">
        <div v-show="showHeader" class="customer-form__card-header">
            <h2 class="customer-form__card-header__text">
                <!--      {{ $t("branch.show.editOrCreate") }}-->
                <app-icon
                    class="customer-form__card-header__text-icon"
                    icon="user-add"
                />
                {{ $t(sectionTitle) }}
            </h2>

            <app-button theme="gray" @click="redirectTo('/customers')">{{
                $t("components.common.back")
            }}</app-button>
        </div>
        <div class="customer-form__card-body" :class="{ 'p-0': !showHeader }">
            <div class="d-flex flex-column" style="gap: 20px 24px">
                <div>
                    <div class="application-input-wrap">
                        <div class="application-input-title">
                            {{ $t("common.fio") }}
                        </div>
                        <AppInput
                            v-model="form.username"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            :placeholder="$t('common.fio')"
                        />
                        <div
                            v-show="$v.form.username.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-nowrap" style="gap: 20px">
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("common.passportNumber") }}
                        </div>
                        <AppInput
                            v-model="form.passport_no"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            v-mask="'AA #######'"
                            :placeholder="$t('common.passportNumber')"
                        />
                        <div
                            v-show="$v.form.passport_no.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("common.dateOfBirthday") }}
                        </div>
                        <AppDatePicker
                            class="card-modal__datepicker w-100"
                            v-model="form.birthday"
                            :show-today="false"
                            size="extra-large"
                            value-format="YYYY-MM-DD"
                            placeholder="components.common.startDate"
                        />
                        <div
                            v-show="$v.form.birthday.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-nowrap" style="gap: 20px">
                    <div
                        class="application-input-wrap application-input-theme-login w-50"
                    >
                        <div class="application-input-title">
                            {{ $t("common.subjectType") }}
                        </div>
                        <AppSelect
                            v-model="form.is_company"
                            :items="companyEnum"
                            item-name="name"
                            item-value="value"
                            class="application-input"
                            size="extra-large"
                        />
                        <div
                            v-show="$v.form.is_company.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                    <div
                        class="application-input-wrap application-input-theme-login w-50"
                    >
                        <div class="application-input-title">
                            {{ $t("common.sex") }}
                        </div>
                        <AppSelect
                            v-model="form.gender"
                            :items="clientGender"
                            item-name="name"
                            item-value="id"
                            class="application-input"
                            size="extra-large"
                        />
                        <div
                            v-show="$v.form.gender.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-nowrap" style="margin-right: 20px">
                    <div
                        class="application-input-wrap application-input-theme-login w-50"
                    >
                        <div class="application-input-title">
                            {{ $t("components.common.clientCategory") }}
                        </div>
                        <AppSelect
                            v-model="form.client_type"
                            :items="category_client"
                            item-name="name"
                            item-value="id"
                            class="application-input"
                            size="extra-large"
                        />
                        <div
                            v-show="$v.form.gender.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column" style="gap: 20px 24px">
                <div class="w-100">
                    <div class="customer-phone-form__col">
                        <div
                            class="application-input-wrap"
                            @click="phoneOpened = true"
                        >
                            <label class="application-input-title">
                                {{ $t("components.common.phoneNumber") }}
                                <span class="application-input-additional"
                                    >*</span
                                >
                            </label>
                            <div
                                class="application-input customer-phone-box"
                                :class="{
                                    'customer-phone-box--error': phoneError,
                                    'customer-phone-box--opened': phoneOpened,
                                }"
                            >
                                {{ form.customer_phones[0]?.phone }}
                                <AppIcon
                                    class="customer-phone-box__chevron"
                                    icon="chevron-down"
                                />
                            </div>
                            <div
                                v-if="phoneError"
                                class="application-input-error"
                            >
                                {{ $t("validations.requiredField") }}
                            </div>
                        </div>
                        <transition name="dialer">
                            <phonedropdown
                                v-if="phoneOpened"
                                :isMainPhoneDisabled="false"
                                :phones="form.customer_phones"
                                :incoming-phone="form.customer_phones[0]?.phone"
                                :selected-phone="form.customer_phones[0]?.phone"
                                @select-phone="$emit('select-phone', $event)"
                                @closeDrop="phoneOpened = false"
                                @input="form.customer_phones = $event"
                            />
                        </transition>
                    </div>
                </div>

                <div class="d-flex flex-nowrap" style="gap: 20px">
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("common.email") }}
                        </div>
                        <AppInput
                            v-model="form.email"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            :placeholder="$t('components.common.email')"
                        />
                        <div
                            v-show="$v.form.email.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("components.common.clientCode") }}
                        </div>
                        <AppInput
                            v-model.number="form.code"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            :placeholder="$t('components.common.clientCode')"
                        />
                        <div
                            v-show="$v.form.code.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-nowrap" style="gap: 20px">
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("common.tin") }}
                        </div>
                        <AppInput
                            v-model.number="form.tin"
                            v-mask="'#########'"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            :placeholder="$t('common.tin')"
                        />
                        <div
                            v-show="$v.form.tin.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                    <div class="application-input-wrap w-50">
                        <div class="application-input-title">
                            {{ $t("common.pinfl") }}
                        </div>
                        <AppInput
                            v-model.number="form.pinfl"
                            v-mask="'##############'"
                            class="application-input"
                            type="text"
                            size="extra-large"
                            :placeholder="$t('common.pinfl')"
                        />
                        <div
                            v-show="$v.form.pinfl.$anyError"
                            class="application-input-error"
                        >
                            {{ $t("validations.requiredField") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-action
            class="customer-form__card-actions"
            :class="{ 'p-0': !showHeader }"
            :actions="actions"
            :is-edit="isDirty"
            @cancel:form="emit('reset')"
            @submit:form="emit('submit:form')"
        />
    </div>
</template>

<script>
import AppSelect from "@/components/partials/AppSelect.vue";
import AppInput from "@/components/partials/AppInput.vue";
import { translationExists } from "@/mixins/local/translationExists.mixin";
import { required } from "vuelidate/lib/validators";
import AppIcon from "@/components/partials/AppIcon.vue";
import AppButton from "@/components/partials/AppButton.vue";
import Phonedropdown from "@/components/phonedropdown.vue";
import CreateAction from "@/components/admin/Customer/Create/Action.vue";
import AppDatePicker from "@/components/partials/AppDatePicker.vue";
import clientCategories from "@/config/clientCategories";

export default {
    name: "CreateCard",
    emits: ["input", "reset:form", "submit:form", "select-phone"],
    mixins: [translationExists],
    components: {
        AppDatePicker,
        CreateAction,
        Phonedropdown,
        AppButton,
        AppIcon,
        AppInput,
        AppSelect,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        companyEnum: {
            type: Array,
            required: true,
            default: () => [],
        },
        clientGender: {
            type: Array,
            required: true,
            default: () => [],
        },
        actions: {
            type: Array,
            required: true,
            default: () => ["reset", "cancel", "submit"],
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    model: {
        prop: "value",
        event: "input",
    },
    data: () => ({
        phoneOpened: false,
        phoneError: false,
        category_client: clientCategories,

        initialData: {},
    }),
    computed: {
        form: {
            get() {
                return this.value;
            },
            set($value) {
                this.$emit("input", $value);
            },
        },
        sectionTitle() {
            return this.form.id
                ? "open-apps-form.clientInfo"
                : "customer.createUser";
        },

        isDirty() {
            const val1 = JSON.stringify(this.value);
            const val2 = JSON.stringify(this.initialData);
            return val1 != val2;
        },
    },
    methods: {
        redirectTo(route) {
            this.$router.push(route);
        },
        emit(event) {
            this.$emit(event);
        },

        updateInitialData() {
            const copy = Object.assign({}, this.value);
            if (this.value.customer_phones) {
                copy.customer_phones = this.value.customer_phones.map((el) =>
                    Object.assign({}, el)
                );
            }
            this.initialData = copy;
        }
    },

    mounted() {
        this.updateInitialData()
    },
    validations: {
        form: {
            username: {
                required,
            },
            passport_no: {
                required,
            },
            birthday: {
                required,
            },
            is_company: {
                required,
            },
            gender: {
                required,
            },
            email: {
                required,
            },
            code: {
                required,
            },
            tin: {
                required,
            },
            pinfl: {
                required,
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
.customer-phone-form {
    $self: &;
    &__col {
        position: relative;
        width: 100%;
        &--large {
            width: 100%;
        }
    }
    &__footer {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 28px;
        > * {
            justify-content: flex-end;
            width: calc(100% + 16px);
        }
    }
    &__separated-button:first-child {
        margin-right: auto;
    }
    &--chat {
        #{$self}__col {
            width: calc(50% - 28px);
            &--large {
                width: 100%;
            }
        }
    }
    &--empty {
        margin-top: -24px;
        #{$self}__col {
            margin-top: 24px;
            margin-bottom: 0;
        }
    }
    &--client-bank-editable {
        margin-bottom: -18px !important;
    }
}
.customer-phone-box {
    $self: &;
    transition: border 0.25s;
    display: flex;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    height: 48px;
    padding: 0 17px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-family: $font-secondary;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    &__chevron {
        transition: transform 0.25s;
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -10px;
        width: 20px;
        height: 20px;
        color: var(--color-gray-lighten);
    }
    &--error {
        border: 1px solid #e55353;
        animation: error 0.5s 1 both;
    }
    &--opened {
        border: 1px solid var(--color-primary);
        #{$self}__chevron {
            transform: rotate(180deg);
        }
    }
}
</style>
