import Vue from 'vue';

export default new class UserColorsController {
    #userColors = Vue.observable({});
    #colors = ['#ACC1FF', '#FFAEAE', '#CAE9BF', '#FFE194', '#FEC7FF'];
    #colorsIndex = 0;
    getColor(userName) {
        if(!this.#userColors[userName]) {
            if(this.#colorsIndex >= this.#colors.length) {
                this.#colorsIndex = 0;
            }
            this.#userColors[userName] = this.#colors[this.#colorsIndex++];
        }
        return this.#userColors[userName];
    }
}
