import {DateFormatter} from "@/helpers/dates";

interface ITableHeader {
    name: string;
    key?: string;
    width?: string;
}

const CREDIT_CALCULATOR_TABLE_HEADER: ITableHeader[] = [
    {
        name: 'common.table.number',
        key: 'id',
        width: '10px'
    },
    {
        name: 'common.table.periodShort',
        key: 'pay_date'
    },
    {
        name: 'common.table.loanRepayment',
        key: 'monthly_main_debt'
    },
    {
        name: 'common.table.interestPayment',
        key: 'monthly_percent_debt'
    },
    {
        name: 'common.table.totalPayout',
        key: 'monthly_total_payment'
    }
];
const DEPOSIT_CALCULATOR_TABLE_HEADER: ITableHeader[] = [
    {
        name: 'common.table.number',
        key: 'id',
        width: '10px'
    },
    {
        name: 'common.table.periodShort',
        key: 'pay_date'
    },
    {
        name: 'common.table.monthly_payment',
        key: 'monthly_payment'
    }
];

type Period = {
    start: string,
    end: string
}

interface IDepositTableInfo {
    pay_date: string,
    pay_for_days: number,
    monthly_payment: string
}

interface CreditTableInfo {
    pay_date: string,
    pay_for_days: number,
    remain_debt: string,
    monthly_main_debt: string,
    monthly_percent_debt: string,
    monthly_total_payment: string
}

interface ICreditTable {
    table: CreditTableInfo[],
    interest: string,
    debt_total: string,
    percent_total: string,
    total_total: string,
    period: Period
}

interface IDepositTable {
    table: IDepositTableInfo[],
    interest: string,
    currency: string,
    percent: string,
    period: Period
}

const CREDIT_ADDITIONAL_CALCULATOR_TABLE_HEADER = (model: ICreditTable): ITableHeader[] => ([
    {
        name: 'common.total',
    },
    {
        name: new DateFormatter(model?.period?.end).format("DD/MM/YYYY"),
    },
    {
        name: model?.debt_total,
    },
    {
        name: model?.interest,
    },
    {
        name: model?.total_total,
    }
]);

const DEPOSIT_ADDITIONAL_CALCULATOR_TABLE_HEADER = (model: IDepositTable): ITableHeader[] => ([
    {
        name: 'common.total',
    },
    {
        name: new DateFormatter(model?.period?.end).format("DD/MM/YYYY"),
    },
    {
        name: model?.interest,
    },
]);

export {
    CREDIT_CALCULATOR_TABLE_HEADER,
    DEPOSIT_CALCULATOR_TABLE_HEADER,
    CREDIT_ADDITIONAL_CALCULATOR_TABLE_HEADER,
    DEPOSIT_ADDITIONAL_CALCULATOR_TABLE_HEADER
}
