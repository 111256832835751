import {get, post, put} from "@/helpers/api";
import {CustomerData} from "@/services/customer/CustomerModel";
import {
    selectCustomer,
    setSearchMessage,
    BaseChatCommand,
    useUpdateChat,
    addCustomer
} from "../customerHelpers";
import app from "@/main";

export class SetSearchMessageCommand extends BaseChatCommand {
    execute(message, type) {
        setSearchMessage({message, type}, this.chatData);
    }
}
export class InputFormCommand extends BaseChatCommand {
    execute(data) {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.customerForm = { ...data };
        update();
    }
}
export class SelectPhoneCommand extends BaseChatCommand {
    execute(phone) {
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.selectedPhone = phone;
        update();
    }
}
export class CreateCustomerCommand extends BaseChatCommand {
    execute() {
        if(this.chatData.customerLoading) return;
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        dataToUpdate.customerLoading = true;
        update();
        post('/customers', this.chatData.customerForm)
            .then(response => {
                if (response.data.success) {
                    addCustomer(response.data.data, this.chatData);
                    selectCustomer(response.data.data.id, this.chatData);
                } else {
                    throw new Error();
                }
            })
            .finally(() => {
                dataToUpdate.customerLoading = false;
                update();
            })
    }
}
export class UpdateCustomerCommand extends BaseChatCommand {
    execute() {
        if(this.chatData.customerLoading) return;
        const [dataToUpdate, update] = useUpdateChat(this.chatData);
        const formData = {
            ...this.chatData.customerForm,
        };

        formData.customer_phones = formData.customer_phones.filter(({phone}) => phone);

        delete formData.updated_at;
        delete formData.created_at;
        delete formData.bank_client_id;

        dataToUpdate.customerLoading = true;
        update();

        put('/customers/' + this.chatData.selectedCustomer, formData)
            .then(response => {
                if (response.data.success) {
                    const customers = [...this.chatData.customers];
                    const idx = customers.findIndex(c => c.id === response.data.data.id);
                    const customer = new CustomerData(response.data.data, this.chatData.data.phone);
                    customers.splice(idx, 1, customer);
                    dataToUpdate.customers = customers;
                    update();
                    selectCustomer(response.data.data.id, this.chatData);
                    app.$store.commit('notifications/error', {title: "requestsStatuses.success.index", text: "customer.customerSuccessUpdated", type: "success"})
                } else {
                    throw new Error();
                }
            })
            .finally(() => {
                dataToUpdate.customerLoading = false;
                update();
            })
    }
}
export class LoadGTSPCustomerCommand extends BaseChatCommand {
    execute() {
        const customer = this.chatData.customerForm;
        get(`/customers/${customer.passport_no.replace(' ', '-')}/${customer.birthday}`)
            .then((r) => {
                if (r.status !== 200 || r.data.result.code === undefined){
                    app.$store.commit('notifications/error', 'Ошибка сервера')
                }
                if (r.data.result.code === '02379'){
                    app.$store.commit('notifications/error', r.data.result.message)
                }
                if (r.data.result.code === '02000'){
                    const info = r.data.response;
                    const [dataToUpdate, update] = useUpdateChat(this.chatData);
                    dataToUpdate.customerForm = {
                        ...customer,
                        username: (info.last_name || '') + ' ' + (info.first_name || '') + ' ' + (info.patronym || ''),
                        tin: info.inn,
                        pinfl: info.pin,
                        passport_info: info.give_place_name,
                        gender: parseInt(info.sex),
                    }
                    update();
                }
            }).catch(e => {
                console.log(e);
                app.$store.commit('notifications/error', 'Ошибка ГЦП')
            });
    }
}
