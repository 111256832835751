<template>
    <div>
        <AppTabs v-model="active" :items="productTabs" theme="loan" @change="onTabChange">
            <template #body.loan>
                <div key="a">
                    <div class="product-info-heading">{{ $t("components.common.loans") }}</div>
                    <ProductInfoTable :data="customer && customer.loans" empty-name="components.common.credits" :parser="loansParser" :loading="loansLoading">
                        <template #slot.action="{item}">
                            <AppButton class="ml-auto" theme="primary-outline" @click="showModal('loan', item.loanId, 'loanId')">{{ $t("components.common.moreDetails") }}</AppButton>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
            <template #body.deposit>
                <div key="b">
                    <div class="product-info-heading">{{ $t("components.common.holdings") }}</div>
                    <ProductInfoTable :data="customer && customer.deposits" empty-name="components.common.deposits" :parser="depositsParser" :loading="depositsLoading">
                        <template #slot.action="{item}">
                            <AppButton class="ml-auto" theme="primary-outline" @click="showModal('deposit', item.savDepId, 'savDepId')">{{ $t("components.common.moreDetails") }}</AppButton>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
            <template #body.card>
                <div key="c">
                    <div class="product-info-heading d-flex align-items-center justify-content-between">
                        {{ $t("components.common.cards") }}
                        <LoadingContent v-if="customerCards.length" :loading="processDelayActive">
                            <AppButton class="pr-8 pl-7" @click="refreshProcessCards">
                                <AppIcon class="mr-3" icon="cloud-refresh-outline" size="18"/>
                                {{ $t("components.common.update") }}
                            </AppButton>
                        </LoadingContent>
                    </div>
                    <ProductInfoTable :data="customerCards" empty-name="components.common.kart" :parser="cardsParser" :loading-items="currentlyProcessingCards" :loading="modalSpinner">
                        <template #slot.logo="{item, loading}">
                            <div v-if="loading" class="d-flex align-items-center">
                                <div class="placeholder placeholder--card-icon mr-3-a"></div>
                                <div class="placeholder placeholder--medium-a"></div>
                            </div>
                            <div v-else class="product-info-card">
                                <div class="product-info-card__logo">
                                    <img :src="item.logo" alt="">
                                </div>
                                {{ (item.typeName || '-') | capitalize }}
                            </div>
                        </template>
                        <template #slot.action="{item}">
                            <div class="block-wrap justify-content-end flex-nowrap">
                                <ActiveOperationsButtons :card="item" :identification-data="identificationData" short @action="openActiveOperations(item, $event)"/>
                                <AppButton theme="primary-outline" @click="openCardModal(item)">{{ $t("components.common.moreDetails") }}</AppButton>
                            </div>
                        </template>
                    </ProductInfoTable>
                </div>
            </template>
        </AppTabs>
        <!-- Kredit modal -->
        <div v-if="modals.loan.status">
            <LoanModal v-if="modals.loan.status" ref="loanModal" :modals="modals"
                       :values="{loanSchedule:loanSchedule,loanHistoryAccountPage:loanHistoryAccountPage,getLoanValue:getLoanValue, getAccountValueById:getAccountValueById,getBranch:getBranch}"
                       :cards="customerCards"
                       @detail="openDetailModal"
                       @close-modal="modals.loan.status = false"
                       @card-click="onLoanCardSelected"
            />
        </div>

        <LoanDetail v-if="modals.detail.status" :info="modals.detail"
                    :values="{loanSchedule:loanSchedule,loanHistoryAccountPage:loanHistoryAccountPage,getLoanValue:getLoanValue, getAccountValueById:getAccountValueById,getBranch:getBranch}" @close="modals.detail.status = false"/>

        <!-- Deposit modal -->
        <DepositModal v-if="modals.deposit.status" ref="depositModal" :modals="modals" :branch="{getBranch:getBranch}"
                      :values="{grafikPage:grafikPage,historyAccountPage:historyAccountPage}" @close-modal="modals.deposit.status = false"/>

        <!-- Card modal -->
        <CardModal v-if="modals.card.status" ref="cardModal" :card-error="cardError" :user-data="client" :card-loading="cardLoading" :identification-data="identificationData" :card="selectedCardData"
                   @identification-request="modals.identification.status = $event" @active-operations-submit="cardActionCompleted"
                   @close-modal="closeCardModal" @action="openActiveOperations(selectedCardData, $event)"/>

        <ActiveOperationsModal v-if="modals.operations.status" ref="operationsModal" :identification-data="identificationData"
                               :type="operationType" :card="selectedCardData" :user-data="client"
                               @identification-request="modals.identification.status = $event"
                               @active-operations-submit="cardActionCompleted" @close-modal="closeActiveOperationsModals"/>
        <UserIdentificationModal v-if="modals.identification.status" ref="identificationModal" @submit="requestIdentification" @close-modal="modals.identification.status = false"/>
        <ActiveOperationCompletedModal v-if="modals.operationsComplete.status"  ref="operationsCompleteModal" :success="operationSuccess" :reason="operationErrorReason"
                                       @close-modal="modals.operationsComplete.status = false"/>
    </div>
</template>

<script>
import {get, post} from "../helpers/api"
import moment from "moment";
import 'moment/locale/ru';
import AppTabs from "./partials/AppTabs";
import AppButton from "./partials/AppButton";
import AppIcon from "./partials/AppIcon";
import HUMO_LOGO from '../assets/img/Humo.svg';
import UZCARD_LOGO from '../assets/img/uzcard.svg';
import MASTER_LOGO from '../assets/img/master.svg';
import ActiveOperationsButtons from "./partials/pages/ActiveOperationsButtons";
import activeOperationsMixin from "../mixins/activeOperationsMixin";
import ProductInfoTable from "./partials/pages/monitoring/ProductInfoTable";
import {capitalize} from "../helpers/strings";
import delayMixin from "../mixins/delayMixin";
import LoadingContent from "./partials/LoadingContent";
import VISA_LOGO from "@/assets/img/Visa.svg";
import UPI_LOGO from "@/assets/img/UnionPay.svg";
import { decodeHtmlToString } from "../helpers/other"

const LoanDetail = () => import("./modals/LoanDetail")
const DepositModal = () => import("@/components/DepositModal");
const LoanModal = () => import("./LoanModal");
const CardModal = () => import("./CardModal.vue");
const UserIdentificationModal = () => import("./modals/UserIdentificationModal");
const ActiveOperationsModal = () => import("./modals/ActiveOperationsModal");
const ActiveOperationCompletedModal = () => import("./modals/ActiveOperationCompletedModal");

export default {
    name: 'MybankProduct',
    components: {
        LoadingContent,
        ProductInfoTable,
        ActiveOperationCompletedModal,
        ActiveOperationsButtons,
        ActiveOperationsModal,
        UserIdentificationModal,
        AppIcon,
        AppButton,
        LoanModal,
        AppTabs,
        LoanDetail,
        DepositModal,
        CardModal,
    },
    mixins: [activeOperationsMixin, delayMixin('process')],
    filters: {
        capitalize
    },
    props: {
        processingCards: {
            type: Object,
            default: () => ({})
        },
        clientFormInfo: {
            type: Object
        },
    },
    data() {
        return {
            operationType: null,
            important_inform: true,
            active: 'loan',
            startDate: '',
            endDate: '',
            card_type: '',
            loading: false,
            status: false,
            createCard: false,
            currentPage: 10,
            grafikPage: 10,
            historyAccountPage: 10,
            loanHistoryAccountPage: 10,
            loanSchedule: 10,
            nothingFound: false,
            customer: {
                loans: [],
                deposits: [],
                cards: []
            },
            modals: {
                loan: {
                    status: false,
                    model: null,
                    id: null,
                    schedule: [],
                    account: {
                        info: false,
                        dateBegin: moment().subtract(1, 'month').format('DD.MM.YYYY'),
                        dateClose: moment().format('DD.MM.YYYY'),
                        history: []
                    },
                    guar: []
                },
                deposit: {
                    status: false,
                    model: null,
                    id: null,
                    totalPercent: 0,
                    schedule: [],
                    account: {
                        info: false,
                        dateBegin: moment().subtract(1, 'month').format('DD.MM.YYYY'),
                        dateClose: moment().format('DD.MM.YYYY'),
                        history: []
                    }
                },
                card: {
                    status: false,
                    model: null,
                    iabs: null,
                    id: null,
                    schedule: [],
                    filter: {
                        date_from: null,
                        date_to: null,
                    }
                },
                detail: {
                    status: false
                },
                identification: {
                    status: false
                },
                operations: {
                    status: false
                },
                operationsComplete: {
                    status: false
                }
            },
            loanNumbers: [1, 2, 3, 4, 5, 6, 7, 11, 13, 22, 24, 26, 28, 39, 46, 55, 57, 102, 118],
            radioStyle: {
                display: "block",
                height: "35px",
                lineHeight: "30px"
            },
            sms: 0,
            value: "",
            search: '',
            typeCard: '',
            spinner: false,
            modalSpinner: false,
            loansLoading: false,
            depositsLoading: false,
            cardSpinner: false,
            productTabs: [
                {
                    name: 'components.common.loans',
                    key: 'loan'
                },
                {
                    name: 'components.common.holdings',
                    key: 'deposit'
                },
                {
                    name: 'components.common.cards',
                    key: 'card'
                }
            ],
            selectedCard: null,
            operationSuccess: false,
            operationErrorReason: null
        };
    },
    computed: {
        selectedCardData() {
            return this.customerCards.find(card => card.cardNumber === this.selectedCard);
        },
        client() {
            if (this.clientFormInfo) {
                return this.clientFormInfo;
            }
            return this.userData
        },
        paginateSchedule() {
            return this.modals.card.schedule.reverse().slice(0, this.currentPage > 10 ? this.currentPage : 10)
        },
        paginateGrafikSchedule() {
            const iRowsCount = this.grafikPage > 10 ? this.grafikPage : 10;
            return this.modals.deposit.schedule.reverse().slice(0, iRowsCount)
        },
        historyAccountPagination() {
            return this.modals.deposit.account.history.reverse().slice(0, this.historyAccountPage > 10 ? this.historyAccountPage : 10)
        },
        loanHistoryPage() {
            return this.modals.loan.account.history.reverse().slice(0, this.loanHistoryAccountPage > 10 ? this.loanHistoryAccountPage : 10)
        },
        loanSchedulePagition() {
            return this.modals.loan.schedule.reverse().slice(0, this.loanSchedule > 10 ? this.loanSchedule : 10)
        },
        rows() {
            return this.modals.card.schedule.length
        },
        customerCards() {
            const keys = Object.keys(this.customer.cards);
            if (!keys.length) return [];

            return keys.reduce((acc, key) => {
                const arr = this.customer.cards[key];
                let cards = [];
                if (arr) {
                    cards = arr.map(card => {
                        let state;
                        let logo;

                        switch (key) {
                            case 'HUMO':
                                state = card.stateCode === 0 ? 'active' : 'disabled';
                                logo = HUMO_LOGO;
                                break;
                            case 'INTERNATIONAL':
                                state = card.stateCode === 2 ? 'active' : 'disabled';

                                switch (card.paySysDescription.toUpperCase()) {
                                    case "MASTERCARD": {
                                        logo = MASTER_LOGO
                                        break
                                    }
                                    case "VISA": {
                                        logo = VISA_LOGO
                                        break
                                    }
                                    case "UPI": {
                                        logo = UPI_LOGO
                                        break
                                    }
                                }
                                break;
                            case 'UZCARD':
                                state = card.stateCode === '000' ? 'active' : 'disabled';
                                logo = UZCARD_LOGO;
                                break;
                        }
                        let typeName = key.toLowerCase();

                        if (key === 'INTERNATIONAL') {
                            typeName = card.paySysDescription;
                        }

                        let obj = {
                            ...card,
                            type: key,
                            typeName,
                            state,
                            logo
                        };

                        const cData = this.processingCards[card.cardNumber];
                        if (cData && cData.data && !cData.error) {
                            const d = this.processingCards[card.cardNumber].data;
                            obj = {
                                ...obj,
                                stateName: d.statusDescription,
                                state: d.isActive ? 'active' : 'disabled',
                                cardBalance: d.balance,
                                isActionsPossible: d.isActionsPossible,
                                sms: d.sms,
                                phone: d.phone,
                                pincnt: d.pincnt,
                            }
                        }

                        return obj;
                    });
                }
                return acc.concat(cards)
            }, []);
        },
        currentlyProcessingCards() {
            const loading = [];
            this.customerCards.forEach((card, idx) => {
                if(this.isCardProcessing(card)) {
                    loading.push(idx);
                }
            });
            return loading;
        },
        cardError() {
            return this.selectedCard && this.processingCards[this.selectedCard] && this.processingCards[this.selectedCard].error;
        },
        cardLoading() {
            return this.selectedCard && this.processingCards[this.selectedCard] && this.processingCards[this.selectedCard].loading;
        },
    },
    methods: {
        onLoanCardSelected(card) {
            this.$refs.loanModal.close();
            this.openCardModal(card);
        },
        openCardModal(cardItem) {
            if(!(cardItem.cardNumber in this.processingCards)) {
                this.$emit('process-cards', [cardItem]);
            }
            this.selectedCard = cardItem.cardNumber;
            this.modals.card.status = true;
        },
        openDetailModal() {
          this.$set(this.modals.loan, 'status', false);
          setTimeout(() => {
              this.$set(this.modals.detail, 'status', true);
          }, 100)
        },
        checkLength(event, context) {
            if (context.schedule.length < event.target.value) {
                event.target.value = context.schedule.length + 1
                context.value = context.schedule.length + 1
            }
        },
        showModal(type, id, idFieldName = 'id', cardtype) {
            this.loading = true;
            this.card_type = cardtype
            this.modals[type].model = null
            this.modals[type].id = null
            this.modals[type].schedule = []
            let tempName = this.customer[type + 's'].filter(item => {
                if (item[idFieldName] === id) return item;
            })
            this.modals[type].status = true
            if (tempName.length) {
                if (type === 'card') {
                    get('/aibs/card-info-by-id/' + id)
                        .then(r => {
                            this.loading = false;
                            if (r.data.success) {
                                this.important_inform = false
                                this.modals.card.iabs = r.data.data;
                            } else {
                                this.nothingFound = true
                                this.important_inform = false
                            }
                        })
                        .catch(e => {
                            this.important_inform = false
                            this.nothingFound = true
                            this.loading = false;
                        })
                    this.loadCardTransactions(id);
                }

                this.modals[type].model = tempName[0]
                this.modals[type].id = tempName[0][idFieldName]
            }
            this.goToAccountList('loan')
            this.goToAccountList('deposit')
        },
        loadCardTransactions(id) {
            if (null == id) {
                return;
            }
            if (this.$refs.cardModal) {
                this.modals.card.filter.date_from = this.$refs.cardModal.startDate
                this.modals.card.filter.date_to = this.$refs.cardModal.endDate
            }
            this.cardSpinner = true
            const query = {}

            query.dateBegin = this.modals.card.filter.date_from
                ? moment(this.modals.card.filter.date_from, 'DD.MM.YYYY').format('DD.MM.YYYY') : '';
            query.dateClose = this.modals.card.filter.date_to
                ? moment(this.modals.card.filter.date_to, 'DD.MM.YYYY').format('DD.MM.YYYY') : '';

            const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&')

            this.modals.card.schedule = [];

            get(`/aibs/card-history-by-id/${id}?${queryString}`)
                .then(r => {
                    this.cardSpinner = false
                    if (r.data.success) {
                        this.modals.card.schedule = r.data.data;
                    } else {
                        this.nothingFound = true
                    }

                }).catch((_) => {
                this.nothingFound = true
                this.cardSpinner = false
            })
        },
        closeCardModal(e) {
            this.modals.card.status = false;
            this.selectedCard = null;
        },
        formatSum(value, cent = false) {
            let result
            value = cent ? (value / 100) : value;
            if (value !== undefined) {
                result = value.toString().replaceAll(" ", "")
                result = Number(result).toFixed(0)
                result = result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                return result.replaceAll(',', ' ')
            }

        },

        loadCustomerLoans() {
            if (this.client.bank_client_id && !this.customer.loans.length) {
                this.loading = true;
                this.loansLoading = true
                get('/aibs/loans?clientUid=' + this.client.bank_client_id)
                    .then(r => {
                        this.loansLoading = false
                        if (r.data.success) {
                            this.customer.loans = r.data.data
                        } else {
                            this.nothingFound = true
                        }
                        this.loading = false;
                    }).catch(e => {
                        console.log(e)
                        this.loansLoading = false
                        this.loading = false;
                        this.nothingFound = true
                    })
            }
        },
        loadCustomerDeposits() {
            if (this.client.bank_client_id && !this.customer.deposits.length) {
                this.loading = true;
                this.depositsLoading = true
                get('/aibs/deposits?clientUid=' + this.client.bank_client_id)
                    .then(r => {
                        this.depositsLoading = false
                        if (r.data.success) {
                            this.customer.deposits = r.data.data
                        } else {
                            this.nothingFound = true
                        }
                        this.loading = false;
                    }).catch(e => {
                        this.depositsLoading = false
                        console.log(e)
                        this.loading = false;
                        this.nothingFound = true
                    })
            }
        },
        loadCustomerCards() {
            if (this.client.bank_client_id) {
                this.$set(this.customer, 'cards', {});
                this.loading = true;
                this.modalSpinner = true
                if (this.$refs.depositModal !== undefined) {
                    this.$refs.depositModal.depositSpinner = true
                }
                return get('/aibs/cards/' + this.client.bank_client_id)
                    .then(r => {
                        if (this.$refs.depositModal !== undefined) {
                            this.$refs.depositModal.depositSpinner = false
                        }
                        this.modalSpinner = false
                        if (r.data.success) {
                            this.$set(this.customer, 'cards', r.data.data);
                        } else {
                            this.nothingFound = true
                        }
                        this.loading = false;
                    })
                    .catch(e => {
                        if (this.$refs.depositModal !== undefined) {
                            this.$refs.depositModal.depositSpinner = false
                        }
                        this.modalSpinner = false
                        this.loading = false;
                        this.nothingFound = true
                    })
            }
        },
        modalTabChanged(type, e) {
            this.loading = true

            if (type === 'loan') {
                if (!this.modals.loan.schedule.length) {
                    if (e === "3") {
                        if (this.$refs.loanModal !== undefined) {
                            this.$refs.loanModal.loanSpinner = true
                        }
                        get('/aibs/loan-schedule?loanId=' + this.modals.loan.id)
                            .then(r => {
                                if (this.$refs.loanModal !== undefined) {
                                    this.$refs.loanModal.loanSpinner = false
                                }
                                if (r.data.success && r.data.data.length) {
                                    this.modals.loan.schedule = r.data.data;
                                } else {
                                    this.nothingFound = true
                                }

                                this.loading = false

                            }).catch(e => {
                            console.log(e)
                            if (this.$refs.loanModal !== undefined) {
                                this.$refs.loanModal.loanSpinner = false
                            }
                            this.loading = false
                            this.nothingFound = true
                        })
                    }
                }
                if(e === '4') {
                    if (this.$refs.loanModal !== undefined) {
                        this.$refs.loanModal.loanSpinner = true
                    }
                    get('/aibs/loan-guard-list/' + this.modals.loan.id)
                        .then(r => {
                            if (this.$refs.loanModal !== undefined) {
                                this.$refs.loanModal.loanSpinner = false
                            }
                            if (r.data.success && r.data.data.length) {
                                this.modals.loan.guar = r.data.data;
                            } else {
                                this.nothingFound = true
                            }

                            this.loading = false

                        }).catch(e => {
                        console.log(e)
                        if (this.$refs.loanModal !== undefined) {
                            this.$refs.loanModal.loanSpinner = false
                        }
                        this.loading = false
                        this.nothingFound = true
                    })
                }

            }
            if (type === 'deposit') {
                if (e === "3") {
                    if (!this.modals.deposit.schedule.length) {
                        if (this.$refs.depositModal !== undefined) {
                            this.$refs.depositModal.depositGrafik = true
                        }
                        get('/aibs/deposit-payment-schedule?saveDepId=' + this.modals.deposit.id)
                            .then(r => {
                                if (this.$refs.depositModal !== undefined) {
                                    this.$refs.depositModal.depositGrafik = false
                                }
                                if (r.data.success && r.data.data.length) {
                                    this.modals.deposit.schedule = r.data.data;
                                    let sum = 0;
                                    r.data.data.forEach(item => {
                                        if (item.proc) {
                                            sum += parseInt(item.proc)
                                        }
                                    })
                                    this.modals.deposit.totalPercent = sum;
                                } else {
                                    this.nothingFound = true
                                }
                                this.loading = false
                            }).catch(e => {
                            this.loading = false;
                            this.nothingFound = true
                            if (this.$refs.depositModal !== undefined) {
                                this.$refs.depositModal.depositGrafik = false
                            }
                        })
                    }
                }
            }
        },
        getBranch(type) {
            let txt = '';
            if (type === 'loan') {
                if (this.modals.loan.model.codeFilial)
                    txt += this.modals.loan.model.codeFilial;
                if (this.modals.loan.model.filialName)
                    txt += ' (' + this.modals.loan.model.filialName + ')';
            } else if (type === 'deposit') {
                if (this.modals.deposit.model.filialCode)
                    txt += this.modals.deposit.model.filialCode;
                if (this.modals.deposit.model.filialName)
                    txt += ' (' + this.modals.deposit.model.filialName + ')';
            }
            return txt;
        },
        getCurrency(cur) {
            switch (cur) {
                case '000':
                    return "Сум";
                case '840':
                    return "Доллар";
                case '978':
                    return "Евро";
            }
        },
        getLoanValue(account1, account2 = false) {
            let result;
            if (this.modals.loan.model !== undefined) {
                this.loanNumbers.forEach(k => {
                    if (this.modals.loan.model['loan' + k] !== undefined) {
                        let acc = this.modals.loan.model['loan' + k];
                        let accSaldo = this.modals.loan.model['saldo' + k];
                        if (acc.substr(0, 5) === account1) {
                            result = accSaldo;
                        }
                    }
                })
            }
            return result ? this.formatSum(result, true) : 0;
        },
        getAccountInfo(account, type = 'loan') {
            let result = false;
            if (this.modals[type].model !== undefined) {
                if (type === 'loan') {
                    this.loanNumbers.forEach(k => {
                        if (this.modals[type].model[type + k] !== undefined) {
                            let acc = this.modals[type].model[type + k];
                            if (acc.substr(0, 5) === account) {
                                result = acc;
                            }
                        }
                    })
                }
            }
            return result;
        },
        getLoanAccountById(id) {
            if (this.modals['loan'].model['loan' + id] !== undefined) {
                return this.modals['loan'].model['loan' + id];
            }
            return false;
        },
        getAccountValueById(id) {
            let result = false;
            if (this.modals['loan'].model['loan' + id] !== undefined) {
                result = this.modals.loan.model['saldo' + id];
            }
            return result ? this.formatSum(result, true) : 0;
        },
        openAccount(account, type = 'loan') {
            this.loading = true
            this.modals[type].account.info = type === 'loan' ? this.getAccountInfo(account) : account
            this.loadAccountHistory(type)
        },
        openAccountById(id, type = 'loan') {
            this.loading = true
            this.modals[type].account.info = this.getLoanAccountById(id);
            this.loadAccountHistory(type)
        },
        loadAccountHistory(type = 'loan') {
            this.spinner = true
            if (this.$refs.depositModal !== undefined) {
                this.$refs.depositModal.depositSpinner = true
            }
            if (type === 'loan' && this.modals[type].account.info) {
                const query = {}
                query.clientUid = this.client.bank_client_id;

                if (undefined !== this.modals[type].account.dateBegin)
                    query.dateBegin = this.modals[type].account.dateBegin
                if (undefined !== this.modals[type].account.dateClose)
                    query.dateClose = this.modals[type].account.dateClose

                const queryString = Object.keys(query)
                    .map(key => key + '=' + query[key])
                    .join('&')

                get(`/aibs/account-history-by-num/${this.modals[type].account.info}?${queryString}`)
                    .then(r => {
                        this.spinner = false
                        if (this.$refs.depositModal !== undefined) {
                            this.$refs.depositModal.depositSpinner = false
                        }
                        if (r.status === 200) {
                            this.modals[type].account.history = r.data.data || []
                        } else {
                            this.nothingFound = true
                        }
                        this.loading = false
                    }).catch(e => {
                    console.log(e)
                    this.spinner = false
                    this.loading = false
                    this.nothingFound = true
                    if (this.$refs.depositModal !== undefined) {
                        this.$refs.depositModal.depositSpinner = false
                    }
                })
            } else if (type === 'deposit' && this.modals[type].account.info) {
                if (this.$refs.depositModal !== undefined) {
                    this.$refs.depositModal.depositSpinner = true
                }
                const query = {}
                query.clientUid = this.client.bank_client_id;

                if (undefined !== this.modals[type].account.dateBegin)
                    query.dateBegin = this.modals[type].account.dateBegin
                if (undefined !== this.modals[type].account.dateClose)
                    query.dateClose = this.modals[type].account.dateClose

                const queryString = Object.keys(query)
                    .map(key => key + '=' + query[key])
                    .join('&')

                get(`/aibs/account-history-by-num/${this.modals[type].account.info}?${queryString}`)
                    .then(r => {
                        if (this.$refs.depositModal !== undefined) {
                            this.$refs.depositModal.depositSpinner = false
                        }
                        if (r.status === 200) {
                            this.modals[type].account.history = r.data.data || []
                        } else {
                            this.nothingFound = true
                        }
                        this.loading = false
                    }).catch(e => {

                    this.loading = false
                    this.nothingFound = true
                    if (this.$refs.depositModal !== undefined) {
                        this.$refs.depositModal.depositSpinner = false
                    }
                })
            }
            this.loading = false
        },
        goToAccountList(type = 'loan') {
            this.modals[type].account.info = false
            this.modals[type].account.dateBegin = moment().subtract(1, 'month').format('DD.MM.YYYY')
            this.modals[type].account.dateClose = moment().format('DD.MM.YYYY')
            this.modals[type].account.history = []
        },

        isCardProcessing(cardItem) {
            return cardItem.cardNumber in this.processingCards && this.processingCards[cardItem.cardNumber].loading;
        },
        onTabChange(tab) {
            if(tab === 'card') {
                const cards = this.customerCards.filter(card => !(card.cardNumber in this.processingCards));
                this.$emit('process-cards', cards);
            }
        },

        openActiveOperations(card, type) {
            if(this.activeOperationsCheckIdentification(type)) {
                this.$set(this.modals.operations, 'status', true);
                this.operationType = type;
                this.selectedCard = card.cardNumber;
            } else {
                this.$set(this.modals.identification, 'status', true);
            }
        },
        closeActiveOperationsModals() {
            this.$set(this.modals.operations, 'status', false);
            if(!this.modals.card.status) {
                this.selectedCard = null;
            }
            this.operationType = null;
        },
        requestIdentification() {
            this.$set(this.modals.identification, 'status', false);
            this.$set(this.modals.operations, 'status', false);
            this.$set(this.modals.operationsComplete, 'status', false);
            this.$set(this.modals.card, 'status', false);
            this.$emit('request-identification', {forced: true});
        },
        cardActionCompleted({success, reason}) {
            let event = 'process-cards';
            if(this.selectedCardData.typeName === 'uzcard') {
                event = 'process-cards-delay';
            }
            this.$emit(event, [this.selectedCardData]);
            this.operationSuccess = success;
            this.operationErrorReason = reason;
            this.closeActiveOperationsModals();
            this.$set(this.modals.operationsComplete, 'status', true);
        },
        loansParser(item, idx) {
            return [
                {
                    type: 'simple',
                    value: idx + 1 + '. ' + ( this.htmlToString(item.productName || '-') ),
                    placeholderType: 'medium-a'
                },
                {
                    name: 'components.common.amountOfCredit',
                    icon: 'wallet',
                    value: item.amount ? this.formatSum(item.amount, true) : '-',
                },
                {
                    name: 'components.common.creditStatus',
                    icon: 'info-circle',
                    value: item.stateDesc || '-'
                },
                {
                    name: 'components.common.timeToGet',
                    value: item.contractDate || '-',
                    icon: 'time'
                },
                {
                    type: 'slot',
                    value: 'action',
                    placeholderType: 'button',
                    width: '10px'
                }
            ]
        },
        depositsParser(item, idx) {
            return [
                {
                    type: 'simple',
                    value: idx + 1 + '. ' + (item.depName || '-'),
                    placeholderType: 'medium-a'
                },
                {
                    name: 'components.common.depositAmount',
                    value: item.amount ? this.formatSum(item.amount, true) : '-',
                    icon: 'wallet',
                },
                {
                    name: 'components.common.term',
                    value: item.depTemp || '-',
                    icon: 'time'
                },
                {
                    name: 'components.common.openTime',
                    value: item.openDate || '-',
                    icon: 'time'
                },
                {
                    type: 'slot',
                    value: 'action',
                    width: '10px'
                }
            ]
        },
        cardsParser(item) {
            return [
                {
                    type: 'slot',
                    value: 'logo'
                },
                {
                    name: 'components.common.cardCategory',
                    value: item.contractTypeGroupName,
                    icon: 'grid-alt'
                },
                {
                    name: 'components.common.cardNumber',
                    value: item.cardNumberMasked,
                    icon: 'credit-card-front'
                },
                {
                    name: 'components.common.cardExpiry',
                    value: item.valid,
                    icon: 'time-reverse'
                },
                {
                    name: 'components.common.cardStatus',
                    value: item.stateName,
                    theme: item.state === 'active' ? 'success' : 'danger'
                },
                {
                    type: 'slot',
                    value: 'action',
                    width: '10px',
                    placeholderType: 'button'
                }
            ]
        },
        refreshProcessCards() {
            if(this.processDelayActive) return;
            this.$emit('process-cards', this.customerCards);
            this.processStartDelay(null, 10000);
        },
        htmlToString(text) {
            return decodeHtmlToString(text);
        }
    },

    created() {
        this.loadCustomerLoans();
        this.loadCustomerCards();
    },
    watch: {
        'modals.deposit.schedule': {
            handler: function (val) {
                if (val.length < this.grafikPage) {
                    this.grafikPage = val.length + 1
                }
            },
        },
        active() {
            switch (this.active) {
                case 'loan':
                    this.loadCustomerLoans();
                    break;
                case 'deposit':
                    this.loadCustomerDeposits();
                    break;
            }
        },
        startDate(val) {
            if (val.length > 10) {
                this.$refs.endInput.focus()
            }
        },
    }
};
</script>
<style lang="scss">
@import '../assets/scss/variables';
.input-group-text {
    width: 100% !important;
    display: block;
    text-align: left;
}

.input-group-prepend {
    width: 220px !important;
}

.input-group-prepend.w-50 {
    width: 50% !important;
}


.form-control:disabled {
    background: unset !important;
}

.card_modal {
    .ant-modal-body {
        padding: 0;
    }

    .modal_header {
        padding: 32px 64px;
    }
}

.agrement {
    span {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray-lighten);

        &.active {
            color: var(--color-primary);
        }
    }

    img {
        margin-right: 18px;
        display: inline-block;
    }
}

.page_info {
    margin-right: 33px;

    p {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray);

        span {
            font-size: 16px;
            line-height: 19px;
            color: var(--color-gray);
        }
    }
}


.is_error {
    p {
        font-size: 20px;
    }
}

.clickable_account {
    cursor: pointer;
    font-weight: bold !important;

    &:hover {
        color: var(--color-primary-dark);
    }
}

.product-info-icon {
    transition: transform .25s;
    &--active {
        transform: rotate(180deg);
    }
}
.product-info-table {
    $self: &;
    --width-default: 324px;
    --width-start: 15%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0;
    font-family: $font-secondary;
    width: 100%;
    &__row {
        & + & {
            #{$self}__cell {
                height: 100px;
                padding-top: 28px;
                &:before {
                    top: 4px;
                    height: calc(100% - 4px);
                }
            }
        }
    }
    &__cell {
        height: 96px;
        padding: 24px 72px 24px 0;
        position: relative;
        z-index: 0;
        width: 10px;
        min-width: var(--w, 10px);
        white-space: nowrap;
        &--large {
            width: 100%;
        }
        &--normal-wrap {
            white-space: normal;
        }
        &:before {
            content: '';
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--color-white);
        }
        &:first-child {
            padding-left: 42px;
            width: 368px;
            white-space: normal;
            &:before {
                border-radius: 12px 0 0 12px;
            }
        }
        &:last-child {
            padding-right: 34px;
            width: auto;
            &:before {
                border-radius: 0 12px 12px 0;
            }
        }
    }
    &__button {
        margin-left: auto;
    }
    &__name {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 32px;
        margin-right: 12px;
        background: var(--color-white-bluesh);
        border-radius: 8px;
        padding: 5px;
        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
        }
    }
    &--deposit,
    &--loan {
        --width-default: 264px;
        --width-start: 24%;
    }
    &--card {
        #{$self}__name {
            text-transform: capitalize;
        }
    }
    &--short-card {
        #{$self}__cell {
            padding-right: 34px;
            &:nth-child(1) {
                padding-left: 42px;
            }
            &:last-child {
                padding-right: 34px;
                width: 10px;
            }
        }
    }
    &--phone-small,
    &--phone {
        #{$self}__cell {
            padding-right: 0px;
            min-width: 0;
            width: var(--w, 10px);
            &:last-child {
                padding-right: 48px;
            }
        }
    }
    &--phone-small {
        #{$self}__cell {
            padding-top: 18px;
            height: 82px;
        }
        #{$self}__row + #{$self}__row {
                #{$self}__cell {
                    padding-top: 18px;
                    height: 86px;
                }
        }
    }
    &--history {
        #{$self}__cell {
            height: 81px;
            padding-right: 0;
            min-width: 0;
            width: var(--w, 10px);
            &:first-child {
                padding-left: 32px;
                padding-right: 57px;
            }
            &:last-child {
                padding-right: 32px;
            }
        }
        #{$self}__row--stacked #{$self}__cell {
            &:first-child:before {
                border-bottom-left-radius: 0;
            }
            &:last-child:before {
                border-bottom-right-radius: 0;
            }
        }
        #{$self}__row-body {
            #{$self}__cell {
                height: 81px;
                padding-top: 28px;
                &:before {
                    height: calc(100% - 4px);
                    border-top: 1px solid #E1E1E1;
                }
                &:first-child:before {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 12px;
                }
                &:last-child:before {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
}
.product-info-card {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 32px;
        margin-right: 12px;
        background: var(--color-white-bluesh);
        border-radius: 8px;
        padding: 5px;
        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
        }
    }
}
.product-info-container {
    padding-left: 48px;
    padding-right: 48px;
}
.product-info-button {
    padding-left: 32px;
    padding-right: 32px;
}
.product-info-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 24px;
}
.product-info-description {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    text-align: center;
}
</style>
