import Dialog from "@/components/modals/ConfirmModal.vue";
import i18n from "@/config/i18nSetup"
import store from "@/store";
import Vue from "vue";

class ConfirmController {
    call(title = "components.common.confirmDelete.title", message = "components.common.confirmDelete.description") {
        return new Promise((resolve, reject) => {
            const dialog = new Vue({
                store,
                i18n,
                methods: {
                    closeHandler(fn, arg) {
                        return function () {
                            fn(arg);
                            dialog.$destroy();
                            dialog.$el.remove();
                        };
                    }
                },
                render(h) {
                    const titleNode = h(
                        'div',
                        {},
                        i18n.t(title)
                    );
                    const messageNode = h(
                        'div',
                        {},
                        i18n.t(message)
                    );

                    return h(
                        Dialog,
                        {
                         on: {
                            action: this.closeHandler(resolve),
                            "close-modal": this.closeHandler(reject)
                         },
                         scopedSlots: {
                             title: () => titleNode,
                             text: () => messageNode,
                         }
                        },
                        [ titleNode, messageNode ]
                    );
                }
            })

            document.body.appendChild(dialog.$mount().$el);
        })
    }
}

export default ConfirmController

