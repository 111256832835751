const deposits = {
    "1": {
        "name": "Аванс",
        "terms": [
            6,
            12
        ],
        "UZS": [{
            "6": 17,
            "12": 19
        }],
        "USD": [{
            "6": 3,
            "12": 5
        }],
        "EUR": [{
            "6": 1,
            "12": 3
        }]
    },
    "2": {
        "name": "Максимал фойда",
        "terms": [
            1,
            2,
            3,
            6,
            9,
            12,
            18,
            24
        ],
        "UZS": [{
            "1": 14,
            "2": 15,
            "3": 16,
            "6": 17,
            "9": 18,
            "12": 19,
            "18": 20,
            "24": 21
        }],
        "USD": [{
            "1": 2,
            "2": 2,
            "3": 3,
            "6": 3.5,
            "9": 4,
            "12": 5,
            "18": 5.1,
            "19": 5,
            "24": 5.2,
        }],
        "EUR": [{
            "1": 1,
            "2": 1,
            "3": 1,
            "6": 1.5,
            "9": 2,
            "12": 3,
            "18": 3.5,
            "24": 3.5,

        }]
    },
    "3": {
        "name": "Online",
        "terms": [
            13
        ],
        "UZS": [{
            "13": 19
        }],
        "USD": [{
            "13": 5
        }],
        "EUR": [{
            "13": 3
        }]
    }
}
const minDeposits = [{
        'UZS': 1000000,
        'USD': 100,
        'EUR': 100

    },
    {
        'UZS': 500000,
        'USD': 50,
        'EUR': 50

    },
    {
        'UZS': 3000000,
        'USD': 300,
        'EUR': 300

    }
]
const depositsName = [{
        name: 'Аванс',
        id: 1
    },
    {
        name: 'Максимал фойда',
        id: 2
    },
    {
        name: 'On-line',
        id: 3
    },

]
const depositsCurrency = ['UZS', 'USD', 'EUR']

const DEPOSIT_DIRECTORY_LIST = [
    {
        value: "UZS",
        title: "UZS"
    },
    {
        value: "USD",
        title: "USD"
    },
    {
        value: "EUR",
        title: "EUR"
    },
]

export { deposits, depositsName, depositsCurrency, minDeposits, DEPOSIT_DIRECTORY_LIST }
