<template>
    <div class="auto-debit-general-stats-table">
        <div class="auto-debit-general-stats-table__header">
            <div class="auto-debit-general-stats-table__heading">
                {{ $t("common.autoDebitHistory") }}
            </div>
            <div
                v-if="dateRange"
                class="auto-debit-general-stats-table__header-actions"
            >
                <div class="auto-debit-general-stats-table__datepicker">
                    <AppDatePicker
                        :value="dateRange.start"
                        value-format="DD.MM.YYYY"
                        size="extra-large"
                        :allow-clear="false"
                        @change="onStartDateChange"
                    />
                    <span>-</span>
                    <AppDatePicker
                        :value="dateRange.end"
                        value-format="DD.MM.YYYY"
                        size="extra-large"
                        :allow-clear="false"
                        @change="onEndDateChange"
                    />
                </div>
                <AppButton @click="onFilterSubmit">
                    {{ $t("components.common.apply") }}
                </AppButton>
            </div>
        </div>
        <AppTable
            class="auto-debit-general-stats-table__table"
            :class="tableCssClass"
            :head="tableHeaders"
            :data="tableItems"
            theme="admin-page"
        >
            <template #cell.autoDebit="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ getFormattedMoney(item.auto_pogoshenie) }}
                </span>
            </template>
            <template #cell.autoPay="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span v-else>
                    {{ getFormattedMoney(item.auto_pay) }}
                </span>
            </template>
            <template #cell.creditId="{ item }">
                <div
                    v-if="loading"
                    class="skeleton"
                    :style="{ height: '20px' }"
                />
                <span
                    v-else
                    :class="{
                        'auto-debit-general-stats-table__link':
                            loanIds.includes(item.loan_id),
                    }"
                    @click="onIdClick(item.loan_id)"
                >
                    {{ item.loan_id }}
                </span>
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from "../partials/AppTable.vue";
import AppDatePicker from "../partials/AppDatePicker.vue";
import AppButton from "../partials/AppButton.vue";

export default {
    name: "AutoDebitGeneralStatsTable",

    components: {
        AppTable,
        AppDatePicker,
        AppButton,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },

        loading: {
            type: Boolean,
        },

        dateRange: {
            type: Object,
        },

        loanIds: {
            type: Array,
        },

        isCall: {
            type: Boolean,
        },
    },

    data: () => ({}),

    methods: {
        getFormattedMoney(val) {
            if (val) {
                return Intl.NumberFormat("ru", {
                    maximumFractionDigits: 0,
                }).format(val);
            }
            return "-";
        },

        onStartDateChange(_, val) {
            this.$emit("startDate", val);
        },

        onEndDateChange(_, val) {
            this.$emit("endDate", val);
        },

        onFilterSubmit() {
            this.$emit("update");
        },

        onIdClick(id) {
            if (this.loanIds.includes(item.loan_id)) {
                this.$emit("loanId", id);
            }
        },
    },

    computed: {
        tableHeaders() {
            return [
                [
                    {
                        name: "common.table.autoDebitService",
                        key: "autoDebit",
                    },
                    {
                        name: "common.table.serviceAutoPay",
                        key: "autoPay",
                    },
                    {
                        name: "common.table.creditId",
                        key: "creditId",
                    },
                ],
            ];
        },

        tableItems() {
            if (this.loading) {
                const list = [];
                for (let index = 0; index < 20; index++) {
                    list.push({ id: index });
                }
                return list;
            }

            return this.items;
        },

        tableCssClass() {
            return {
                "call-height": this.isCall,
            };
        },
    },
};
</script>

<style lang="scss">
.auto-debit-general-stats-table__header {
    margin-bottom: 24px;
}

.auto-debit-general-stats-table__heading {
    font-size: 28px;
    font-weight: 500;
}

.auto-debit-general-stats-table__header-actions {
    margin-top: 24px;
    gap: 24px;

    display: flex;
    align-items: center;
}

.auto-debit-general-stats-table__datepicker {
    display: flex;
    align-items: center;
    gap: 12px;

    .app-datepicker {
        width: 278px;
    }
}

.auto-debit-general-stats-table__link {
    color: var(--color-primary);
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}

.auto-debit-general-stats-table__table {
    background: white;
    height: calc(100vh - 479px);

    &.call-height {
        height: calc(100vh - 570px);
    }

    th {
        background: #edf2ff;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
    }
}
</style>
