import {post} from "../helpers/api";
import {activeOperationsCodes} from "../store/modules/activeOperations";

const { pin, block, unblock, sms, nosms } = activeOperationsCodes;
const ACTION_SUBMIT_EVENT_NAME = 'active-operations-submit';
const IDENTIFICATION_REQUEST_EVENT_NAME = 'identification-request';

// Все методы и внутренние свойства миксина записываются с префиксом activeOperations во избежание коллизии имен
const activeOperationsMixin = {
    props: {
        card: {
            type: Object,
            default: () => ({})
        },
        userData: {
            type: Object,
            default: () => ({})
        },
        identificationData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            activeOperationsCodes,
            activeOperationsModel: {
                reason: '',
                comment: '',
                selected: false,
                phone: '',
                smsState: 'on'
            },
            activeOperationsErrors: {
                reason: null,
                comment: null,
                selected: null,
                phone: null
            },
            activeOperationsLoading: false,
            activeOperationsType_: null, // Где-то может быть динамический тип операций
            activeOperationsSent: false
        }
    },
    computed: {
        activeOperationsEnabled() {
            if(!this.activeOperationsType) return false;
            return this.activeOperationsType in this.activeOperationsCardActions[this.card.typeName.toLowerCase()];
        },
        activeOperationsType() { // Переопределяется для динамического типа операций
            return this.activeOperationsType_;
        },
        activeOperationsCardActions() {
            const uzcard = this.$store.state.activeOperations.uzcard.actions ?? {},
                  humo = this.$store.state.activeOperations.humo.actions ?? {},
                  visa = this.$store.state.activeOperations.visa.actions ?? {},
                  upi =  this.$store.state.activeOperations.upi.actions ?? {},
                  mastercard = this.$store.state.activeOperations.mastercard.actions ?? {}

            if(!('requestResolved' in this.identificationData)) {
                return {
                    uzcard: {
                        [block]: uzcard[block]
                    },
                    humo: {
                        [block]: humo[block]
                    },
                    visa: {
                        [block]: visa[block]
                    },
                    upi: {
                        [block]: upi[block]
                    },
                    mastercard: {
                        [block]: mastercard[block]
                    }
                }
            }
            return {
                uzcard,
                humo,
                mastercard,
                visa,
                upi
            }
        },
    },
    methods: {
        activeOperationsOnInput(key, isError = null) {
            this.$set(this.activeOperationsErrors, key, isError);
        },
        activeOperationsGetData() {
            let data = {
                processing: this.card.typeName,
                cardNumber: this.card.cardNumber,
                valid: (this.card.cardValid || this.card.dateExpiry).replace('/', ''),
                customer_id: this.userData.id,
                identification_id: this.identificationData.requestId,
                action: String(this.activeOperationsCardActions[this.card.typeName][this.activeOperationsType])
            };

            switch(this.activeOperationsType) {
                case block:
                    delete data.identification_id;
                    data.comment = this.activeOperationsModel.comment;
                    data.reason = this.activeOperationsModel.reason;
                    break;
                case unblock:
                    data.comment = this.activeOperationsModel.comment;
                    break;
                case sms:
                    data.phoneNumber = '+998' + this.activeOperationsModel.phone.replaceAll(' ', '');
                    break;
                case nosms:
                    data.phoneNumber = this.card.phone;
            }

            return data;
        },
        activeOperationsCorrect() {
            const type = this.activeOperationsType;
            let errors = {};
            if(block === type) {
                errors = {
                    reason: '',
                    comment: '',
                    selected: false,
                };
            } else if(unblock === type) {
                errors = {
                    comment: '',
                    selected: false,
                };
            } else if(sms === type) {
                this.activeOperationsErrors.phone = this.activeOperationsModel.phone.length < 9
                if(this.activeOperationsErrors.phone) return false;
            }

            let correct = true;
            for(let key in errors) {
                if(!this.activeOperationsModel[key]) {
                    this.activeOperationsOnInput(key, true);
                    correct = false;
                }
            }

            return correct;
        },
        activeOperationsCheckIdentification(type) {
            const resolved = this.identificationData.requestResolved;
            return resolved || type === block;
        },
        activeOperationsSubmit() {
            if(!this.activeOperationsEnabled) return;

            if(!this.activeOperationsCheckIdentification(this.activeOperationsType)) {
                this.$emit(IDENTIFICATION_REQUEST_EVENT_NAME, true);
                return;
            }
            if(this.activeOperationsCorrect()) {
                this.activeOperationsLoading = true;
                return post('/card-action/run-actions', this.activeOperationsGetData())
                    .then(({data}) => {
                        if(data.success) {
                            this.$emit(ACTION_SUBMIT_EVENT_NAME, {
                                success: data.success
                            });
                            this.activeOperationsSent = true;
                        } else if(data.error === 'IDENTIFICATION') {
                            this.$emit(IDENTIFICATION_REQUEST_EVENT_NAME, true);
                        } else {
                            this.$emit(ACTION_SUBMIT_EVENT_NAME, {
                                success: false,
                                reason: data.error
                            });
                        }
                    })
                    .catch(e => {
                        this.$emit(ACTION_SUBMIT_EVENT_NAME, {
                            success: false
                        });
                    })
                    .finally(() => {
                        this.activeOperationsLoading = false;
                    })
            }
        }
    }
}
export default activeOperationsMixin;
